import React from 'react';
import ErrorCard from '@components/ErrorCard';
import PageTitle from '@components/app/PageTitle';
import { Button, Typography } from '@material-tailwind/react';
import FullPageLoader from '@components/FullPageLoader';
import { setIsFavoriteEstablishment, useEstablishments } from '@lib/Establishment';
import Card from '@components/Card';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import { MapIcon } from '@heroicons/react/24/solid';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';

function EstablishmentCard({
  establishment,
  mutate,
  isValidating,
}: {
    establishment: any;
    mutate: () => Promise<any>;
    isValidating: boolean;
}): React.ReactElement {
  /**
   * Set favorite
   * @param establishmentID
   * @param isFav
   */
  const setFavorite = async (
    establishmentID: number,
    isFav: boolean,
  ) => setIsFavoriteEstablishment(
    establishmentID, isFav,
  ).then(async () => mutate());

  /**
   * Handle set favorite
   * @param establishmentID
   * @param isFav
   */
  const handleSetFavorite = async (
    establishmentID: number,
    isFav: boolean,
  ) => {
    let successMessage;
    let errorMessage;
    let loadingMessage;

    if (isFav) {
      successMessage = 'Ajouté aux favoris !';
      errorMessage = 'Impossible d\'ajouter aux favoris';
      loadingMessage = 'Ajout en cours...';
    } else {
      successMessage = 'Retiré des favoris !';
      errorMessage = 'Impossible de retirer des favoris';
      loadingMessage = 'Retrait en cours...';
    }

    return toast.promise(
      setFavorite(
        establishmentID, isFav,
      ), {
        loading: loadingMessage,
        success: successMessage,
        error: errorMessage,
      },
    );
  };

  return (
    <Card className="shadow h-full flex flex-col p-5">
      <div className="mb-5">
        <div>
          <div className="flex justify-between">
            <h3 className="text-lg font-bold flex-1">{establishment.name}</h3>
            <Typography
              color="blue-gray"
              className="flex items-center gap-1.5 font-normal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className={classNames(
                  '-mt-0.5 h-5 w-5 ',
                  // @ts-ignore
                  establishment.rating ? 'text-yellow-700' : 'text-gray-400',
                )}
              >
                <path
                  fillRule="evenodd"
                  d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                  clipRule="evenodd"
                />
              </svg>
              {/* @ts-ignore */}
              {establishment.rating || 'Aucune note'}
            </Typography>
          </div>
          <p className="text-xs">{establishment.description}</p>
        </div>
      </div>
      <div className="mb-3 mt-auto">
        <div>
          <a
            className="text-sm text-primary"
            href={establishment.website}
            target="_blank"
            rel="noreferrer"
          >
            {establishment.website}
          </a>
          <br />
          <a
            className="text-sm text-primary"
            href={`tel:${establishment.phone}`}
            target="_blank"
            rel="noreferrer"
          >
            {establishment.phone.replace(
              /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5',
            )}
          </a>
        </div>
        <div>
          <div className="flex items-center gap-2">
            <MapIcon className="text-primary w-5 h-5" />
            <Typography color="gray" className="text-sm">
              {
                  // @ts-ignore
                  establishment?.name
                }
              ,
              {' '}
              {/* @ts-ignore */}
              {establishment?.address?.city}
              {' '}
              {/* @ts-ignore */}
              {establishment?.address?.zip}
            </Typography>
          </div>
        </div>
        <div>
          <div className="flex items-center gap-2">
            <BuildingOffice2Icon className="text-primary w-5 h-5" />
            <Typography color="gray" className="text-sm">
              {
                  // @ts-ignore
                  establishment?.establishment_sector.name
                }
            </Typography>
          </div>
        </div>
      </div>
      <Button
        disabled={isValidating}
        className={classNames(
          // @ts-ignore
          'bg-primary', establishment.is_favorite && 'bg-yellow-700',
        )}
        onClick={async () => handleSetFavorite(
          //   @ts-ignore
          establishment.id, !establishment.is_favorite,
        )}
      >
        {
            // @ts-ignore
            establishment.is_favorite === true ? 'Retirer des favoris' : 'Ajouter aux favoris'
          }
      </Button>
    </Card>
  );
}

export default function EstablishmentsListPage(): React.ReactElement {
  const [city, setCity] = React.useState('');
  const {
    data: establishments,
    error: establishmentsError,
    mutate,
    isValidating,
  } = useEstablishments();

  const successCallback = (position: GeolocationPosition): void => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${lng}&lat=${lat}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const strippedZip = data.features[0].properties.postcode.substring(
          0, 2,
        );
        setCity(`${data.features[0].properties.city} (${strippedZip})`);
      });
  };

  const errorCallback = (geolocationError: GeolocationPositionError): void => {
    // eslint-disable-next-line no-console
    console.error(
      'geolocation error', geolocationError,
    );
  };

  React.useEffect(
    () => {
      navigator?.geolocation?.getCurrentPosition(
        successCallback, errorCallback,
      );
    }, [city, establishments],
  );

  if (establishmentsError) {
    return (
      <ErrorCard message="Impossible de charger vos favoris" />
    );
  }

  if (!establishments) {
    return <FullPageLoader />;
  }

  const aroundMeEstablishments = establishments?.filter((establishment) => {
    const strippedZip = city.substring(
      city.length - 3, city.length - 1,
    );
    // @ts-ignore
    const missionZip = establishment.address.zip.toString();
    return missionZip.includes(strippedZip);
  });

  // Filter out around me establishments in main list
  const filteredEstablishments = establishments?.filter((establishment) => {
    const strippedZip = city.substring(
      city.length - 3, city.length - 1,
    );
    // @ts-ignore
    const missionZip = establishment.address.zip.toString();
    return !missionZip.includes(strippedZip);
  });

  return (
    <>
      <PageTitle title="Les Etablissements" />

      <div className="px-5">

        <section className="mb-5">
          <div className="mb-5">
            <h2 className="text-2xl font-bold mb-5">Autour de moi</h2>
            <div>
              <Typography color="gray" className="text-sm">
                {city}
              </Typography>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {aroundMeEstablishments?.map((establishment) => (
              <EstablishmentCard
                establishment={establishment}
                mutate={mutate}
                isValidating={isValidating}
              />
            ))}
          </div>
        </section>

        <section className="mb-5">
          <div className="mb-5">
            <h2 className="text-2xl font-bold mb-5">En France</h2>
          </div>

          <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {filteredEstablishments?.map((establishment) => (
              <EstablishmentCard
                establishment={establishment}
                mutate={mutate}
                isValidating={isValidating}
              />
            ))}
          </div>
        </section>

        {establishments && establishments.length === 0 && (
        <Typography className="text-2xl font-bold mb-5">
          Il n&apos;y a pas d&apos;établissement enregistré.
        </Typography>
        )}
      </div>

    </>

  );
}
