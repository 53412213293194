import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import type React from 'react';

interface ActiveLinkProps
{
  children: React.ReactNode;
  className?: string;
  to: string;
  as?: string;
  target?: string;
  title?: string;
  onClick?: () => void;
  activeAction?: boolean;
}

/**
 * @param {React.ReactNode} children
 * @param {string} className
 * @param {string} to
 * @param {string} as
 * @param {string} target
 * @param {string} title
 * @param {() => void} onClick
 */
export default function ActiveLink({
  children,
  className,
  to,
  as,
  target,
  title,
  onClick,
  activeAction,
}: ActiveLinkProps): React.ReactElement {
  const location = useLocation();
  const { pathname } = location;
  const isSamePath = pathname === to || pathname === as;
  const isSubPage = pathname.startsWith(to) && to !== '/';
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
    navigate(to);
  };

  return (
    <Link
      target={target}
      to={to}
      title={title}
      onClick={handleClick}
      className={classNames(
        className, isSamePath && activeAction && 'text-primary border-primary border rounded-full', isSubPage && activeAction && 'text-primary border-primary border rounded-full',
      )}
    >
      {children}
    </Link>
  );
}

ActiveLink.defaultProps = {
  className: '',
  as: '',
  target: '',
  onClick: () => {},
  title: '',
  activeAction: false,
};
