import { Address } from '@/custom-types/Address';
import Supabase from '@lib/Supabase';
import useSWR from 'swr';

/**
 * Get the address of a profile
 * @param profileID
 */
export const getAddress = async (profileID: number) => {
  const { data, error } = await Supabase
    .from('profile')
    .select(`
        id,
        address_id,
        address:address_id (
          id,
          first_line,
          second_line,
          city,
          zip,
          country
        )
        `)
    .eq(
      'id', profileID,
    )
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

/**
 * Get real-time address of a profile
 * @param profileID
 */
export const useAddress = (profileID: number) => useSWR(
  ['address', profileID], () => getAddress(profileID),
);

/**
 * Set the address of a profile
 * @param profileID
 * @param addressData
 */
export const setAddress = async (
  profileID: number, addressData: Address,
) => {
  try {
    const {
      address: currentAddress,
    } = await getAddress(profileID);

    if (currentAddress) {
      const { data, error } = await Supabase
        .from('address')
        .update(addressData)
        .eq(
          // @ts-ignore
          'id', currentAddress[0].address.id,
        );

      if (error) {
        throw new Error(error.message);
      }

      return { data, error };
    }

    const { data, error } = await Supabase
      .from('address')
      .insert(addressData);

    if (error) {
      throw new Error(error.message);
    }

    // Link the address to the profile
    const { error: updateProfileError } = await Supabase
      .from('profile')
    // @ts-ignore
      .update({ address_id: data?.[0]?.id })
      .eq(
        'id', profileID,
      );

    if (updateProfileError) {
      throw new Error(updateProfileError.message);
    }

    return { data, error };
  } catch (error) {
    throw new Error(error.message);
  }
};

/**
 * Set the address of a profile
 * @param profileID
 * @param addressData
 */
export const setAddressByID = async (addressData: Address) => {
  const { data, error } = await Supabase
    .from('address')
    .update(addressData)
    .eq(
      'id', addressData.id,
    );

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

/**
 * Create address
 * @param addressData the address to create
 * @param profileID the profile to link the address to
 */
export const createAddress = async (
  profileID: string,
  addressData: Address,
) => {
  if (addressData === undefined || addressData === null) {
    throw new Error('No data provided');
  }

  const { data, error } = await Supabase
    .from('address')
    .insert({
      first_line: addressData.first_line,
      second_line: addressData.second_line,
      city: addressData.city,
      zip: addressData.zip,
      country: 'FRANCE',
    })
    .select('*')
    .single();

  if (error) {
    throw new Error(error.message);
  }

  const { error: updateProfileError } = await Supabase
    .from('profile')
    .update({ address_id: data?.id })
    .eq(
      'id', profileID,
    );

  if (updateProfileError) {
    throw new Error(updateProfileError.message);
  }

  return data;
};

/**
 * Create address
 * @param addressData the address to create
 */
export const createAddressAlone = async (addressData: {
  first_line: string,
  second_line: string,
  city: string,
  zip: string,
}) => {
  if (addressData === undefined || addressData === null) {
    throw new Error('No data provided');
  }

  const { data, error } = await Supabase
    .from('address')
    .insert({
      first_line: addressData.first_line,
      second_line: addressData.second_line,
      city: addressData.city,
      zip: addressData.zip,
      country: 'FRANCE',
    })
    .select('*')
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};
