import { useLocation } from 'react-router-dom';
import ActiveLink from '@/components/app/ActiveLink';
import React from 'react';
import
{
  ChevronLeftIcon,
  BellIcon,
  ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import { Badge, Button } from '@material-tailwind/react';
import useProfile from '@lib/Profile';
import { useCountUnreadNotifications } from '@lib/Notification';

/**
 *
 */
export default function PageTitle({
  title,
}: {
  title: string
}): React.ReactElement {
  const [showBackButton, setShowBackButton] = React.useState(false);
  const [backButtonLink, setBackButtonLink] = React.useState('');
  const [strippedPath, setStrippedPath] = React.useState('');
  const location = useLocation();
  const { pathname } = location;
  const { data: profile, error: profileError } = useProfile();
  const { data: count } = useCountUnreadNotifications();

  React.useEffect(
    () => {
      const isNested = pathname.split('/').length > 2;

      if (isNested) {
        setShowBackButton(true);
        setBackButtonLink(pathname.split('/').slice(
          0, -1,
        ).join('/'));
      }

      const strippedPathToSet = pathname.split('/').slice(1).join('/');
      setStrippedPath(strippedPathToSet);

      window.document.title = `${import.meta.env.VITE_APP_NAME} - ${title}`;
    }, [title, pathname, strippedPath],
  );

  if (profileError || !profile || count === undefined) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div className="flex items-center w-full px-5 py-3 bg-base-100 lg:bg-base-100 mb-5 rounded-br-2xl rounded-bl-2xl">
      {showBackButton && (
        <ActiveLink title="Retour en arrière" to={backButtonLink}>
          <Button size="sm" className="bg-primary flex items-center px-1 py-1 mr-3">
            <ChevronLeftIcon strokeWidth={3} className="w-7 h-7 lg:w-5 lg:h-5 inline-block" />
            <span className="hidden lg:block pr-2">Retour</span>
          </Button>
        </ActiveLink>
      )}
      <div className="text-lg font-semibold breadcrumbs w-full overflow-visible text-neutral flex">
        <li className="font-semibold flex justify-between w-full">
          {title}
          <div className="flex items-center">
            {/* @ts-ignore */}
            {profile?.role.code !== 'admin' && (
              <ActiveLink title="Notifications" to="/chat" className="lg:hidden flex items-center">
                <ChatBubbleLeftEllipsisIcon className="h-7 w-7 mr-2" />
              </ActiveLink>
            )}

            <ActiveLink title="Notifications" to="/notifications" className="lg:hidden flex items-center">
              {count !== 0 ? (
                <Badge>
                  <BellIcon className="h-7 w-7" />
                </Badge>
              ) : (
                <BellIcon className="h-7 w-7" />
              )}
            </ActiveLink>
          </div>
        </li>
      </div>
    </div>
  );
}
