import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import {
  Card,
  CardHeader,
  Input,
  Typography,
  CardBody,
} from '@material-tailwind/react';
import FullPageLoader from '@/components/FullPageLoader';
import Chip from '@/components/Chip';
import ErrorCard from '@/components/ErrorCard';
import { User } from '@/custom-types/User';
import { useOfferCandidates } from '@lib/Offer';
import { useParams } from 'react-router-dom';

const TABLE_HEAD = [
  'ID',
  'Nom',
  'Age',
  'Statut',
  'Date de Création',
];

function TableRows({
  users,
}: {
    users: User[];
}) {
  return (
    <tbody>
      {/* @ts-ignore */}
      {users && users.length > 0 && users?.map((
        {
          id,
          // @ts-ignore
          customID,
          // eslint-disable-next-line camelcase
          profile,
          // @ts-ignore
          retained,
        }, index,
      ) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const isLast = index === users?.length - 1;
        const classes = isLast
          ? 'p-4'
          : 'p-4 border-b border-blue-gray-50';

        return (
          <tr key={id}>

            {/* ID */}
            <td className={classes}>
              {customID}
            </td>

            {/* Name & Email.ts */}
            <td className={classes}>
              <div className="flex items-center gap-3">
                <div className="flex gap-2">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                  >
                    {profile?.first_name}
                    {' '}
                    {profile?.last_name}
                  </Typography>
                  {retained === true && (
                    <Chip value="Retenu" variant="success" />
                  )}
                  {retained === false && (
                    <Chip value="Refusé" variant="danger" />
                  )}
                </div>
              </div>
            </td>

            <td className={classes}>
              <Chip
                value={profile?.birth_date ? `${new Date().getFullYear() - new Date(profile?.birth_date).getFullYear()} ans` : 'Non renseigné'}
                variant={profile?.role?.name ? 'primary' : 'secondary'}
              />
            </td>

            <td className={classes}>
              <Chip
                value={
                      // @ts-ignore
                      profile?.validated === true ? 'Confirmé' : 'Non confirmé'
                    }
                variant={
                      // @ts-ignore
                      profile?.validated === true ? 'success' : 'danger'
                    }
              />
            </td>

            {/* Created At */}
            <td className={classes}>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {/* eslint-disable-next-line camelcase */}
                {profile?.created_at && new Date(profile?.created_at).toLocaleDateString(
                  'fr-FR', { year: 'numeric', month: 'long', day: 'numeric' },
                )}
              </Typography>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}

export default function AdminOfferCandidatesPage() {
  const [filteredUsers, setFilteredUsers] = React.useState(null);

  const params = useParams();

  const { data: users, error, mutate } = useOfferCandidates(params.id as unknown as number);

  if (error) {
    // @ts-ignore
    return <ErrorCard message={error.message} />;
  }

  if (!users) {
    return <FullPageLoader />;
  }

  // Add a custom ID to each user because the UUID is not user-friendly
  let newID = 1;
  users.forEach((user) => {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    user.customID = newID;
    newID += 1;
  });

  /**
   * Search for a user by name or email
   * @param searchTerm
   */
  const searchUser = (searchTerm: string) => {
    if (!searchTerm) {
      setFilteredUsers(null);
      return;
    }

    // @ts-ignore
    const matchedUsers = users.filter((user: User) => {
      const fullName = `${user?.profile?.first_name} ${user?.profile?.last_name}`;
      const email = user?.email;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase())
                || email.toLowerCase().includes(searchTerm.toLowerCase());
    });

    // @ts-ignore
    setFilteredUsers(matchedUsers);
  };

  return (
    <>

      {/* User deletion confirmation modal */}
      <Card className="h-full w-full">

        {/* Header w/ Search */}
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" color="blue-gray">
                Candidats pour l&apos;offre N°
                {params.id}
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                {users.length}
                {' '}
                candidat
                {users.length > 1 && 's'}
              </Typography>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <div className="w-full md:w-72">
              <Input
                type="search"
                crossOrigin="true"
                label="Rechercher"
                onChange={(e) => searchUser(e.target.value)}
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </CardHeader>

        {/* Table */}
        <CardBody className="overflow-scroll px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((
                  head, index,
                ) => (
                  <th
                    key={head}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {head}
                      {' '}
                      {index !== TABLE_HEAD.length - 1 && (
                        <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <TableRows
                // @ts-ignore
              users={filteredUsers || users}
              mutate={mutate}
            />
          </table>
        </CardBody>
      </Card>
    </>
  );
}
