import React from 'react';
import toast from 'react-hot-toast';
import {
  Input, Button,
} from '@material-tailwind/react';
import { createEducation } from '@lib/Education';

export default function CreateEducation({
  setOpen,
  mutate,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mutate: any;
}): React.ReactElement {
  const [updatingInfo, setUpdatingInfo] = React.useState(false);

  const [formData, setFormData] = React.useState({
    title: '',
    description: '',
    school: '',
    city: '',
    start_date: '',
    end_date: '',
  });

  const handleCreateEducation = async () => createEducation({
    title: formData.title,
    description: formData.description,
    school: formData.school,
    city: formData.city,
    start_date: formData.start_date,
    end_date: formData.end_date,
  });

  const handleCreation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUpdatingInfo(true);

    await toast.promise(
      handleCreateEducation(),
      {
        loading: 'Création de l\'éducation...',
        success: 'Éducation créée avec succès !',
        error: 'Une erreur est survenue lors de la création de l\'éducation',
      },
    ).then(() => {
      setUpdatingInfo(false);
      setOpen(false);
      mutate();
    })
      .catch(() => {
        setUpdatingInfo(false);
        mutate();
      });
  };

  return (
    <form onSubmit={handleCreation}>
      <fieldset className="flex flex-col gap-4" disabled={updatingInfo}>
        <Input
          crossOrigin={undefined}
          type="text"
          label="Titre"
          name="title"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          required
        />
        <Input
          crossOrigin={undefined}
          type="text"
          label="École"
          name="school"
          value={formData.school}
          onChange={(e) => setFormData({ ...formData, school: e.target.value })}
          required
        />
        <Input
          crossOrigin={undefined}
          type="text"
          label="Ville"
          name="city"
          value={formData.city}
          onChange={(e) => setFormData({ ...formData, city: e.target.value })}
          required
        />
        <Input
          crossOrigin={undefined}
          type="date"
          label="Date de début"
          name="start_date"
          value={formData.start_date}
          onChange={(e) => setFormData({ ...formData, start_date: e.target.value })}
          required
        />
        <Input
          crossOrigin={undefined}
          type="date"
          label="Date de fin"
          name="end_date"
          value={formData.end_date}
          onChange={(e) => setFormData({ ...formData, end_date: e.target.value })}
        />
        <p className="text-sm text-gray-500">
          Si vous êtes toujours en cours d&apos;études, laissez la date de fin vide.
        </p>
        <Button type="submit" className="bg-primary">
          Ajouter
        </Button>
      </fieldset>
    </form>
  );
}
