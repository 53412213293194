import
{
  CalendarIcon as SolidCalendarIcon,
  HomeIcon as SolidHomeIcon,
  UserIcon as SolidUserIcon,
  BuildingOfficeIcon as SolidBuildingOfficeIcon,
} from '@heroicons/react/24/solid';

import
{
  BuildingOfficeIcon,
  CalendarIcon as OutlineCalendarIcon,
  HomeIcon as OutlineHomeIcon,
  UserIcon as OutlineUserIcon,
} from '@heroicons/react/24/outline';

export const navigation = [
  {
    title: 'Accueil',
    href: '/',
    ActiveIcon: SolidHomeIcon,
    Icon: OutlineHomeIcon,
  },
  {
    title: 'Agenda',
    href: '/agenda',
    ActiveIcon: SolidCalendarIcon,
    Icon: OutlineCalendarIcon,
  },
  {
    title: 'Etablissements',
    href: '/establishments',
    ActiveIcon: SolidBuildingOfficeIcon,
    Icon: BuildingOfficeIcon,
  },
  {
    title: 'Profil',
    href: '/profile',
    ActiveIcon: SolidUserIcon,
    Icon: OutlineUserIcon,
  },
];

export const userNavigation = [
  {
    title: 'Profil',
    href: '/profile',
    ActiveIcon: SolidUserIcon,
    Icon: OutlineUserIcon,
  },
];
