import React from 'react';

const TitleContext = React.createContext({
  title: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setTitle: (title: string) => {},
});

export const useTitle = () => {
  const title = React.useContext(TitleContext);

  if (!title) {
    throw new Error('useTitle has to be used within itleContext.Provider Component.');
  }

  return title;
};

export default TitleContext;
