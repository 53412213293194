import React from 'react';
import { useSignalments } from '@lib/signalment';
import ErrorCard from '@components/ErrorCard';
import FullPageLoader from '@components/FullPageLoader';
import Supabase from '@lib/Supabase';
import toast from 'react-hot-toast';
import { Button } from '@material-tailwind/react';

export default function SignalmentsPage() :React.ReactElement {
  const { data, error, mutate } = useSignalments();

  if (error) {
    return <ErrorCard message={error.message} />;
  }

  if (!data) {
    return <FullPageLoader />;
  }

  const deleteSignalment = async (id: number) => Supabase
    .from('signalment')
    .delete()
    .eq(
      'id', id,
    ).then(() => mutate());

  const handleDelete = async (id: number) => toast.promise(
    deleteSignalment(id), {
      loading: 'Suppression en cours...',
      success: 'Signalment supprimé',
      error: 'Une erreur est survenue',
    },
  );

  return (
    <div>
      <h1 className="text-xl font-semibold">Signalements</h1>
      <section>
        {data.map((signalment) => (
          <div key={signalment.id} className="p-5 border mb-5 rounded-lg flex items-center justify-between">
            <div>
              <p className="text-base font-semibold">
                L&apos;établissement
                {' '}
                {
                    // @ts-ignore
                    signalment.establishment.name
}
                {' '}
                a signalé un problème
                avec
                {' '}
                {
                    // @ts-ignore
                    signalment.candidate.first_name
}
                {' '}
                {
                    // @ts-ignore
                    signalment.candidate.last_name
}
              </p>
              <p className="text-base">
                Objet:
                {' '}
                {signalment.message}
              </p>
            </div>
            <div>
              <Button
                className="bg-error"
                onClick={() => handleDelete(signalment.id)}
              >
                Supprimer
              </Button>
            </div>
          </div>
        ))}
        {data.length === 0 && (
        <p className="text-base font-semibold">
          Aucun signalement.
        </p>
        )}
      </section>
    </div>
  );
}
