import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import AuthLayout from '@/layouts/AuthLayout';
import SignIn from '@pages/auth/sign-in/page';
import EmailVerified from '@/pages/auth/email-verified/page';
import AuthStatus from '@/pages/auth/status/page';
import Verify from '@/pages/auth/verify/page';
import VerifyEmail from '@/pages/auth/verify-email/page';
import Unauthorized from '@components/Unauthorized';
import { Toaster } from 'react-hot-toast';
import UserLayout from '@/layouts/UserLayout';
import Home from '@/pages/app/dashboard/page';
import FullPageLoader from '@components/FullPageLoader';
import NotFound from '@/components/NotFound';
import { AuthContext, useAuth } from '@/context/Auth';
import Settings from '@/pages/app/settings/page';
import Agenda from '@/pages/app/agenda/page';
import NotificationsPage from '@/pages/app/notifications/page';
import UserProfile from '@/pages/app/profile/page';
import OnboardingLayout from '@/layouts/OnboardingLayout';
import ProfileCV from '@/pages/app/profile/cv/page';
import JobDetails from '@/pages/app/dashboard/details/page';
import AdminLayout from '@/layouts/AdminLayout';
import Dashboard from '@/pages/admin/dashboard/page';
import UserManagement from '@/pages/admin/users/page';
import NotFoundAdminPage from '@/pages/admin/not-found/page';
import ApplyPage from '@/pages/app/dashboard/details/apply/page';
import ApplyChatPage from '@/pages/app/dashboard/details/apply/chat/page';
import EstablishmentsPage from '@/pages/admin/establishments/page';
import OffersPage from '@/pages/admin/offers/page';
import OnboardingPage from '@/pages/onboarding/page';
import MissionsPage from '@/pages/app/profile/missions/page';
import useProfile from '@lib/Profile';
import ErrorCard from '@components/ErrorCard';
import EstablishmentDashboard from '@pages/establishment/dashboard';
import EstablishmentPage from '@pages/establishment/establishment/page';
import EstablishmentChatPage from '@pages/establishment/chat/page';
import UserChatList from '@/pages/app/chat/page';
import UserConversationChatPage from '@/pages/app/chat/chat/page';
import EstablishmentOffersPage from '@pages/establishment/offers/page';
import OfferCandidatesPage from '@pages/establishment/offers/candidates/page';
import EditEstablishmentPage from '@pages/establishment/establishment/edit/page';
import AdminJobTypesPage from '@pages/admin/job-types/page';
import { Button, ThemeProvider } from '@material-tailwind/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import { registerSW } from 'virtual:pwa-register';

import FavoritesPage from '@pages/app/favorites/page';
import SignalmentsPage from '@pages/admin/signalments/page';
import EstablishmentsListPage from '@pages/app/establishments/page';
import AdminOfferCandidatesPage from '@pages/admin/offers/candidates/page';

class ErrorBoundary extends React.Component {
  private readonly isDev: boolean;

  private readonly isProd: boolean;

  constructor(props: {
    children: React.ReactNode;
  }) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };

    const nodeEnv = process.env.NODE_ENV;

    this.isDev = nodeEnv === 'development';

    this.isProd = nodeEnv === 'production';
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(
    error: any,
    errorInfo: any,
  ) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    // Remove this file's stack trace from the error stack trace
    // eslint-disable-next-line no-param-reassign
    error.stack = error.stack
      .split('\n')
      .filter((line: string) => !line.includes('App.tsx'))
      .join('\n');

    // eslint-disable-next-line no-console
    console.error(
      error, errorInfo,
    );

    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    // @ts-ignore
    // eslint-disable-next-line react/destructuring-assignment
    if (this.isProd && this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          className="flex flex-col items-center justify-center h-screen lg:bg-primary"
          style={{
            backgroundImage: 'url(/images/sun-tornado.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <div
            className="p-10 bg-white lg:shadow-lg flex flex-col items-center justify-center h-full lg:h-auto rounded-none lg:rounded-lg w-full lg:w-auto"
          >
            <img src="/images/logo.svg" alt="Migration Logo" className="h-24 w-64" />
            <ErrorCard
              message="Nous sommes terriblement désolés pour le désagrément. Veuillez réessayer plus tard."
            />
            <div className="flex flex-col lg:flex-row gap-3 w-full mt-5">
              <Button
                className="w-full bg-primary"
                onClick={() => {
                  //   Set path to previous page & force hard reload
                  const currentPath = window.location.pathname;

                  window.location.pathname = currentPath.substring(
                    0, currentPath.lastIndexOf('/'),
                  );
                }}
              >
                Retourner en arrière
              </Button>
              <Button
                className="w-full bg-primary"
                onClick={() => window.location.reload()}
              >
                Recharger la
                page
              </Button>
            </div>
          </div>
        </div>
      );
    }

    // @ts-ignore
    // eslint-disable-next-line react/destructuring-assignment
    if (this.isDev && this.state.hasError) {
      return (
        <div
          className="flex flex-col items-center justify-center h-screen bg-white"
        >
          <div
            className="p-10 bg-white flex flex-col items-start justify-center h-full lg:h-auto rounded-none lg:rounded-lg w-full lg:w-auto"
          >
            <h1 className="text-2xl font-semibold">An error occurred.</h1>
            <h2 className="text-lg font-semibold text-error">
              {
                // @ts-ignore
                // eslint-disable-next-line react/destructuring-assignment
                this.state.error && this.state.error.toString()
              }
            </h2>
            <div>
              <h3>Stack trace:</h3>
              <SyntaxHighlighter
                className="rounded-xl shadow-lg"
                language="typescript"
                style={materialLight}
                showLineNumbers
                wrapLines
              >
                {
                // @ts-ignore
                // eslint-disable-next-line react/destructuring-assignment
                this.state.errorInfo?.componentStack
}
              </SyntaxHighlighter>
            </div>

            <div className="flex flex-col lg:flex-row gap-3 w-full mt-5">
              <Button
                className="w-full bg-error"
                onClick={() => window.location.reload()}
              >
                Reload
              </Button>
              <a
                href={
                  // @ts-ignore
                  // eslint-disable-next-line react/destructuring-assignment
                `https://stackoverflow.com/search?q=[reactjs]+${this.state.error?.toString()}`
}
                target="_blank"
                rel="noreferrer"
                className="w-full"
              >
                <Button
                  className="w-full bg-white border text-text"
                >
                  Search on StackOverflow
                </Button>
              </a>
            </div>
          </div>
        </div>
      );
    }

    // @ts-ignore
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    return this.props.children;
  }
}

export default function App(): React.ReactElement {
  const [
    showReload,
    setShowReload,
  ] = React.useState(false);
  const [
    waitingWorker,
    setWaitingWorker,
  ] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  if (showReload) {
    return (
      <div
        className="flex flex-col items-center justify-center h-screen lg:bg-primary"
        style={{
          backgroundImage: 'url(/images/sun-tornado.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div
          className="p-10 bg-white lg:shadow-lg flex flex-col items-center justify-center h-full lg:h-auto rounded-none lg:rounded-lg w-full lg:w-auto"
        >
          <img src="/images/logo.svg" alt="Migration Logo" className="h-24 w-64" />
          <ErrorCard
            message="Une nouvelle version est disponible."
          />
          <div className="flex flex-col lg:flex-row gap-3 w-full mt-5">
            <Button
              className="w-full bg-primary"
              onClick={() => {
                if (waitingWorker) {
                  waitingWorker.postMessage({ type: 'SKIP_WAITING' });
                }
                setShowReload(false);
                window.location.reload();
              }}
            >
              Mettre à jour
            </Button>
          </div>
        </div>
      </div>
    );
  }

  React.useEffect(
    () => {
      if ('serviceWorker' in navigator) {
        registerSW({
          onNeedRefresh: onSWUpdate,
        });
      }
    }, [],
  );

  const {
    session,
    setSession,
    status,
  } = useAuth();

  const {
    data: profile,
    error: profileError,
    isLoading: profileLoading,
  } = useProfile();

  if (!session) {
    return (
      <BrowserRouter>
        <ThemeProvider>

          <AuthContext.Provider
            // @ts-ignore
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{ session, setSession, status }}
          >
            <React.Suspense fallback={<FullPageLoader />}>
              <Routes>
                <Route
                  path="/"
                  element={<AuthLayout />}
                  errorElement={<ErrorBoundary />}
                >
                  <Route
                    path="/auth"
                    errorElement={<ErrorBoundary />}
                  >
                    <Route
                      path="sign-in"
                      element={<SignIn />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="email-verified"
                      element={<EmailVerified />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="status"
                      element={<AuthStatus />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="verify"
                      element={<Verify />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="verify-email"
                      element={<VerifyEmail />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="*"
                      element={<NotFound />}
                      errorElement={<ErrorBoundary />}
                    />
                  </Route>
                  <Route
                    path="*"
                    element={<NotFound />}
                    errorElement={<ErrorBoundary />}
                  />
                </Route>
              </Routes>

            </React.Suspense>
            <Toaster
              position="top-center"
            />
          </AuthContext.Provider>
        </ThemeProvider>
      </BrowserRouter>
    );
  }

  if (profileError) {
    return <FullPageLoader />;
  }

  if (profileLoading) {
    return <FullPageLoader />;
  }

  return (
    <BrowserRouter>
      <ThemeProvider>
        <ErrorBoundary>
          <AuthContext.Provider
            // @ts-ignore
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{ session, setSession, status }}
          >
            <React.Suspense fallback={<FullPageLoader />}>
              <Routes>
                <Route
                  path="unauthorized"
                  element={<Unauthorized />}
                  errorElement={<ErrorBoundary />}
                />

                <Route
                  path="/auth"
                  errorElement={<ErrorBoundary />}
                >
                  <Route
                    path="sign-in"
                    element={<SignIn />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="email-verified"
                    element={<EmailVerified />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="status"
                    element={<AuthStatus />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="verify"
                    element={<Verify />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="verify-email"
                    element={<VerifyEmail />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="*"
                    element={<NotFound />}
                    errorElement={<ErrorBoundary />}
                  />
                </Route>

                {/* @ts-ignore */}
                {profile?.role?.code === 'candidate' && (
                  <Route
                    path="/"
                    element={<UserLayout />}
                    errorElement={<ErrorBoundary />}
                  >
                    <Route
                      path=""
                      element={<Home />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="auth/*"
                      element={<NotFound />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="favorites"
                      element={<FavoritesPage />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="establishments"
                      element={<EstablishmentsListPage />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="job"
                      element={<Home />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="job/:id"
                      element={<JobDetails />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="job/:id/apply"
                      element={<ApplyPage />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="job/:id/apply/chat"
                      element={<ApplyPage />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="job/:id/apply/chat/:conversationID"
                      element={<ApplyChatPage />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="settings"
                      element={<Settings />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="agenda"
                      element={<Agenda />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="notifications"
                      element={<NotificationsPage />}
                      errorElement={<ErrorBoundary />}
                    />

                    {/* @ts-ignore */}
                    {profile?.role.code !== 'admin' && (
                    <>
                      <Route
                        path="chat"
                        element={<UserChatList />}
                        errorElement={<ErrorBoundary />}
                      />

                      <Route
                        path="chat/:conversationID"
                        element={<UserConversationChatPage />}
                        errorElement={<ErrorBoundary />}
                      />
                    </>
                    )}

                    <Route
                      path="profile"
                      errorElement={<ErrorBoundary />}
                    >
                      <Route
                        path=""
                        element={<UserProfile />}
                        errorElement={<ErrorBoundary />}
                      />

                      <Route
                        path="cv"
                        element={<ProfileCV />}
                        errorElement={<ErrorBoundary />}
                      />

                      <Route
                        path="missions"
                        element={<MissionsPage />}
                        errorElement={<ErrorBoundary />}
                      />
                    </Route>
                    <Route
                      path="*"
                      element={<NotFound />}
                      errorElement={<ErrorBoundary />}
                    />
                  </Route>
                )}

                <Route
                  path="onboarding"
                  element={<OnboardingLayout />}
                  errorElement={<ErrorBoundary />}
                >
                  <Route
                    path=""
                    element={<OnboardingPage />}
                    errorElement={<ErrorBoundary />}
                  />
                </Route>

                {/* @ts-ignore */}
                {profile?.role.code === 'admin' && (
                <Route
                  path="/"
                  element={<AdminLayout />}
                  errorElement={<ErrorBoundary />}
                >
                  <Route
                    path=""
                    element={<Dashboard />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="users"
                    element={<UserManagement />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="establishments"
                    element={<EstablishmentsPage />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="jobs"
                    element={<OffersPage />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="signalments"
                    element={<SignalmentsPage />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="jobs/:id"
                    element={<JobDetails />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="jobs/:id/candidates"
                    element={<AdminOfferCandidatesPage />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="job-types"
                    element={<AdminJobTypesPage />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="*"
                    element={<NotFoundAdminPage />}
                    errorElement={<ErrorBoundary />}
                  />
                </Route>
                )}

                {/* @ts-ignore */}
                {profile?.role.code === 'establishment' && (
                <Route
                  path="/"
                  element={<AdminLayout />}
                  errorElement={<ErrorBoundary />}
                >
                  <Route
                    path=""
                    element={<EstablishmentDashboard />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="establishment"
                    errorElement={<ErrorBoundary />}
                  >
                    <Route
                      path=""
                      element={<EstablishmentPage />}
                      errorElement={<ErrorBoundary />}
                    />

                    <Route
                      path="edit"
                      element={<EditEstablishmentPage />}
                      errorElement={<ErrorBoundary />}
                    />
                  </Route>

                  <Route
                    path="offers"
                    element={<EstablishmentOffersPage />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="offers/:id"
                    element={<JobDetails />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="offers/:id/candidates"
                    element={<OfferCandidatesPage />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="chat"
                    element={<EstablishmentChatPage />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="chat/:conversationID"
                    element={<UserConversationChatPage />}
                    errorElement={<ErrorBoundary />}
                  />

                  <Route
                    path="*"
                    element={<NotFoundAdminPage />}
                    errorElement={<ErrorBoundary />}
                  />
                </Route>
                )}

                <Route
                  path="*"
                  element={<FullPageLoader />}
                  errorElement={<ErrorBoundary />}
                />

              </Routes>

            </React.Suspense>
            <Toaster
              position="top-center"
            />
          </AuthContext.Provider>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
}
