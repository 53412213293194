import React from 'react';
import ChatPage from '@components/ChatPage';
import PageTitle from '@components/app/PageTitle';

export default function UserConversationChatPage(): React.ReactElement {
  return (
    <div className="lg:p-10">
      <PageTitle title="Chat" />
      <ChatPage />
    </div>
  );
}
