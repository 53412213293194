import React from 'react';
import toast from 'react-hot-toast';
import {
  Input,
  Button,
} from '@material-tailwind/react';
import { updateExperience } from '@lib/Experience';

export default function UpdateExperience({
  setOpen,
  education,
  mutate,
}: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    education: any;
    mutate: () => Promise<any>;
}): React.ReactElement {
  const [updatingInfo, setUpdatingInfo] = React.useState(false);

  const [formData, setFormData] = React.useState({
    title: education.title,
    description: education.description,
    company: education.company,
    city: education.city,
    start_date: education.start_date,
    end_date: education.end_date,
  });

  const handleUpdateEducation = async () => updateExperience({
    id: education.id,
    title: formData.title,
    description: formData.description,
    company: formData.company,
    city: formData.city,
    start_date: formData.start_date,
    end_date: formData.end_date,
  }).then(() => mutate());

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUpdatingInfo(true);

    await toast.promise(
      handleUpdateEducation(),
      {
        loading: 'Modification de l\'expérience...',
        success: 'Expérience modifiée avec succès !',
        error: 'Une erreur est survenue lors de la modification de l\'expérience',
      },
    ).then(() => {
      setUpdatingInfo(false);
      setOpen(false);
    })
      .catch(() => {
        setUpdatingInfo(false);
      });
  };

  return (
    <form onSubmit={handleUpdate}>
      <fieldset className="flex flex-col gap-4" disabled={updatingInfo}>
        <Input
          crossOrigin={undefined}
          type="text"
          label="Titre"
          name="title"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.currentTarget.value })}
          required
        />
        <Input
          crossOrigin={undefined}
          type="text"
          label="Description"
          name="description"
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.currentTarget.value })}
          required
        />
        <Input
          crossOrigin={undefined}
          type="text"
          label="Société"
          name="company"
          value={formData.company}
          onChange={(e) => setFormData({ ...formData, company: e.currentTarget.value })}
          required
        />
        <Input
          crossOrigin={undefined}
          type="text"
          label="Ville"
          name="city"
          value={formData.city}
          onChange={(e) => setFormData({ ...formData, city: e.currentTarget.value })}
          required
        />
        <Input
          crossOrigin={undefined}
          type="date"
          label="Date de début"
          name="start_date"
          value={formData.start_date}
          onChange={(e) => setFormData({ ...formData, start_date: e.currentTarget.value })}
          required
        />
        <Input
          crossOrigin={undefined}
          type="date"
          label="Date de fin"
          name="end_date"
          value={formData.end_date}
          onChange={(e) => setFormData({ ...formData, end_date: e.currentTarget.value })}
        />
        <Button type="submit" className="bg-primary">
          Modifier
        </Button>
      </fieldset>
    </form>
  );
}
