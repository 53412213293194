import { useAuth } from '@/context/Auth';
import Unauthorized from '@components/Unauthorized';
import Supabase from '@lib/Supabase';
import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Description.
 * @returns
 *
 */
export default function AuthStatus(): React.ReactElement {
  // @ts-ignore
  const { error } = useAuth();
  const location = useLocation();
  const [pageValues, setPageValues] = React.useState({
    title: 'Succès !',
    description: 'Vous êtes connecté.',
    hint: 'Vous pouvez fermer cette page.',
  });

  React.useEffect(
    () => {
      Supabase.auth
        .getSession()
        .catch((supaError) => {
          throw new Error(supaError);
        });

      if (location.hash) {
      // Url decode the hash
      // Hash format is like this:
      // #error=unauthorized_client&error_code=401&error_description=Email.ts+link+is+invalid
        const decodedHash = decodeURIComponent(location.hash.substr(1));
        const hashParams = new URLSearchParams(decodedHash);
        const hashError = hashParams.get('error');
        const hashErrorDescription = hashParams.get('error_description');

        if (hashError) {
          switch (hashError) {
            case 'unauthorized_client':
              setPageValues({
                title: 'Erreur',
                description: 'Vous n\'êtes pas autorisé à accéder à cette page.',
                hint: 'Veuillez réessayer.',
              });
              break;
            case 'invalid_grant':
              setPageValues({
                title: 'Erreur',
                description: 'Le lien est invalide.',
                hint: 'Veuillez réessayer.',
              });
              break;
            default:
              setPageValues({
                title: 'Erreur',
                description: hashError,
                hint: hashErrorDescription || 'Veuillez réessayer.',
              });
              break;
          }
        }
      }
    }, [location],
  );

  if (error) {
    return <Unauthorized />;
  }

  return (
    <div className="w-full h-full flex items-center justify-center">
      <section className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary to-secondary opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <p className="text-2xl">{pageValues.title}</p>
            <h1 className="text-4xl font-bold tracking-tight text-neutral sm:text-6xl">
              {pageValues.description}
            </h1>
            <p className="mt-6 text-xl leading-8 text-neutral/80">
              {pageValues.hint}
            </p>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary to-secondary opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </section>
    </div>
  );
}
