import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Copyright from '@/components/Copyright';
import { useAuth } from '@/context/Auth';

/**
 * Description.
 * @param children - The children of the component.
 * @returns React.ReactElement
 *
 */
export default function AuthLayout(): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const { status } = useAuth();

  const path = location.pathname.split('/')[1];

  if (status === 'unauthenticated') {
    if (!['auth'].includes(path)) {
      navigate('/auth/sign-in');
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
  }

  return (
    <>
      <main className="w-full">
        <Outlet />
      </main>
      <div className="flex justify-center">
        <Copyright />
      </div>
    </>
  );
}
