import React from 'react';
import {
  createJobType, deleteJobType, updateJobType, useJobTypes,
} from '@lib/JobTypes';
import ErrorCard from '@components/ErrorCard';
import FullPageLoader from '@components/FullPageLoader';
import PageTitle from '@components/app/PageTitle';
import { Input, Button } from '@material-tailwind/react';
import toast from 'react-hot-toast';
import { TrashIcon } from '@heroicons/react/24/outline';

export default function AdminJobTypesPage(): React.ReactElement {
  const {
    data: jobTypes,
    error: jobTypesError,
    mutate,
    isLoading,
  } = useJobTypes();
  const [newJobType, setNewJobType] = React.useState<string>('');

  if (jobTypesError) {
    return <ErrorCard message={jobTypesError.message} />;
  }

  if (!jobTypes) {
    return <FullPageLoader />;
  }

  /**
     * Handle edit job type click
     * @param event
     */
  const handleEditJobTypeClick = async (
    id: number, value: string,
  ) => updateJobType(
    id, value,
  );

  const handleAddJobType = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (newJobType) {
      toast.promise(
        createJobType({
          name: newJobType,
        }),
        {
          loading: 'En cours...',
          success: 'Type de job ajouté',
          error: 'Erreur lors de l\'ajout du type de job',
        },
        {
          style: {
            minWidth: '250px',
          },
        },
      ).then(() => {
        setNewJobType('');
      })
        .then(() => mutate());
    }
  };

  const handleDeleteJobType = async (id: number) => toast.promise(
    deleteJobType(id),
    {
      loading: 'En cours...',
      success: 'Type de job supprimé',
      error: 'Erreur lors de la suppression du type de job',
    },
    {
      style: {
        minWidth: '250px',
      },
    },
  ).then(() => mutate());

  return (
    <div>
      <PageTitle title="Types de Jobs" />
      <div className="mb-5">
        <p
          className="block mb-2 text-xs font-semibold tracking-wide text-gray-600 uppercase"
        >
          Listing
        </p>
        <form onSubmit={(e) => {
          e.preventDefault();
          return toast.promise(
            mutate(),
            {
              loading: 'En cours...',
              success: 'Modification effectuée',
              error: 'Erreur lors de la modification',
            },
            {
              style: {
                minWidth: '250px',
              },
            },
          );
        }}
        >
          {jobTypes && jobTypes.length > 0 && jobTypes.map((jobType) => (
            <div
              className="relative flex w-full mb-3"
              id={`jobTypeListing-${jobType.id}`}
              key={jobType.id}
            >
              <Input
                crossOrigin="true"
                label="Nom du type de job"
                disabled={isLoading}
                type="text"
                defaultValue={jobType.name}
                onChange={(e) => handleEditJobTypeClick(
                  jobType.id, e.target.value,
                )}
                className="pr-20"
                containerProps={{
                  className: 'min-w-0',
                }}
              />
              <Button
                size="sm"
                type="button"
                onClick={() => handleDeleteJobType(jobType.id)}
                className="!absolute right-1 top-[6px] rounded bg-error py-1"
                disabled={isLoading}
              >
                <TrashIcon className="h-5 w-5" />
              </Button>
            </div>

          ))}
          <Button
            type="submit"
            className="bg-primary w-full"
          >
            Enregistrer
          </Button>
        </form>
      </div>
      <div>
        <form onSubmit={handleAddJobType}>
          <p
            className="block mb-2 text-xs font-semibold tracking-wide text-gray-600 uppercase"
          >
            Ajouter un type de job
          </p>
          <div className="relative flex w-full mb-3">
            <Input
              crossOrigin="true"
              label="Nom du type de job"
              disabled={isLoading}
              type="text"
              name="newJobType"
              value={newJobType}
              onChange={(e) => setNewJobType(e.target.value)}
              className="pr-20"
              containerProps={{
                className: 'min-w-0',
              }}
            />
            <Button
              size="sm"
              type="submit"
              className="!absolute right-1 top-1 rounded bg-primary"
              disabled={isLoading}
            >
              Ajouter
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
