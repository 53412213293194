import React from 'react';
import { Typography } from '@material-tailwind/react';
import Card from '@/components/Card';

interface ErrorCardProps {
  message?: string;
}

export default function ErrorCard({ message }: ErrorCardProps): React.ReactElement {
  return (
    <Card>
      <div className="flex flex-col items-center justify-center h-full gap-3">
        <img src="/server-down.svg" alt="Aucun dossier" width={300} height={300} />
        <Typography variant="h5" color="blue-gray">
          Oh oh... Une erreur est survenue.
        </Typography>
        {import.meta.env.VITE_APP_ENV === 'development' && (
          <p className="text-sm mt-1 font-normal max-w-screen-md whitespace-normal overflow-scroll">
            {message}
          </p>
        )}
      </div>
    </Card>
  );
}

ErrorCard.defaultProps = { message: undefined };
