import { getProfile } from '@/lib/Profile';
import Supabase from '@lib/Supabase';
import useSWR from 'swr';

export const getMissions = async () => {
  const profile = await getProfile();

  const { data: missions, error } = await Supabase
    .from('mission')
    .select(`
        id,
        candidate_id,
        offer_id,
        offer:offer_id (
          id,
          title,
          description,
          start_date,
          end_date,
          start_time,
          establishment_id,
          experience_required,
          conditions,
          profile_wished,
          missions,
          full_time,
          contract_type_id,
          is_published,
          contract_type:contract_type_id (
            id,
            name
          ),
          establishment:establishment_id (
            id,
            name,
            description,
            phone,
            website,
            address:address_id (
              id,
              first_line,
              second_line,
              city,
              zip,
              country
            )
          )
        ),
        profile:candidate_id (
          id,
          first_name,
          last_name
        )
      `).eq(
      // @ts-ignore
      'candidate_id', profile?.id,
    );

  if (error) {
    throw new Error(error.message);
  }

  const { data: ratings, error: ratingsError } = await Supabase
    .from('rating_establishment')
    .select('*');

  missions?.forEach((offer) => {
    // @ts-ignore
    const establishmentRating = ratings?.find((rating: {
      establishment_id: number;
      // @ts-ignore
    }) => rating.establishment_id === offer.offer.establishment_id);

    const newOffer = offer.offer;

    // @ts-ignore
    newOffer.establishment.rating = establishmentRating?.rating;

    return newOffer;
  });

  if (ratingsError) {
    throw new Error(ratingsError.message);
  }

  return missions;
};

export const useMissions = () => useSWR(
  'all-missions', getMissions,
);

export const assignCandidate = async (
  offerId: number, candidateId: number,
) => {
  const { data, error } = await Supabase
    .from('mission')
    .insert({
      offer_id: offerId,
      candidate_id: candidateId,
    })
    .select('*')
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};
