import React from 'react';
import useProfile from '@lib/Profile';
import { useMissions } from '@lib/Mission';
import ErrorCard from '@components/ErrorCard';
import FullPageLoader from '@components/FullPageLoader';
import { Database } from '@/custom-types/supabase';
import PageTitle from '@components/app/PageTitle';
import { Button, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import JobCard from '@components/app/JobCard';

export default function MissionsPage(): React.ReactElement {
  const { data: profile, error: profileError } = useProfile();
  const { data: missions, error } = useMissions();

  if (profileError) {
    return (
      <ErrorCard message="Impossible de charger votre profil" />
    );
  }
  if (error) {
    return (
      <ErrorCard message="Impossible de charger les missions" />
    );
  }

  if (!profile) {
    return (
      <FullPageLoader />
    );
  }

  if (!missions) {
    return (
      <FullPageLoader />
    );
  }

  const offers = missions?.length > 0 ? missions?.map((mission) => mission.offer) as unknown as Database['public']['Tables']['offer']['Row'][] : [];

  if (!offers) {
    return (
      <FullPageLoader />
    );
  }

  const upComingOffers = offers.filter((offer) => {
    const today = new Date().toISOString();
    const offerDate = new Date(offer.start_date).toISOString();
    return offerDate >= today;
  });

  const pastOffers = offers.filter((offer) => {
    const today = new Date().toISOString();
    const offerDate = new Date(offer.start_date).toISOString();
    return offerDate < today;
  });

  return (
    <>
      <PageTitle title="Mes Missions" />
      <section className="px-5">
        <div>

          <Typography className="text-2xl font-bold mb-5">
            Missions passées ou en cours
          </Typography>

          <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {pastOffers && pastOffers.length > 0 && offers && offers.map((offer) => (
              <JobCard key={offer.id} offer={offer} />
            ))}
          </div>

          {pastOffers && pastOffers.length === 0 && (
            <div className="bg-base-100">
              <Typography className="text-lg font-normal mb-5">
                Aucun résultat.
              </Typography>
            </div>
          )}

          <Typography className="text-2xl font-bold my-5">
            Missions à venir
          </Typography>

          <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {upComingOffers && upComingOffers.map((offer) => (
              <JobCard key={offer.id} offer={offer} />
            ))}
          </div>

          {upComingOffers && upComingOffers.length === 0 && (
            <div className="bg-base-100">
              <Typography className="text-lg font-normal mb-5">
                Aucun résultat.
              </Typography>
              <Link to="/" className="w-full">
                <Button className="bg-primary w-full">
                  Trouver une mission
                </Button>
              </Link>
            </div>
          )}
        </div>
      </section>
    </>

  );
}
