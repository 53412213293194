import Supabase from '@lib/Supabase';
import useSWR from 'swr';
import { getProfile } from '@lib/Profile';
import { Encrypt } from '@lib/Encryption';
import { createNotification } from '@lib/Notification';
import { getEstablishmentManagersByEstablishmentId } from '@lib/EstablishmentManagers';

/**
 * Get all messages
 */
export const getMessages = async (conversationID: string) => {
  try {
    const { data: messages, error } = await Supabase
      .from('message')
      .select('*')
      .eq(
        'conversation_id', conversationID,
      );

    if (error) throw new Error(error.message);

    return messages;
  } catch (error) {
    throw new Error(error.message);
  }
};

/**
 * Get real-time messages from a conversation
 */
export const useMessages = (conversationID: string) => useSWR(
  ['messages', conversationID], () => getMessages(conversationID),
);

/**
 * Send a message to a conversation
 * @param message
 * @param conversationID
 */
export const sendMessage = async (
  message: string, conversationID: number,
) => {
  // Ensure message is not empty
  if (!message) throw new Error('Message cannot be empty');

  const profile = await getProfile();

  const { data, error } = await Supabase
    .from('message')
    .insert({
      content: message,
      conversation_id: conversationID,
      // @ts-ignore
      sender_id: profile?.id,
    })
    .single();

  if (error) throw new Error(error.message);

  const { data: conversation, error: conversationError } = await Supabase
    .from('conversation')
    .select('*')
    .eq(
      'id', conversationID,
    )
    .single();

  if (conversationError) throw new Error(conversationError.message);

  // eslint-disable-next-line max-len
  const establishmentManagers = await getEstablishmentManagersByEstablishmentId(conversation?.establishment_id);

  if (!establishmentManagers) throw new Error('No establishment managers found');

  // @ts-ignore
  establishmentManagers.forEach(async (manager: { user_id: string; }) => {
    const notification = await createNotification({
      level: '1',
      read: false,
      title: 'Nouveau message',
      type: 'Message',
      // @ts-ignore
      user_id: manager?.profile?.user_id,
      content: `Vous avez reçu un nouveau message de la part de ${profile?.first_name} ${profile?.last_name}`,
    });

    if (!notification) throw new Error('Notification not created');

    return data;
  });

  return data;
};

export const createSystemMessage = async (
  message: string,
  conversationID: number,
  userID: string,
) => {
  // Ensure message is not empty
  if (!message) throw new Error('Message cannot be empty');

  const encryptedMessage = Encrypt(message);

  const { data, error } = await Supabase
    .from('message')
    .insert({
      content: encryptedMessage,
      conversation_id: conversationID,
      is_system: true,
    })
    .select('*')
    .single();

  if (error) throw new Error(error.message);

  await createNotification({
    level: '1',
    read: false,
    title: 'Nouveau message',
    type: 'Message',
    user_id: userID,
    content: 'Vous avez reçu un nouveau message.',
  });

  return data;
};
