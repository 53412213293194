import type React from 'react';
import classNames from 'classnames';

interface ContainerProps
{
  children: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
}

/**
 * @param props
 *
 */
export default function Container({
  children,
  fullWidth,
  className,
}: ContainerProps): React.ReactElement {
  return (
    <div className={
    classNames(
      'container mx-auto px-4 pb-40',
      !fullWidth && 'max-w-2xl',
      className,
    )
}
    >
      {children}
    </div>
  );
}

Container.defaultProps = {
  fullWidth: false,
  className: '',
};
