import useSWR from 'swr';
import Supabase from '@lib/Supabase';
import SupabaseAdmin from '@/lib/SupabaseAdmin';
import { User } from '@supabase/supabase-js';

export const getAdmins = async () => {
  const { data: { users }, error } = await SupabaseAdmin.auth.admin.listUsers();

  if (error) throw error;

  const { data: profiles, error: profilesError } = await Supabase
    .from('profile')
    .select(`
        id,
        user_id,
        first_name,
        last_name,
        type:profile_type (
          id,
          name
        ),
        role_id,
        validated,
        role:role_id (
            id,
            code,
            name
        )
`)
    .eq(
      'role_id', 2,
    );

  if (profilesError) throw profilesError;

  const realAdminsArray: User[] = [];

  users.map((user) => {
    const profile = profiles
      .find((profileToUpdate) => profileToUpdate
        .user_id === user.id);

    if (profile) {
      const mappedUser = user;
      // @ts-ignore
      mappedUser.profile = profile;
      realAdminsArray.push(mappedUser);
      return mappedUser;
    }

    return null;
  });

  return realAdminsArray;
};

export const useAdmins = () => useSWR(
  'admins', getAdmins,
);
