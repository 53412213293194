import Container from '@components/Container';
import LoginForm from '@pages/auth/sign-in/form.component';
import type React from 'react';

/**
 * Description.
 * @returns
 *
 */
export default function LoginPage(): React.ReactElement {
  return (
    <Container className="min-h-screen flex items-center justify-center py-32">
      <div className="w-full max-w-lg">
        <div className="w-full mb-5">
          <img src="/images/logo.svg" alt="Logo" width={160} height={80} />
          <h1 className="sr-only">
            Azélie Santé
          </h1>
          <h2 className="text-2xl font-bold mb-2 text-neutral">
            Connectez-vous pour continuer
          </h2>
        </div>
        <LoginForm />
        <p className="text-sm text-gray-600">
          <a
            href="https://www.dashlane.com/fr/blog/quest-ce-que-lauthentification-sans-mot-de-passe-et-pourquoi-sy-interesser"
            target="blank"
            className="underline"
            title={"Article sur l'authentification sans mot de passe"}
          >
            En savoir plus sur l&apos;authentification sans mot de passe
            {' '}
            {'->'}
          </a>
        </p>
      </div>
    </Container>
  );
}
