import React from 'react';
import ChatPage from '@components/ChatPage';
import PageTitle from '@components/app/PageTitle';

export default function ApplyChatPage(): React.ReactElement {
  return (
    <>
      <PageTitle title="Chat" />
      <ChatPage />
    </>
  );
}
