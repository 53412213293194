import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import {
  Card,
  CardHeader,
  Input,
  Typography,
  CardBody,
  IconButton,
  Tooltip,
  Button,
} from '@material-tailwind/react';
import toast from 'react-hot-toast';
import Popover from '@/components/Popover';
import FullPageLoader from '@/components/FullPageLoader';
import ConfirmationModal from '@/components/ConfirmationModal';
import ErrorCard from '@/components/ErrorCard';
import {
  createEstablishment,
  deleteEstablishment,
  useEstablishments,
  useEstablishmentSectors,
} from '@lib/Establishment';
import { Establishment, Establishments } from '@/custom-types/Establishment';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useUsers } from '@/lib/Users';
import Select from '@/components/form/Select';
import {
  addEstablishmentManager,
  getEstablishmentManagersByEstablishmentId,
  removeEstablishmentManager,
} from '@lib/EstablishmentManagers';
import { useProfilesByRoleID } from '@lib/Profile';

const TABLE_HEAD = ['ID', 'Nom', 'Date de Création', 'Actions'];

function TableRows({
  establishments,
  handleViewClick,
  handleDeleteClick,
}: {
  establishments: Establishments;
  handleViewClick: (establishmentID: number) => void;
  handleDeleteClick: (establishmentID: number) => void;
}) {
  return (
    <tbody>
      {/* @ts-ignore */}
      {establishments?.map((
        {
          id,
          // @ts-ignore
          customID,
          // eslint-disable-next-line camelcase
          name,
          // eslint-disable-next-line camelcase
          created_at,
        },
        index,
      ) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const isLast = index === establishments?.length - 1;
        const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50';

        return (
          <tr key={id}>
            {/* ID */}
            <td className={classes}>{customID}</td>

            {/* Name */}
            <td className={classes}>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-medium"
              >
                {name}
              </Typography>
            </td>

            {/* Created At */}
            <td className={classes}>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {/* eslint-disable-next-line camelcase */}
                {created_at
                    && new Date(created_at).toLocaleDateString(
                      'fr-FR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      },
                    )}
              </Typography>
            </td>

            {/* Actions */}
            <td className={classes}>
              <Tooltip content="Voir l'etablissement">
                <IconButton
                  variant="text"
                  onClick={() => handleViewClick(id)}
                >
                  <EyeIcon className="h-4 w-4" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Supprimer l'etablissement">
                <IconButton
                  variant="text"
                  onClick={() => handleDeleteClick(id)}
                >
                  <TrashIcon className="h-4 w-4" />
                </IconButton>
              </Tooltip>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}

export default function EstablishmentsPage() {
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  // @ts-ignore
  const [establishmentToDelete, setEstablishmentToDelete]: [
    null,
    React.Dispatch<React.SetStateAction<number | null>>
  ] = React.useState(null);
  const [viewEstablishmentPopoverOpen, setViewEstablishmentPopoverOpen] = React.useState(false);
  const [addEstablishmentPopoverOpen, setAddEstablishmentPopoverOpen] = React.useState(false);
  const [establishmentToView, setEstablishmentToView] = React.useState(null);
  const [filteredEstablishments, setFilteredEstablishments] = React.useState(null);
  const [disableEverything, setDisableEverything] = React.useState(false);
  const [managerToAdd, setManagerToAdd] = React.useState(null);

  const [formValues, setFormValues] = React.useState({
    name: '',
    description: '',
    capacity: 0,
    sector_id: null,
    address: {
      first_line: '',
      second_line: '',
      zip: null,
      city: '',
      country: 'france',
    },
    manager: null,
    website: '',
    phone: '',
  });

  const { data: establishments, error, mutate } = useEstablishments();
  const { data: users, error: usersError } = useUsers();
  const {
    data: establishmentSectors,
    error: establishmentSectorsError,
  } = useEstablishmentSectors();

  const {
    data: establishmentManagers,
    error: establishmentManagersError,
  } = useProfilesByRoleID(6);

  if (establishmentSectorsError) {
    return <ErrorCard message={establishmentSectorsError.message} />;
  }

  if (establishmentManagersError) {
    return <ErrorCard message={establishmentManagersError.message} />;
  }

  if (error) {
    return <ErrorCard message={error.message} />;
  }

  if (usersError) {
    return <ErrorCard message={usersError.message} />;
  }

  if (!establishments || !users || !establishmentSectors || !establishmentManagers) {
    return <FullPageLoader />;
  }

  users?.map((user) => {
    // @ts-ignore
    if (user.profile !== undefined) {
      return user;
    }

    // has no profile, not eligible, remove from list
    users.splice(
      // @ts-ignore
      user, 1,
    );

    return null;
  });

  // Add a custom ID to each establishment because the UUID is not establishment-friendly
  let newID = 1;
  establishments.forEach((establishment) => {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    establishment.customID = newID;
    newID += 1;
  });

  /**
   * Triggered when the establishment clicks on the delete button in the table
   */
  const handleDeleteClick = (establishmentID: number) => {
    setEstablishmentToDelete(establishmentID);
    setConfirmationModalOpen(true);
  };

  /**
   * Triggered when the establishment confirms the deletion of the establishment via the modal
   */
  const establishmentDeleteCallback = async () => {
    toast
      .promise(
        // @ts-ignore
        deleteEstablishment(establishmentToDelete),
        {
          loading: "Suppression de l'établissement...",
          success: 'Etablissement supprimé !',
          error: "Une erreur est survenue lors de la suppression de l'établissement",
        },
      )
      .then(() => {
        mutate();
      });
  };

  const handleViewClick = async (establishmentID: number) => {
    // @ts-ignore
    const establishment = establishments.find((e) => e.id === establishmentID);
    // @ts-ignore
    establishment.managers = await getEstablishmentManagersByEstablishmentId(establishmentID);
    // @ts-ignore
    setEstablishmentToView(establishment);
    setViewEstablishmentPopoverOpen(true);
  };

  const searchEstablishment = (searchTerm: string) => {
    if (!searchTerm) {
      setFilteredEstablishments(null);
      return;
    }

    const matchedEstablishments = establishments
    // @ts-ignore
      .filter((establishment: Establishment) => establishment
        .name.toLowerCase().includes(searchTerm.toLowerCase()));

    // @ts-ignore
    setFilteredEstablishments(matchedEstablishments);
  };

  const handleSubmitAddEstablishment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisableEverything(true);

    toast
      .promise(
        // @ts-ignore
        createEstablishment(formValues),
        {
          loading: "Ajout de l'establishment...",
          success: 'Etablissement ajouté !',
          error: (createEstablishmentError) => createEstablishmentError.message,
        },
      )
      .then(() => {
        mutate();
        setDisableEverything(false);
        setAddEstablishmentPopoverOpen(false);
        setFormValues({
          name: '',
          description: '',
          capacity: 0,
          sector_id: null,
          address: {
            first_line: '',
            second_line: '',
            zip: null,
            city: '',
            country: 'france',
          },
          manager: null,
          website: '',
          phone: '',
        });
      })
      .catch(() => {
        setDisableEverything(false);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const removeManagerFromEstablishment = async (managerID: number) => {
    // @ts-ignore
    const establishment = establishmentToView;
    // @ts-ignore
    establishment.managers = establishment.managers.filter((manager) => manager.id !== managerID);
    // @ts-ignore
    await removeEstablishmentManager(
      // @ts-ignore
      establishmentToView?.id, managerID,
    );
    // @ts-ignore
    setEstablishmentToView(establishment);
  };

  const handleRemoveManagerFromEstablishment = async (managerID: number) => toast.promise(
    removeManagerFromEstablishment(managerID),
    {
      loading: 'Suppression du gestionnaire...',
      success: 'Gestionnaire supprimé !',
      error: 'Une erreur est survenue lors de la suppression du gestionnaire',
    },
  );

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [name]: value,
      },
    }));
  };

  return (
    <>
      {/* Popover for adding an establishment */}
      <Popover
        title="Ajouter un établissement"
        open={addEstablishmentPopoverOpen}
        setOpen={setAddEstablishmentPopoverOpen}
      >
        <form onSubmit={handleSubmitAddEstablishment}>
          <fieldset disabled={disableEverything} className="flex flex-col gap-3">
            <Input
              crossOrigin={false}
              type="text"
              label="Nom"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              required
            />
            <Input
              crossOrigin={false}
              type="text"
              label="Description"
              name="description"
              value={formValues.description}
              onChange={handleChange}
              required
            />
            <Input
              crossOrigin={false}
              type="number"
              label="Téléphone"
              name="phone"
              value={formValues.phone as unknown as number}
              onChange={handleChange}
              required
            />
            <Input
              crossOrigin={false}
              type="text"
              label="Site Web"
              name="website"
              value={formValues.website}
              onChange={handleChange}
              required
            />
            <Input
              crossOrigin={false}
              type="number"
              min={formValues.capacity}
              label="Capacité"
              name="capacity"
              value={formValues.capacity as unknown as number}
              onChange={handleChange}
            />
            <Select
              setValue={(result) => {
                // @ts-ignore
                setFormValues({ ...formValues, sector_id: result.value });
              }}
              options={establishmentSectors.map((sector) => ({
                label: sector.name,
                value: sector.id,
              }))}
              label={"Secteur d'activité"}
              value={formValues.sector_id}
              required
            />
            <Input
              crossOrigin={false}
              type="text"
              label="Adresse 1"
              name="first_line"
              value={formValues.address.first_line}
              onChange={handleAddressChange}
              required
            />
            <Input
              crossOrigin={false}
              type="text"
              label="Adresse 2"
              name="second_line"
              value={formValues.address.second_line}
              onChange={handleAddressChange}
            />
            <Input
              crossOrigin={false}
              type="number"
              label="Code postal"
              name="zip"
              value={formValues.address.zip as unknown as number}
              onChange={handleAddressChange}
              required
            />
            <Input
              crossOrigin={false}
              type="text"
              label="Ville"
              name="city"
              value={formValues.address.city}
              onChange={handleAddressChange}
              required
            />
            <Button
              size="sm"
              type="submit"
              className="bg-primary flex items-center justify-center gap-2"
            >
              Ajouter
              <PlusIcon className="h-5 w-5" />
            </Button>
          </fieldset>
        </form>
      </Popover>

      {/* Popover for Viewing establishments */}
      <Popover
        title="Informations de l'établissement"
        open={viewEstablishmentPopoverOpen}
        setOpen={setViewEstablishmentPopoverOpen}
      >
        <Typography
          variant="h6"
          className="font-semibold text-gray-600 text-sm mb-5"
        >
          Informations générales
        </Typography>

        <div className="rounded-lg border mb-5">
          <table className="w-full table-auto text-left">
            <thead>
              <tr>
                <th className="bg-blue-gray-50/50 p-4 transition-colors">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    Clé
                  </Typography>
                </th>
                <th className="bg-blue-gray-50/50 p-4 transition-colors">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    Valeur
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Nom
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {establishmentToView?.name}
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Description
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {establishmentToView?.description}
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Adresse
                </td>
                <td className="p-4">
                  <p className="text-sm">
                    {/* @ts-ignore */}
                    {establishmentToView?.address?.first_line}
                  </p>
                  <p className="text-sm">
                    {/* @ts-ignore */}
                    {establishmentToView?.address?.second_line}
                  </p>
                  <p className="text-sm">
                    {/* @ts-ignore */}
                    {establishmentToView?.address?.zip}
                    {' '}
                    {/* @ts-ignore */}
                    {establishmentToView?.address?.city}
                  </p>
                  <p className="text-sm">
                    {/* @ts-ignore */}
                    {establishmentToView?.address?.country?.name}
                  </p>
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Site Web
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {!establishmentToView?.website && 'Aucun site web'}
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Téléphone
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {establishmentToView?.phone}
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Capacité
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {establishmentToView?.capacity}
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Secteur d&apos;activité
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {establishmentToView?.establishment_sector?.name}
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Date de Création
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {establishmentToView?.created_at
                    // @ts-ignore
                    && new Date(establishmentToView?.created_at).toLocaleDateString(
                      'fr-FR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      },
                    )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p className="font-semibold text-gray-600 text-sm mb-5">
            Gestionnaire(s)
          </p>
          <div className="flex flex-col gap-3">
            {
                // @ts-ignore
                establishmentToView?.managers
                // @ts-ignore
                  .length > 0 && establishmentToView?.managers?.map((manager) => (
                    <div key={manager.profile?.id} className="flex items-center justify-between gap-3 border p-2 rounded mb-2">
                      <Typography variant="h6" className="font-semibold text-gray-600 text-sm">
                        {manager.profile?.first_name}
                        {' '}
                        {manager.profile?.last_name}
                      </Typography>
                      <Tooltip content="Supprimer le gestionnaire">
                        <IconButton
                          variant="text"
                          onClick={() => handleRemoveManagerFromEstablishment(manager.profile?.id)}
                        >
                          <TrashIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </div>
                ))
}

            {
              // @ts-ignore
              establishmentToView?.managers.length === 0 && (
                <Typography variant="h6" className="font-semibold text-gray-600 text-sm">
                  Aucun gestionnaire
                </Typography>
              )
}
          </div>
        </div>
        <div className="mb-5">
          <p className="font-semibold text-gray-600 text-sm mb-5">
            Ajouter un gestionnaire
          </p>
          <div className="flex flex-col gap-2">
            <Select
              // @ts-ignore
              setValue={(result) => setManagerToAdd(result.value)}
              options={establishmentManagers.map((manager) => ({
                label: `#${manager?.id} ${manager?.first_name} ${manager?.last_name}`,
                value: manager.id,
              }))}
              label="Sélectionnez un gestionnaire"
              value={managerToAdd}
            />
            <Button
              size="sm"
              className="bg-primary flex items-center justify-center gap-2 w-full"
              onClick={async () => {
                await toast.promise(
                  addEstablishmentManager(
                    //   @ts-ignore
                    establishmentToView?.id, managerToAdd,
                  ).then(() => {
                    // @ts-ignore
                    establishmentToView
                      .managers
                      .push(establishmentManagers.find((manager) => manager.id === managerToAdd));
                    setManagerToAdd(null);
                  }),
                  {
                    loading: 'Ajout du gestionnaire...',
                    success: 'Gestionnaire ajouté !',
                    error: 'Une erreur est survenue lors de l\'ajout du gestionnaire',
                  },
                );
              }}
            >
              Ajouter
            </Button>
          </div>
        </div>
        {
          // @ts-ignore
            establishmentToView?.ratings && establishmentToView?.ratings?.length > 0 && (
            <div className="flex flex-col gap-3">
              <Typography variant="h6" className="font-semibold text-gray-600 text-sm mb-5">
                Notes (
                {
                      // @ts-ignore
                      establishmentToView?.ratings?.length
                    }
                {' '}
                avis)
                <br />
                Note Globale:
                {' '}
                {
                      // @ts-ignore
                      establishmentToView.overallRating
                    }
                /5
              </Typography>
              {
                    // @ts-ignore
                    establishmentToView?.ratings?.map((rating) => (
                      <div key={rating.id} className="flex flex-col gap-3 border p-2 rounded mb-5">
                        <Typography variant="h6" className="font-semibold text-gray-600 text-sm">
                          Établissement
                          {' '}
                          {rating.establishment.name}
                        </Typography>
                        <Typography className="text-sm">
                          Note:
                          {' '}
                          {rating.stars}
                          /5
                        </Typography>
                        <Typography className="text-sm">
                          Critère:
                          {' '}
                          {rating.criteria}
                        </Typography>
                        <Typography className="text-sm">
                          Offre N°
                          {rating.offer.id}
                          {' '}
                          -
                          {' '}
                          {rating.offer.title}
                        </Typography>
                        <Typography className="text-sm">
                          {rating.created_at && (
                            <>
                              Le
                              {' '}
                              {new Date(rating.created_at).toLocaleDateString('fr-FR')}
                              {' '}
                              par
                              {' '}
                              {rating.profile.first_name}
                              {' '}
                                {rating.profile.last_name}
                                {' '}
                              (
                              {rating.profile.phone}
                              )
                              {' '}
                              -
                                {' '}
                              profil N°
                              {rating.profile.id}
                            </>
                          )}
                        </Typography>
                      </div>
                    ))
                  }
            </div>
            )
        }
      </Popover>

      {/* Establishment deletion confirmation modal */}
      <Card className="h-full w-full">
        <ConfirmationModal
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          title="Supprimer l'établissement"
          description="Êtes-vous sûr de vouloir supprimer cet établissement ?"
          confirmText="Supprimer"
          cancelText="Annuler"
          callBack={() => establishmentDeleteCallback()}
        />

        {/* Header w/ Search */}
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" color="blue-gray">
                Gestion des établissements
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                Visualisez les informations de tous les établissements
              </Typography>
            </div>
            <div>
              <Button
                size="sm"
                className="bg-primary flex items-center justify-center gap-2"
                onClick={() => setAddEstablishmentPopoverOpen(true)}
              >
                Ajouter un établissement
                <PlusIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <div className="w-full md:w-72">
              <Input
                type="search"
                crossOrigin="true"
                label="Rechercher"
                onChange={(e) => searchEstablishment(e.target.value)}
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </CardHeader>

        {/* Table */}
        <CardBody className="overflow-scroll px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((
                  head, index,
                ) => (
                  <th
                    key={head}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {head}
                      {' '}
                      {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon
                        strokeWidth={2}
                        className="h-4 w-4"
                      />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <TableRows
              // @ts-ignore
              establishments={filteredEstablishments || establishments}
              handleViewClick={handleViewClick}
              handleDeleteClick={handleDeleteClick}
            />
          </table>
        </CardBody>
      </Card>
    </>
  );
}
