import React, { ForwardRefExoticComponent, RefAttributes, SVGProps } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Typography,
} from '@material-tailwind/react';
import {
  PresentationChartBarIcon,
  UserIcon,
  BriefcaseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChatBubbleLeftIcon,
  BuildingOfficeIcon,
  ShieldExclamationIcon, BuildingOffice2Icon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import ActiveLink from '@/components/admin/ActiveLink';
import useProfile from '@lib/Profile';
import ErrorCard from '@components/ErrorCard';
import FullPageLoader from '@components/FullPageLoader';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

type MenuItem = {
    label: string;
    icon: ForwardRefExoticComponent<Omit<SVGProps<SVGSVGElement> & RefAttributes<SVGSVGElement>, 'ref'>>;
    href: string;
    children?: MenuItem[];
    target?: string;
  };

const adminMenuItems: MenuItem[] = [
  {
    label: 'Tableau de Bord',
    icon: PresentationChartBarIcon,
    href: '/',

  },
  {
    label: 'Utilisateurs',
    icon: UserIcon,
    href: '/users/',
  },
  {
    label: 'Etablissements',
    icon: BuildingOffice2Icon,
    href: '/establishments/',
  },
  {
    label: 'Offres',
    icon: BriefcaseIcon,
    href: '/jobs/',
  },
  {
    label: 'Signalements',
    icon: ShieldExclamationIcon,
    href: '/signalments/',
  },
  {
    label: 'Types de Jobs',
    icon: BriefcaseIcon,
    href: '/job-types/',
  },

];

const establishmentMenuItems:MenuItem[] = [
  {
    label: 'Tableau de Bord',
    icon: PresentationChartBarIcon,
    href: '/',

  },
  {
    label: 'Mon Etablissement',
    icon: BuildingOfficeIcon,
    href: '/establishment/',
  },
  {
    label: 'Mes Offres',
    icon: BriefcaseIcon,
    href: '/offers/',
  },
  {
    label: 'Conversations',
    icon: ChatBubbleLeftIcon,
    href: '/chat/',
  },
  {
    label: 'FAQ',
    icon: QuestionMarkCircleIcon,
    href: 'https://www.azelie-sante.fr/p/faq',
    target: '_blank',
  },
];

export default function SidebarContents(): React.ReactElement {
  const [open, setOpen] = React.useState(0);
  const { data: profile, error: profileError } = useProfile();

  React.useEffect(
    () => {
      if (window.location.pathname.includes('permanent-folder')) {
        setOpen(1);
      } else if (window.location.pathname.includes('migration')) {
        setOpen(2);
      } else if (window.location.pathname.includes('tools')) {
        setOpen(3);
      }
    },
    [],
  );

  if (profileError) {
    return <ErrorCard message="Impossible de charger votre profil" />;
  }

  if (!profile) {
    return <FullPageLoader />;
  }

  const handleOpen = (value: React.SetStateAction<number>): void => {
    setOpen(open === value ? 0 : value);
  };

  let menuItems: MenuItem[] = [];

  // @ts-ignore
  if (profile.role?.code === 'admin') menuItems = adminMenuItems;
  // @ts-ignore
  if (profile.role?.code === 'establishment') menuItems = establishmentMenuItems;

  return (
    <List className="sidebar bg-white z-50">
      {menuItems.map((
        item, index,
      ) => (
        // @ts-ignore
        item?.children ? (
          <Accordion
            key={item.href}
            open={open === index}
            icon={(
              <ChevronDownIcon
                strokeWidth={2.5}
                className={classNames(
                  'mx-auto h-4 w-4 transition-transform',
                  open === index ? 'rotate-180' : '',
                )}
              />
                            )}
          >
            <ListItem
              selected={open === index}
              className={classNames('bg-transparent hover:bg-transparent p-0 py-1')}
            >
              <AccordionHeader
                onClick={(): void => handleOpen(index)}
                className={classNames(
                  'border-b-0 p-3 rounded-xl',
                  window.location.pathname.includes(item.href) && 'bg-gray-100 text-black',
                )}
              >
                <ListItemPrefix>
                  {React.createElement(
                    item.icon,
                    {
                      className: 'h-5 w-5',
                      strokeWidth: 2,
                    },
                  )}
                </ListItemPrefix>
                <Typography className="text-sm mr-auto font-[500]">
                  {item.label}
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1 pl-3">
              {/* Create bar next to subitems */}
              <List className="p-0">
                {/* @ts-ignore */}
                {item.children.map((child) => (
                  <ListItem
                    key={child.label}
                    selected={window.location.pathname === child.href}
                    className="bg-transparent p-0 m-0 hover:bg-transparent"
                  >
                    <ActiveLink
                      to={child.href}
                      className="flex items-center gap-2 p-2 w-full"
                    >
                      <Typography className="text-sm mr-auto font-[500] flex justify-between align-center w-full">
                        {child.label}
                      </Typography>
                      <ListItemSuffix>
                        <ChevronRightIcon strokeWidth={2.5} className="h-3 w-3" />
                      </ListItemSuffix>
                    </ActiveLink>
                  </ListItem>
                ))}
              </List>
            </AccordionBody>
          </Accordion>
        ) : (
          <ListItem
            key={item?.label}
            selected={window.location.pathname === item?.href}
            className={classNames('bg-transparent hover:bg-transparent p-0')}
          >
            {item?.target ? (
              <a
                className="flex items-center gap-2 p-1 w-full py-4 px-2"
                href={item?.href}
                target={item?.target}
                rel="noreferrer"
              >
                <ListItemPrefix className="mr-2">
                  {React.createElement(
                    item.icon,
                    {
                      className: 'h-5 w-5',
                      strokeWidth: 2,
                    },
                  )}
                </ListItemPrefix>
                <Typography className="text-sm mr-auto font-[500]">
                  {item.label}
                </Typography>
              </a>
            ) : (
              <ActiveLink
                to={item?.href}
                className="flex items-center gap-2 p-1 w-full py-4 px-2"
              >
                <ListItemPrefix className="mr-2">
                  {React.createElement(
                    item.icon,
                    {
                      className: 'h-5 w-5',
                      strokeWidth: 2,
                    },
                  )}
                </ListItemPrefix>
                <Typography className="text-sm mr-auto font-[500]">
                  {item.label}
                </Typography>
              </ActiveLink>
            )}
          </ListItem>
        )
      ))}
    </List>
  );
}
