import React, { useContext } from 'react';
import { Drawer as BaseDrawer, IconButton, Typography } from '@material-tailwind/react';
import SidebarContents from '@/components/admin/SidebarContents';
import Navbar from '@/components/admin/Navbar';
import Sidebar from '@/components/admin/Sidebar';
import { DrawerContext } from '@/context/Drawer';

interface DrawerProps {
    children: React.ReactNode;
}

export default function Drawer({ children }: DrawerProps): React.ReactElement {
  const [drawerOpen, setDrawerOpen] = useContext(DrawerContext);

  return (
    <Sidebar>
      <Navbar />
      {children}
      <BaseDrawer
        open={drawerOpen}
        onClose={(): void => {
          setDrawerOpen(false);
        }}
        className="w-full flex flex-col"
      >
        <div className="flex items-center justify-between px-4">
          <Typography variant="h5" color="blue-gray">
            Menu
          </Typography>
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={(): void => {
              setDrawerOpen(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>
        <SidebarContents />
      </BaseDrawer>
    </Sidebar>
  );
}
