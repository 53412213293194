import { Database } from '@/custom-types/supabase';
import { CalendarIcon, ClockIcon, MapIcon } from '@heroicons/react/24/solid';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  IconButton,
} from '@material-tailwind/react';
import React from 'react';
import LoadingJobCard from '@/components/app/LoadingJobCard';
import classNames from 'classnames';
import ActiveLink from '@/components/app/ActiveLink';
import { useHasApplied, useIsRetainedByOffer } from '@lib/ProfileOffers';
import { rateEstablishment, useEstablishmentOverallRating, useEstablishmentRatings } from '@lib/Rating';
import Modal from '@components/Modal';
import { StarIcon } from '@heroicons/react/24/outline';
import { useProfile } from '@/lib/Profile';
import toast from 'react-hot-toast';
import FullPageLoader from '@components/FullPageLoader';

export default function JobCard({
  offer,
}: {
  offer: Database['public']['Tables']['offer']['Row']
}) {
  if (!offer) {
    return (
      <LoadingJobCard />
    );
  }

  const [ratingModalOpen, setRatingModalOpen] = React.useState(false);
  const [ratings, setRatings] = React.useState([
    { criteria: 'Accueil', rating: 0 },
    { criteria: 'Bien-être au travail', rating: 0 },
    { criteria: 'Ambiance', rating: 0 },
  ]);

  if (!offer.is_published) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  // @ts-ignore
  const establishmentID = offer?.establishment?.id;

  const { data: hasApplied, error: hasAppliedError } = useHasApplied(offer?.id);
  const { data: isRetained, error: isRetainedError } = useIsRetainedByOffer(offer?.id);
  const {
    data: establishmentOverallRating,
    error: establishmentOverallRatingError,
  } = useEstablishmentOverallRating(establishmentID);

  const {
    data: establishmentRating,
    error: establishmentRatingError,
  } = useEstablishmentRatings(establishmentID);

  const { data: profile, error: profileError } = useProfile();

  if (profileError) {
    throw new Error(profileError.message);
  }

  if (!profile) {
    return <LoadingJobCard />;
  }

  if (establishmentOverallRatingError) {
    return (
      <FullPageLoader />
    );
  }

  if (establishmentRatingError) {
    return (
      <FullPageLoader />
    );
  }

  if (hasAppliedError) {
    return (
      <FullPageLoader />
    );
  }

  if (isRetainedError) {
    return (
      <FullPageLoader />
    );
  }

  const startDate = new Date(offer?.start_date)
    .toLocaleDateString(
      'fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
    );

  const endDate = new Date(offer?.end_date)
    .toLocaleDateString(
      'fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
    );

  const daysLeft = Math.floor((new Date(offer?.start_date).getTime() - new Date()
    .getTime()) / (1000 * 3600 * 24));

  const isExpired = daysLeft < 0;

  const userHasGivenRating = establishmentRating?.some((rating) => rating
    // @ts-ignore
    .user_id === offer?.establishment?.user_id);

  const ratingSubmit = async () => {
    for (let i = 0; i < ratings.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await rateEstablishment(
        establishmentID,
        profile?.id,
        ratings[i].rating,
        ratings[i].criteria,
        offer?.id,
      );
    }
  };

  const handleRatingSubmit = async () => toast.promise(
    ratingSubmit(), {
      loading: 'Envoi de votre note...',
      success: 'Votre note a bien été envoyée !',
      error: 'Erreur lors de l\'envoi de votre note',
    },
  );

  return (
    <>
      <Card className="shadow-lg">
        <CardHeader floated={false} color="blue-gray">
          <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
          <IconButton
            size="sm"
            color="red"
            variant="text"
            className="!absolute top-4 right-4 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-6 w-6"
            >
              <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
            </svg>
          </IconButton>
        </CardHeader>
        <CardBody>
          {offer?.is_urgent && (
          <div className="mb-3">
            <span className="bg-red-500/20 text-red-400 px-3 py-1 rounded-xl text-sm font-semibold">
              URGENT
            </span>
          </div>
          )}

          {hasApplied && !isRetained && (
          <p className="bg-green-500/20 text-green-400 px-3 py-1 rounded-xl text-sm font-semibold mb-3 text-center">
            Vous avez postulé à cette offre
          </p>
          )}

          {isRetained && (
          <p className="bg-green-500/20 text-green-400 px-3 py-1 rounded-xl text-sm font-semibold mb-3 text-center">
            Vous avez été retenu pour cette offre
          </p>
          )}

          <div className="mb-3 flex items-center justify-between">
            <Typography variant="h5" color="blue-gray" className="font-semibold">
              {offer?.title}
            </Typography>
            <Typography
              color="blue-gray"
              className="flex items-center gap-1.5 font-normal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className={classNames(
                  '-mt-0.5 h-5 w-5 ',
                  // @ts-ignore
                  establishmentOverallRating ? 'text-yellow-700' : 'text-gray-400',
                )}
              >
                <path
                  fillRule="evenodd"
                  d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                  clipRule="evenodd"
                />
              </svg>
              {establishmentOverallRating ? (
                '/5'
              ) : (
                ''
              )}
              {/* @ts-ignore */}
              {establishmentOverallRating || 'Aucune note'}
              {' '}
              (
              {establishmentRating?.length || 0}
              {' '}
              avis)
            </Typography>
          </div>
          <div className="mb-2 flex gap-2 flex-wrap">
            <span className="bg-primary/20 text-primary px-3 py-1 rounded-xl text-sm font-medium">
              #
              {/* @ts-ignore */}
              {offer?.contract_type?.name}
            </span>
            <span className="bg-primary/20 text-primary px-3 py-1 rounded-xl text-sm font-medium">
              #
              {offer?.full_time ? 'Temps plein' : 'Temps partiel'}
            </span>
          </div>
          <Typography color="gray">
            {offer?.description && offer?.description.slice(
              0, 100,
            )}
          </Typography>
          <div className="flex flex-col flex-wrap items-start mt-5 gap-3">
            <div className="flex items-center gap-2">
              <CalendarIcon className="text-primary w-5 h-5" />
              <Typography color="gray" className="text-sm">
                Du
                {' '}
                {startDate}
                {' '}
                au
                {' '}
                {endDate}
              </Typography>
            </div>
            <div className="flex items-center gap-2">
              <MapIcon className="text-primary w-5 h-5" />
              <Typography color="gray" className="text-sm">
                {
                 // @ts-ignore
                 offer?.establishment?.name
               }
                ,
                {' '}
                {/* @ts-ignore */}
                {offer?.establishment?.address?.city}
                {' '}
                {/* @ts-ignore */}
                {offer?.establishment?.address?.zip}
              </Typography>
            </div>
            <div className="flex items-center gap-2">
              <ClockIcon className="text-primary w-5 h-5" />
              <Typography color="gray" className="text-sm">
                {isExpired ? (
                  <>
                    Expirée
                  </>
                ) : (
                  <>
                    Dans
                    {' '}
                    {daysLeft}
                    {' '}
                    jours
                  </>
                )}
              </Typography>
            </div>
            {
             // @ts-ignore
               offer?.establishment?.website && (
               <a
                       // @ts-ignore
                 href={offer?.establishment?.website}
                 target="_blank"
                 title="Aller sur le site web de l&apos;établissement"
                 rel="noreferrer"
               >
                 <span className="bg-primary/20 text-primary px-3 py-1 rounded-xl text-sm font-medium">
                   {
                  // @ts-ignore
                  offer?.establishment?.website
                }
                 </span>
               </a>
               )
           }
          </div>
        </CardBody>
        <CardFooter className="pt-3 mt-auto grid grid-cols-2 gap-3">
          <ActiveLink
            to={
               // @ts-ignore
               // eslint-disable-next-line no-nested-ternary
               !hasApplied && !isExpired ? `/job/${offer?.id}` : hasApplied && !isExpired ? `/job/${offer?.id}/apply/chat/${hasApplied.conversation_id}` : ''
             }
            className="w-full h-full"
          >
            <Button
              size="sm"
              className={classNames(
                'h-full w-full', isExpired ? 'bg-gray-500' : 'bg-primary',
              )}
              disabled={isExpired}
              fullWidth
            >
              {isExpired && !hasApplied && 'Expirée'}
              {!isExpired && hasApplied && 'Continuer la conversation'}
              {!isExpired && !hasApplied && 'Voir l\'offre'}
              {isExpired && hasApplied && 'Expirée'}
            </Button>
          </ActiveLink>

          {!isExpired && hasApplied && (
          <ActiveLink
            to={`/job/${offer?.id}`}
            className="w-full h-full"
          >
            <Button
              size="sm"
              className={classNames(
                'h-full', isExpired ? 'bg-gray-500' : 'bg-primary',
              )}
              disabled={isExpired}
              fullWidth
            >
              Voir l&apos;offre
            </Button>
          </ActiveLink>
          )}

          {isExpired && isRetained && (
          <Button
            size="sm"
            className={classNames(
              'h-full', userHasGivenRating ? 'bg-gray-500' : 'bg-primary',
            )}
            disabled={userHasGivenRating}
            onClick={() => setRatingModalOpen(true)}
            fullWidth
          >
            Noter l&apos;établissement
          </Button>
          )}
        </CardFooter>
      </Card>
      <Modal
        setOpen={setRatingModalOpen}
        title={"Noter l'établissement"}
        open={ratingModalOpen}
        confirmText="Envoyer ma note"
        callBack={handleRatingSubmit}
      >
        <div className="flex flex-col items-start gap-3">
          {ratings.map((
            rating, index,
          ) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="flex justify-between items-center gap-2 w-full" key={index}>
              <div className="flex items-center gap-2">
                <StarIcon className="text-primary w-5 h-5" />
                <Typography color="gray" className="text-sm">
                  {rating.criteria}
                </Typography>
              </div>

              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  required
                  className="hidden"
                  name={rating.criteria}
                  id={`${rating.criteria}-1`}
                  value={1}
                  onChange={(e) => {
                    const newRatings = [...ratings];
                    newRatings[index].rating = parseInt(
                      e.target.value, 10,
                    );
                    setRatings(newRatings);
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`${rating.criteria}-1`}>1</label>

                <input
                  type="radio"
                  required
                  name={rating.criteria}
                  id={`${rating.criteria}-2`}
                  value={2}
                  onChange={(e) => {
                    const newRatings = [...ratings];
                    newRatings[index].rating = parseInt(
                      e.target.value, 10,
                    );
                    setRatings(newRatings);
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`${rating.criteria}-2`}>2</label>

                <input
                  type="radio"
                  required
                  name={rating.criteria}
                  id={`${rating.criteria}-3`}
                  value={3}
                  onChange={(e) => {
                    const newRatings = [...ratings];
                    newRatings[index].rating = parseInt(
                      e.target.value, 10,
                    );
                    setRatings(newRatings);
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`${rating.criteria}-3`}>3</label>

                <input
                  type="radio"
                  required
                  name={rating.criteria}
                  id={`${rating.criteria}-4`}
                  value={4}
                  onChange={(e) => {
                    const newRatings = [...ratings];
                    newRatings[index].rating = parseInt(
                      e.target.value, 10,
                    );
                    setRatings(newRatings);
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`${rating.criteria}-4`}>4</label>

                <input
                  type="radio"
                  required
                  name={rating.criteria}
                  id={`${rating.criteria}-5`}
                  value={5}
                  onChange={(e) => {
                    const newRatings = [...ratings];
                    newRatings[index].rating = parseInt(
                      e.target.value, 10,
                    );
                    setRatings(newRatings);
                  }}
                />

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`${rating.criteria}-5`}>5</label>

              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
