import NavItems from '@components/app/MobileNavItems';
import type React from 'react';

/**
 *
 */
export default function BottomNav(): React.ReactElement {
  return (
    <div className="btm-nav z-40 bg-base-100 border-t lg:hidden fixed shadow-[0px -2px 5px 0px rgba(0,0,0,0.75)] bottom-0 w-full py-2 pb-5 flex justify-between gap-5 items-end px-5 rounded-tr-2xl rounded-tl-2xl shadow-2xl">
      <NavItems />
    </div>
  );
}
