import React from 'react';
import classNames from 'classnames';

export default function Chip({
  value,
  className,
  variant,
}: {
  value: string | number | undefined | null;
  className?: string;
  variant?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger';
}): React.ReactElement {
  let customClassNames = '';
  switch (variant) {
    case 'primary':
      customClassNames = 'text-primary bg-primary/20';
      break;

    case 'secondary':
      customClassNames = 'text-secondary bg-secondary/20';
      break;

    case 'info':
      customClassNames = 'text-blue-500 bg-blue-500/20';
      break;

    case 'success':
      customClassNames = 'text-success bg-success/20';
      break;

    case 'warning':
      customClassNames = 'text-warning bg-warning/20';
      break;

    case 'danger':
      customClassNames = 'text-error bg-error/20';
      break;

    default:
      customClassNames = 'text-primary bg-primary/20';
      break;
  }

  return (
    <span className={classNames(
      `${customClassNames} px-2 py-1 text-xs rounded-lg font-semibold capitalize`, className,
    )}
    >
      {value ?? 'Non défini'}
    </span>
  );
}

Chip.defaultProps = {
  className: '',
  variant: 'primary',
};
