import useSWR from 'swr';
import Supabase from '@lib/Supabase';
import SupabaseAdmin from '@/lib/SupabaseAdmin';

export const getUsers = async () => {
  const { data: { users }, error } = await SupabaseAdmin.auth.admin.listUsers();

  if (error) throw error;
  const { data: profiles, error: profilesError } = await Supabase
    .from('profile')
    .select(`
        id,
        user_id,
        birth_date,
        first_name,
        last_name,
        current_establishment_type,
        establishment_sector:current_establishment_type (
          id,
          name
        ),
        experience_years,
        type:profile_type (
          id,
          name
        ),
        role_id,
        validated,
        role:role_id (
            id,
            code,
            name
        ),
        address_id,
        address:address_id (
            id,
            first_line,
            second_line,
            city,
            zip
        )
`);

  if (profilesError) throw profilesError;

  const { data: documents, error: documentsError } = await Supabase
    .from('documents')
    .select(`
        id,
        user_id,
        name
    `);

  if (documentsError) throw documentsError;

  const { data: experiences, error: experiencesError } = await Supabase
    .from('experience')
    .select(`
        id,
        profile_id,
        title,
        description,
        company,
        start_date,
        end_date,
        city
    `);

  if (experiencesError) throw experiencesError;

  const { data: educations, error: educationsError } = await Supabase
    .from('education')
    .select(`
        id,
        profile_id,
        title,
        description,
        school,
        start_date,
        end_date,
        city
    `);

  if (educationsError) throw educationsError;

  const { data: ratings, error: ratingsError } = await Supabase
    .from('rating_candidate')
    .select(`
            id,
            created_at,
            profile_id,
            stars,
            establishment_id,
            establishment:establishment_id (
              id,
              name
            ),
            criteria,
            offer_id,
            offer:offer_id (
              id,
              title
            )
        `);

  if (ratingsError) throw ratingsError;

  users.map((user) => {
    const profile = profiles.find((profileToUpdate) => profileToUpdate.user_id === user.id);
    const mappedUser = user;

    const overallRating = ratings
      .filter((rating) => rating.profile_id === profile?.id)
      .reduce(
        (
          acc, rating,
        ) => acc + Number(rating.stars), 0,
      ) / ratings
      .filter((rating) => rating.profile_id === profile?.id).length;

    // @ts-ignore
    mappedUser.documents = documents
      .filter((document) => document.user_id === user.id);

    // @ts-ignore
    mappedUser.experiences = experiences
      .filter((experience) => experience.profile_id === profile?.id);

    // @ts-ignore
    mappedUser.educations = educations
      .filter((education) => education.profile_id === profile?.id);

    // @ts-ignore
    mappedUser.ratings = ratings
      .filter((rating) => rating.profile_id === profile?.id);

    // @ts-ignore
    mappedUser.overallRating = overallRating;

    // @ts-ignore
    mappedUser.profile = profile;

    return mappedUser;
  });

  // Map states to addresses

  return users;
};

export const useUsers = () => useSWR(
  'users', getUsers,
);

export const getUser = async (id: string) => {
  const { data, error } = await Supabase.auth.admin.getUserById(id);

  const { data: profile, error: profileError } = await Supabase
    .from('profile')
    .select(`
        id,
        first_name,
        last_name,
        role_id,
        validated,
        type:profile_type (
          id,
          name
        ),
        validated,
        role:role_id (
            id,
            name,
            prettyName
        )
`)
    .eq(
      'id', id,
    )
    .single();

  if (error) throw error;

  if (profileError) throw profileError;

  // eslint-disable-next-line no-param-reassign
  // @ts-ignore
  data.profile = profile;

  return data;
};

export const updateUser = async (
  // @ts-ignore
  id,
  // @ts-ignore
  updates,
) => {
  const { data, error } = await SupabaseAdmin.auth.updateUser(
    id,
    // @ts-ignore
    updates,
  );

  if (error) throw new Error(error.message);

  return data;
};

export const deleteUser = async (id: string) => {
  const { error } = await SupabaseAdmin.auth.admin.deleteUser(id);

  if (error) throw new Error(error.message);
};

export const countUsers = async () => {
  const { data, error } = await Supabase
    .from('profile')
    .select('id');

  if (error) throw new Error(error.message);

  return data?.length;
};

export const useCountUsers = () => useSWR(
  'usersCount', countUsers,
);

export const userStats = async () => {
  // Get users created in the last month then compare to this month and return the difference
  let lastMothDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
  let thisMonthDate = new Date(new Date().setMonth(new Date().getMonth()));

  // Format date to YYYY-MM-DD
  // @ts-ignore
  // eslint-disable-next-line prefer-destructuring
  lastMothDate = lastMothDate.toISOString().split('T')[0];
  // @ts-ignore
  // eslint-disable-next-line prefer-destructuring
  thisMonthDate = thisMonthDate.toISOString().split('T')[0];

  const { data: users, error } = await Supabase
    .from('profile')
    .select('id, created_at')
    .gte(
      'created_at', thisMonthDate,
    );

  if (error) throw new Error(error.message);

  const { data: usersThisMonth, error: usersThisMonthError } = await Supabase
    .from('profile')
    .select('id, created_at')
    .gte(
      'created_at', lastMothDate,
    );

  if (usersThisMonthError) throw new Error(usersThisMonthError.message);

  const thisMonth = usersThisMonth?.length || 0;

  const lastMonth = users?.length || 0;

  return thisMonth - lastMonth;
};

export const useUserStats = () => useSWR(
  'userStats', userStats,
);

export const getProfileByID = async (id: number) => {
  const { data, error } = await Supabase
    .from('profile')
    .select(`
            id,
            user_id,
            birth_date,
            first_name,
            last_name,
            current_establishment_type,
            establishment_sector:current_establishment_type (
            id,
            name
            ),
            experience_years,
            type:profile_type (
            id,
            name
            ),
            role_id,
            validated,
            role:role_id (
                id,
                code,
                name
            ),
            address_id,
            address:address_id (
                id,
                first_line,
                second_line,
                city,
                zip
            )
    `)
    .eq(
      'id', id,
    )
    .single();

  if (error) throw new Error(error.message);

  return data;
};
