import React from 'react';
import { useManagerEstablishment } from '@lib/Establishment';
import FullPageLoader from '@components/FullPageLoader';
import Chip from '@components/Chip';
import { Button, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { useEstablishmentOverallRating } from '@lib/Rating';
import { StarIcon } from '@heroicons/react/24/outline';
import Skeleton from 'react-loading-skeleton';
import InfoCard from '@components/InfoCard';

export default function EstablishmentPage(): React.ReactElement {
  const { data, error } = useManagerEstablishment();

  const establishmentID = data?.id;

  const {
    data: establishmentOverallRating,
  } = useEstablishmentOverallRating(establishmentID);

  if (error) {
    return <FullPageLoader />;
  }

  if (!data) {
    return (
      <InfoCard title="Impossible de charger la page" message={"Vous n'avez pas encore d'établissement."} />
    );
  }

  return (
    <div>
      <h1 className="mb-2">Mon Établissement</h1>
      <div className="flex items-center justify-between mb-5">
        <h2 className="text-xl font-medium">
          Etablissement
          {' '}
          {data.name}
        </h2>
        {establishmentOverallRating !== undefined && (
          <div className="flex items-center gap-2">
            <p className="text-sm">Note des candidats:</p>
            <StarIcon className="w-5 h-5 text-yellow-600" />
            <Typography variant="paragraph" className="text-yellow-600">
              {establishmentOverallRating || 0}
              {' '}
              / 5
            </Typography>
          </div>
        )}
        {!establishmentOverallRating && (
          <Skeleton width={200} height={20} />
        )}
      </div>

      <p className="text-sm">
        {data.description}
      </p>

      <h3 className="text-lg font-medium mt-4">Coordonnées</h3>
      <div className="mb-5">
        <Chip value="Adresse" />
        <p>
          {/* @ts-ignore */}
          {data.address.first_line}
          <br />
          {/* @ts-ignore */}
          {data.address.zip}
          {' '}
          {/* @ts-ignore */}
          {data.address.city.toUpperCase()}
        </p>
      </div>

      <div className="mb-5">
        <Chip value="Numéro de Téléphone" />
        <p>
          {data.phone}
        </p>
      </div>

      <div className="mb-5">
        <Chip value="Site Web" />
        <div>
          <a href={data.website} target="_blank" rel="noreferrer" className="text-primary">
            {data.website}
          </a>
        </div>
      </div>

      <div className="mb-5">
        <Chip value="Capacité" />
        <p>
          {data.capacity}
          {' '}
          personnes
        </p>
      </div>

      <div className="mb-5">
        <Chip value="Type d'établissement" />
        <p>
          {
              // @ts-ignore
              data.establishment_sector?.name
}
        </p>
      </div>

      <Link to="/establishment/edit">
        <Button className="bg-base-100 text-text border shadow-none w-full">
          Modifier
        </Button>
      </Link>
    </div>
  );
}
