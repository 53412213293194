import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { assignCandidateToOffer, useSingleOffer } from '@/lib/Offer';
import PageTitle from '@/components/app/PageTitle';
import ErrorCard from '@/components/ErrorCard';
import { Button, Typography } from '@material-tailwind/react';
import ProfileCV from '@/pages/app/profile/cv/page';
import classNames from 'classnames';
import useProfile from '@lib/Profile';
import { createConversation } from '@lib/Conversation';
import toast from 'react-hot-toast';
import supabase from '@lib/Supabase';
import FullPageLoader from '@components/FullPageLoader';
import { createSystemMessage } from '@lib/Message';
import { useFiles } from '@lib/File';
import { useFileTypes } from '@lib/FileTypes';

export default function ApplyPage(): React.ReactElement {
  const params = useParams<{ id: string }>() as {id: unknown} as { id: number};
  const { id } = params;
  const { data: offer, error } = useSingleOffer(id);
  const navigate = useNavigate();
  const [chatExists, setChatExists] = React.useState(false);

  const { data: profile, error: profileError } = useProfile();
  const { data: documents, error: documentsError } = useFiles();
  const { data: documentTypes, error: documentTypesError } = useFileTypes();

  /**
   * Check if conversation exists
   */
  const conversationExists = async () => {
    if (!offer) return null;

    if (!offer.id) throw new Error('Impossible de charger l\'offre');
    if (!profile?.id) throw new Error('Impossible de charger votre profil');
    // @ts-ignore
    if (!offer?.establishment?.id) {
      throw new Error('Impossible de charger le profil de l\'établissement');
    }

    const {
      data: existingConversation,
      error: existingConversationError,
    } = await supabase
      .from('conversation')
      .select('id,offer_id,sender_id,establishment_id, establishment_id')
      .eq(
        'offer_id', offer.id,
      )
      .eq(
        // @ts-ignore
        'sender_id', profile?.id,
      )
      .eq(
        // @ts-ignore
        'establishment_id', offer?.establishment?.id,
      );

    if (existingConversationError) throw existingConversationError;

    if (existingConversation.length > 0) {
      setChatExists(true);
    }

    return existingConversation[0];
  };

  React.useEffect(
    () => {
      conversationExists().then((c) => c);
    }, [offer],
  );

  if (profileError) {
    return <ErrorCard message="Impossible de charger votre profil" />;
  }

  if (error) {
    return (
      <ErrorCard message="Impossible de charger l'offre" />
    );
  }

  if (documentsError) {
    return (
      <ErrorCard message="Impossible de charger vos documents" />
    );
  }

  if (documentTypesError) {
    return (
      <ErrorCard message="Impossible de charger les types de documents" />
    );
  }

  if (!offer || !profile || !documents || !documentTypes) {
    return (
      <FullPageLoader />
    );
  }

  const requiredDocuments = [1, 2, 3, 4];

  const missingDocuments = requiredDocuments.filter((requiredDocument) => {
    // @ts-ignore
    const document = documents.find((d) => d.type_id === requiredDocument);
    return !document;
  });

  /**
   * Handle postulate with chat
   */
  const handlePostulateWithChat = async () => {
    const existingConversation = await conversationExists();

    if (existingConversation) {
      navigate(`/job/${id}/apply/chat/${existingConversation.id}`);
      return true;
    }

    const assigned = await assignCandidateToOffer(
      offer.id, profile.id,
    );

    if (!assigned) {
      throw new Error('Impossible de postuler');
    }

    const conversationCreated = await createConversation(
      offer.id,
      // @ts-ignore
      offer?.establishment?.id,
      // @ts-ignore
      profile?.id,
    );

    if (!conversationCreated) {
      throw new Error('Impossible de créer la conversation');
    }

    const systemMessage = await createSystemMessage(
      'Vous êtes en contact, vous pouvez désormais échanger. Ne partagez pas d\'informations personnelles (numéro de téléphone, adresse, etc.). La candidature reste potentielle et non officielle tant qu\'elle n\'est pas validée par les deux parties. Un validation côté établissement est nécessaire pour que la candidature soit officielle.',
      conversationCreated.id,
      profile.user_id,
    );

    if (!systemMessage) {
      throw new Error('Impossible de créer le message');
    }

    // @ts-ignore
    return navigate(`/job/${id}/apply/chat/${conversationCreated.id}`);
  };

  /**
   * Handle postulate classic
   */
  const handlePostulateClassic = async () => {
    const existingConversation = await conversationExists();

    if (existingConversation) {
      navigate(`/job/${id}/apply/chat/${existingConversation.id}`);
      return true;
    }

    const assigned = await assignCandidateToOffer(
      offer.id, profile.id,
    );

    if (!assigned) {
      throw new Error('Impossible de postuler');
    }

    const conversationCreated = await createConversation(
      offer.id,
      // @ts-ignore
      offer?.establishment?.profile.id,
      // @ts-ignore
      profile?.id,
    );

    if (!conversationCreated) {
      throw new Error('Impossible de créer la conversation');
    }

    const systemMessage = await createSystemMessage(
      'Postulat effectué. Il convient aux deux parties de valider ou non la candidature. Cette dernière peut-être incrémentée d\'un échange pour clarifier certains points.',
      conversationCreated.id,
      profile.user_id,
    );

    if (!systemMessage) {
      throw new Error('Impossible de créer le message');
    }

    // @ts-ignore
    return navigate(`/job/${id}/apply/chat/${conversationCreated.id}`);
  };

  /**
   * Handle postulate with chat click
   */
  const handlePostulateWithChatClick = async () => {
    await toast.promise(
      handlePostulateWithChat(),
      {
        loading: 'Redirection vers le chat...',
        success: 'Vous êtes en contact avec l\'Etablissement !',
        error: (e) => `Une erreur est survenue : ${e.message}`,
      },
    );
  };

  /**
   * Handle postulate classic click
   */
  const handlePostulateClassicClick = async () => {
    await toast.promise(
      handlePostulateClassic(),
      {
        loading: 'Postulat en cours...',
        success: 'Vous avez postulé à l\'offre !',
        error: (e) => `Une erreur est survenue : ${e.message}`,
      },
    );
  };

  return (
    <>
      {/* @ts-ignore */}
      <PageTitle title={`Postuler à l'offre "${offer?.title}" dans l'établissement "${offer?.establishment?.name}" à ${offer?.establishment.address.city}`} />
      <div>
        <div className="px-5">
          <Typography className="text-2xl font-bold mt-5">
            Récapitulatif de votre profil
          </Typography>
        </div>
        <ProfileCV showTitle={false} />
      </div>
      <div className={classNames('mx-5 p-5 rounded-lg flex flex-col lg:flex-row justify-center gap-3')}>
        <Button
          className="bg-primary w-full"
          onClick={() => handlePostulateClassicClick()}
          disabled={missingDocuments.length > 0}
        >
          {chatExists ? 'Continuer la conversation' : 'Postuler'}
        </Button>
        <Button
          className="bg-primary w-full"
          onClick={() => handlePostulateWithChatClick()}
          disabled={missingDocuments.length > 0}
        >
          Chat
        </Button>
      </div>
    </>
  );
}
