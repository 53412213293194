import React from 'react';
import Loader from '@components/Loader';
/**
 *
 */
export default function FullPageLoader(): React.ReactElement {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-white">
      <Loader />
    </div>
  );
}
