import { useLocation } from 'react-router-dom';
import React from 'react';
import classNames from 'classnames';
import { navigation } from '@components/app/nav';
import ActiveLink from '@/components/app/ActiveLink';

/**
 *
 */
export default function MobileNavItems(): React.ReactElement {
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      {navigation.map((Item) => (
        <li key={Item.title} className="list-none">
          <ActiveLink
            title={`Go to ${Item.title} page`}
            className={classNames('flex flex-col items-center justify-center text-neutral')}
            to={Item.href}
          >
            {pathname === Item.href ? (

              Item.ActiveIcon && (
                React.createElement(
                  Item.ActiveIcon, {
                    className: classNames('text-primary w-7 h-7'),
                  },
                )
              )
            ) : (
              Item.Icon && (
                React.createElement(
                  Item.Icon, {
                    className: classNames('w-7 h-7'),
                  },
                )
              )
            )}
            <span className="btm-nav-label text-xs font-medium lg:text-lg">
              {Item.title}
            </span>
          </ActiveLink>
        </li>
      ))}
    </>
  );
}
