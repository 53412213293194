import Supabase from '@lib/Supabase';
import useSWR from 'swr';
import { Offer } from '@/custom-types/Offer';
import SupabaseAdmin from '@lib/SupabaseAdmin';
import { createNotification } from '@lib/Notification';
import { getEstablishmentManagerFromOfferID } from '@lib/Establishment';
import { getProfile, getSingleProfileFromProfileID } from '@lib/Profile';
import { assignCandidate } from '@lib/Mission';
import { createSystemMessage } from '@lib/Message';

const selectQuery = `
  id,
  created_at,
  title,
  description,
  start_date,
  end_date,
  start_time,
  establishment_id,
  is_urgent,
  contract_type_id,
  experience_required,
  conditions,
  profile_wished,
  full_time,
  missions,
  is_published,
  target,
  archived,
  contract_type:contract_type_id (
    id,
    name
  ),
  establishment:establishment_id (
    id,
    name,
    description,
    phone,
    website,
    capacity,
    sector_id,
    establishment_sector:sector_id (
      id,
      name
    ),
    address:address_id (
      id,
      first_line,
      second_line,
      city,
      zip,
      country
    )
  )
`;

export const getOffers = async () => {
  const {
    data: offers,
    error,
  } = await Supabase
    .from('offer')
    .select(selectQuery)

    .order(
      'created_at', { ascending: false },
    );

  if (error) {
    throw new Error(error.message);
  }

  const { data: ratings } = await Supabase
    .from('rating_establishment')
    .select('*');

  offers?.forEach((offer) => {
    // @ts-ignore
    const establishmentRating = ratings?.find((rating: {
      establishment_id: number;
      // @ts-ignore
    }) => rating.establishment_id === offer.establishment_id);

    const newOffer = offer;

    // @ts-ignore
    newOffer.establishment.rating = establishmentRating?.rating;

    return newOffer;
  });

  const { data: candidates, error: candidatesError } = await Supabase
    .from('profiles_offers')
    .select(`
      profile_id,
      profile:profile_id (
        id,
        first_name,
        last_name,
        phone,
        birth_date,
        bio,
        experience_years,
        address_id,
        education (
          id,
          title,
          description,
          start_date,
          end_date,
          city,
          school,
          profile_id
        ),
        experience (
          id,
          title,
          description,
          start_date,
          end_date,
          city,
          company,
          profile_id
        ),
        address:address_id (id,
            city,
            country,
            zip,
            first_line,
            second_line
        )
      ),
      offer_id
  `)
    .in(
      'offer_id', offers?.map((offer) => offer.id),
    );

  if (candidatesError) {
    throw new Error(candidatesError.message);
  }

  return offers?.map((offer) => {
    const offerCandidates = candidates?.filter((candidate) => candidate.offer_id === offer.id);
    return {
      ...offer,
      candidates: offerCandidates,
    };
  });
};

export const useOffers = () => useSWR(
  'all-offers', getOffers,
);

export const getOffersForProfileType = async () => {
  const profile = await getProfile();

  if (!profile?.id) {
    throw new Error('NO_PROFILE_ID_FOUND');
  }

  const { data: offers, error } = await Supabase
    .from('offer')
    .select(selectQuery)
    .order(
      'created_at', { ascending: false },
    );
  if (error) {
    throw new Error(error.message);
  }

  const { data: ratings } = await Supabase
    .from('rating_establishment')
    .select('*');

  offers?.forEach((offer) => {
    // @ts-ignore
    const establishmentRating = ratings?.find((rating: {
      establishment_id: number;
      // @ts-ignore
    }) => rating.establishment_id === offer.establishment_id);

    const newOffer = offer;

    // @ts-ignore
    newOffer.establishment.rating = establishmentRating?.rating;

    return newOffer;
  });

  // @ts-ignore
  const profileType = profile?.type?.name;

  const profileTypeUpperCase = profileType?.toUpperCase();

  const offersForProfileType = offers?.filter((offer: {
    target: string;
  }) => {
    const offerTargetUpperCase = offer?.target?.toUpperCase();

    if (!offerTargetUpperCase) return false;

    return offerTargetUpperCase.includes(profileTypeUpperCase);
  });

  return offersForProfileType;
};

export const useOffersForProfileType = () => useSWR(
  'offers-for-profile-type', getOffersForProfileType,
);

export const getSingleOffer = async (id: number) => {
  const { data: offer, error } = await Supabase
    .from('offer')
    .select(selectQuery)
    .eq(
      'id', id,
    )

    .single();

  if (error) {
    throw new Error(error.message);
  }

  const { data: ratings } = await Supabase
    .from('rating_establishment')
    .select('*');

  // @ts-ignore
  const establishmentRating = ratings?.find((rating: {
    establishment_id: number;
  }) => rating.establishment_id === offer?.establishment_id);

  const newOffer = offer;

  // @ts-ignore
  newOffer.establishment.rating = establishmentRating?.rating;

  return offer;
};

export const useSingleOffer = (id: number) => useSWR(
  ['single-offer', id], () => getSingleOffer(id),
);

/**
 * Create a new offer.
 * @param offer
 */
export const createOffer = async (offer: Offer) => {
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  offer.start_time = Number(offer.start_time);
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  offer.experience_required = Number(offer.experience_required);
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  offer.contract_type_id = Number(offer.contract_type_id);
  if (offer.end_date) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    offer.end_date = new Date(offer.end_date);
  } else {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    delete offer.end_date;
  }

  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  offer.start_date = new Date(offer.start_date);
  // Remove id from the offer
  // @ts-ignore
  // eslint-disable-next-line no-param-reassign
  delete offer.id;
  const { data, error } = await Supabase
    .from('offer')
    .insert(offer)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const deleteOffer = async (id: number) => {
  const { error } = await Supabase
    .from('offer')
    .delete()
    .eq(
      'id', id,
    );

  if (error) {
    throw new Error(error.message);
  }
};

export const getOfferCandidates = async (id: number) => {
  if (!id) {
    throw new Error('MISSING_OFFER_ID');
  }

  const { data, error } = await Supabase
    .from('profiles_offers')
    .select(`
      profile_id,
      profile:profile_id (
        id,
        user_id,
        created_at,
        first_name,
        last_name,
        phone,
        birth_date,
        bio,
        experience_years,
        address_id,
        validated,
        type,
        profile_type:type (
            id,
            name
        ),
        education (
          id,
          title,
          description,
          start_date,
          end_date,
          city,
          school,
          profile_id
        ),
        experience (
          id,
          title,
          description,
          start_date,
          end_date,
          city,
          company,
          profile_id
        ),
        address:address_id (id,
            city,
            country,
            zip,
            first_line,
            second_line
        )
      ),
      offer_id,
      retained
  `)
    .eq(
      // @ts-ignore
      'offer_id', id,
    );

  if (error) {
    throw new Error(error.message);
  }

  const { data: documents, error: documentsError } = await Supabase
    .from('documents')
    .select(`
        id,
        user_id,
        name,
        type_id,
        type:type_id (
            id,
            name
        )
    `);

  if (documentsError) throw documentsError;

  const { data: ratings } = await Supabase
    .from('rating_candidate')
    .select('*');

  data?.forEach((candidate) => {
    // @ts-ignore
    const candidateRating = ratings?.find((rating: {
      profile_id: number;
      // @ts-ignore
    }) => rating.profile_id === candidate.profile_id);

    const newCandidate = candidate;

    // @ts-ignore
    newCandidate.profile.rating = candidateRating?.rating;

    // @ts-ignore
    newCandidate.profile.documents = documents
      .filter((document) => document
        // @ts-ignore
        .user_id === candidate.profile.user_id);

    return newCandidate;
  });

  return data;
};

export const useOfferCandidates = (id: number) => useSWR(
  ['offer-candidates', id], () => getOfferCandidates(id),
);

export const retainCandidate = async (
  offerId: number, profileID: number,
) => {
  const { error } = await Supabase
    .from('profiles_offers')
    .update({
      retained: true,
    })
    .eq(
      'offer_id', offerId,
    )
    .eq(
      'profile_id', profileID,
    );

  if (error) {
    throw new Error(error.message);
  }

  const profile = await getSingleProfileFromProfileID(profileID);

  const assignToMission = await assignCandidate(
    offerId, profileID,
  );

  if (!assignToMission) {
    throw new Error('ASSIGN_TO_MISSION_FAILED');
  }

  // Get conversation ID
  const { data: conversation, error: conversationError } = await Supabase
    .from('conversation')
    .select('id')
    .eq(
      'sender_id', profileID,
    )
    .eq(
      'offer_id', offerId,
    );

  if (conversationError) {
    throw new Error(conversationError.message);
  }

  if (!conversation || conversation?.length === 0) {
    throw new Error('NO_CONVERSATION_FOUND');
  }

  const { error: messageError } = await createSystemMessage(
    'Cette mission est désormais validée. Les deux parties peuvent continuer à échanger pour clarifier les modalités de début de contrat.',
    conversation[0]?.id,
    profile.user_id,
  );

  if (messageError) {
    throw new Error(messageError.message);
  }

  await createNotification({
    level: '1',
    read: false,
    title: 'Candidature retenue',
    type: 'Message',
    user_id: profile.user_id,
    content: `Votre candidature pour l'offre ${offerId} a été retenue.`,
  });
};

export const refuseCandidate = async (
  offerId: number,
  profileId: number,
  refuseMessage: string,
) => {
  if (!refuseMessage) {
    throw new Error('MISSING_REFUSE_MESSAGE');
  }

  if (!profileId) {
    throw new Error('MISSING_PROFILE_ID');
  }

  if (!offerId) {
    throw new Error('MISSING_OFFER_ID');
  }

  const { data: profile, error: profileError } = await Supabase
    .from('profile')
    .select('id,user_id')
    .eq(
      'id', profileId,
    )
    .single();

  if (profileError) {
    throw new Error(profileError.message);
  }

  if (!profile?.user_id) {
    throw new Error('NO_USER_ID_FOUND');
  }

  const { error: userError } = await SupabaseAdmin
    .auth.admin.getUserById(profile?.user_id);

  if (userError) {
    throw new Error(userError.message);
  }

  const { data: mission, error: missionError } = await Supabase
    .from('mission')
    .select('id')
    .eq(
      'candidate_id', profileId,
    )
    .eq(
      'offer_id', offerId,
    );

  if (missionError) {
    throw new Error(missionError.message);
  }

  if (!mission) {
    throw new Error('NO_MISSION_FOUND');
  }

  if (mission.length > 0) {
    //   Delete the entry, if it exists
    const { error: deleteMissionError } = await Supabase
      .from('mission')
      .delete()
      .eq(
        'id', mission[0].id,
      );

    if (deleteMissionError) {
      throw new Error(deleteMissionError.message);
    }
  }

  const { error } = await Supabase
    .from('profiles_offers')
    .update({
      retained: false,
    })
    .eq(
      'offer_id', offerId,
    )
    .eq(
      'profile_id', profileId,
    );

  if (error) {
    throw new Error(error.message);
  }

  // Get conversation ID
  const { data: conversation, error: conversationError } = await Supabase
    .from('conversation')
    .select('id')
    .eq(
      'sender_id', profileId,
    )
    .eq(
      'offer_id', offerId,
    );

  if (conversationError) {
    throw new Error(conversationError.message);
  }

  if (!conversation || conversation?.length === 0) {
    throw new Error('NO_CONVERSATION_FOUND');
  }

  const { error: messageError } = await createSystemMessage(
    refuseMessage,
    conversation[0]?.id,
    profile.user_id,
  );

  if (messageError) {
    throw new Error(messageError.message);
  }

  if (!profile?.user_id) {
    throw new Error('NO_USER_ID_FOUND');
  }

  const notificationCreated = await createNotification({
    level: '1',
    read: false,
    title: 'Candidature refusée',
    type: 'Message',
    user_id: profile?.user_id,
    content: `Votre candidature pour l'offre ${offerId} n'a pas été retenue.`,
  });

  if (!notificationCreated) {
    throw new Error('NOTIFICATION_NOT_CREATED');
  }
};

export const editOffer = async (
  offer: Offer,
  offerID: number,
) => {
  const { data, error } = await Supabase
    .from('offer')
    .update(offer)
    .eq(
      'id', offerID,
    )
    .select()
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const countOffers = async () => {
  const { data, error } = await Supabase
    .from('offer')
    .select('id');

  if (error) {
    throw new Error(error.message);
  }

  return data?.length;
};

export const useCountOffers = () => useSWR(
  'count-offers', countOffers,
);

/**
 * Get the number of offers created this month compared to last month.
 */
export const offerStats = async () => {
  // Get users created in the last month then compare to this month and return the difference
  let lastMothDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
  let thisMonthDate = new Date(new Date().setMonth(new Date().getMonth()));

  // Format date to YYYY-MM-DD
  // @ts-ignore
  // eslint-disable-next-line prefer-destructuring
  lastMothDate = lastMothDate.toISOString().split('T')[0];
  // @ts-ignore
  // eslint-disable-next-line prefer-destructuring
  thisMonthDate = thisMonthDate.toISOString().split('T')[0];

  const { data: offers, error } = await Supabase
    .from('offer')
    .select('id, created_at')
    .gte(
      'created_at', thisMonthDate,
    );

  if (error) {
    throw new Error(error.message);
  }

  const { data: offersThisMonth, error: offersThisMonthError } = await Supabase
    .from('offer')
    .select('id, created_at')
    .gte(
      'created_at', lastMothDate,
    );

  if (offersThisMonthError) {
    throw new Error(offersThisMonthError.message);
  }

  const thisMonth = offersThisMonth?.length || 0;
  const lastMonth = offers?.length || 0;

  return thisMonth - lastMonth;
};

export const useOfferStats = () => useSWR(
  'offerStats', offerStats,
);

/**
 * Assign a candidate to an offer.
 * @param offerID
 * @param profileID
 */
export const assignCandidateToOffer = async (
  offerID: number,
  profileID: number,
) => {
  // Check if the candidate is already assigned to the offer
  const { data: alreadyAssigned, error: alreadyAssignedError } = await Supabase
    .from('profiles_offers')
    .select('profile_id,offer_id')
    .eq(
      'profile_id', profileID,
    )
    .eq(
      'offer_id', offerID,
    );

  if (alreadyAssignedError) {
    throw new Error(alreadyAssignedError.message);
  }

  if (alreadyAssigned?.length) {
    return alreadyAssigned[0];
  }

  const { data, error } = await Supabase
    .from('profiles_offers')
    .insert({
      offer_id: offerID,
      profile_id: profileID,
    })
    .select('profile_id,offer_id')
    .single();

  if (error) {
    throw new Error(error.message);
  }

  const manager = await getEstablishmentManagerFromOfferID(data.offer_id);

  const managerProfile = await getSingleProfileFromProfileID(manager.manager_id);

  await createNotification({
    level: '1',
    read: false,
    title: 'Nouvelle candidature',
    type: 'Message',
    user_id: managerProfile.user_id,
    content: `Nouveau candidat pour l'offre ${data.offer_id}.`,
  });

  return data;
};

export const getOffersFromFavoriteEstablishments = async () => {
  const profile = await getProfile();

  const { data: favoriteEstablishments, error: favoriteEstablishmentsError } = await Supabase
    .from('favorite_establishment')
    .select('establishment_id')
    .eq(
      'profile_id', profile?.id,
    );

  if (favoriteEstablishmentsError) {
    throw new Error(favoriteEstablishmentsError.message);
  }

  if (!favoriteEstablishments) {
    return [];
  }

  const favoriteEstablishmentsIDs = favoriteEstablishments.map((favoriteEstablishment: {
    establishment_id: number;
  }) => favoriteEstablishment.establishment_id);

  const { data, error } = await Supabase
    .from('offer')
    .select(selectQuery)
    .in(
      'establishment_id', favoriteEstablishmentsIDs,
    );

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const useOffersFromFavoriteEstablishments = () => useSWR(
  'offers-from-favorite-establishments', getOffersFromFavoriteEstablishments,
);

export const getOffer = async (id: number) => {
  const { data, error } = await Supabase
    .from('offer')
    .select(selectQuery)
    .eq(
      'id', id,
    )
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const useOffer = (id: number) => useSWR(
  ['simple-offer-by-id', id], () => getOffer(id),
);

export const duplicateOffer = async (id: number) => {
  const { data: offer, error } = await Supabase
    .from('offer')
    .select('*')
    .eq(
      'id', id,
    )
    .single();

  if (error) {
    throw new Error(error.message);
  }

  const { data: newOffer, error: newOfferError } = await Supabase
    .from('offer')
    .insert({
      ...offer,
      id: undefined,
    })
    .single();

  if (newOfferError) {
    throw new Error(newOfferError.message);
  }

  return newOffer;
};

export const archiveOffer = async (id: number) => {
  if (!id) {
    throw new Error('MISSING_OFFER_ID');
  }

  const { error } = await Supabase
    .from('offer')
    .update({
      is_published: false,
      archived: true,
    })
    .eq(
      'id', id,
    );

  if (error) {
    throw new Error(error.message);
  }
};

export const unarchiveOffer = async (id: number) => {
  if (!id) {
    throw new Error('MISSING_OFFER_ID');
  }

  const { error } = await Supabase
    .from('offer')
    .update({
      is_published: false,
      archived: false,
    })
    .eq(
      'id', id,
    );

  if (error) {
    throw new Error(error.message);
  }
};
