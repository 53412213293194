import ReactDOM from 'react-dom/client';
import React from 'react';
import App from '@/App';
import '@styles/globals.scss';
import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://95aceeaf7e95f6373afed1b3272278d7@o4506067862880256.ingest.sentry.io/4506067864190976',
    integrations: [
      new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control
      // for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%.
    // You may want to change it to 100% while in
    // development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session,
  // change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as unknown as HTMLElement);

// eslint-disable-next-line function-paren-newline
root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <React.StrictMode>
    <App />
  </React.StrictMode>);
