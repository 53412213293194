import { getAuth } from '@/context/Auth';
import { Database } from '@/custom-types/supabase';
import Supabase from '@lib/Supabase';
import toast from 'react-hot-toast';
import useSWR from 'swr';
import { getProfile } from '@lib/Profile';
import { send } from '@lib/Email';
import SupabaseAdmin from '@lib/SupabaseAdmin';

const notificationTemplate = `
<!doctype html>
<html lang="fr" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <title> Nouvelle Notification </title>
  <!--[if !mso]><!-->
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <!--<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style type="text/css">
    #outlook a {
      padding: 0;
    }

    body {
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }

    table,
    td {
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }

    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
    }

    p {
      display: block;
      margin: 13px 0;
    }

  </style>
  <!--[if mso]>
    <noscript>
    <xml>
    <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    </noscript>
    <![endif]-->
  <!--[if lte mso 11]>
    <style type="text/css">
      .mj-outlook-group-fix { width:100% !important; }
    </style>
    <![endif]-->
  <style type="text/css">
    @media only screen and (min-width:480px) {
      .mj-column-per-100 {
        width: 100% !important;
        max-width: 100%;
      }
    }

  </style>
  <style media="screen and (min-width:480px)">
    .moz-text-html .mj-column-per-100 {
      width: 100% !important;
      max-width: 100%;
    }

  </style>
  <style type="text/css">
    @media only screen and (max-width:480px) {
      table.mj-full-width-mobile {
        width: 100% !important;
      }

      td.mj-full-width-mobile {
        width: auto !important;
      }
    }

  </style>
  <style type="text/css">
    a,
    span,
    td,
    th {
      -webkit-font-smoothing: antialiased !important;
      -moz-osx-font-smoothing: grayscale !important;
    }

  </style>
</head>

<body style="word-spacing:normal;background-color:#ffffff;">
  <div style="display:none;font-size:1px;color:#ffffff;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden;"> Preview - Nouvelle Notification </div>
  <div style="background-color:#ffffff;" lang="fr">
    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:150px;">
                                <img alt="image description" height="auto" src="https://www.azelie-sante.fr/static/img/Logo-azelie.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:14px;" width="150">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Helvetica, Arial, sans-serif;font-size:18px;font-weight:400;line-height:24px;text-align:left;color:#434245;">
                          <h1 style="margin: 0; font-size: 24px; line-height: normal; font-weight: bold;"> Azélie Santé </h1>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:24px;text-align:left;color:#434245;">Azélie Santé vous met en relation les établissements sociaux et médico-sociaux. Trouvez des offres facilement et répondez-y librement.</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Helvetica, Arial, sans-serif;font-size:18px;font-weight:400;line-height:24px;text-align:left;color:#434245;">Vous avez reçu une nouvelle notification sur votre compte, <br>
                          {{ .Message }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%;">
                          <tbody>
                            <tr>
                              <td align="center" bgcolor="#9fbb00" role="presentation" style="border:none;border-radius:12px;cursor:auto;mso-padding-alt:10px 25px;background:#9fbb00;" valign="middle">
                                <a href="https://app.azelie-sante.fr" style="display: inline-block; background: #9fbb00; color: #ffffff; font-family: Helvetica, Arial, sans-serif; font-size: 14px; font-weight: bold; line-height: 20px; margin: 0; text-decoration: none; text-transform: uppercase; padding: 10px 25px; mso-padding-alt: 0px; border-radius: 12px;" target="_blank"> Voir la notification </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin:0px auto;max-width:600px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
        <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
              <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
              <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Helvetica, Arial, sans-serif;font-size:18px;font-weight:400;line-height:24px;text-align:left;color:#434245;">Si vous avez besoin d'aide, n'hésitez pas à nous contacter à l'addresse <a href="mailto:contact@azelie-sante.fr" style="color: #9fbb00; text-decoration: none;">contact@azelie-sante.fr</a>!</div>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Helvetica, Arial, sans-serif;font-size:18px;font-weight:bold;line-height:24px;text-align:left;color:#434245;">L'équipe <a href="https://azelie-sante.fr" target="_blank" style="color: #9fbb00; text-decoration: none;">Azélie Santé</a></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
  </div>
</body>

</html>
`;

/**
 * Get all notifications
 */
export const getNotifications = async () => {
  // @ts-ignore
  const profile = await getProfile();

  const { data: notifications, error } = await Supabase
    .from('notification')
    .select('*')
    .eq(
      // @ts-ignore
      'user_id', profile?.user_id,
    )
    .order(
      'created_at', { ascending: false },
    );

  if (error) {
    throw new Error(error.message);
  }
  return notifications;
};

/**
 * Get all notifications
 */
export const useNotifications = () => useSWR(
  'notifications', getNotifications,
);

type NotificationInput = {
  level: Database['public']['Enums']['notification_severity'],
  read: boolean,
  title: string,
  type: Database['public']['Enums']['notification_type'],
  user_id: string,
  content: string,
};

/**
 * Create a notification
 * @param notification NotificationInput
 */
export const createNotification = async (notification: NotificationInput) => {
  // Ensure there are no missing fields
  if (!notification.level) throw new Error('Missing level');
  if (!notification.title) throw new Error('Missing title');
  if (!notification.type) throw new Error('Missing type');
  if (!notification.user_id) throw new Error('Missing user_id');

  const { data, error } = await Supabase
    .from('notification')
    .insert(notification)
    .select('*')
    .single();

  if (error) {
    throw new Error(error.message);
  }

  const { data: user } = await SupabaseAdmin
    .auth
    .admin
    .getUserById(notification.user_id);

  const emailTemplate = notificationTemplate.replace(
    '{{ .Message }}', notification.content as string,
  );

  const emailSent = await send({
    to: user?.user?.email as string,
    subject: notification.title,
    html: emailTemplate,
  });

  if (!emailSent) {
    throw new Error('EMAIL_NOT_SENT');
  }

  return data;
};

/**
 * Update a notification
 * @param id
 */
export const deleteNotification = async (id: number) => {
  const toastID = toast.loading('Chargement...');
  const { data, error } = await Supabase
    .from('notification')
    .delete()
    .eq(
      'id', id,
    );

  if (error) {
    toast.dismiss(toastID);
    throw new Error(error.message);
  }
  toast.dismiss(toastID);

  toast.success('Notification supprimée');

  return data;
};

/**
 * Update a notification
 * @param id
 */
export const markNotificationAsRead = async (id: number) => {
  const toastID = toast.loading('Chargement...');
  const { data, error } = await Supabase
    .from('notification')
    .update({ read: true })
    .eq(
      'id', id,
    );

  if (error) {
    toast.dismiss(toastID);
    throw new Error(error.message);
  }
  toast.dismiss(toastID);

  toast.success('Notification marquée comme lue');

  return data;
};

/**
 * Update a notification
 * @param id
 */
export const markNotificationAsUnRead = async (id: number) => {
  const toastID = toast.loading('Chargement...');
  const { data, error } = await Supabase
    .from('notification')
    .update({ read: false })
    .eq(
      'id', id,
    );

  if (error) {
    toast.dismiss(toastID);
    throw new Error(error.message);
  }

  toast.dismiss(toastID);
  toast.success('Notification marquée comme non lue');

  return data;
};

/**
 * Update a notification
 */
export const markAllNotificationsAsRead = async () => {
  const toastID = toast.loading('Chargement...');
  const { data, error } = await Supabase
    .from('notification')
    .update({ read: true })
    .eq(
      'read', false,
    );

  if (error) {
    toast.dismiss(toastID);
    throw new Error(error.message);
  }

  toast.dismiss(toastID);
  toast.success('Notifications marquées comme lues');

  return data;
};

/**
 * Update a notification
 */
export const countUnreadNotifications = async () => {
  // @ts-ignore
  const { data: auth } = await getAuth();

  const { session } = auth;

  if (!session) return { count: undefined, error: undefined };

  const { data, error } = await Supabase
    .from('notification')
    .select('*')
    .eq(
      'user_id', session?.user?.id,
    )
    .eq(
      'read', false,
    );

  if (error) {
    throw new Error(error.message);
  }

  return data?.length;
};

/**
 * Get the number of unread notifications
 */
export const useCountUnreadNotifications = () => useSWR(
  'count-notifications', countUnreadNotifications,
);
