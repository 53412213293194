import React, { useState } from 'react';
import {
  CloudArrowUpIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import {
  Alert,
  Button,
  Typography,
} from '@material-tailwind/react';
import Select from '@components/form/Select';

export type ExtendedFile = File & {
  docType?: string;
};

/**
 * Dropzone component
 * @param {File[]} files - Files to upload
 * @param setFiles - setter for files
 * @param error - error message
 * @param setError - setter for error message
 * @param formats - accepted formats
 * @param sizeLimit
 * @param sizeLimitUnit
 * @return {Element} - Dropzone component
 */
export default function Dropzone({
  files,
  setFiles,
  error,
  setError,
  formats,
  sizeLimit,
  sizeLimitUnit,
  fileTypes,
}: {
    files: ExtendedFile[];
    setFiles: React.Dispatch<React.SetStateAction<ExtendedFile[]>>;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    formats: string;
    sizeLimit?: number;
    sizeLimitUnit?: string;
    fileTypes?: {
      value: string | number | boolean | undefined | null;
      label: string;
    }[];
}): React.ReactElement {
  const [dragActive, setDragActive] = useState(false);

  // eslint-disable-next-line no-param-reassign
  formats = formats.toUpperCase();

  const updateFileList = (uploadedFiles: string | any[] | FileList) => {
    const newFiles = [...files];
    const uniqueFilesArray = new Set(files.map((file) => file.name));

    if (uploadedFiles && uploadedFiles.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i];
        const fileExtension = file.name.split('.')[1].toUpperCase();

        // Check if file is in the accepted formats
        if (!formats.includes(fileExtension)) {
          setError(`Le fichier ${file.name} n'est pas au format accepté`);
        } else if (!uniqueFilesArray.has(file.name)) {
          newFiles.push(file);
          uniqueFilesArray.add(file.name);
        }
      }

      setFiles(newFiles);
    } else {
      setFiles([]);
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError('');

    if (sizeLimit && e.target.files && e.target.files[0].size > sizeLimit * 1000000) {
      setError(`La taille du fichier ne doit pas dépasser ${sizeLimit} ${sizeLimitUnit}.`);
      return;
    }

    if (e.target.files) {
      updateFileList(e.target.files);
    } else {
      updateFileList([]);
    }
  };

  function removeFile(idx: number) {
    const newArr = [...files];
    newArr.splice(
      idx, 1,
    );
    updateFileList(newArr);
  }

  function openFileExplorer() {
    try {
      const inputFile = document.getElementById('file-dropzone');
      inputFile?.click();
    } catch (err) {
      throw new Error(err);
    }
  }

  function handleDrop(e: React.DragEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    updateFileList(e.dataTransfer.files);
  }

  function handleDragLeave(e: React.DragEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  }

  function handleDragOver(e: React.DragEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }

  function handleDragEnter(e: React.DragEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  }

  const formatFileSize = (fileSize: string | number) => {
    let formattedSize;

    if (typeof fileSize === 'string') {
      formattedSize = Math.abs(parseInt(
        fileSize, 10,
      ));
    } else {
      formattedSize = Math.abs(fileSize);
    }

    const def = [
      {
        size: 1,
        label: 'octets',
      },
      {
        size: 1000,
        label: 'ko',
      },
      {
        size: 1000 * 1000,
        label: 'Mo',
      },
      {
        size: 1000 * 1000 * 1000,
        label: 'Go',
      },
      {
        size: 1000 * 1000 * 1000 * 1000,
        label: 'To',
      },
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < def.length; i++) {
      if (formattedSize < def[i].size) {
        return `${
          (formattedSize / def[i - 1].size)
            .toFixed(2)
        }
      ${def[i - 1].label}`;
      }
    }

    return formattedSize;
  };

  return (
    <form
      onDragEnter={handleDragEnter}
      onSubmit={(e) => e.preventDefault()}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      className="w-full p-5 flex flex-col justify-center"
      id="dropzone"
    >
      <input
        className=""
        tabIndex={-1}
        accept={formats}
        multiple
        hidden
        id="file-dropzone"
        type="file"
        name="file"
        onChange={(e) => handleChange(e)}
      />
      <button
        type="button"
        className={classNames(
          'flex flex-col items-center h-full w-full', dragActive ? 'bg-gray-100' : 'bg-white', 'border-2 border-dashed border-gray-300 rounded-lg p-5 mb-5',
        )}
        onClick={() => openFileExplorer()}
      >
        <CloudArrowUpIcon className="w-24 h-24 text-primary" />
        <Typography
          variant="h5"
          className="text-center text-sm"
        >
          Cliquer ou déposer vos fichiers ici
        </Typography>
        <Typography
          className="text-center text-gray-500 font-norma text-sm"
        >
          Seuls les fichiers au format
          {' '}
          {formats.toLowerCase().replace(
            /,/g, ', ',
          )}
          {' '}
          sont acceptés.
        </Typography>
        {sizeLimit && (
        <Typography
          className="text-center text-gray-500 font-norma text-sm"
        >
          La taille maximale est de
          {' '}
          {sizeLimit}
          {' '}
          {sizeLimitUnit}
          .
        </Typography>
        )}
      </button>

      {files?.length > 0 && (
        <div className="flex flex-col items-start rounded-lg p-3 mb-5">
          {files?.length > 0 && files?.map((
            file: ExtendedFile, idx: number,
          ) => (
            <div
                            // eslint-disable-next-line react/no-array-index-key
              key={idx}
              className="flex flex-col md:flex-row gap-3 items-center border p-3 rounded-lg w-full mb-5"
            >
              <div className="flex flex-col flex-1">
                <p className="text-sm">{file.name}</p>
                <p className="text-xs text-gray-500">{formatFileSize(file.size)}</p>
              </div>
              {fileTypes && fileTypes.length > 0 && (
              <div>
                <Select
                  setValue={(e) => {
                    // @ts-ignore
                    const newArr = [...files];
                    // @ts-ignore
                    newArr[idx].docType = e.value;
                    return setFiles(newArr);
                  }}
                  options={fileTypes.map((type) => ({
                    value: type.value,
                    label: type.label,
                  }))}
                  label="Type de Document"
                  value={file.docType}
                />
              </div>
              )}
              <Button
                size="sm"
                className="text-red-500 bg-red-500/20 shadow-none cursor-pointer w-full md:w-auto"
                onClick={() => removeFile(idx)}
              >
                Retirer
              </Button>
            </div>
          ))}
        </div>
      )}

      {error && (
        <Alert
          icon={<ExclamationCircleIcon className="w-7 h-7" />}
          className="bg-error/10 text-error font-semibold mb-5 flex items-center"
        >
          {error}
        </Alert>
      )}
    </form>
  );
}

Dropzone.defaultProps = {
  sizeLimit: 2,
  sizeLimitUnit: 'Mo',
  fileTypes: [],
};
