import Popover from '@components/Popover';
import {
  Button,
  Input,
  Switch,
  Textarea,
} from '@material-tailwind/react';
import Select from '@components/form/Select';
import { PlusIcon } from '@heroicons/react/24/solid';
import React from 'react';
import toast from 'react-hot-toast';
import { createOffer, editOffer } from '@lib/Offer';
import { useContractTypes } from '@lib/ContractTypes';
import ErrorCard from '@components/ErrorCard';
import Loader from '@components/Loader';
import useProfile, { useProfileTypes } from '@lib/Profile';
import { useEstablishments } from '@lib/Establishment';
import Chip from '@components/Chip';

export default function OfferPopovers({
  mutate,
  addOfferPopoverOpen,
  setAddOfferPopoverOpen,
  setModifyOfferPopoverOpen,
  modifyOfferPopoverOpen,
  formValues,
  setFormValues,
}: {
    mutate: () => void,
    addOfferPopoverOpen: boolean,
    setAddOfferPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setModifyOfferPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>,
    modifyOfferPopoverOpen: boolean,
    formValues: {
      id: number,
      title: string,
      description: string,
      start_date: string,
      end_date: string,
      is_urgent: boolean,
      start_time: string,
      contract_type_id: number,
      experience_required: string,
      conditions: string,
      profile_wished: string,
      missions: string,
      is_published: boolean,
      establishment_id: string,
      target: string
    },
    setFormValues: React.Dispatch<React.SetStateAction<{
      id: number,
      title: string,
      description: string,
      start_date: string,
      end_date: string,
      is_urgent: boolean,
      start_time: string,
      contract_type_id: number,
      experience_required: string,
      conditions: string,
      profile_wished: string,
      missions: string,
      is_published: boolean,
      establishment_id: string,
      target: string
    }>>,
}): React.ReactElement {
  const [disableEverything, setDisableEverything] = React.useState(false);
  const { data: contractTypes, error: contractTypesError } = useContractTypes();
  const { data: profile, error: profileError } = useProfile();
  const { data: establishments, error: establishmentsError } = useEstablishments();
  const { data: profileTypes, error: profileTypesError } = useProfileTypes();
  const [targetSelectValue, setTargetSelectValue] = React.useState<{
    label: string,
    value: number
  }>();
  const hasEndDate = formValues.end_date !== null;
  const [isShortMission, setIsShortMission] = React.useState(!hasEndDate);

  if (profileError) {
    return <ErrorCard message={profileError.message} />;
  }

  if (contractTypesError) {
    return <ErrorCard message={contractTypesError.message} />;
  }

  if (establishmentsError) {
    return <ErrorCard message={establishmentsError.message} />;
  }

  if (profileTypesError) {
    return <ErrorCard message={profileTypesError.message} />;
  }

  if (!contractTypes || !profile || !establishments || !profileTypes) {
    return <Loader />;
  }

  const handleSubmitAddOffer = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisableEverything(true);

    if (!formValues.establishment_id) {
      setDisableEverything(false);
      return toast.error('Veuillez sélectionner un établissement');
    }

    if (isShortMission) {
    //     Remove contract_type_id if it's a short mission
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      delete formValues.contract_type_id;

      if (!formValues.end_date) {
        setDisableEverything(false);
        return toast.error('Veuillez sélectionner une date de fin');
      }
    }

    if (!isShortMission) {
      //    Remove end_date if it's not a short mission
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      delete formValues.end_date;
    }

    return toast
      .promise(
        // @ts-ignore
        createOffer(formValues),
        {
          loading: "Ajout de l'offre...",
          success: 'Offre ajoutée !',
          error: "Une erreur est survenue lors de l'ajout de l'offre",
        },
      )
      .then(() => {
        mutate();
        setDisableEverything(false);
        setAddOfferPopoverOpen(false);
      })
      .catch(() => {
        setDisableEverything(false);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitModifyOffer = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisableEverything(true);
    const data = formValues;

    if (!isShortMission) {
      if (!formValues.contract_type_id) {
        setDisableEverything(false);
        return toast.error('Veuillez sélectionner un type de contrat');
      }

      if (formValues.end_date !== null) {
        // @ts-ignore
        data.end_date = null;
      }
    } else {
      if (!formValues.end_date) {
        setDisableEverything(false);
        return toast.error('Veuillez sélectionner une date de fin');
      }

      if (formValues.contract_type_id) {
        // @ts-ignore
        data.contract_type_id = null;
      }
    }

    return toast
      .promise(
        // @ts-ignore
        editOffer(
          // @ts-ignore
          data,
          data.id,
        ),
        {
          loading: "Modification de l'offre...",
          success: 'Offre modifiée !',
          error: "Une erreur est survenue lors de la modification de l'offre",
        },
      )
      .then(() => {
        mutate();
        setDisableEverything(false);
        setModifyOfferPopoverOpen(false);
      })
      .catch(() => {
        setDisableEverything(false);
      });
  };

  const clearFormValues = () => {
    // @ts-ignore
    setFormValues((prev) => ({
      ...prev,
      id: 0,
      title: '',
      description: '',
      start_date: null,
      end_date: null,
      is_urgent: false,
      start_time: '8h00',
      contract_type_id: null,
      experience_required: '',
      conditions: '',
      profile_wished: '',
      missions: '',
      is_published: false,
      target: '',
    }));
  };

  return (
    <>
      {/* Popover for adding an offer */}
      <Popover
        title="Ajouter une offre"
        open={addOfferPopoverOpen}
        setOpen={(value) => {
          if (value === false) {
            clearFormValues();
          }
          setAddOfferPopoverOpen(value);
        }}
      >
        <div className="flex gap-3 justify-evenly">
          <p className="text-xs">
            Offre d&apos;emploi
          </p>
          <Switch
            onChange={() => setIsShortMission((prev) => !prev)}
            checked={isShortMission}
            crossOrigin={undefined}
          />
          <p className="text-xs">
            Mission Courte
          </p>
        </div>
        <div className="mb-3">
          {formValues.target?.length > 0 && (
            <p className="text-sm text-gray-600">
              Profils ciblés :
            </p>
          )}
          <div className="flex gap-2 flex-wrap">
            {formValues.target?.length > 0 && formValues.target.split(', ').map((target) => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {target.length > 0 && (
                  // @ts-ignore
                  <Chip value={(
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {target}
                      {/* eslint-disable-next-line react/button-has-type */}
                      <button
                        className="ml-2 cursor-pointer"
                        onClick={() => {
                          setFormValues((prev) => ({
                            ...prev,
                            target: prev.target.replace(
                              `${target}, `, '',
                            ),
                          }));
                        }}
                      >
                        x
                      </button>
                    </>
                  )}
                  />
                )}
              </>
            ))}
          </div>
        </div>
        <form
          className="flex flex-col gap-2 mb-3"
          onSubmit={(e) => {
            e.preventDefault();
            const target = profileTypes.find((type) => type.id === targetSelectValue);

            // If the target is already in the list, don't add it
            if (formValues.target.split(', ').includes(`${target.name}`)) {
              return toast('Ce profil est déjà dans la liste !');
            }

            return setFormValues((prev) => ({
              ...prev,
              target: formValues.target.concat(`${target.name}, `),
            }));
          }}
        >
          <div>
            <Select
                // @ts-ignore
              setValue={(e) => setTargetSelectValue(e.value)}
              options={profileTypes.map((type) => ({
                label: type.name,
                value: type.id,
              }))}
              label="Profils ciblés"
              // @ts-ignore
              value={targetSelectValue}
            />
          </div>
          <Button
            type="submit"
            size="sm"
            className="bg-primary flex items-center justify-center gap-2 w-full"
          >
            Ajouter
            <PlusIcon className="h-5 w-5" />
          </Button>
        </form>

        <form onSubmit={handleSubmitAddOffer}>
          <fieldset disabled={disableEverything} className="flex flex-col gap-3">
            {
              // @ts-ignore
              profile?.role?.code === 'admin' && (
              <Select
                setValue={(value) => {
                // @ts-ignore
                  setFormValues((prev) => ({ ...prev, establishment_id: value.value }));
                }}
                options={establishments.map((establishment) => ({
                  label: establishment.name,
                  value: establishment.id,
                }))}
                label="Etablissement"
                value={formValues.establishment_id}
                required
              />
              )
}

            <Input
              crossOrigin={false}
              type="text"
              label="Titre"
              name="title"
              value={
                                // @ts-ignore
                                formValues.title
                            }
              onChange={handleChange}
              required
            />
            <Textarea
              label="Description"
              name="description"
              value={formValues.description}
              onChange={(e) => setFormValues((prev) => ({
                ...prev,
                description: e.target.value,
              }))}
              required
            />
            <Input
              crossOrigin={false}
              type="date"
              label="Date de début"
              name="start_date"
              value={formValues.start_date}
              onChange={handleChange}
              required
            />
            {isShortMission && (
              <Input
                crossOrigin={false}
                type="date"
                label="Date de fin"
                name="end_date"
                value={formValues.end_date}
                onChange={handleChange}
                required={isShortMission}
              />
            )}

            <div>
              <div className="mb-1">
                <Input
                  crossOrigin={false}
                  type="text"
                  label="Heure de début"
                  name="start_time"
                  value={formValues.start_time}
                  onChange={handleChange}
                  required
                />
              </div>
              <p className="text-xs text-gray-600">
                Exemple: 05h30
              </p>
            </div>

            {!isShortMission && (
              <Select
                setValue={(value) => {
                  // @ts-ignore
                  setFormValues((prev) => ({ ...prev, contract_type_id: value.value }));
                }}
                options={contractTypes.map((contractType) => ({
                  label: contractType.name,
                  value: contractType.id,
                }))}
                label="Type de contrat"
                value={formValues.contract_type_id}
                required
              />
            )}

            <div>
              <div className="mb-1">
                <Input
                  crossOrigin={false}
                  type="text"
                  label="Expérience requise"
                  name="experience_required"
                  value={formValues.experience_required}
                  onChange={handleChange}
                  required
                />
              </div>
              <p className="text-xs text-gray-600">
                Indiquez 0 si aucune expérience n&apos;est requise.
              </p>
            </div>

            <Textarea
              label="Conditions"
              name="conditions"
              value={formValues.conditions}
              onChange={(e) => setFormValues((prev) => ({
                ...prev,
                conditions: e.target.value,
              }))}
            />

            <Textarea
              label="Profil recherché"
              name="profile_wished"
              value={formValues.profile_wished}
              onChange={(e) => setFormValues((prev) => ({
                ...prev,
                profile_wished: e.target.value,
              }))}
            />

            <Textarea
              label="Missions"
              name="missions"
              value={formValues.missions}
              onChange={(e) => setFormValues((prev) => ({
                ...prev,
                missions: e.target.value,
              }))}
            />

            <Switch
              crossOrigin={false}
              color="green"
              label={
                <p className="text-sm">Publier l&apos;offre ?</p>
                            }
              checked={formValues.is_published}
                            // eslint-disable-next-line max-len
              onChange={() => setFormValues((prev) => ({ ...prev, is_published: !prev.is_published }))}
            />

            <Switch
              crossOrigin={false}
              color="green"
              label={
                <p className="text-sm">L&apos;offre est-elle urgente ?</p>
                  }
              checked={formValues.is_urgent}
              onChange={() => setFormValues((prev) => ({ ...prev, is_urgent: !prev.is_urgent }))}
            />

            <Button
              size="sm"
              type="submit"
              className="bg-primary flex items-center justify-center gap-2"
            >
              Ajouter
              <PlusIcon className="h-5 w-5" />
            </Button>

          </fieldset>
        </form>
      </Popover>

      {/* Popover for editing an offer */}
      <Popover
        title="Modifier une offre"
        open={modifyOfferPopoverOpen}
        setOpen={setModifyOfferPopoverOpen}
      >
        <div className="flex gap-3 justify-evenly">
          <p className="text-xs">
            Offre d&apos;emploi
          </p>
          <Switch
            onChange={() => setIsShortMission((prev) => !prev)}
            checked={isShortMission}
            crossOrigin={undefined}
          />
          <p className="text-xs">
            Mission Courte
          </p>
        </div>
        <div className="mb-3">
          {formValues.target?.length > 0 && (
          <p className="text-sm text-gray-600">
            Profils ciblés :
          </p>
          )}
          <div className="flex gap-2 flex-wrap">
            {formValues.target?.length > 0 && formValues.target.split(', ').map((target) => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {target.length > 0 && (
                // @ts-ignore
                <Chip value={(
                                      // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {target}
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button
                      className="ml-2 cursor-pointer"
                      onClick={() => {
                        setFormValues((prev) => ({
                          ...prev,
                          target: prev.target.replace(
                            `${target}, `, '',
                          ),
                        }));
                      }}
                    >
                      x
                    </button>
                  </>
                                  )}
                />
                )}
              </>
            ))}
          </div>
        </div>

        <form
          className="flex flex-col gap-2 mb-3"
          onSubmit={(e) => {
            e.preventDefault();
            const target = profileTypes.find((type) => type.id === targetSelectValue);

            if (!formValues?.target) {
              return setFormValues((prev) => ({
                ...prev,
                target: `${target.name}, `,
              }));
            }

            // If the target is already in the list, don't add it
            if (formValues?.target?.split(', ').includes(`${target.name}`)) {
              return toast('Ce profil est déjà dans la liste !');
            }

            return setFormValues((prev) => ({
              ...prev,
              target: formValues?.target?.concat(`${target.name}, `),
            }));
          }}
        >
          <div>
            <Select
                          // @ts-ignore
              setValue={(e) => setTargetSelectValue(e.value)}
              options={profileTypes.map((type) => ({
                label: type.name,
                value: type.id,
              }))}
              label="Profils ciblés"
                          // @ts-ignore
              value={targetSelectValue}
            />
          </div>
          <Button
            type="submit"
            size="sm"
            className="bg-primary flex items-center justify-center gap-2 w-full"
          >
            Ajouter
            <PlusIcon className="h-5 w-5" />
          </Button>
        </form>

        <form onSubmit={handleSubmitModifyOffer}>
          <fieldset disabled={disableEverything} className="flex flex-col gap-3">
            <Input
              crossOrigin={false}
              type="text"
              label="Titre"
              name="title"
              value={
                              // @ts-ignore
                              formValues.title
                          }
              onChange={handleChange}
              required
            />

            <Textarea
              label="Description"
              name="description"
              value={formValues.description}
              onChange={(e) => setFormValues((prev) => ({
                ...prev,
                description: e.target.value,
              }))}
              required
            />

            <Input
              crossOrigin={false}
              type="date"
              label="Date de début"
              name="start_date"
              value={formValues.start_date}
              onChange={handleChange}
              required
            />

            {isShortMission && (
            <Input
              crossOrigin={false}
              type="date"
              label="Date de fin"
              name="end_date"
              value={formValues.end_date}
              onChange={handleChange}
              required={isShortMission}
            />
            )}

            <div>
              <div className="mb-1">
                <Input
                  crossOrigin={false}
                  type="text"
                  label="Heure de début"
                  name="start_time"
                  value={formValues.start_time}
                  onChange={handleChange}
                  required
                />
              </div>
              <p className="text-xs text-gray-600">
                Exemple: 05h30
              </p>
            </div>

            {!isShortMission && (
            <Select
              setValue={(value) => {
                // @ts-ignore
                setFormValues((prev) => ({ ...prev, contract_type_id: value.value }));
              }}
              options={contractTypes.map((contractType) => ({
                label: contractType.name,
                value: contractType.id,
              }))}
              label="Type de contrat"
              value={formValues.contract_type_id}
            />
            )}

            <Input
              crossOrigin={false}
              type="text"
              label="Expérience requise"
              name="experience_required"
              value={formValues.experience_required}
              onChange={handleChange}
              required
            />
            <p className="text-xs text-gray-600">
              Indiquez 0 si aucune expérience n&apos;est requise.
            </p>

            <Textarea
              label="Conditions"
              name="conditions"
              value={formValues.conditions}
              onChange={(e) => setFormValues((prev) => ({
                ...prev,
                conditions: e.target.value,
              }))}
            />

            <Textarea
              label="Profil recherché"
              name="profile_wished"
              value={formValues.profile_wished}
              onChange={(e) => setFormValues((prev) => ({
                ...prev,
                profile_wished: e.target.value,
              }))}
            />

            <Textarea
              label="Missions"
              name="missions"
              value={formValues.missions}
              onChange={(e) => setFormValues((prev) => ({
                ...prev,
                missions: e.target.value,
              }))}
            />

            <Switch
              crossOrigin={false}
              color="green"
              label={
                <p className="text-sm">L&apos;offre est-elle urgente ?</p>
                  }
              checked={formValues.is_urgent}
              onChange={() => setFormValues((prev) => ({ ...prev, is_urgent: !prev.is_urgent }))}
            />

            <Switch
              crossOrigin={false}
              color="green"
              label={
                <p className="text-sm">Publier l&apos;offre ?</p>
                          }
              checked={formValues.is_published}
                          // eslint-disable-next-line max-len
              onChange={() => setFormValues((prev) => ({ ...prev, is_published: !prev.is_published }))}
            />

            <Button
              size="sm"
              type="submit"
              className="bg-primary flex items-center justify-center gap-2"
            >
              Modifier
              <PlusIcon className="h-5 w-5" />
            </Button>
          </fieldset>
        </form>
      </Popover>
    </>
  );
}
