import useSWR from 'swr';
import { getProfile, getProfileByUserID } from '@lib/Profile';
import supabase from '@lib/Supabase';
import { getSingleConversation } from '@lib/Conversation';

const tableName = 'profiles_offers';
const selectQuery = `
  profile_id,
  offer_id,
  retained
`;

/**
 * Get conversations if has applied
 * @param hasApplied
 * @param offerID
 */
export const getAppliedConversation = async (
  hasApplied: boolean,
  offerID: number,
) => {
  if (hasApplied === null) return [];
  if (!offerID) throw new Error('Offer ID is required');

  const profile = await getProfile();

  if (!profile) throw new Error('Profile not found');

  if (hasApplied) {
    // Get conversation ID
    const {
      data: conversation,
      error: conversationError,
    } = await supabase
      .from('conversation')
      .select('id,is_open')
      .eq(
        'offer_id', offerID,
      )
      .eq(
        'sender_id', profile?.id,
      )
      .eq(
        'is_open', 'true',
      );

    if (conversationError) throw conversationError;

    return conversation[0];
  }

  return [];
};

/**
 * Get if has applied
 */
export const getHasApplied = async (offerID: number) => {
  const profile = await getProfile();

  if (!profile) throw new Error('Profile not found');

  const { data, error } = await supabase
    .from(tableName)
    .select(selectQuery);

  if (error) throw new Error(error.message);

  const hasApplied = data?.find((item) => item
    .offer_id === offerID && item.profile_id === profile.id);

  if (!hasApplied) return false;

  const appliedConversation = await getAppliedConversation(
    true, offerID,
  );

  if (!appliedConversation) return false;

  return {
    ...hasApplied,
    // @ts-ignore
    conversation_id: appliedConversation.id,
  };
};

/**
 * Get real-time has applied
 * @param offerID
 */
export const getCandidateMissions = async () => {
  const profile = await getProfile();

  if (!profile) throw new Error('Profile not found');

  const { data, error } = await supabase
    .from(tableName)
    .select(`
      profile_id,
      offer_id,
      offer:offer_id (
        id,
        created_at,
        title,
        description,
        start_date,
        end_date,
        start_time,
        establishment_id,
        is_urgent,
        contract_type_id,
        experience_required,
        conditions,
        profile_wished,
        full_time,
        missions,
        is_published,
        contract_type:contract_type_id (
          id,
          name
        ),
        establishment:establishment_id (
          id,
          name,
          description,
          phone,
          website,
          capacity,
          public,
          address:address_id (
            id,
            first_line,
            second_line,
            city,
            zip,
            country
          ),
        )
      ),
      retained
    `)
    .eq(
      'profile_id', profile.id,
    )
    .eq(
      'retained', true,
    );

  if (error) throw new Error(error.message);

  return data;
};

/**
 * Get real-time has applied
 */
export const useCandidateMissions = () => useSWR(
  'candidate-missions', () => getCandidateMissions(),
);

export const getIsRetainedByOffer = async (offerID: number) => {
  const profile = await getProfile();

  if (!profile) throw new Error('Profile not found');

  const { data, error } = await supabase
    .from(tableName)
    .select(selectQuery)
    .eq(
      'offer_id', offerID,
    );

  if (error) throw new Error(error.message);

  const isRetained = data?.find((item) => item.retained === true);

  if (!isRetained) {
    //   Check if no answer or rejected
    if (data?.find((item) => item.retained === false)) {
      return false;
    }

    return null;
  }

  return true;
};

export const useIsRetainedByOffer = (offerID: number) => useSWR(
  `is-retained-${offerID}`, () => getIsRetainedByOffer(offerID),
);

/**
 * Get real-time has applied
 */
export const useHasApplied = (offerID: number) => useSWR(
  `has-applied-${offerID}`, () => getHasApplied(offerID),
);

/**
 * Get if has retained
 * @param conversationID
= */
export const getIsRetained = async (conversationID: number) => {
  if (!conversationID) throw new Error('Offer ID is required');
  const profile = await getProfile();
  const conversation = await getSingleConversation(conversationID);

  if (!profile) throw new Error('Profile not found');

  const { data, error } = await supabase
    .from(tableName)
    .select(selectQuery)
    .eq(
      'offer_id', conversation.offer_id,
    );

  if (error) throw new Error(error.message);

  const isRetained = data?.find((item) => item.retained === true);

  if (!isRetained) {
  //   Check if no answer or rejected
    if (data?.find((item) => item.retained === false)) {
      return false;
    }

    return null;
  }

  return true;
};

/**
 * Get real-time is retained
 */
export const useIsRetained = (offerID: number) => useSWR(
  `is-retained-${offerID}`, () => getIsRetained(offerID),
);

export const getProfileOffers = async () => {
  const { data, error } = await supabase
    .from(tableName)
    .select(selectQuery);

  if (error) throw new Error(error.message);

  return data;
};

/**
 * Get real-time profile offers
 */
export const useProfileOffers = () => useSWR(
  'profile-offers', () => getProfileOffers(),
);

export const getCandidateMissionsByUserID = async (userID: string) => {
  const profile = await getProfileByUserID(userID);

  if (!profile) throw new Error('Profile not found');

  const profileID = profile.id;

  const { data, error } = await supabase
    .from(tableName)
    .select(`
        profile_id,
        offer_id,
        offer:offer_id (
            id,
            created_at,
            title,
            description,
            start_date,
            end_date,
            start_time,
            establishment_id,
            is_urgent,
            contract_type_id,
            experience_required,
            conditions,
            profile_wished,
            full_time,
            missions,
            is_published,
            contract_type:contract_type_id (
              id,
              name
            ),
            establishment:establishment_id (
              id,
              name
            )
        ),
        retained
        `)
    .eq(
      'profile_id', profileID,
    );

  if (error) throw new Error(error.message);

  return data;
};
