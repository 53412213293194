import {
  Button, IconButton, Textarea, Tooltip, Typography,
} from '@material-tailwind/react';
import {
  ChatBubbleLeftIcon, CheckIcon, ExclamationCircleIcon, EyeIcon, StarIcon, XMarkIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import {
  refuseCandidate, retainCandidate, useOffer, useOfferCandidates,
} from '@lib/Offer';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Modal from '@components/Modal';
import Popover from '@components/Popover';
import Chip from '@components/Chip';
import { useManagerEstablishment } from '@lib/Establishment';
import Supabase from '@lib/Supabase';
import { insertSignalment } from '@lib/signalment';
import {
  rateCandidate,
  useCandidateOverallRating, useCandidateRatings,
} from '@lib/Rating';

export default function CandidateActions({
  candidateID,
  offerID,
  mutate,
}: {
  candidateID: number,
  offerID: number,
  mutate?: () => void
}): React.ReactElement {
  if (!offerID) {
    throw new Error('offerID is required');
  }

  if (!candidateID) {
    throw new Error('candidateID is required');
  }

  const navigate = useNavigate();
  const [viewUserPopoverOpen, setViewUserPopoverOpen] = React.useState(false);
  const [ensureRefuseCandidate, setEnsureRefuseCandidate] = React.useState(false);
  const [refuseMessage, setRefuseMessage] = React.useState('');
  const [signalUserPopoverOpen, setSignalUserPopoverOpen] = React.useState(false);
  const [signalmentMessage, setSignalmentMessage] = React.useState('');
  const [ratingModalOpen, setRatingModalOpen] = React.useState(false);
  const [ratings, setRatings] = React.useState([
    { criteria: 'Qualité des soins', rating: 0 },
    { criteria: 'Ponctualité', rating: 0 },
    { criteria: 'Savoir-être', rating: 0 },
  ]);

  const { data, error, mutate: localMutation } = useOfferCandidates(offerID);
  const {
    data: currentEstablishment,
    error: managerEstablishmentError,
  } = useManagerEstablishment();
  const { data: offer, error: offerError } = useOffer(offerID);
  const {
    data: candidateOverallRating,
    error: candidateOverallRatingError,
  } = useCandidateOverallRating(candidateID);
  const {
    data: candidateRating,
    error: candidateRatingError,
  } = useCandidateRatings(candidateID);

  if (candidateRatingError) {
    return <p className="text-xs">{candidateRatingError.message}</p>;
  }

  if (candidateOverallRatingError) {
    return <p className="text-xs">{candidateOverallRatingError.message}</p>;
  }

  if (managerEstablishmentError) {
    return <p className="text-xs">{managerEstablishmentError.message}</p>;
  }

  if (offerError) {
    return <p className="text-xs">{offerError.message}</p>;
  }

  if (error) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  if (!data) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  if (data.length === 0) {
    throw new Error(`This chat is linked to a conversation without candidates. Offer ID: ${offerID}, candidate ID: ${candidateID}`);
  }

  const candidate = data?.find((c) => c.profile_id === candidateID);

  if (!candidate) {
    throw new Error('Cannot find candidate in candidates list');
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const { retained } = candidate;

  /**
   * Retain a candidate
   * @param profileID
   */
  const handleRetainCandidateClick = async (profileID: number) => {
    toast.promise(
      retainCandidate(
        offerID, profileID,
      ), {
        loading: 'Retenue du candidat...',
        success: 'Candidat retenu !',
        error: 'Une erreur est survenue lors de la retenue du candidat',
      },
    ).then(() => {
      if (mutate) mutate();
      localMutation();
    })
      .catch(() => {
        if (mutate) mutate();
        localMutation();
      });
  };

  /**
   * Refuse a candidate
   * @param profileID
   */
  const handleRefuseCandidate = async (profileID: number) => toast.promise(
    refuseCandidate(
      offerID,
      profileID,
      refuseMessage,
    ), {
      loading: 'Refus du candidat...',
      success: 'Candidat refusé !',
      error: 'Une erreur est survenue lors du refus du candidat',
    },
  ).then(() => {
    if (mutate) mutate();
    localMutation();
  })
    .catch(() => {
      if (mutate) mutate();
      localMutation();
    });

  /**
   * Handle refuse candidate click
   */
  const handleRefuseCandidateClick = async () => {
    setRefuseMessage(`Madame, Monsieur,
  
Nous vous remercions pour l'intérêt que vous avez porté à notre offre d'emploi. Malheureusement, nous avons le regret de vous informer que votre profil ne correspond pas à ce que nous recherchons.

En vous souhaitant une bonne continuation.

Cordialement,
L'Etablissement ${currentEstablishment?.name}`);
    setEnsureRefuseCandidate(true);
  };

  const offerHasStarted = new Date(offer?.start_date) < new Date();

  /**
   * Download a document
   * @param documentToDownload
   * @param userID
   */
  const downloadDocument = async (
    documentToDownload: { id: any; name: any; }, userID: string,
  ) => {
    const { data: fileData, error: fileDownloadError } = await Supabase
      .storage
      .from('documents')
      .download(`${userID}/${documentToDownload.name}`);

    if (fileDownloadError) {
      // @ts-ignore
      if (fileDownloadError.status === 400) {
        throw new Error('Le document est introuvable');
      }

      throw new Error(fileDownloadError.message);
    }

    //   Data is a blob, let's download it
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      documentToDownload.name,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  /**
   * Handle download document
   * @param documentToDownload
   * @param userID
   */
  const handleDownloadDocument = async (
    documentToDownload: { id: any; name: any; },
    userID: string,
  ) => toast.promise(
    downloadDocument(
      documentToDownload, userID,
    ), {
      loading: 'Téléchargement du document...',
      success: 'Document téléchargé !',
      error: (downloadDocumentError) => downloadDocumentError.message,
    },
  );

  /**
   * Signal a candidate
   */
  const signalCandidate = async () => {
    await insertSignalment({
      // @ts-ignore
      candidate_id: candidate?.profile?.user_id,
      establishment_id: currentEstablishment?.id,
      message: signalmentMessage,
    });
  };

  /**
   * Handle signal candidate
   */
  const handleSignalCandidate = async () => toast.promise(
    signalCandidate(), {
      loading: 'Signalement du candidat...',
      success: 'Candidat signalé !',
      error: 'Une erreur est survenue lors du signalement du candidat',
    },
  ).then(() => {
    if (mutate) mutate();
    localMutation();
  })
    .catch(() => {
      if (mutate) mutate();
      localMutation();
    });

  const isRetained = candidate?.retained === true;
  const offerHasEnded = offer?.end_date
      && new Date(offer?.end_date).toISOString() < new Date().toISOString();

  const establishmentHasGivenRating = candidateRating?.some((rating) => rating
  // @ts-ignore
    .user_id === offer?.establishment?.user_id);

  /**
   * Submit rating
   */
  const ratingSubmit = async () => {
    for (let i = 0; i < ratings.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await rateCandidate(
        // @ts-ignore
        candidate?.profile_id,
        offer?.establishment_id,
        ratings[i].rating,
        ratings[i].criteria,
        offer?.id,
      );
    }
  };

  /**
   * Handle rating submit
   */
  const handleRatingSubmit = async () => toast.promise(
    ratingSubmit(), {
      loading: 'Envoi de votre note...',
      success: 'Votre note a bien été envoyée !',
      error: 'Erreur lors de l\'envoi de votre note',
    },
  );

  return (
    <>
      <Tooltip content="Voir le candidat">
        <IconButton variant="text" onClick={() => setViewUserPopoverOpen(true)}>
          <EyeIcon className="h-5 w-5" />
        </IconButton>
      </Tooltip>
      <Tooltip content="Refuser le candidat">
        <IconButton
          variant="text"
          disabled={retained === false || offerHasStarted}
          onClick={() => handleRefuseCandidateClick()}
        >
          <XMarkIcon className="h-5 w-5 text-error" strokeWidth={3} />
        </IconButton>
      </Tooltip>
      <Tooltip content="Discuter avec le candidat">
        <IconButton
          variant="text"
          onClick={() => navigate('/chat')}
          disabled={retained === false}
        >
          <ChatBubbleLeftIcon className="h-5 w-5" />
        </IconButton>
      </Tooltip>
      <Tooltip content="Retenir le candidat">
        <IconButton
          variant="text"
          onClick={() => handleRetainCandidateClick(candidate.profile_id)}
          disabled={
          // @ts-ignore
          retained === true || candidate?.profile?.validated !== true
}
        >
          <CheckIcon className="h-5 w-5 text-success" strokeWidth={3} />
        </IconButton>
      </Tooltip>
      <Tooltip content="Signaler un problème">
        <IconButton
          variant="text"
          onClick={() => setSignalUserPopoverOpen(true)}
          disabled={retained === false || !offerHasStarted}
        >
          <ExclamationCircleIcon className="h-5 w-5 text-warning" strokeWidth={3} />
        </IconButton>
      </Tooltip>

      <Modal
        setOpen={setEnsureRefuseCandidate}
        title="Refuser le candidat"
        open={ensureRefuseCandidate}
        // @ts-ignore
        callBack={() => handleRefuseCandidate(candidate?.profile?.id)}
        confirmText="Refuser"
      >
        <p className="mb-2 text-base">
          Êtes-vous sûr de vouloir refuser le candidat
          {' '}
          {/* @ts-ignore */}
          {candidate?.profile?.first_name}
          {' '}
          {/* @ts-ignore */}
          {candidate?.profile?.last_name}
          ?
        </p>
        <p className="mb-2 text-base">
          Cette action enverra un mail au candidat pour l&apos;informer de son refus.
        </p>
        <p className="mb-2 text-base">
          Vous pouvez, si vous le souhaitez, lui envoyer un message personnalisé:
        </p>
        <div>
          <Textarea
            label="Message"
            // @ts-ignore
            name={`message-${candidate?.profile?.id}`}
            value={refuseMessage}
            onChange={(e) => setRefuseMessage(e.target.value)}
            rows={12}
          />
        </div>
      </Modal>

      <Modal
        setOpen={setSignalUserPopoverOpen}
        title="Faire un signalement"
        open={signalUserPopoverOpen}
          // @ts-ignore
        callBack={() => handleSignalCandidate()}
        confirmText="Signaler"
        confirmColor="warning"
      >
        <p className="mb-2 text-base">
          Êtes-vous sûr de vouloir faire un signalement pour le candidat
          {' '}
          {/* @ts-ignore */}
          {candidate?.profile?.first_name}
          {' '}
          {/* @ts-ignore */}
          {candidate?.profile?.last_name}
          ?
        </p>
        <p className="mb-2 text-base">
          Cette action enverra un mail au candidat ainsi qu&au personnel Azélie Santé.
        </p>
        <p className="mb-2 text-base">
          Précisez la raison de votre signalement:
        </p>
        <div>
          <Textarea
            label="Message"
              // @ts-ignore
            name={`signalmentMessage-${candidate?.profile?.id}`}
            value={signalmentMessage}
            onChange={(e) => setSignalmentMessage(e.target.value)}
            rows={12}
            required
          />
        </div>
      </Modal>

      {/* Popover for signaling users */}
      <Popover
        title="Informations sur le candidat"
        open={viewUserPopoverOpen}
        setOpen={setViewUserPopoverOpen}
      >
        <div className="mb-5">
          {/* @ts-ignore */}
          <Chip
            value={candidate?.retained === true ? 'Retenu' : 'Refusé'}
            variant={candidate?.retained === true ? 'success' : 'danger'}
          />
        </div>
        <Typography variant="h6" className="font-semibold text-gray-600 text-sm mb-5">
          Informations générales
        </Typography>

        <div className="rounded-lg border mb-5">
          <table className="w-full table-auto text-left">
            <thead>
              <tr>
                <th className="bg-blue-gray-50/50 p-4 transition-colors">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    Clé
                  </Typography>
                </th>
                <th className="bg-blue-gray-50/50 p-4 transition-colors">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    Valeur
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Nom</td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {candidate?.profile?.first_name}
                  {' '}
                  {/* @ts-ignore */}
                  {candidate?.profile?.last_name}
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Note</td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {candidateOverallRating ? `${candidateOverallRating}/5` : 'N\'a pas encore été noté'}
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Âge</td>
                <td className="p-4">
                  <Chip
                    // @ts-ignore
                    value={candidate?.profile?.birth_date ? `${new Date().getFullYear() - new Date(candidate?.profile?.birth_date).getFullYear()} ans` : 'Non renseigné'}
                    // @ts-ignore
                    variant={candidate?.profile?.role?.name ? 'primary' : 'secondary'}
                  />
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Date de Création du compte</td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {candidate?.profile
                  //   @ts-ignore
                    .created_at && new Date(candidate?.profile
                  //   @ts-ignore
                    .created_at).toLocaleDateString(
                    'fr-FR', { year: 'numeric', month: 'long', day: 'numeric' },
                  )}
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Téléphone</td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {candidate?.profile?.phone || 'Non renseigné'}
                </td>
              </tr>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Années d&apos;expérience</td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {candidate?.profile?.experience_years || 0}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mb-5">
          <Chip value="Biographie" />
          <p>
            {/* @ts-ignore */}
            {candidate?.profile?.bio || 'Non renseignée'}
          </p>
        </div>

        <div className="mb-5">
          <Chip value="Éducations" />
          <p>
            {/* @ts-ignore */}
            {candidate?.profile?.education.map((edu) => (
              <div>
                <p>
                  {edu?.degree}
                  {' '}
                  -
                  {' '}
                  {edu?.school}
                  {' '}
                  -
                  {' '}
                  {edu?.year}
                </p>
              </div>
            ))}
          </p>
          {/* @ts-ignore */}
          {candidate?.profile?.education.length === 0 && (
          <p>Non renseignées</p>
          )}
        </div>

        <div className="mb-5">
          <Chip value="Expériences" />
          <p>
            {/* @ts-ignore */}
            {candidate?.profile?.experience.map((exp) => (
              <div>
                <p>
                  {exp?.title}
                  {' '}
                  -
                  {' '}
                  {exp?.company}
                  {' '}
                  -
                  {' '}
                  {exp?.year}
                </p>
              </div>
            ))}
          </p>
          {/* @ts-ignore */}
          {candidate?.profile?.experience.length === 0 && (
          <p>Non renseignées</p>
          )}
        </div>

        <section className="mb-5">
          {
            // @ts-ignore
            candidate?.profile?.documents && candidate?.profile?.documents?.length > 0 ? (
            // @ts-ignore
              candidate?.profile?.documents?.map((document) => (
                <article
                  key={document.id}
                  className="border p-5 rounded-lg mb-5 flex flex-col gap-3 items-center justify-between"
                >
                  <Typography variant="h5" className="text-sm">
                    {document.name}
                  </Typography>
                  <Typography variant="h5" className="text-sm text-gray-600">
                    Type:
                    {' '}
                    {document.type?.name}
                  </Typography>
                  <div className="flex gap-2 w-full">
                    {/*  Download button */}
                    <Button
                      size="sm"
                      className="bg-primary text-white shadow-none w-full"
                      onClick={async () => handleDownloadDocument(
                        //   @ts-ignore
                        document, candidate?.profile?.user_id,
                      )}
                    >
                      Télécharger
                    </Button>
                  </div>
                </article>
              ))
            ) : (
              <span>
                Le candidat n&apos;a pas encore envoyé de documents
              </span>
            )
          }
        </section>

        <div className="flex justify-between mb-5">
          <Button
            className="bg-error flex items-center justify-center gap-2"
              // @ts-ignore
            onClick={() => handleRefuseCandidateClick(candidate.profile.id)}
              // @ts-ignore
            disabled={candidate?.retained === false
                || (candidate?.retained === true && offerHasEnded) || offerHasStarted}
          >
            Refuser
            <CheckIcon className="h-5 w-5" />
          </Button>
          <Button
            className="bg-success flex items-center justify-center gap-2"
              // @ts-ignore
            onClick={() => handleRetainCandidateClick(candidate?.profile?.id)}
              // @ts-ignore
            disabled={candidate?.retained === true
                || (candidate?.retained === true && offerHasEnded) || offerHasStarted}
          >
            Retenir
            <XMarkIcon className="h-5 w-5 ml-2" />
          </Button>
        </div>
        {isRetained && offerHasEnded && (
          <Button
            onClick={() => setRatingModalOpen(true)}
            className="bg-primary w-full"
            disabled={establishmentHasGivenRating}
          >
            Noter le candidat
          </Button>
        )}
      </Popover>
      <Modal
        setOpen={setRatingModalOpen}
        title={"Noter l'établissement"}
        open={ratingModalOpen}
        confirmText="Envoyer ma note"
        callBack={handleRatingSubmit}
      >
        <div className="flex flex-col items-start gap-3">
          {ratings.map((
            rating, index,
          ) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="flex justify-between items-center gap-2 w-full" key={index}>
              <div className="flex items-center gap-2">
                <StarIcon className="text-primary w-5 h-5" />
                <Typography color="gray" className="text-sm">
                  {rating.criteria}
                </Typography>
              </div>

              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  required
                  className="hidden"
                  name={rating.criteria}
                  id={`${rating.criteria}-1`}
                  value={1}
                  onChange={(e) => {
                    const newRatings = [...ratings];
                    newRatings[index].rating = parseInt(
                      e.target.value, 10,
                    );
                    setRatings(newRatings);
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`${rating.criteria}-1`}>1</label>

                <input
                  type="radio"
                  required
                  name={rating.criteria}
                  id={`${rating.criteria}-2`}
                  value={2}
                  onChange={(e) => {
                    const newRatings = [...ratings];
                    newRatings[index].rating = parseInt(
                      e.target.value, 10,
                    );
                    setRatings(newRatings);
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`${rating.criteria}-2`}>2</label>

                <input
                  type="radio"
                  required
                  name={rating.criteria}
                  id={`${rating.criteria}-3`}
                  value={3}
                  onChange={(e) => {
                    const newRatings = [...ratings];
                    newRatings[index].rating = parseInt(
                      e.target.value, 10,
                    );
                    setRatings(newRatings);
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`${rating.criteria}-3`}>3</label>

                <input
                  type="radio"
                  required
                  name={rating.criteria}
                  id={`${rating.criteria}-4`}
                  value={4}
                  onChange={(e) => {
                    const newRatings = [...ratings];
                    newRatings[index].rating = parseInt(
                      e.target.value, 10,
                    );
                    setRatings(newRatings);
                  }}
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`${rating.criteria}-4`}>4</label>

                <input
                  type="radio"
                  required
                  name={rating.criteria}
                  id={`${rating.criteria}-5`}
                  value={5}
                  onChange={(e) => {
                    const newRatings = [...ratings];
                    newRatings[index].rating = parseInt(
                      e.target.value, 10,
                    );
                    setRatings(newRatings);
                  }}
                />

                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={`${rating.criteria}-5`}>5</label>

              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}

CandidateActions.defaultProps = {
  mutate: () => {
  },
};
