import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DrawerContext } from '@/context/Drawer';
import React, { useContext } from 'react';

interface ActiveLinkProps
{
  children: React.ReactNode;
  className?: string;
  to: string;
  as?: string;
  target?: string;
  title?: string;
  onClick?: () => void;
}

/**
 * @param props
 *
 */
export default function ActiveLink({
  children,
  className,
  to,
  as,
  target,
  title,
  onClick,
}: ActiveLinkProps): React.ReactElement {
  const location = useLocation();
  const { pathname } = location;
  const isSamePath = pathname === to || pathname === as;
  const isSubPage = pathname.startsWith(to) && to !== '/';
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [drawerOpen, setDrawerOpen] = useContext(DrawerContext);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
    setDrawerOpen(false);
    navigate(to);
  };

  return (
    <Link
      target={target}
      to={to}
      title={title}
      onClick={handleClick}
      className={classNames(
        className, { active: isSamePath || isSubPage },
      )}
    >
      {children}
    </Link>
  );
}

ActiveLink.defaultProps = {
  className: '',
  as: '',
  target: '',
  onClick: () => {},
  title: '',
};
