import Supabase from '@lib/Supabase';
import useSWR from 'swr';

const tableName = 'profile_type';
const selectQuery = `
  id,
  name
`;

/**
 * Get all job types
 */
export const getJobTypes = async (): Promise<{
    id: number,
    name: string,
}[]> => {
  const { data, error } = await Supabase
    .from(tableName)
    .select(selectQuery)
    .order(
      'id', { ascending: true },
    );

  if (error) throw new Error(error.message);

  return data;
};

/**
 * Get real-time job types
 */
export const useJobTypes = () => useSWR(
  'all-job-types', getJobTypes,
);

/**
 * Create job type
 */
export const createJobType = async (data: any) => {
  const { error } = await Supabase
    .from(tableName)
    .insert(data);

  if (error) throw new Error(error.message);

  return true;
};

/**
 * Update job type
 */
export const updateJobType = async (
  id: number, value: string,
) => {
  if (!value) throw new Error('Value is required');
  if (!id) throw new Error('Id is required');

  const { error } = await Supabase
    .from(tableName)
    .update({
      name: value,
    })
    .eq(
      'id', id,
    );

  if (error) throw new Error(error.message);

  return true;
};

/**
 * Delete job type
 */
export const deleteJobType = async (id: number) => {
  const { error } = await Supabase
    .from(tableName)
    .delete()
    .eq(
      'id', id,
    );

  if (error) throw new Error(error.message);

  return true;
};
