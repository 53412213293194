import Supabase from '@lib/Supabase';
import useSWR from 'swr';
import { getProfile } from '@lib/Profile';

/**
 * Get all educations
 */
const getEducations = async () => {
  const profile = await getProfile();

  if (!profile) {
    throw new Error('No profile found');
  }

  const { data, error } = await Supabase
    .from('education')
    .select('*')
    .eq(
      // @ts-ignore
      'profile_id', profile.id,
    )
    .order(
      'created_at', { ascending: false },
    );

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

/**
 * Get all educations
 */
export const useEducations = () => useSWR(
  'educations', () => getEducations(),
);

/**
 * Get education by id
 * @param educationData
 */
export const createEducation = async (educationData: unknown) => {
  const profile = await getProfile();

  if (!profile) {
    throw new Error('No profile found');
  }

  if (!educationData) {
    throw new Error('No education data found');
  }

  // If end_date is empty, remove it
  // @ts-ignore
  if (!educationData?.end_date) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    delete educationData?.end_date;
  }

  const { data: education, error } = await Supabase
    .from('education')
    .insert({
      // @ts-ignore
      ...educationData,
      // @ts-ignore
      profile_id: profile.id,
    });

  if (error) {
    throw new Error(error.message);
  }

  return education;
};

/**
 * Get education by id
 * @param educationData
 */
export const updateEducation = async (educationData: any) => {
  const profile = await getProfile();

  if (!profile) {
    throw new Error('No profile found');
  }

  if (!educationData) {
    throw new Error('No education data found');
  }

  // @ts-ignore
  if (!educationData?.id) {
    throw new Error('No education id found');
  }

  const { data: education, error } = await Supabase
    .from('education')
    .update({
      // @ts-ignore
      ...educationData,
    })
    .eq(
      // @ts-ignore
      'profile_id', profile?.id,
    )
    .eq(
      // @ts-ignore
      'id', educationData?.id,
    );

  if (error) {
    throw new Error(error.message);
  }

  return education;
};

/**
 * Get education by id
 * @param educationID
 */
export const deleteEducation = async (educationID: number) => {
  if (!educationID) {
    throw new Error('No education id found');
  }

  const { data: education, error } = await Supabase
    .from('education')
    .delete()
    .eq(
      'id', educationID,
    );

  if (error) {
    throw new Error(error.message);
  }

  return education;
};
