import Supabase from '@lib/Supabase';
import useSWR from 'swr';

/**
 * Get all candidate ratings
 * @param userID
 */
export const getCandidateRatings = async (userID: number) => {
  const { data, error } = await Supabase
    .from('rating_candidate')
    .select(`
        id,
        profile_id,
        stars,
        establishment_id,
        criteria,
        offer_id
    `)
    .eq(
      'profile_id', userID,
    );

  if (error) throw new Error(error.message);

  return data;
};

/**
 * Get real-time candidate ratings
 * @param userID
 */
export const useCandidateRatings = (userID: number) => useSWR(
  'candidate-ratings', () => getCandidateRatings(userID),
);

/**
 * Get overall rating
 * @param ratings
 * @returns {number}
 */
const getOverallRating = (ratings: any[]): number => {
  const sum = ratings.reduce(
    (
      acc, rating,
    ) => acc + parseInt(
      rating.stars, 10,
    ), 0,
  );

  // Round to 1 decimal
  return Math.round((sum / ratings.length) * 10) / 10;
};

/**
 * Get candidate overall rating
 * @param userID
 */
export const getCandidateOverallRating = async (userID: number) => {
  const { data, error } = await Supabase
    .from('rating_candidate')
    .select(`
            stars
        `)
    .eq(
      'profile_id', userID,
    );

  if (error) throw new Error(error.message);

  return getOverallRating(data);
};

/**
 * Get real-time candidate overall rating
 * @param userID
 */
export const useCandidateOverallRating = (userID: number) => useSWR(
  'candidate-overall-rating', () => getCandidateOverallRating(userID),
);

/**
 * Get establishment rating
 * @param establishmentID
 */
export const getEstablishmentRatings = async (establishmentID: number) => {
  if (!establishmentID) throw new Error('Missing establishment ID');
  const { data, error } = await Supabase
    .from('rating_establishment')
    .select(`
            id,
            profile_id,
            stars,
            establishment_id,
            criteria,
            offer_id
        `)
    .eq(
      'establishment_id', establishmentID,
    );

  if (error) throw new Error(error.message);

  return data;
};

/**
 * Get real-time establishment ratings
 * @param establishmentID
 */
export const useEstablishmentRatings = (establishmentID: number) => useSWR(
  'establishment-ratings', () => getEstablishmentRatings(establishmentID),
);

/**
 * Get establishment rating categories
 * @param establishmentID
 */
export const getEstablishmentRatingCategories = async (establishmentID: number) => {
  if (!establishmentID) throw new Error('Missing establishment ID');
  const { data, error } = await Supabase
    .from('rating_establishment')
    .select(`
            id,
            profile_id,
            stars,
            establishment_id,
            criteria,
            offer_id
        `)
    .eq(
      'establishment_id', establishmentID,
    );

  if (error) throw new Error(error.message);

  // There are 3 categories, let's sum the ratings for each category
  const sumedRatings = data.reduce(
    (
      acc, rating,
    ) => {
      const category = rating.criteria;
      // @ts-ignore
      if (!acc[category]) {
        // @ts-ignore
        acc[category] = [];
      }
      // @ts-ignore
      acc[category].push(rating);
      return acc;
    }, {},
  );

  // Calculate the average for each category
  return Object.keys(sumedRatings).reduce(
    (
      acc, category,
    ) => {
      // @ts-ignore
      acc[category] = getOverallRating(sumedRatings[category]);
      return acc;
    }, {},
  );
};

/**
 * Get real-time establishment rating
 * @param establishmentID
 */
export const useEstablishmentRatingCategories = (establishmentID: number) => useSWR(
  'establishment-rating-categories', () => getEstablishmentRatingCategories(establishmentID),
);

/**
 * Get establishment overall rating
 * @param establishmentID
 */
export const getEstablishmentOverallRating = async (establishmentID:number) => {
  if (!establishmentID) throw new Error('Missing establishment ID');
  const { data, error } = await Supabase
    .from('rating_establishment')
    .select(`
            stars
        `)
    .eq(
      'establishment_id', establishmentID,
    );

  if (error) throw new Error(error.message);

  return getOverallRating(data);
};

/**
 * Get real-time establishment overall rating
 * @param establishmentID
 */
export const useEstablishmentOverallRating = (establishmentID: number) => useSWR(
  'establishment-overall-rating', () => getEstablishmentOverallRating(establishmentID),
);

/**
 * Rate establishment
 * @param establishmentID
 * @param profileID
 * @param stars
 * @param criteria
 * @param offerID
 */
export const rateEstablishment = async (
  establishmentID: number,
  profileID: number,
  stars: number,
  criteria: string,
  offerID: number,
) => {
  const { data, error } = await Supabase
    .from('rating_establishment')
    .insert([
      {
        establishment_id: establishmentID,
        profile_id: profileID,
        stars,
        criteria,
        offer_id: offerID,
      },
    ]);

  if (error) throw new Error(error.message);

  return data;
};

/**
 * Rate candidate
 * @param profileID
 * @param establishmentID
 * @param stars
 * @param criteria
 * @param offerID
 */
export const rateCandidate = async (
  profileID: number,
  establishmentID: number,
  stars: number,
  criteria: string,
  offerID: number,
) => {
  const { data, error } = await Supabase
    .from('rating_candidate')
    .insert([
      {
        establishment_id: establishmentID,
        profile_id: profileID,
        stars,
        criteria,
        offer_id: offerID,
      },
    ]);

  if (error) throw new Error(error.message);

  return data;
};
