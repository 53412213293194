import Supabase from '@lib/Supabase';
import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Container from '@components/Container';
import { HiDocumentText } from 'react-icons/hi';
import NavButton from '@components/app/NavButton';
import useProfile from '@/lib/Profile';
import PageTitle from '@/components/app/PageTitle';
import { useAuth } from '@/context/Auth';
import ErrorCard from '@/components/ErrorCard';
import { FullProfile } from '@/custom-types/Profile';
import Chip from '@components/Chip';
import ConfirmationModal from '@components/ConfirmationModal';
import { deleteUser } from '@lib/Users';
import toast from 'react-hot-toast';

/**
 * User Profile
 */
export default function UserProfile(): React.ReactElement {
  const [open, setOpen] = React.useState(false);
  //  @ts-ignore
  const { data: profile, error: profileError }: {
    profile: FullProfile,
    profileError: Error
  } = useProfile();

  const { session } = useAuth();

  if (profileError) {
    return (
      <ErrorCard message={profileError.message} />
    );
  }

  const userInitials = `${profile?.first_name?.charAt(0)
    .toUpperCase()}${profile?.last_name?.charAt(0)
    .toUpperCase()}`;

  const signOutHandler = async (): Promise<void> => {
    await Supabase.auth.signOut();
  };

  const handleDeleteAccount = async (): Promise<void> => {
    await deleteUser(session?.user?.id);
    await Supabase.auth.signOut();
  };

  const handleDeleteAccountClick = async (): Promise<void> => {
    await toast.promise(
      handleDeleteAccount(),
      {
        loading: 'Suppression en cours...',
        success: 'Votre compte a bien été supprimé',
        error: 'Une erreur est survenue lors de la suppression de votre compte',
      },
      {
        style: {
          minWidth: '250px',
        },
      },
    );
  };

  return (
    <>
      <PageTitle title="Profil" />
      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="Supprimer mon compte"
        description="Êtes-vous sûr de vouloir supprimer votre compte ?"
        callBack={handleDeleteAccountClick}
      />
      <Container>
        <section className="flex w-full items-center justify-around mb-3">
          <div className="w-full">
            <div className="flex gap-1 mb-3 items-center">
              <h4 className="text-sm">Statut du compte</h4>
              {profile?.validated !== undefined && (
              <>
                {' '}
                {profile?.validated === true && (
                <Chip value="Compte validé" variant="success" />
                )}
                {profile?.validated === false && (
                <Chip value="Compte non validé" variant="warning" />
                )}
                {profile?.validated === null && (
                <Chip value="Compte en attente de validation" variant="warning" />
                )}
                {' '}

              </>
              )}
            </div>
            <div className="flex gap-1 mb-3 items-center">
              {!profile?.validated && (
              <p className="text-xs bg-warning/10 text-warning rounded-lg p-5 mb-3">
                Un administrateur doit valider votre compte et vos documents
                justificatifs avant que vous puissiez être sélectionné pour
                une mission. Si vous n&apos;avez pas encore envoyé vos
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                documents, vous pouvez le faire depuis la page "Mon CV".
              </p>
              )}
            </div>

            <div
              className="container mx-auto rounded-3xl bg-primary bg-gradient-to-r from-secondary to-primary p-3"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="h-16 w-16 rounded-full bg-base-100">
                    <div className="flex h-full items-center justify-center">
                      <span className="text-2xl font-bold text-primary">
                        {userInitials}
                      </span>
                    </div>
                  </div>
                  <div className="ml-4">
                    <div className="font-bold text-white text-lg">
                      {`${profile?.first_name} ${profile?.last_name}`}
                    </div>
                    <div className="text-white">
                      <span className="text-xs">
                        {session?.user?.email}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container mb-5">
          <NavButton
            to="/profile/cv"
            description="Gérez votre profil complet"
            icon={HiDocumentText}
            text="Mes Informations"
          />

          <NavButton
            to="/profile/missions"
            description="Gérez vos missions passées et futures"
            icon={HiDocumentText}
            text="Mes Missions"
          />

          <NavButton
            to="https://azelie-sante.fr/p/faq/"
            target="_blank"
            description="Questions fréquentes"
            icon={HiDocumentText}
            text="FAQ"
          />

          <NavButton
            to="https://azelie-sante.fr/p/mentions-legales/"
            target="_blank"
            description="Politique de confidentialité, informations légales"
            icon={HiDocumentText}
            text="Conditions générales d'utilisation"
          />

        </section>
        <button
          type="button"
          className="btn btn-error w-full font-bold mb-3"
          onClick={(): Promise<void> => signOutHandler()}
        >
          Déconnexion
        </button>
        <button
          type="button"
          className="btn btn-error w-full font-bold text-error"
          onClick={(): void => setOpen(true)}
        >
          Supprimer mon compte
        </button>
      </Container>
    </>
  );
}
