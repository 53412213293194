import Supabase from '@lib/Supabase';
import useSWR from 'swr';
import { createNotification } from '@lib/Notification';
import { getProfileByID } from '@lib/Users';

const tableName = 'establishments_managers';
const selectQuery = `
  establishment_id,
  manager_id,
  profile:manager_id (
    id,
    first_name,
    last_name,
    user_id
  ),
  establishment:establishment_id (
      id,
      name,
      phone
  )
`;

/**
 * Get all conversations
 */
export const getEstablishmentManagers = async () => {
  const { data, error } = await Supabase
    .from(tableName)
    .select(selectQuery);

  if (error) throw new Error(error.message);

  return data;
};

/**
 * Get real-time conversations
 */
export const useEstablishmentManagers = () => useSWR(
  'all-establishment-managers', getEstablishmentManagers,
);

export const getEstablishmentManagersByEstablishmentId = async (establishmentId: number) => {
  const { data, error } = await Supabase
    .from(tableName)
    .select(selectQuery)
    .eq(
      'establishment_id', establishmentId,
    );

  if (error) throw new Error(error.message);

  return data;
};

export const useEstablishmentManagersByEstablishmentId = (establishmentId: number) => useSWR(
  `establishment-managers-${establishmentId}`, () => getEstablishmentManagersByEstablishmentId(establishmentId),
);

export const getEstablishmentManagersByUserId = async (userId: string) => {
  const { data, error } = await Supabase
    .from(tableName)
    .select(selectQuery)
    .eq(
      'user_id', userId,
    );

  if (error) throw new Error(error.message);

  return data;
};

export const useEstablishmentManagersByUserId = (userId: string) => useSWR(
  `establishment-managers-${userId}`, () => getEstablishmentManagersByUserId(userId),
);

export const removeEstablishmentManager = async (
  establishmentId: number,
  managerId: number,
) => {
  if (!establishmentId) {
    throw new Error('establishmentId is required');
  }

  if (!managerId) {
    throw new Error('managerId is required');
  }
  const { error } = await Supabase
    .from(tableName)
    .delete()
    .eq(
      'establishment_id', establishmentId,
    )
    .eq(
      'manager_id', managerId,
    );

  if (error) throw new Error(error.message);

  return true;
};

export const addEstablishmentManager = async (
  establishmentId: number,
  managerId: number,
) => {
  if (!establishmentId) {
    throw new Error('establishmentId is required');
  }

  if (!managerId) {
    throw new Error('managerId is required');
  }

  const { error } = await Supabase
    .from(tableName)
    .insert({
      establishment_id: establishmentId,
      manager_id: managerId,
    });

  if (error) throw new Error(error.message);

  const user = await getProfileByID(managerId);

  await createNotification({
    level: '2',
    user_id: user.user_id,
    read: false,
    type: 'Message',
    content: 'Vous avez été ajouté comme gestionnaire d\'établissement',
    title: 'Nouveau rôle',
  });

  return true;
};
