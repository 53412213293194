import React from 'react';
import { useParams } from 'react-router-dom';
import { useSingleConversation } from '@lib/Conversation';
import ErrorCard from '@components/ErrorCard';
import FullPageLoader from '@components/FullPageLoader';
import useProfile, { useSingleProfile } from '@lib/Profile';
import toast from 'react-hot-toast';
import { sendMessage } from '@lib/Message';
import { Button, Input } from '@material-tailwind/react';
import { EnvelopeIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { Message } from '@/custom-types/Message';
import { useIsRetained } from '@lib/ProfileOffers';
import Chip from '@components/Chip';
import { useSingleEstablishment } from '@lib/Establishment';

const wasSentToday = (date: Date) => {
  const today = new Date();
  return date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear();
};

const formatTime = (date: Date) => {
  if (wasSentToday(date)) {
    return date.toLocaleTimeString(
      'fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
      },
    );
  }

  return date.toLocaleDateString(
    'fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  );
};

function ReceiverChatBubble({
  message,
  key,
  profile,
}: {
  message: Message,
  key: any,
  profile: any
}): React.ReactElement {
  return (
    <div className="flex flex-col items-start justify-start space-y-2 mb-5 w-3/4" key={key}>
      <div className="flex flex-row items-center justify-start space-x-2 border p-3 rounded-lg bg-gray-500 text-white">
        <div className="flex flex-col items-start justify-start space-y-1">
          <div className="flex flex-row items-center justify-start space-x-2">
            <p className="font-semibold text-base">
              {profile.role.code === 'candidate' ? 'Recruteur' : 'Candidat'}
            </p>
            <p className="text-sm text-gray-200">
              {formatTime(new Date(message.created_at))}
            </p>
          </div>
          <p className="text-sm">{message.content}</p>
        </div>
      </div>
    </div>
  );
}

function SenderChatBubble({ message, key }: { message: Message, key: any }): React.ReactElement {
  return (
    <div className="flex flex-col items-end justify-start space-y-2 mb-5 w-3/4 ml-auto flex-wrap" key={key}>
      <div className="flex flex-wrap flex-row items-center justify-start space-x-2 bg-primary p-3 rounded-lg text-white">
        <div className="flex flex-wrap flex-col items-end justify-start space-y-1">
          <div className="flex flex-wrap flex-row items-center justify-start space-x-2">
            <p className="font-semibold text-sm">Vous</p>
            <p className="text-xs text-gray-200">
              {formatTime(new Date(message.created_at))}
            </p>
          </div>
          <p className="text-sm">{message.content}</p>
        </div>
      </div>
    </div>
  );
}
function SystemChatBubble({
  message,
  key,
}: {
  message: Message,
  key: any,
}): React.ReactElement {
  return (
    <div className="flex flex-col items-start justify-start space-y-2 mb-5 w-3/4 flex-wrap" key={key}>
      <div className="flex flex-wrap flex-row items-center justify-start space-x-2 border p-3 rounded-lg bg-gray-800 text-white">
        <div className="flex flex-wrap flex-col items-start justify-start space-y-1">
          <div className="flex flex-wrap flex-row items-center justify-start space-x-2">
            <p className="font-semibold text-base">
              Système
            </p>
            <p className="text-sm text-gray-200">
              {formatTime(new Date(message.created_at))}
            </p>
          </div>
          <p className="text-sm break-all">{message.content}</p>
        </div>
      </div>
    </div>
  );
}

export default function ChatPage(): React.ReactElement {
  const [newMessage, setNewMessage] = React.useState('');
  const params = useParams<{
    id: string,
    conversationID: string
  }>() as {
    id: unknown,
    conversationID: unknown
  } as {
    id: number,
    conversationID: number
  };

  const { conversationID } = params;

  const {
    data: conversation,
    error: conversationError,
    mutate: mutateConversation,
  } = useSingleConversation(conversationID);

  const {
    data: isRetained,
    error: isRetainedError,
  } = useIsRetained(conversationID);

  const { data: profile, error: profileError } = useProfile();

  const {
    data: establishment,
  } = useSingleEstablishment(conversation?.establishment_id || 0);

  const {
    data: sender,
  } = useSingleProfile(conversation?.sender_id || 0);

  React.useEffect(
    () => {
      //   Make sure the messages are always in the bottom
      const messagesContainer = document.querySelector('#messages-container');

      if (messagesContainer) {
        const lastMessage = messagesContainer.lastElementChild;
        if (lastMessage) {
          lastMessage.scrollIntoView();
        }
      }

      //   @ts-ignore
    }, [conversation?.messages],
  );

  if (profileError) {
    return <ErrorCard message={profileError.message} />;
  }

  if (isRetainedError) {
    return <FullPageLoader />;
  }

  if (conversationError) {
    return <FullPageLoader />;
  }

  if (!conversation
      || !profile
      || isRetained === undefined
      || establishment === undefined
      || sender === undefined) {
    return <FullPageLoader />;
  }

  // @ts-ignore
  const { messages } = conversation;

  // Sort messages by date
  messages.sort((
    a: { created_at: string; }, b: { created_at: string; },
  ) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    if (dateA < dateB) {
      return -1;
    }

    if (dateA > dateB) {
      return 1;
    }

    return 0;
  });

  /**
   * Handle the submitting of the message form
   * @param e
   */
  const handleSendMessageSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    if (newMessage.trim() === '') {
      return;
    }

    await toast.promise(
      sendMessage(
        newMessage,
        conversation.id,
      ),
      {
        loading: 'Envoi du message...',
        success: 'Message envoyé !',
        error: 'Impossible d\'envoyer le message',
      },
    ).then(() => {
      mutateConversation();
      setNewMessage('');
    })
      .catch(() => {
        toast.error('Impossible d\'envoyer le message');
      });
  };

  let meetingURL = 'https://calendar.google.com/calendar/u/0/r/eventedit?vcon=meet&dates=now&hl=fr';

  meetingURL += `&text=Entretien%20avec%20${establishment.name}`;

  return (
    <>
      {/* @ts-ignore */}
      <h1 className="text-lg font-semibold text-gray-700 mb-5 px-3">
        Conversation avec le/la
        {' '}
        {/* @ts-ignore */}
        {profile.role.code === 'candidate' ? 'Recruteur.euse' : 'Candidat.e'}
      </h1>

      {
        // @ts-ignore
        profile?.role?.code === 'candidate' && (
        <>
          <div className="flex gap-1 mb-3 items-center px-5">
            <h4 className="text-sm">Statut du compte</h4>
            {profile?.validated !== undefined && (
              <>
                {' '}
                {profile?.validated === true && (
                <Chip value="Compte validé" variant="success" />
                )}
                {profile?.validated === false && (
                <Chip value="Compte non validé" variant="warning" />
                )}
                {profile?.validated === null && (
                <Chip value="Compte en attente de validation" variant="warning" />
                )}
                {' '}
              </>
            )}
          </div>
          <div className="flex gap-1 mb-3 items-center mx-auto w-full">
            {!profile?.validated && (
            <p className="text-xs bg-warning/10 text-warning rounded-lg p-5 mb-3 w-full text-center">
              Un administrateur doit valider votre compte et vos documents
              justificatifs avant que vous puissiez être sélectionné pour
              une mission. Si vous n&apos;avez pas encore envoyé vos
              {' '}
              {' '}
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              documents, vous pouvez le faire depuis la page "Mes informations".
            </p>
            )}
          </div>
        </>
        )
}

      {
        // @ts-ignore
        profile?.role?.code === 'establishment' && (
        <div className="flex gap-1 mb-3 items-center px-5">
          <h4 className="text-sm">Statut du compte</h4>
            {sender?.validated !== undefined && (
              <>
                {sender?.validated === true && (
                  <Chip value="Compte validé" variant="success" />
                )}
                {sender?.validated === false && (
                  <Chip value="Compte non validé" variant="warning" />
                )}
                {sender?.validated === null && (
                  <Chip value="Compte en attente de validation" variant="warning" />
                )}
              </>
            )}
        </div>
        )
}

      {
        // @ts-ignore
          profile?.role?.code === 'candidate' && isRetained && (
          <div className="flex justify-center">
            <p className="font-semibold text-sm text-success mb-5 px-3">
              Vous avez été retenu.e pour ce poste. Félicitations !
            </p>
          </div>
          )
      }

      {
        // @ts-ignore
          profile?.role?.code === 'candidate' && isRetained === false && (
          <div className="flex justify-center items-center">
            <p className="font-semibold text-sm mb-5 px-3 text-error">
              Vous n&apos;avez pas été retenu.e pour ce poste,
              de ce fait vous ne pouvez plus envoyer de messages.
            </p>
          </div>
          )
        }

      {
          // @ts-ignore
            profile?.role?.code === 'candidate' && isRetained === null && (
            <div className="flex justify-center">
              <p className="font-semibold text-sm text-gray-700 mb-5 px-3">
                Le recruteur n&apos;a pas encore pris de décision.
              </p>
            </div>
            )
        }

      <div className="overflow-hidden px-3">
        <div className="bg-base-100 rounded-lg border py-5 ">
          <div className="h-[70vh] overflow-y-scroll flex flex-col px-1 lg:px-5 w-full">
            <section className="pb-5 flex-1 px-5" id="messages-container">
              {messages.map((message: Message) => (
                <React.Fragment key={message.id}>

                  {!message.is_system
                          && message.sender_id === profile.id && (
                          <SenderChatBubble
                            message={message}
                            key={message.id}
                          />
                  )}

                  {!message.is_system
                          && message.sender_id !== profile.id
                          && message.sender_id !== null && (
                          <ReceiverChatBubble
                            message={message}
                            key={message.id}
                            profile={profile}
                          />
                  )}

                  {message.is_system
                          && message.sender_id === null && (
                          <SystemChatBubble
                            message={message}
                            key={message.id}
                          />
                  )}
                </React.Fragment>
              ))}
            </section>
          </div>
        </div>

        <section className="mt-auto w-full flex justify-center flex-col items-center mb-5">
          <p className="text-sm text-gray-500 my-2">
            {conversation.is_open ? 'Cette conversation est sécurisée avec un chiffrement de bout en bout. Vous souhaitez discuter en vidéo ? Pour des raisons de sécurité, nous recommandons d\'utiliser des outils comme Google Meet, Zoom, Skype, etc.' : 'Cette conversation est archivée.'}
          </p>
          <form className="w-full" onSubmit={handleSendMessageSubmit}>
            <fieldset
              disabled={isRetained === false}
              className="grid grid-cols-3 gap-3 w-full"
            >
              <div className="col-span-2">
                <Input
                  crossOrigin="anonymous"
                  type="text"
                  name="message"
                  label="Message"
                  className="w-full"
                  icon={<EnvelopeIcon />}
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
              </div>
              <Button
                type="submit"
                className="bg-primary col-span-1 w-full"
              >
                Envoyer
              </Button>
              {
                // @ts-ignore
                profile?.role?.code === 'establishment' && (
                <a
                  target="_blank"
                  href={meetingURL}
                  rel="noreferrer"
                >
                  <Button
                    type="button"
                    className="bg-primary w-full flex items-center gap-3 justify-center"
                  >
                    Programmer un rendez-vous
                    <VideoCameraIcon className="w-5 h-5" />
                  </Button>
                </a>
                )
}
            </fieldset>
          </form>
        </section>
      </div>
    </>
  );
}
