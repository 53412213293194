import React from 'react';
import {
  Input,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react';
import { useMissions } from '@/lib/Mission';
import FullPageLoader from '@/components/FullPageLoader';
import ErrorCard from '@/components/ErrorCard';
import useProfile from '@/lib/Profile';
import JobCard from '@/components/app/JobCard';
import { Database } from '@/custom-types/supabase';
import PageTitle from '@/components/app/PageTitle';
import { FullProfile } from '@/custom-types/Profile';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import LoadingJobCard from '@components/app/LoadingJobCard';
import { Link, useNavigate } from 'react-router-dom';
import { BriefcaseIcon, StarIcon } from '@heroicons/react/24/outline';
import { useOffersForProfileType } from '@lib/Offer';
/**
 *
 */
export function useGreeting(): string {
  //  @ts-ignore
  const { data: profile }: {
    profile: FullProfile
  } = useProfile();

  const timeOfDay = new Date().getHours();

  let greeting = '';
  if (timeOfDay < 12) {
    greeting = 'Bonjour';
  }

  if (timeOfDay >= 12 && timeOfDay < 18) {
    greeting = 'Bon après-midi';
  }

  if (timeOfDay >= 18) {
    greeting = 'Bonsoir';
  }

  if (!profile) {
    return '';
  }

  return `${greeting}, ${' '}${profile?.first_name}${' '}👋`;
}

/**
 * Dashboard page
 */
export default function Home(): React.ReactElement {
  const { data: profile, error: profileError } = useProfile();
  const { data: profileMissions, error: profileMissionsError } = useMissions();
  const greeting = useGreeting();
  const navigate = useNavigate();
  const [formValues, setFormValues] = React.useState({
    search: '',
  });
  const [city, setCity] = React.useState('');
  const [aroundMeOffers, setAroundMeOffers] = React.useState<Database['public']['Tables']['offer']['Row'][]>([]);
  const [
    filteredMissions,
    setFilteredMissions,
  ] = React.useState<Database['public']['Tables']['mission']['Row'][]>([]);

  const { data: missions, error } = useOffersForProfileType();

  const currentPath = window.location.pathname;

  const successCallback = (position: GeolocationPosition): void => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    const url = `https://api-adresse.data.gouv.fr/reverse/?lon=${lng}&lat=${lat}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const strippedZip = data.features[0].properties.postcode.substring(
          0, 2,
        );
        setCity(`${data.features[0].properties.city} (${strippedZip})`);
      });
  };

  const errorCallback = (geolocationError: GeolocationPositionError): void => {
    // eslint-disable-next-line no-console
    console.error(
      'geolocation error', geolocationError,
    );
  };

  React.useEffect(
    () => {
      navigator?.geolocation?.getCurrentPosition(
        successCallback, errorCallback,
      );

      // if currentpath isnt '/', redirect to '/'
      if (currentPath !== '/') {
        navigate('/');
      }

      if (missions
            && missions?.length > 0
            && city
            && city.length > 0
            && formValues.search.length === 0) {
        const aroundOffers = missions?.filter((mission) => {
          const strippedZip = city.substring(
            city.length - 3, city.length - 1,
          );
            // @ts-ignore
          const missionZip = mission.establishment.address.zip.toString();
          return missionZip.includes(strippedZip);
        });

        // @ts-ignore
        setAroundMeOffers(aroundOffers);

        const filteredOffers = missions?.filter((mission) => {
          if (!mission.is_published) {
            return false;
          }
          const strippedZip = city.substring(
            city.length - 3, city.length - 1,
          );
            // @ts-ignore
          const missionZip = mission.establishment.address.zip.toString();
          return !missionZip.includes(strippedZip);
        });

        // @ts-ignore
        setFilteredMissions(filteredOffers);
      }
    }, [formValues.search, city, missions],
  );

  const offers = profileMissions && profileMissions?.length > 0 ? profileMissions?.map((mission) => mission.offer) as unknown as Database['public']['Tables']['offer']['Row'][] : [];

  // Remove unpublished offers
  // @ts-ignore
  // eslint-disable-next-line no-unused-expressions
  offers && offers?.length > 0 && offers?.forEach((offer) => {
    if (!offer.is_published) {
      // @ts-ignore
      offers.splice(
        // @ts-ignore
        offer, 1,
      );
    }
  });

  if (!profileMissions) {
    return (
      <FullPageLoader />
    );
  }

  if (!profile) {
    return (
      <FullPageLoader />
    );
  }

  if (error) {
    return <ErrorCard message={error.message} />;
  }

  if (profileError) {
    return (
      <ErrorCard message="Impossible de charger votre profil" />
    );
  }
  if (profileMissionsError) {
    return (
      <ErrorCard message="Impossible de charger les missions" />
    );
  }

  /**
   * Handle search event
   * @param event
   */
  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();

    // @ts-ignore
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    const filteredOffers = missions?.filter((mission) => {
      // @ts-ignore
      const missionTitle = mission.title.toLowerCase();
      // @ts-ignore
      const missionCity = mission.establishment.address.city.toLowerCase();
      // @ts-ignore
      const missionZip = mission.establishment.address.zip.toString().toLowerCase();
      const search = value.toLowerCase();

      return missionTitle.includes(search)
        || missionCity.includes(search)
        || missionZip.includes(search);
    });

    // @ts-ignore
    setFilteredMissions(filteredOffers);
    setAroundMeOffers([]);
  };

  // Short offers are the ones without an end date or an end date shorter than 2 weeks
  const shortOffers = missions?.filter((offer) => {
    if (!offer.end_date) {
      return true;
    }
    const endDate = new Date(offer.end_date);
    const today = new Date();
    const diffTime = Math.abs(endDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 14;
  });

  const longOffers = missions?.filter((offer) => {
    const endDate = new Date(offer.end_date);
    const today = new Date();
    const diffTime = Math.abs(endDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 14;
  });

  const shortAroundOffers = shortOffers?.filter((mission) => {
    const strippedZip = city.substring(
      city.length - 3, city.length - 1,
    );
    // @ts-ignore
    const missionZip = mission.establishment.address.zip.toString();
    return missionZip.includes(strippedZip);
  });

  const longAroundOffers = longOffers?.filter((mission) => {
    const strippedZip = city.substring(
      city.length - 3, city.length - 1,
    );
    // @ts-ignore
    const missionZip = mission.establishment.address.zip.toString();
    return missionZip.includes(strippedZip);
  });

  const shortCountryOffers = shortOffers?.filter((mission) => {
    // If the mission is in "shortAroundOffers", delete it
    const missionID = mission.id;
    const shortAroundOffersIDs = shortAroundOffers?.map((offer) => offer.id);
    if (shortAroundOffersIDs?.includes(missionID)) {
      return false;
    }
    return true;
  });

  const longCountryOffers = longOffers?.filter((mission) => {
    // If the mission is in "longAroundOffers", delete it
    const missionID = mission.id;
    const longAroundOffersIDs = longAroundOffers?.map((offer) => offer.id);
    if (longAroundOffersIDs?.includes(missionID)) {
      return false;
    }

    return true;
  });

  // @ts-ignore
  return (
    <>
      <PageTitle title={greeting} />

      <section className="px-5">
        <section className="mb-5">
          <form className="mb-2">
            <fieldset>
              <div className="flex gap-3">
                <Input
                  crossOrigin={undefined}
                  type="text"
                  name="search"
                  label="Rechercher une mission"
                  value={formValues.search}
                  onChange={handleSearch}
                  icon={<MagnifyingGlassIcon className="text-gray-400" />}
                  className="bg-base-100 w-full"
                />
              </div>
            </fieldset>
          </form>
        </section>

        <section className="px-5 flex items-center justify-between gap-5 mb-5">
          <Link
            to="/favorites"
            title="Voir mes établissements favoris"
            className="w-full"
          >
            <button
              type="button"
              className="border py-5 px-3 w-full rounded-xl font-semibold flex lg:flex-row gap-2 flex-col items-center justify-center"
            >
              <StarIcon className="text-primary w-10 lg:w-7 lg:h-7 h-10 lg:w-7 lg:h-7" />
              Mes Favoris
            </button>
          </Link>

          <Link
            to="/profile/missions"
            title="Voir toutes mes missions"
            className="w-full"
          >
            <button
              type="button"
              className="border py-5 px-3 w-full rounded-xl font-semibold flex lg:flex-row gap-2 flex-col items-center justify-center"
            >
              <BriefcaseIcon className="text-primary w-10 lg:w-7 lg:h-7 h-10 lg:w-7 lg:h-7" />
              Mes missions
            </button>
          </Link>
        </section>

      </section>
      <section className="px-5">

        <Tabs value="all">
          <TabsHeader className="flex flex-col lg:flex-row">
            <Tab className="z-10" value="all">
              <span className="text-lg font-semibold">Tout</span>
            </Tab>
            <Tab className="z-10" value="aroundMe">
              <span className="text-lg font-semibold">Autour de moi</span>
            </Tab>
            <Tab className="z-10" value="shortMissions">
              <span className="text-lg font-semibold">Missions</span>
            </Tab>
            <Tab className="z-10" value="longMissions">
              <span className="text-lg font-semibold">Offres d&apos;Emploi</span>
            </Tab>
          </TabsHeader>
          <TabsBody>
            <TabPanel value="all">
              <Typography className="text-2xl font-bold mb-5">
                Toutes les missions
              </Typography>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                {missions && missions.length > 0
                    && missions.map((offer) => (
                      // @ts-ignore
                      <JobCard key={offer.id} offer={offer} />
                    ))}
              </div>

              {filteredMissions && filteredMissions.length === 0 && (
              <Typography className="text-lg font-normal mb-5">
                Il n&apos;y a aucune mission disponible pour le moment.
              </Typography>
              )}
            </TabPanel>
            <TabPanel value="aroundMe">
              {aroundMeOffers && aroundMeOffers.length > 0 && (
              <section className="mb-5">
                <Typography className="text-2xl font-bold mb-5">
                  Autour de moi
                  {' '}
                  <span
                    className="text-sm bg-primary/20 text-primary py-1 px-3 rounded-xl font-medium"
                  >
                    {city && city}
                  </span>
                </Typography>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                  {aroundMeOffers && aroundMeOffers.length > 0 && aroundMeOffers.map((offer) => (
                    <JobCard key={offer.id} offer={offer} />
                  ))}

                  {(!aroundMeOffers) && (
                    <>
                      <LoadingJobCard />
                      <LoadingJobCard />
                      <LoadingJobCard />
                      <LoadingJobCard />
                      <LoadingJobCard />
                      <LoadingJobCard />
                    </>
                  )}
                  {aroundMeOffers && aroundMeOffers.length === 0 && (
                    <Typography className="text-lg font-normal mb-5">
                      Il n&apos;y a aucune mission disponible pour le moment.
                    </Typography>
                  )}
                </div>
              </section>
              )}
            </TabPanel>
            <TabPanel value="shortMissions">
              {shortAroundOffers && shortAroundOffers.length > 0 && (
              <div className="mb-5">
                <Typography className="text-2xl font-bold mb-5">
                  Missions courtes autour de moi
                  {' '}
                  <span
                    className="text-sm bg-primary/20 text-primary py-1 px-3 rounded-xl font-medium"
                  >
                    {city && city}
                  </span>
                </Typography>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                  {shortAroundOffers && shortAroundOffers.length > 0
                          && shortAroundOffers.map((offer) => (
                            <JobCard key={offer.id} offer={offer} />
                          ))}
                </div>
              </div>
              )}

              <div className="mb-5">
                <Typography className="text-2xl font-bold mb-5">
                  Missions courtes hors de ma ville
                </Typography>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                  {shortCountryOffers && shortCountryOffers.length > 0
                      && shortCountryOffers.map((offer) => (
                        <JobCard key={offer.id} offer={offer} />
                      ))}

                  {(!shortCountryOffers) && (
                  <>
                    <LoadingJobCard />
                    <LoadingJobCard />
                    <LoadingJobCard />
                    <LoadingJobCard />
                    <LoadingJobCard />
                    <LoadingJobCard />
                  </>
                  )}

                  {shortCountryOffers && shortCountryOffers.length === 0 && (
                    <Typography className="text-lg font-normal mb-5">
                      Il n&apos;y a aucune mission disponible pour le moment.
                    </Typography>
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel value="longMissions">
              {longAroundOffers && longAroundOffers.length > 0 && (
                <div className="mb-5">
                  <Typography className="text-2xl font-bold mb-5">
                    Offres d&apos;emploi autour de moi
                    {' '}
                    <span
                      className="text-sm bg-primary/20 text-primary py-1 px-3 rounded-xl font-medium"
                    >
                      {city && city}
                    </span>
                  </Typography>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    {longAroundOffers && longAroundOffers.length > 0
                      && longAroundOffers.map((offer) => (
                        <JobCard key={offer.id} offer={offer} />
                      ))}
                  </div>
                </div>
              )}

              <div className="mb-5">
                <Typography className="text-2xl font-bold mb-5">
                  Missions longues hors de ma ville
                </Typography>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                  {longCountryOffers && longCountryOffers.length > 0
                      && longCountryOffers.map((offer) => (
                        <JobCard key={offer.id} offer={offer} />
                      ))}

                  {(!longCountryOffers) && (
                  <>
                    <LoadingJobCard />
                    <LoadingJobCard />
                    <LoadingJobCard />
                    <LoadingJobCard />
                    <LoadingJobCard />
                    <LoadingJobCard />
                  </>
                  )}

                  {longCountryOffers && longCountryOffers.length === 0 && (
                    <Typography className="text-lg font-normal mb-5">
                      Il n&apos;y a aucune offre d&apos;emploi disponible pour le moment.
                    </Typography>
                  )}
                </div>
              </div>
            </TabPanel>
          </TabsBody>
        </Tabs>

        {formValues.search.length > 0 && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {filteredMissions
                    && filteredMissions.length > 0
                    && filteredMissions.map((offer) => (
                      // @ts-ignore
                      <JobCard key={offer.id} offer={offer} />
                    ))}
          </div>

          {filteredMissions && filteredMissions.length === 0 && (
          <div className="bg-base-100 mx-auto p-10 rounded-xl shadow">
            <Typography className="text-lg font-normal mb-5">
              Aucun résultat ne correspond à votre recherche.
            </Typography>
          </div>
          )}
        </>
        )}

        {(!aroundMeOffers || aroundMeOffers.length === 0) && formValues.search.length === 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {missions && missions.length > 0 && missions.map((offer) => (
            <JobCard
              key={offer.id}
                      // @ts-ignore
              offer={offer}
            />
          ))}

          {(!missions) && (
          <>
            <LoadingJobCard />
            <LoadingJobCard />
            <LoadingJobCard />
            <LoadingJobCard />
            <LoadingJobCard />
            <LoadingJobCard />
          </>
          )}

          {missions && missions.length === 0 && (
          <Typography className="text-lg font-normal mb-5">
            Il n&apos;y a aucune mission disponible pour le moment.
          </Typography>
          )}
        </div>
        )}

      </section>
    </>

  );
}
