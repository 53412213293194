import Container from '@components/Container';
import NavButton from '@components/app/NavButton';
import { HiDesktopComputer, HiOutlineCog } from 'react-icons/hi';
import React from 'react';
import PageTitle from '@/components/app/PageTitle';

/**
 *
 */
export default function Settings(): React.ReactElement {
  return (
    <>
      <PageTitle title="Paramètres" />
      <Container>
        <NavButton to="/settings/info" text="Informations" description="Email de contact, Sécurité etc.." icon={HiDesktopComputer} />
        <NavButton to="/admin" text="Administration" description="Gestion de l'application" icon={HiOutlineCog} />
      </Container>
    </>
  );
}
