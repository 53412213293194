import React from 'react';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react';

export default function ConfirmationModal({
  open,
  setOpen,
  title,
  description,
  confirmText,
  cancelText,
  callBack,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description: string;
  confirmText?: string;
  cancelText?: string;
  callBack?: () => void;
}) {
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleConfirm = () => {
    if (callBack) callBack();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      size="xs"
      handler={handleOpen}
    >
      <DialogHeader>
        <span className="text-lg">
          {title}
        </span>
      </DialogHeader>
      <DialogBody className="pt-0">
        <p className="text-base">
          {description}
        </p>
      </DialogBody>
      <DialogFooter className="flex gap-2">
        <Button
          onClick={() => setOpen(false)}
          className="bg-gray-500/20 text-gray-700"
        >
          <span>
            {cancelText}
          </span>
        </Button>
        <Button
          className="bg-error"
          onClick={() => handleConfirm()}
        >
          <span>
            {confirmText}
          </span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

ConfirmationModal.defaultProps = {
  confirmText: 'Confirmer',
  cancelText: 'Annuler',
  callBack: () => {},
};
