import React from 'react';
import ActiveLink from '@/components/app/ActiveLink';
import NotificationPopOver from '@components/app/NotificationPopOver';
import classNames from 'classnames';
import {
  Navbar,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react';
import { navigation } from '@components/app/nav';
import Supabase from '@lib/Supabase';
import useProfile from '@lib/Profile';
import {
  ChevronDownIcon,
  CogIcon,
  PowerIcon,
  ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import ErrorCard from '@/components/ErrorCard';
import { FullProfile } from '@/custom-types/Profile';

function ProfileMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const closeMenu = () => setIsMenuOpen(false);

  const profileMenuItems = [
    {
      label: 'Déconnexion',
      icon: PowerIcon,
      href: '',
    },
  ];

  //  @ts-ignore
  const { data: profile, error: profileError }: {
    profile: FullProfile,
    profileError: Error
  } = useProfile();

  if (profileError) {
    return (
      <ErrorCard message={profileError.message} />
    );
  }

  if (!profile) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  // eslint-disable-next-line no-unused-expressions
  profile?.role?.code === 'admin' && (
    profileMenuItems.unshift({
      label: 'Administration',
      icon: CogIcon,
      href: '/admin',
    })
  );

  const userInitials = `${profile?.first_name?.charAt(0).toUpperCase()}${profile?.last_name?.charAt(0).toUpperCase()}`;

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <Typography
            as="span"
            variant="small"
            className="font-bold py-2 border rounded-full px-2 border-blue-gray-100"
          >
            {userInitials}
          </Typography>
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${
              isMenuOpen ? 'rotate-180' : ''
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map((
          { label, icon, href }, key,
        ) => {
          const isLastItem = key === profileMenuItems.length - 1;
          let shouldRender = true;
          // @ts-ignore
          if (profileMenuItems[key].role) {
          // @ts-ignore
            shouldRender = profileMenuItems[key].role === profile?.role?.code;
          }

          if (!shouldRender) {
            return null;
          }
          return (
            <MenuItem
              key={label}
              onClick={() => {
                closeMenu();
                if (label === 'Déconnexion') {
                  Supabase.auth.signOut();
                }
              }}
              className={`flex items-center gap-2 rounded ${
                isLastItem
                  ? 'hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10'
                  : ''
              }`}
            >
              {React.createElement(
                icon, {
                  className: `h-4 w-4 ${isLastItem ? 'text-red-500' : ''}`,
                  strokeWidth: 2,
                },
              )}
              {
                href ? (
                  <ActiveLink to={href} activeAction>
                    <Typography
                      as="span"
                      variant="small"
                      className="font-normal"
                      color={isLastItem ? 'red' : 'inherit'}
                    >
                      {label}
                    </Typography>

                  </ActiveLink>
                ) : (
                  <Typography
                    as="span"
                    variant="small"
                    className="font-normal"
                    color={isLastItem ? 'red' : 'inherit'}
                  >
                    {label}
                  </Typography>
                )
              }
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

function NavList() {
  return (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      {navigation.map(({ title, Icon, href }) => (
        <ActiveLink
          title={`Aller à la page ${title}`}
          key={title}
          to={href}
          className={classNames('font-medium text-sm hover:text-primary')}
          activeAction
        >
          <MenuItem className="flex items-center gap-2 lg:rounded-full">
            {React.createElement(
              Icon, { className: 'h-[18px] w-[18px]' },
            )}
            {' '}
            {title}
          </MenuItem>
        </ActiveLink>
      ))}
    </ul>
  );
}

/**
 * Navbar
 */
export default function NavBar(): React.ReactElement {
  const { data: profile, error: profileError } = useProfile();

  if (profileError || !profile) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <Navbar className="mx-auto hidden lg:block shadow-none max-w-none">
      <div className="relative mx-auto flex items-center text-blue-gray-900">
        <Typography
          as="a"
          href="/"
          className="mr-4 ml-2 cursor-pointer py-1.5 font-medium"
        >
          <img
            src="/images/logo.svg"
            alt="Logo Azélie Santé"
            className="h-15 w-24"
          />

        </Typography>
        <div>
          <NavList />
        </div>
        <div className="flex flex-1 mr-3 justify-end">
          {/* @ts-ignore */}
          {profile?.role.code !== 'admin'
              && (
              <ActiveLink title="Conversations" to="/chat" className="flex items-center">
                <ChatBubbleLeftEllipsisIcon className="h-7 w-7 mr-2" />
              </ActiveLink>
              )}
          <NotificationPopOver />
        </div>
        <ProfileMenu />
      </div>
    </Navbar>
  );
}
