import Supabase from '@lib/Supabase';
import useSWR from 'swr';

const tableName = 'document_type';
const selectQuery = `
  id,
  name
`;

/**
 * Get all conversations
 */
export const getFileTypes = async () => {
  const { data, error } = await Supabase
    .from(tableName)
    .select(selectQuery);

  if (error) throw new Error(error.message);

  return data;
};

/**
 * Get real-time conversations
 */
export const useFileTypes = () => useSWR(
  'all-file-types', getFileTypes,
);
