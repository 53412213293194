import React from 'react';
import toast from 'react-hot-toast';
import {
  Input,
  Button,
} from '@material-tailwind/react';
import { FullProfile } from '@/custom-types/Profile';
import { createAddress, setAddress } from '@/lib/Address';

export default function UpdateAddress({
  profile,
  mutate,
  setOpenInfo,
}: {
  profile: FullProfile;
  mutate: () => MutationCallback
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
  const [updatingInfo, setUpdatingInfo] = React.useState(false);

  const [formValues, setFormValues] = React.useState({
    first_line: '',
    second_line: '',
    zip: '',
    city: '',
  });

  // Set form values from profile only once to be able to edit them
  React.useEffect(
    () => {
      setFormValues({
        first_line: profile?.address?.first_line as string,
        second_line: profile?.address?.second_line as string,
        zip: profile?.address?.zip as string,
        city: profile?.address?.city as string,
      });
    }, [profile],
  );

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCreateOrUpdateAddress = async () => {
    const hasAddressID = profile?.address?.id !== undefined;

    if (hasAddressID) {
      return setAddress(
      // @ts-ignore
        profile?.id, {
          first_line: formValues.first_line,
          second_line: formValues.second_line,
          zip: formValues.zip,
          city: formValues.city,
        },
      )
        .then(() => {
          setOpenInfo(false);
          setUpdatingInfo(false);
          mutate();
        })
        .catch(() => {
          setUpdatingInfo(false);
        });
    }

    return createAddress(
      // @ts-ignore
      profile?.id, {
        first_line: formValues.first_line,
        second_line: formValues.second_line,
        zip: formValues.zip,
        city: formValues.city,
      },
    )
      .then(() => {
        setOpenInfo(false);
        setUpdatingInfo(false);
        mutate();
      })
      .catch(() => {
        setUpdatingInfo(false);
      });
  };

  const handleAddressChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUpdatingInfo(true);

    await toast.promise(
      handleCreateOrUpdateAddress(),
      {
        loading: 'Mise à jour en cours...',
        success: 'Votre adresse a été mise à jour !',
        error: 'Impossible de mettre à jour votre adresse.',
      },
    );
  };
  return (
    <form onSubmit={handleAddressChange}>
      <fieldset className="flex flex-col gap-4" disabled={updatingInfo}>

        <p className="font-bold text-gray-500 text-sm">
          Adresse
        </p>

        <Input
          crossOrigin={undefined}
          type="text"
          label="Adresse"
          onChange={(e): Promise<void> => handleInputChange(e)}
          name="first_line"
              // @ts-ignore
          value={formValues?.first_line || ''}
          required
        />

        <Input
          crossOrigin={undefined}
          type="text"
          label="Adresse (suite)"
          onChange={(e): Promise<void> => handleInputChange(e)}
          name="second_line"
              // @ts-ignore
          value={formValues?.second_line || ''}
        />

        <Input
          crossOrigin={undefined}
          type="text"
          label="Code Postal"
          onChange={(e): Promise<void> => handleInputChange(e)}
          name="zip"
              // @ts-ignore
          value={formValues?.zip || ''}
          required
        />

        <Input
          crossOrigin={undefined}
          type="text"
          label="Ville"
          onChange={(e): Promise<void> => handleInputChange(e)}
          name="city"
              // @ts-ignore
          value={formValues?.city || ''}
          required
        />

        <Button type="submit" className="bg-primary">Enregistrer</Button>
      </fieldset>
    </form>
  );
}
