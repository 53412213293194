import Supabase from '@lib/Supabase';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { Conversation } from '@/custom-types/Conversation';
import { Decrypt } from '@lib/Encryption';

const tableName = 'conversation';
const selectQuery = `
  id,
  created_at,
  sender_id,
  establishment_id,
  offer_id,
  is_open,
  offer:offer_id (
    id,
    created_at,
    title
  ),
  establishment:establishment_id (
    id,
    created_at,
    name,
    description,
    phone
  )
`;

/**
 * Get all conversations
 */
export const getConversations = async () => {
  const { data: conversations, error } = await Supabase
    .from(tableName)
    .select(selectQuery);

  if (error) {
    throw new Error(error.message);
  }

  const fullConversations: Conversation[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < conversations.length; i++) {
    const conversation = conversations[i];
    const {
      data: messages,
      error: messagesError,
      // eslint-disable-next-line no-await-in-loop
    } = await Supabase
      .from('message')
      .select(`
        id,
        created_at,
        sender_id,
        content,
        profile:sender_id (
          id,
          created_at,
          first_name,
          last_name
        ),
        conversation_id
      `)
      .eq(
        'conversation_id', conversation.id,
      );

    if (messagesError) {
      throw new Error(messagesError.message);
    }

    // @ts-ignore
    // eslint-disable-next-line no-await-in-loop
    const decryptedMessages = await Promise
      .all(messages?.map(async (message: { content: string; }) => {
        // eslint-disable-next-line no-param-reassign
        message.content = await Decrypt(message.content);
        return message;
      }));

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    conversation.messages = decryptedMessages;

    const {
      data: sender,
      error: senderError,
      // eslint-disable-next-line no-await-in-loop
    } = await Supabase
      .from('profile')
      .select(`
          id,
          first_name,
          last_name
        `)
      .eq(
        'id', conversation.sender_id,
      )
      .single();

    if (senderError) {
      throw new Error(senderError.message);
    }

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    conversation.sender = sender;

    const {
      data: receivers,
      error: receiversError,
      // eslint-disable-next-line no-await-in-loop
    } = await Supabase
      .from('establishments_managers')
      .select(`
        establishment_id,
        manager_id,
        profile:manager_id (
            id,
            first_name,
            last_name
        )
`)
      .eq(
        'establishment_id', conversation.establishment_id,
      );

    if (receiversError) {
      console.log('here');
      throw new Error(receiversError.message);
    }

    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    conversation.receivers = receivers;

    // @ts-ignore
    fullConversations.push(conversation);
  }

  return fullConversations;
};

/**
 * Get real-time conversations
 */
export const useConversations = () => useSWR(
  'all-conversations', getConversations,
);

/**
 * Get a conversation by ID
 * @param conversationID Conversation ID
 */
export const getConversation = async (conversationID: string) => {
  const { data: conversation, error } = await Supabase
    .from(tableName)
    .select(selectQuery)
    .eq(
      'id', conversationID,
    );

  if (error) throw new Error(error.message);

  return conversation;
};

/**
 * Get a real-time conversation by ID
 * @param conversationID Conversation ID
 */
export const useConversation = (conversationID: string) => {
  const {
    data, error, mutate, isLoading,
  } = useSWRImmutable(
    ['conversation', conversationID], () => getConversation(conversationID),
  );

  Supabase.channel('conversation-update-channel')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'profile' },
      () => mutate(),
    )
    .subscribe();

  return {
    data, error, mutate, isLoading,
  };
};

/**
 * Create a conversation
 */
export const createConversation = async (
  offerID: number,
  establishmentID: number | null,
  senderID: number,
) => {
  const conversation = {
    offer_id: offerID,
    establishment_id: establishmentID,
    sender_id: senderID,
  };

  const { data, error } = await Supabase
    .from(tableName)
    .insert(conversation)
    .select('id')
    .single();

  if (error) throw new Error(error.message);

  return data;
};

/**
 * Update a conversation
 * @param conversationID
 */
export const getSingleConversation = async (conversationID: number) => {
  const { data: conversation, error } = await Supabase
    .from(tableName)
    .select(`
      id,
      offer_id,
      sender_id,
      establishment_id,
      is_open
    `)
    .eq(
      'id', conversationID,
    )
    .single();

  if (error) throw new Error(error.message);

  const { data: messages, error: messagesError } = await Supabase
    .from('message')
    .select(`
      id,
      created_at,
      sender_id,
      content,
      is_system,
      profile:sender_id (
        id,
        created_at,
        first_name,
        last_name
      ),
      conversation_id
    `)
    .eq(
      'conversation_id', conversationID,
    );

  if (messagesError) throw new Error(messagesError.message);

  // @ts-ignore
  conversation.messages = messages;

  return conversation;
};

/**
 * Get a real-time conversation by ID
 * @param conversationID
 */
export const useSingleConversation = (conversationID: number) => {
  const {
    data, error, mutate, isLoading,
  } = useSWRImmutable(
    ['conversation', conversationID], () => getSingleConversation(conversationID),
  );

  Supabase.channel('conversation-update-channel')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'profile' },
      () => mutate(),
    )
    .subscribe();

  return {
    data, error, mutate, isLoading,
  };
};
