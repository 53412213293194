import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { Database } from '@/custom-types/supabase';

const SupabaseAdmin: SupabaseClient<Database> = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_SERVICE_ROLE,
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
    },
  },
);

export default SupabaseAdmin;
