import classNames from 'classnames';
import React from 'react';

interface LoaderProps
{
  className?: string;
}

/**
 * @param props
 *
 */
export default function Loader({
  className = '',
}: LoaderProps): React.ReactElement {
  return (
    <div className={classNames(
      'center', className,
    )}
    >
      <div className="lds-grid">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

Loader.defaultProps = {
  className: '',
};
