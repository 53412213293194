import React from 'react';
import { Typography } from '@material-tailwind/react';
import Card from '@/components/Card';

interface ErrorCardProps {
    title: string;
    message?: string;
}

export default function InfoCard({ title, message }: ErrorCardProps): React.ReactElement {
  return (
    <Card>
      <div className="flex flex-col items-center justify-center h-full gap-3">
        <img src="/sad.svg" alt="Aucun dossier" width={300} height={300} />
        <Typography variant="h5" color="blue-gray">
          {title}
        </Typography>
        {import.meta.env.VITE_APP_ENV === 'development' && (
        <Typography color="gray" variant="small" className="mt-1 font-normal text-text">
          {message}
        </Typography>
        )}
      </div>
    </Card>
  );
}

InfoCard.defaultProps = { message: undefined };
