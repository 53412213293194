import React from 'react';
import toast from 'react-hot-toast';
import {
  Input, Button,
} from '@material-tailwind/react';
import { createExperience } from '@lib/Experience';

export default function CreateExperience({
  setOpenExperiences,
  mutate,
}: {
  setOpenExperiences: React.Dispatch<React.SetStateAction<boolean>>;
  mutate: any;
}): React.ReactElement {
  const [updatingInfo, setUpdatingInfo] = React.useState(false);

  const [formData, setFormData] = React.useState({
    title: '',
    description: '',
    company: '',
    city: '',
    start_date: '',
    end_date: '',
  });

  const handleCreateExperience = async () => createExperience({
    title: formData.title,
    description: formData.description,
    company: formData.company,
    city: formData.city,
    start_date: formData.start_date,
    end_date: formData.end_date,
  });

  const handleCreation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUpdatingInfo(true);

    await toast.promise(
      handleCreateExperience(),
      {
        loading: 'Création de l\'expérience...',
        success: 'Expérience créée avec succès !',
        error: 'Une erreur est survenue lors de la création de l\'expérience',
      },
    ).then(() => {
      setUpdatingInfo(false);
      setOpenExperiences(false);
      mutate();
    })
      .catch(() => {
        setUpdatingInfo(false);
        mutate();
      });
  };
  return (
    <form onSubmit={handleCreation}>
      <fieldset className="flex flex-col gap-4" disabled={updatingInfo}>
        <Input
          crossOrigin={undefined}
          type="text"
          label="Titre"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          name="title"
          required
        />
        <Input
          crossOrigin={undefined}
          type="text"
          label="Description"
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          name="description"
          required
        />
        <Input
          crossOrigin={undefined}
          type="text"
          label="Entreprise"
          value={formData.company}
          onChange={(e) => setFormData({ ...formData, company: e.target.value })}
          name="company"
          required
        />
        <Input
          crossOrigin={undefined}
          type="text"
          label="Ville"
          value={formData.city}
          onChange={(e) => setFormData({ ...formData, city: e.target.value })}
          name="city"
          required
        />
        <Input
          crossOrigin={undefined}
          type="date"
          label="Date de début"
          value={formData.start_date}
          onChange={(e) => setFormData({ ...formData, start_date: e.target.value })}
          name="start_date"
          required
        />
        <Input
          crossOrigin={undefined}
          type="date"
          label="Date de fin"
          value={formData.end_date}
          onChange={(e) => setFormData({ ...formData, end_date: e.target.value })}
          name="end_date"
        />
        <p className="text-sm text-gray-500">
          Si vous êtes toujours en poste, laissez la date de fin vide.
        </p>
        <Button type="submit" className="bg-primary">
          Ajouter
        </Button>
      </fieldset>
    </form>
  );
}
