import React from 'react';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react';

/**
 * Modal component
 * @param open
 * @param setOpen
 * @param title
 * @param children
 * @param confirmText
 * @param cancelText
 * @param callBack
 * @param confirmColor
 * @returns {React.JSX.Element}
 * @constructor
 */
export default function Modal({
  open,
  setOpen,
  title,
  children,
  confirmText,
  cancelText,
  callBack,
  confirmColor,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  children: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  callBack?: () => void;
  confirmColor?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'info';
}): React.JSX.Element {
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleConfirm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (callBack) callBack();
    setOpen(false);
  };

  let confirmColorClass = 'bg-primary';
  if (confirmColor === 'secondary') confirmColorClass = 'bg-secondary';
  if (confirmColor === 'danger') confirmColorClass = 'bg-danger';
  if (confirmColor === 'success') confirmColorClass = 'bg-success';
  if (confirmColor === 'warning') confirmColorClass = 'bg-warning';
  if (confirmColor === 'info') confirmColorClass = 'bg-info';

  return (
    <Dialog
      open={open}
      size="xs"
      handler={handleOpen}
    >
      <form onSubmit={handleConfirm}>
        <DialogHeader>
          <span className="text-lg">
            {title}
          </span>
        </DialogHeader>
        <DialogBody className="pt-0">
          {children}
        </DialogBody>
        <DialogFooter className="flex gap-2">
          <Button
            onClick={() => setOpen(false)}
            className="bg-gray-500/20 text-gray-700"
          >
            <span>
              {cancelText}
            </span>
          </Button>
          <Button
            className={confirmColorClass}
            type="submit"
          >
            <span>
              {confirmText}
            </span>
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
}

Modal.defaultProps = {
  confirmText: 'Confirmer',
  cancelText: 'Annuler',
  callBack: () => {},
  confirmColor: 'primary',
};
