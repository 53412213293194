import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

export default function Select({
  label,
  options,
  value,
  setValue,
  required,
  disabled,
  className,
  size,
}: {
  label: string;
  options: {
    value: string | number | boolean | undefined | null;
    label: string
  }[];
  value: string | number | boolean | undefined | null;
  // eslint-disable-next-line no-shadow
  setValue: (result: {
    value: string | number | boolean | undefined | null;
    label: string;
  }) => void;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}): React.ReactElement {
  const [shouldBeDisabled, setShouldBeDisabled] = React.useState(false);

  React.useEffect(
    () => {
      // Look for parent fieldset and check if it's shouldBeDisabled
      const fieldset = document.querySelector(`input[name="${label}"]`)?.closest('fieldset');
      if (fieldset && fieldset.disabled) {
        setShouldBeDisabled(true);
      }
    }, [],
  );

  let sizeClasses = '';
  let optionSizeClasses = '';
  switch (size) {
    case 'sm':
      sizeClasses = 'px-2 py-1 text-xs';
      optionSizeClasses = 'text-xs';
      break;
    case 'md':
      sizeClasses = 'px-3 py-2.5 text-sm';
      optionSizeClasses = 'text-sm';
      break;
    case 'lg':
      sizeClasses = 'px-4 py-3 text-base';
      optionSizeClasses = 'text-base';
      break;
    default:
      sizeClasses = 'px-3 py-2 text-sm';
      optionSizeClasses = 'text-sm';
      break;
  }

  return (
    <>
      <input
        type="hidden"
        name={label}
        value={value as string | number}
        required={required}
        disabled={shouldBeDisabled || disabled}
      />
      {/* @ts-ignore */}
      <Listbox
        value={value}
            // @ts-ignore
        onChange={setValue}
        disabled={shouldBeDisabled || disabled}
        className={
              classNames(
                shouldBeDisabled && 'pointer-events-none', 'w-full min-w-fit',
              )
            }
        aria-required={required}
      >
        <div className="relative mt-1">
          <Listbox.Button
            aria-required={required}
            className={classNames(
              'text-blue-gray-700 cursor-pointer relative w-full min-w-fit rounded-lg pl-3 pr-10 text-left border border-blue-gray-200/80 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary sm:text-sm',
              shouldBeDisabled && 'bg-blue-gray-100/40 border-0',
              sizeClasses,
              className,
            )}
          >
            <span className="block truncate">
              {value !== undefined && options
                .find((option) => option.value === value)?.label && (
                `${label}: ${options.find((option) => option.value === value)?.label}`
              )}

              {((value === undefined || value === null) && !options
                .find((option) => option.value === value)) && label}

              {value !== undefined && required && (
                <span className="text-error text-xs ml-1">*</span>
              )}

            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((
                option, optionIdx,
              ) => (
                option && option.label !== undefined && option.value !== undefined && (
                <Listbox.Option
                            // eslint-disable-next-line react/no-array-index-key
                  key={optionIdx}
                  className={({ active }) => `w-full relative cursor-pointer select-none py-2 pl-4 pr-4 ${
                    active ? 'bg-primary/10 text-primary' : 'text-gray-900'
                  } ${optionSizeClasses}`}
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`w-full block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center text-primary pl-3">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
                )
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </>
  );
}

Select.defaultProps = {
  required: false,
  disabled: false,
  className: '',
  size: 'md',
};
