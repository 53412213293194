import PageTitle from '@/components/app/PageTitle';
import { Button, Typography } from '@material-tailwind/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSingleOffer } from '@/lib/Offer';
import Skeleton from 'react-loading-skeleton';

import {
  CalendarIcon, ClockIcon, MapIcon, QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import FullPageLoader from '@/components/FullPageLoader';
import { useMissions } from '@/lib/Mission';
import ActiveLink from '@/components/app/ActiveLink';
import ErrorCard from '@/components/ErrorCard';
import useProfile from '@/lib/Profile';
import { FullProfile } from '@/custom-types/Profile';
import { StarIcon } from '@heroicons/react/24/outline';
import { setIsFavoriteEstablishment, useIsFavoriteEstablishment } from '@lib/Establishment';
import toast from 'react-hot-toast';
import { useEstablishmentOverallRating, useEstablishmentRatingCategories } from '@lib/Rating';

export default function JobDetails({
  offerID,
}: { offerID?: number }): React.ReactElement {
  let id: number;
  let stackCards = false;
  let showTitle = true;
  let canPostulate = true;

  if (offerID) {
    id = offerID;
    stackCards = true;
    showTitle = false;
  } else {
    const params = useParams<{ id: string }>() as { id: unknown } as { id: number };
    id = params.id;
  }

  const { data: offer } = useSingleOffer(id);
  const { data: missions } = useMissions();
  // @ts-ignore
  const { data: isFavorite, mutate } = useIsFavoriteEstablishment(offer?.establishment?.id);

  //  @ts-ignore
  const { data: profile }: {
        profile: FullProfile,
        profileError: Error
    } = useProfile();

  //  @ts-ignore
  const establishmentID = offer?.establishment?.id;

  const {
    data: establishmentOverallRating,
  } = useEstablishmentOverallRating(establishmentID);

  const { data: fullEstablishmentRatings } = useEstablishmentRatingCategories(establishmentID);

  if (!missions || !offer || !profile) {
    return <FullPageLoader />;
  }

  const isExpired = new Date(offer?.start_date) < new Date();

  if (isExpired && profile?.role?.code === 'candidate') {
    return (
      <ErrorCard message="Cette offre n'est plus disponible." />
    );
  }

  if (!offer?.is_published) {
    return (
      <ErrorCard message="Impossible de trouver l'offre." />
    );
  }

  if (missions && missions.length > 0) {
    for (let i = 0; i < missions.length; i += 1) {
      if (Number(missions[i].offer_id) === Number(id)) {
        canPostulate = false;
        break;
      }
    }
  }

  if (profile?.role?.code !== 'candidate') {
    canPostulate = false;
  }

  const startDate = new Date(offer?.start_date)
    .toLocaleDateString(
      'fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
    );

  const endDate = new Date(offer?.end_date)
    .toLocaleDateString(
      'fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
    );

  const daysLeft = Math.floor((new Date(offer?.start_date).getTime() - new Date()
    .getTime()) / (1000 * 3600 * 24));

  // @ts-ignore
  return (
    <>
      {showTitle && (
        <PageTitle title="Détails de l'offre" />
      )}
      <div className="px-5">
        <div className={classNames(
          'grid-cols-3 gap-10', !stackCards && '2xl:grid',
        )}
        >
          {/* LEFT SECTION */}
          <div className="mb-5 bg-base-100 shadow rounded-xl p-10">
            <div className="flex flex-col flex-wrap items-start gap-3 mb-2">
              <div className="flex items-start gap-2 flex-col">
                <CalendarIcon className="text-primary w-5 h-5" />
                <Typography color="gray" className="text-sm font-medium">
                  {offer ? (
                    <>
                      Du
                      {' '}
                      {startDate}
                      {' '}
                      au
                      {' '}
                      {endDate}
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </Typography>
              </div>
            </div>
            <div className="flex items-start gap-2 mb-2 flex-col">
              <MapIcon className="text-primary w-5 h-5" />
              <a
                title="Voir sur Google Maps"
                                // @ts-ignore
                href={`https://www.google.com/maps/search/?api=1&query=${offer?.establishment?.address?.first_line} ${offer?.establishment?.address?.city} ${offer?.establishment?.address?.zip}`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography className="text-sm text-primary flex flex-col font-medium">
                  <span>
                    {/* @ts-ignore */}
                    {offer?.establishment?.address?.first_line}
                  </span>
                  <span>
                    {/* @ts-ignore */}
                    {offer?.establishment?.address?.city}
                    {' '}
                    {/* @ts-ignore */}
                    {offer?.establishment?.address?.zip}
                  </span>
                </Typography>
              </a>
            </div>
            {offer?.start_time && (
            <div className="flex items-start gap-2 mb-2 flex-col">
              <ClockIcon className="text-primary w-5 h-5" />
              <Typography color="gray" className="text-sm font-medium">
                à
                {' '}
                {offer?.start_time}
                H
              </Typography>
            </div>
            )}
            <div className="flex items-start gap-2 mb-2 flex-col">
              <QuestionMarkCircleIcon className="text-primary w-5 h-5" />
              <Typography color="gray" className="text-sm font-medium">
                {!isExpired && (
                <>
                  Dans
                  {' '}
                  {daysLeft}
                  {' '}
                  jours
                </>
                )}
              </Typography>
            </div>
            <div className="flex items-start gap-2 mb-2 flex-col">
              <ClockIcon className={classNames(
                'w-5 h-5', profile?.role?.code === 'candidate' && profile?.experience_years
                                && profile?.experience_years < offer?.experience_required ? 'text-error' : 'text-primary',
              )}
              />
              <Typography
                color="gray"
                className={classNames(
                  'text-sm font-medium', profile?.role?.code === 'candidate' && profile?.experience_years
                                    && profile?.experience_years < offer?.experience_required && 'text-error',
                )}
              >
                {offer?.experience_required === 0 ? 'Aucune' : offer?.experience_required}
                {' '}
                année
                {offer?.experience_required > 0 && 's'}
                {' '}
                d&apos;expérience requise
                {offer?.experience_required > 0 && 's'}
              </Typography>
            </div>

            {profile?.role?.code === 'candidate' && offer?.experience_required !== 0 && profile?.experience_years
                            && profile?.experience_years < offer?.experience_required && (
                            <Typography className="text-sm font-medium text-error">
                              Vous pouvez postuler à cette offre
                              mais vous ne remplissez pas les critères d&apos;expérience requis.
                            </Typography>
            )}
          </div>

          {/* RIGHT SECTION */}
          <div className="bg-base-100 shadow rounded-xl p-10 flex-1 col-span-2">
            <Typography variant="h2">
              {offer?.title || <Skeleton />}
            </Typography>
            <Typography variant="paragraph">
              {offer?.description || <Skeleton />}
            </Typography>

            {establishmentOverallRating ? (
              <div className="flex items-center gap-2">
                <p className="text-sm">Note des autre candidats:</p>
                <StarIcon className="w-5 h-5 text-yellow-600" />
                <Typography variant="paragraph" className="text-yellow-600">
                  {establishmentOverallRating || 0}
                  {' '}
                  / 5
                </Typography>
              </div>
            ) : (
              <p className="text-xs">Cet établissement n&apos;a pas encore été noté.</p>
            )}
            {fullEstablishmentRatings && (
            <div>
              {Object.keys(fullEstablishmentRatings)?.map((
                key, index,
              ) => (
                //   @ts-ignore
                <div
                  className="flex items-center gap-2"
                  key={
                            // eslint-disable-next-line react/no-array-index-key
                            index
                          }
                >
                  <p className="text-sm">{key}</p>
                  <p className="text-sm">
                    {
                            // @ts-ignore
                            fullEstablishmentRatings[key]
                          }
                  </p>
                  <StarIcon className="w-5 h-5 text-yellow-600" />
                </div>
              ))}
            </div>
            )}

            {/* establishments */}
            <div className="flex justify-between items-center">
              <p className="font-bold text-primary mt-5 text-sm bg-primary/20 py-1 px-3 rounded-xl w-fit mb-2">
                L&apos; établissement
              </p>
              {profile?.role?.code === 'candidate' && (
              <Button
                className={classNames(
                  'bg-transparent shadow-none text-text flex gap-2 items-center hover:shadow-none', isFavorite && 'text-yellow-600',
                )}
                onClick={async () => toast.promise(
                  setIsFavoriteEstablishment(
                    // @ts-ignore
                    offer?.establishment?.id, !isFavorite,
                  ), {
                    loading: 'Chargement...',
                    success: isFavorite ? 'Etablissement retiré des favoris' : 'Etablissement ajouté aux favoris',
                    error: 'Une erreur est survenue',
                  },
                ).then(() => {
                  mutate();
                })}
              >
                Favori
                <StarIcon
                  className="w-5 h-5"
                  fill={isFavorite ? 'currentColor' : 'none'}
                />
              </Button>
              )}
            </div>
            <div className="flex-col items-center gap-2 mb-5">
              <div className="mb-3">
                <Typography variant="h5">
                  {/* @ts-ignore */}
                  {offer?.establishment?.name || <Skeleton />}
                </Typography>
                <Typography variant="paragraph">
                  {/* @ts-ignore */}
                  {offer?.establishment?.description || <Skeleton count={5} />}
                </Typography>
              </div>
              <p className="font-bold text-gray-500 text-sm">
                Quelques informations
              </p>
              {/* @ts-ignore */}
              {offer?.establishment?.capacity !== 0 && (
                <Typography variant="paragraph" className="font-medium">
                  Capacité :
                  {' '}
                  {/* @ts-ignore */}
                  {offer?.establishment?.capacity}
                  {' '}
                  personnes
                </Typography>
              )}
              <Typography variant="paragraph" className="font-medium text-sm">
                Type d&apos;établissement:
                {' '}
                {/* @ts-ignore */}
                {offer?.establishment?.establishment_sector?.name}
                {/* @ts-ignore */}
              </Typography>
            </div>

            {/* PROFILE */}
            {offer?.profile_wished && (
              <>
                <p className="font-bold text-primary mt-5 text-sm bg-primary/20 py-1 px-3 rounded-xl w-fit mb-2">
                  Le profil recherché
                </p>
                <div className="mb-5">
                  <Typography>
                    {offer?.profile_wished || <Skeleton count={5} />}
                  </Typography>
                </div>
              </>
            )}

            {/* MISSIONS */}
            {offer?.missions && (
              <>
                <p className="font-bold text-primary mt-5 text-sm bg-primary/20 py-1 px-3 rounded-xl w-fit mb-2">
                  Les missions
                </p>
                <div className="mb-5">
                  <Typography>
                    {offer?.missions || <Skeleton count={5} />}
                  </Typography>
                </div>
              </>
            )}

            {/* CONDITIONS */}
            {offer?.conditions && (
              <>
                <p className="font-bold text-primary mt-5 text-sm bg-primary/20 py-1 px-3 rounded-xl w-fit mb-2">
                  Les conditions proposées
                </p>
                <div className="mb-5">
                  <Typography>
                    {offer?.conditions || <Skeleton count={5} />}
                  </Typography>
                </div>
              </>
            )}

            {canPostulate && (
              <div className="flex gap-2 justify-between">
                <ActiveLink to={`/job/${offer?.id}/apply`}>
                  <Button fullWidth className="bg-primary w-full">
                    Postuler
                  </Button>
                </ActiveLink>
                <ActiveLink to={`/job/${offer?.id}/apply`}>
                  <Button fullWidth className="bg-primary w-full">
                    Chat
                  </Button>
                </ActiveLink>
              </div>
            )}
          </div>

        </div>
      </div>

    </>
  );
}

JobDetails.defaultProps = {
  offerID: undefined,
};
