import React from 'react';
import BottomNav from '@components/app/BottomNav';
import NavBar from '@components/app/NavBar';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import FullPageLoader from '@components/FullPageLoader';
import { useAuth } from '@/context/Auth';
import useProfile from '@/lib/Profile';
import { markNotificationAsRead, useNotifications } from '@lib/Notification';
import Copyright from '@components/Copyright';

export default function UserLayout(): React.ReactElement {
  const location = useLocation();
  const { status } = useAuth();
  const navigate = useNavigate();
  const { data: profile, error: profileError } = useProfile();
  const { data: notifications } = useNotifications();
  // if location includes "auth", redirect to /
  if (location?.pathname?.includes('auth')) {
    window.location.href = '/';
  }

  React.useEffect(
    () => {
      if (typeof Notification !== 'undefined' && Notification.permission !== 'granted') {
        Notification.requestPermission()
          .then((result) => {
            // @ts-ignore
            const hasNotifications = notifications?.length > 0 && notifications
              .map((notification) => {
                const wasRead = notification.read;
                return !wasRead;
              }).includes(true);

            const icon = '/images/logo.png';

            if (result === 'granted' && hasNotifications) {
              // eslint-disable-next-line array-callback-return
              notifications?.map((notification): void => {
                const isDisplayed = localStorage.getItem(`notification-${notification.id}`);
                if (!isDisplayed) {
                  const notif = new Notification(
                    import.meta.env.VITE_APP_NAME, {
                      body: `${notification.type} - ${notification.title}`,
                      icon,
                      vibrate: [200, 100, 200],
                      data: {
                        url: notification.url,
                      },
                      badge: icon,
                    },
                  );

                  if (notif) {
                    // Save in localstorage that the notification has been displayed
                    localStorage.setItem(
                      `notification-${notification.id}`,
                      notification.id,
                    );
                    notif.onclick = () => {
                      window.focus();
                      notif.close();
                      //   mark as read
                      markNotificationAsRead(notification.id).then((r) => r);
                    };
                  }
                }
              });
            }
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(
              'notification access error', err,
            );
          });
      }
    }, [profile],
  );

  if (profileError) {
    return (
      <>
        <NavBar />
        <FullPageLoader />
        <BottomNav />
      </>
    );
  }

  if (status === 'loading') {
    return (
      <>
        <NavBar />
        <FullPageLoader />
        <Copyright />
        <BottomNav />
      </>
    );
  }

  if (status === 'unauthenticated') {
    navigate('/auth/sign-in');
    return (
      <>
        <NavBar />
        <FullPageLoader />
        <Copyright />
        <BottomNav />
      </>
    );
  }

  // @ts-ignore
  if (profile?.onboarding_completed === false || profile?.data === null) {
    navigate('/onboarding/');
    return (
      <>
        <NavBar />
        <FullPageLoader />
        <Copyright />
        <BottomNav />
      </>
    );
  }

  if (!profile) {
    return (
      <>
        <NavBar />
        <FullPageLoader />
        <Copyright />
        <BottomNav />
      </>
    );
  }

  return (
    <>
      <NavBar />
      <div className="bg-base-100 pb-16 min-h-screen">
        <Outlet />
      </div>
      <Copyright />
      <BottomNav />
    </>
  );
}
