import { HiChevronRight } from 'react-icons/hi';
import React from 'react';
import ActiveLink from '@/components/app/ActiveLink';

interface NavButtonInterface
{
  to: string;
  text: string;
  description?: string;
  icon: React.ElementType;
  target?: string;
}

interface ButtonContentsInterface
{
  text: string;
  description?: string;
  icon: React.ElementType;
}

function ButtonContents({
  text,
  description,
  icon,
}: ButtonContentsInterface): React.ReactElement {
  return (
    <div className="flex items-center h-full w-full">
      <span className="text-primary bg-primary/10 p-5 rounded-xl">
        {React.createElement(
          icon, { size: 24 },
        )}
      </span>
      <div className="ml-2 text-start">
        <p className="font-semibold text-lg">
          {text}
        </p>
        {description && (
          <span className="text-sm font-normal inline">
            {description}
          </span>
        )}
      </div>
      <div className="flex justify-end flex-1">
        <HiChevronRight size={24} />
      </div>
    </div>
  );
}

ButtonContents.defaultProps = {
  description: '',
};

/**
 * @param props
 *
 */
export default function NavButton({
  to,
  text,
  description,
  icon,
  target,
}: NavButtonInterface): React.ReactElement {
  if (target) {
    return (
      <a
        href={to}
        target={target}
        className="bg-base-100 p-2 rounded-xl shadow flex items-center justify-between mb-5"
      >
        <ButtonContents
          text={text}
          description={description}
          icon={icon}
        />
      </a>
    );
  }

  return (
    <ActiveLink
      to={to}
      className="bg-base-100 p-2 rounded-xl shadow flex items-center justify-between mb-5"
    >
      <ButtonContents
        text={text}
        description={description}
        icon={icon}
      />
    </ActiveLink>
  );
}

NavButton.defaultProps = {
  description: '',
  target: '',
};
