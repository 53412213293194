import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import FullPageLoader from '@components/FullPageLoader';
import { useAuth } from '@/context/Auth';
import { Typography } from '@material-tailwind/react';
import Copyright from '@/components/Copyright';
import Supabase from '@/lib/Supabase';
import ErrorCard from '@/components/ErrorCard';
import useProfile from '@/lib/Profile';

export default function OnboardingLayout(): React.ReactElement {
  const { status, session } = useAuth();
  const navigate = useNavigate();
  const { data: profile, error: profileError } = useProfile();

  if (status === 'unauthenticated') {
    navigate('/auth/sign-in');
  }

  if (profileError) {
    return <ErrorCard message={profileError.message} />;
  }

  if (!session) return (<FullPageLoader />);

  const email = session?.user?.email;

  if (status === 'loading') {
    return (
      <FullPageLoader />
    );
  }

  // @ts-ignore
  if (profile?.onboarding_completed) {
    navigate('/');
    return (
      <FullPageLoader />
    );
  }

  return (
    <div className="mx-auto min-h-screen flex flex-col items-center justify-center px-5 mb-32">
      <img src="/images/logo.svg" alt="logo" className="w-40 h-20 mb-5 self-start" />
      <Outlet />
      <Typography color="gray" className="text-sm text-gray-500 mt-10">
        Vous êtes actuellement connecté
        <br />
        avec l&apos;adresse
        {' '}
        <b>{email}</b>
        .
        {' '}
        <button
          type="button"
          onClick={() => Supabase.auth.signOut()}
          className="text-primary hover:underline"
        >
          Déconnexion
        </button>
      </Typography>

      <div>
        <Copyright />
      </div>
    </div>
  );
}
