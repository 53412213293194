import Supabase from '@lib/Supabase';
import useSWR from 'swr';

const tableName = 'contract_type';
const selectQuery = `
  id,
  name
`;

/**
 * Get all conversations
 */
export const getContractTypes = async () => {
  const { data, error } = await Supabase
    .from(tableName)
    .select(selectQuery);

  if (error) throw new Error(error.message);

  return data;
};

/**
 * Get real-time conversations
 */
export const useContractTypes = () => useSWR(
  'contract-types', getContractTypes,
);
