import Supabase from '@lib/Supabase';
import useSWR from 'swr';
import { getProfile } from '@lib/Profile';

/**
 * Get all experiences
 */
const getExperiences = async () => {
  const profile = await getProfile();

  if (!profile) {
    throw new Error('No profile found');
  }

  const { data: experiences, error } = await Supabase
    .from('experience')
    .select('*')
    .eq(
      // @ts-ignore
      'profile_id', profile.id,
    )
    .order(
      'start_date', { ascending: false },
    );

  if (error) {
    throw new Error(error.message);
  }

  return experiences;
};

/**
 * Get all experiences
 */
export const useExperiences = () => useSWR(
  ['all-experiences'], getExperiences,
);

/**
 * Get experience by id
 * @param experienceData
 */
export const createExperience = async (experienceData: any) => {
  const profile = await getProfile();

  if (!profile) {
    throw new Error('No profile found');
  }

  // If end_date is empty, remove it
  // @ts-ignore
  if (!experienceData?.end_date) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    delete experienceData?.end_date;
  }

  const { data, error } = await Supabase
    .from('experience')
    .insert({
      // @ts-ignore
      ...experienceData,
      // @ts-ignore
      profile_id: profile.id,
    });

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

/**
 * Update experience by id
 * @param experienceData
 */
export const updateExperience = async (experienceData: unknown) => {
  const profile = await getProfile();

  if (!profile) {
    throw new Error('No profile found');
  }

  if (!experienceData) {
    throw new Error('No experience data provided');
  }

  // @ts-ignore
  if (!experienceData.id) {
    throw new Error('No experience ID provided');
  }

  const { data, error } = await Supabase
    .from('experience')
    .update({
      // @ts-ignore
      ...experienceData,
    })
    .eq(
      // @ts-ignore
      'profile_id', profile.id,
    )
    .eq(
      // @ts-ignore
      'id', experienceData.id,
    );

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

/**
 * Delete experience by id
 * @param experienceID
 */
export const deleteExperience = async (experienceID: number) => {
  if (!experienceID) {
    throw new Error('No experience ID provided');
  }

  const { data, error } = await Supabase
    .from('experience')
    .delete()
    .eq(
      'id', experienceID,
    );

  if (error) {
    throw new Error(error.message);
  }

  return data;
};
