import type React from 'react';
import { Typography } from '@material-tailwind/react';

export default function Footer(): React.ReactElement {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="py-3 text-center">
      <Typography
        variant="small"
        className="mb-4 text-center font-normal text-blue-gray-500 md:mb-0"
      >
        &copy;
        {' '}
        {currentYear}
        {' '}
        <a href="https://azelie-sante.fr" target="_blank" rel="noreferrer">Azélie Santé</a>
        {' '}
        - Tous droits réservés.
      </Typography>
    </footer>
  );
}
