import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/Auth';
import FullPageLoader from '@/components/FullPageLoader';
import Footer from '@/components/admin/Footer';
import Drawer from '@/components/admin/Drawer';
import { DrawerContext, useDrawer } from '@/context/Drawer';

export default function AdminLayout(): React.ReactElement {
  const { status } = useAuth();
  const drawer = useDrawer();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(
    () => {
      if (status === 'unauthenticated' && location.pathname === '/') {
        navigate('/auth/sign-in');
      }
    },
    [status, navigate],
  );

  if (status === 'loading') {
    return <FullPageLoader />;
  }

  return (
    <DrawerContext.Provider value={drawer}>
      <Drawer>
        <div
          className="bg-[#fafbfc] lg:rounded-2xl p-5 h-full"
          style={{
            backgroundImage: 'url(/images/sun-tornado.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <div className="bg-white p-5 lg:p-10 shadow-lg rounded-lg h-full">
            <Outlet />
          </div>
        </div>
        <Footer />
      </Drawer>
    </DrawerContext.Provider>
  );
}
