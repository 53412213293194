import classNames from 'classnames';
import React from 'react';
import
{
  EyeIcon,
} from '@heroicons/react/24/solid';
import {
  useNotifications,
  markNotificationAsRead,
  markAllNotificationsAsRead,
  markNotificationAsUnRead,
  deleteNotification,
} from '@/lib/Notification';
import Loader from '@/components/Loader';
import { Database } from '@/custom-types/supabase';
import { Button } from '@material-tailwind/react';

interface NotificationsPanelProps
{
  className?: string;
}

/**
 * @param props
 *
 */
export default function NotificationsPanel({
  className,
}: NotificationsPanelProps): React.ReactElement {
  const { data: notifications, mutate } = useNotifications();

  if (!notifications) {
    return <Loader />;
  }

  return (
    <>
      {notifications?.length > 0 && (
      <div className={classNames(
        'w-full lg:w-screen max-w-screen-md flex flex-col items-center justify-center flex-auto overflow-hidden bg-base-100 text-sm leading-6 rounded-xl p-5 lg:p-0 z-50 mx-auto', className,
      )}
      >
        <div className="py-4 z-50 w-full">
          <div className="w-full px-2 sm:px-0">
            <ul>
              {notifications.map((notif: Database['public']['Tables']['notification']['Row']) => (
                <li
                  key={notif.id}
                  className="relative rounded-lg p-3 border mb-5"
                >
                  <div className="flex items-center space-x-3">
                    <div>
                      <div className="flex gap-2 mb-2">
                        {!notif.read ? (
                          <span className="bg-primary/10 text-primary rounded-lg font-medium py-1/2 px-2 text-uppercase text-sm">
                            Nouveau
                          </span>
                        ) : (
                          <span className="bg-gray-500/10 text-gray-600 rounded-lg font-medium py-1/2 px-2 text-uppercase text-sm">
                            Lu
                          </span>
                        )}
                        <span className="bg-blue-900/10 text-blue-900 rounded-lg font-medium py-1/2 px-2 text-uppercase text-sm">
                          {notif.type}
                        </span>
                      </div>
                      <h3 className="text-base font-medium leading-5 mb-1">
                        {notif.title}
                      </h3>
                      <p className="text-sm leading-5 text-gray-600">
                        {notif.content}
                      </p>

                      <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-neutral/60">
                        {/* @ts-ignore */}
                        <li>
                          {new Date(notif.created_at).toLocaleDateString(
                            'fr-FR', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                            },
                          )}
                        </li>
                        <li>&middot;</li>
                        <li>
                          {notif.level === '1' && (
                          <span className="bg-success/10 text-success rounded-lg font-medium py-1/2 px-2">
                            Information
                          </span>
                          )}

                          {notif.level === '2' && (
                          <span className="bg-warning/10 text-warning rounded-lg font-medium py-1/2 px-2">
                            Attention
                          </span>
                          )}

                          {notif.level === '3' && (
                          <span className="bg-error/10 text-error rounded-lg font-medium py-1/2 px-2">
                            Erreur
                          </span>
                          )}
                        </li>
                      </ul>

                      <div className="flex gap-3 mt-1">

                        {!notif.read && (
                        <button
                          type="button"
                          className="hover:underline text-gray-500"
                          onClick={() => markNotificationAsRead(notif.id).then(() => mutate())}
                        >
                          Marquer comme lu
                        </button>
                        )}

                        {notif.read && (
                        <button
                          type="button"
                          className="hover:underline text-gray-500"
                          onClick={() => markNotificationAsUnRead(notif.id).then(() => mutate())}
                        >
                          Marquer comme non lu
                        </button>
                        )}

                        <button
                          type="button"
                          className="hover:underline text-gray-500"
                          onClick={() => deleteNotification(notif.id).then(() => mutate())}
                        >
                          Supprimer
                        </button>
                      </div>
                    </div>

                  </div>

                </li>
              ))}
            </ul>
          </div>

        </div>
        <div className="w-full bg-base-100">
          <Button
            type="button"
            className="flex items-center justify-center w-full bg-primary gap-2"
            onClick={() => markAllNotificationsAsRead()}
          >
            <EyeIcon
              className="h-5 w-5 flex-none text-white"
              aria-hidden="true"
            />
            Tout marquer comme lu
          </Button>
        </div>
      </div>
      )}
      {notifications.length === 0 && (
      <div className={classNames(
        'w-full lg:w-screen max-w-screen-md flex-auto overflow-hidden rounded-3xl bg-base-100 text-sm leading-6 ', className,
      )}
      >
        <div className="p-4">
          <div className="w-full max-w-screen-md px-2 sm:px-0">
            <div className="rounded-xl p-3">
              <h3 className="text-sm font-medium leading-5 text-primary">
                Vous n&apos;avez aucune notification.
              </h3>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
}

NotificationsPanel.defaultProps = {
  className: '',
};
