import type React from 'react';
import classNames from 'classnames';

interface CardProps {
    children: React.ReactNode;
    className?: string;
}

export default function Card({ children, className }: CardProps): React.ReactElement {
  const baseClassNames = 'w-full rounded-lg mx-auto p-0 lg:p-10';

  return (
    <div className={classNames(
      baseClassNames,
      className,
    )}
    >
      {children}
    </div>
  );
}

Card.defaultProps = { className: '' };
