import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ErrorCard from '@/components/ErrorCard';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { Typography } from '@material-tailwind/react';
import TitleContext from '@/context/Title';
import { useProfile } from '@/lib/Profile';
import { FullProfile } from '@/custom-types/Profile';
import { Link } from 'react-router-dom';
import FullPageLoader from '@components/FullPageLoader';
import { useCountUsers, useUserStats } from '@lib/Users';
import { useCountOffers, useOfferStats } from '@lib/Offer';
import { useCountEstablishments, useEstablishmentStats } from '@lib/Establishment';

export default function Dashboard(): React.ReactElement {
  let greeting = 'Bonjour';
  const hour = new Date().getHours();
  const { setTitle } = React.useContext(TitleContext);
  const { data: userCount, error: userCountError } = useCountUsers();
  const { data: userStats, error: userStatsError } = useUserStats();
  const { data: offersCount, error: offersCountError } = useCountOffers();
  const { data: establishmentsCount, error: establishmentsCountError } = useCountEstablishments();
  const { data: establishmentStats, error: establishmentStatsError } = useEstablishmentStats();
  const { data: offerStats, error: offerStatsError } = useOfferStats();

  if (hour >= 12 && hour < 18) {
    greeting = 'Bon après-midi';
  } else if (hour >= 18) {
    greeting = 'Bonsoir';
  }

  // @ts-ignore
  const { data: profile, error }: {
    profile: FullProfile,
    error: Error
  } = useProfile();

  React.useEffect(() => {
    setTitle('Tableau de Bord');
  });

  if (error) {
    return (
      <ErrorCard message="Impossible de charger votre profil" />
    );
  }

  if (userCountError) {
    return (
      <ErrorCard message="Impossible de charger le nombre d'utilisateurs" />
    );
  }

  if (offersCountError) {
    return (
      <ErrorCard message="Impossible de charger le nombre d'offres" />
    );
  }

  if (userStatsError) {
    return (
      <ErrorCard message="Impossible de charger les statistiques" />
    );
  }

  if (establishmentStatsError) {
    return (
      <ErrorCard message="Impossible de charger les statistiques" />
    );
  }

  if (offerStatsError) {
    return (
      <ErrorCard message="Impossible de charger les statistiques" />
    );
  }

  if (establishmentsCountError) {
    return (
      <ErrorCard message="Impossible de charger le nombre d'établissements" />
    );
  }

  if (!profile) {
    return <FullPageLoader />;
  }

  if (userCount === undefined
    || !offersCount === undefined
    || !establishmentsCount === undefined
    || !userStats === undefined
    || !establishmentStats === undefined
    || !offerStats === undefined) {
    return <FullPageLoader />;
  }

  return (
    <div className="mb-5">
      <Typography variant="h4" className="font-normal">
        {profile?.first_name
          ? (
            <>
              <span>{greeting}</span>
              ,
              {' '}
              <strong>
                {profile?.first_name}
                .
              </strong>
            </>
          )
          : (
            <Skeleton />
          )}
      </Typography>
      <p className="mb-5 font-normal text-sm mt-0 text-gray-600">
        Nous sommes le
        {' '}
        {new Date().toLocaleDateString(
          'fr-FR',
          {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          },
        )}
        .
      </p>

      <section>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <Link to="/users" className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5">
            <div>
              <h3 className="mb-2 text-gray-600">Utilisateurs</h3>
              <p className="text-2xl font-bold text-black">
                {userCount}
              </p>
              <p className="text-sm font-semibold mt-2 text-success">
                +
                {' '}
                {userStats}
                {' '}
                ce mois-ci
              </p>
            </div>
            <ArrowRightIcon className="w-5 h-5" />
          </Link>
          <Link to="/establishments" className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5">
            <div>
              <h3 className="mb-2 text-gray-600">Etablissements</h3>
              <p className="text-2xl font-bold text-black">
                {establishmentsCount}
              </p>
              <p className="text-sm font-semibold mt-2 text-success">
                +
                {' '}
                {establishmentStats}
                {' '}
                ce mois-ci
              </p>
            </div>
            <ArrowRightIcon className="w-5 h-5" />
          </Link>
          <Link
            to="/jobs"
            className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5"
          >
            <div>
              <h3 className="mb-2 text-gray-600">Offres</h3>
              <p className="text-2xl font-bold text-black">
                {offersCount}
              </p>
              <p className="text-sm font-semibold mt-2 text-success">
                +
                {' '}
                {offerStats}
                {' '}
                ce mois-ci
              </p>
            </div>
            <ArrowRightIcon className="w-5 h-5" />
          </Link>
        </div>
      </section>

      <section>
        <h3 className="mb-2">Vous souhaitez: </h3>
        {profile.role.code === 'admin' && (
        <>
          <Link to="/users" title="Utilisateurs" className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5">
            Gérer les utilisateurs
            <ArrowRightIcon className="w-5 h-5" />
          </Link>
          <Link to="/establishments" title="Etablissements" className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5">
            Gérer les établissements
            <ArrowRightIcon className="w-5 h-5" />
          </Link>
          <Link to="/jobs" title="Offres" className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5">
            Gérer les offres
            <ArrowRightIcon className="w-5 h-5" />
          </Link>
        </>
        )}

        {profile.role?.code === 'establishment' && (
        <>
          <Link to="/establishment" title="Mon Etablissement" className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5">
            Gérer mon établissement
            <ArrowRightIcon className="w-5 h-5" />
          </Link>
          <Link to="/offers" title="Mes Offres" className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5">
            Gérer mes offres
            <ArrowRightIcon className="w-5 h-5" />
          </Link>
        </>
        )}
      </section>
    </div>
  );
}
