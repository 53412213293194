import React from 'react';
import toast from 'react-hot-toast';
import {
  Input,
  Button,
  Textarea,
} from '@material-tailwind/react';
import { setProfile, useProfileTypes } from '@/lib/Profile';
import { FullProfile } from '@/custom-types/Profile';
import Select from '@components/form/Select';
import { useEstablishmentSectors } from '@lib/Establishment';
import ErrorCard from '@components/ErrorCard';
import FullPageLoader from '@components/FullPageLoader';

export default function UpdateInfo({
  profile,
  mutate,
  setOpenInfo,
}: {
  profile: FullProfile;
  mutate: () => MutationCallback
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
  const [updatingInfo, setUpdatingInfo] = React.useState(false);
  const { data: profileType, error: profileTypeError } = useProfileTypes();
  const {
    data: establishmentSectors,
    error: establishmentSectorsError,
  } = useEstablishmentSectors();

  const [formValues, setFormValues] = React.useState({
    first_name: '',
    last_name: '',
    phone: '',
    bio: '',
    type: 0,
    birth_date: '',
    current_establishment_type: 0,
    working_hours: 0,
  });

  // Set form values from profile only once to be able to edit them
  React.useEffect(
    () => {
      setFormValues({
        first_name: profile?.first_name as string,
        last_name: profile?.last_name as string,
        phone: profile?.phone as string,
        bio: profile?.bio as string,
        type: profile?.type?.id as number,
        birth_date: profile?.birth_date as string,
        current_establishment_type: profile?.current_establishment_type as number,
        working_hours: profile?.working_hours as number,
      });
    }, [profile],
  );

  if (profileTypeError) {
    toast.error('Une erreur est survenue lors de la récupération des types de profil.');
  }

  if (establishmentSectorsError) {
    return <ErrorCard message={establishmentSectorsError.message} />;
  }

  if (!establishmentSectors) {
    return <FullPageLoader />;
  }

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleTextareaChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleInformationUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUpdatingInfo(true);
    const profileOnly = {
      user_id: profile?.user_id,
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      phone: formValues.phone,
      bio: formValues.bio,
      birth_date: formValues.birth_date,
      type: formValues.type,
      current_establishment_type: formValues.current_establishment_type,
      working_hours: formValues.working_hours,
    };

    toast.promise(
      // @ts-ignore
      setProfile(profileOnly),
      {
        loading: 'Mise à jour en cours...',
        success: 'Votre profil a été mis à jour avec succès !',
        error: 'Une erreur est survenue lors de la mise à jour de votre profil.',
      },
    ).then(() => {
      setOpenInfo(false);
      setUpdatingInfo(false);
      mutate();
    })
      .catch(() => {
        setUpdatingInfo(false);
      });
  };

  if (!profileType) {
    return <div />;
  }

  return (
    <form onSubmit={handleInformationUpdate}>
      <fieldset className="flex flex-col gap-4" disabled={updatingInfo}>
        <p className="font-bold text-gray-500 text-sm">
          Général
        </p>

        <Input
          crossOrigin={undefined}
          type="text"
          label="Prénom"
          onChange={(e): Promise<void> => handleInputChange(e)}
          name="first_name"
              // @ts-ignore
          value={formValues?.first_name || ''}
          required
        />

        <Input
          crossOrigin={undefined}
          type="text"
          label="Nom"
          onChange={(e): Promise<void> => handleInputChange(e)}
          name="last_name"
              // @ts-ignore
          value={formValues?.last_name || ''}
          required
        />

        <Select
            // @ts-ignore
            // eslint-disable-next-line camelcase
          setValue={(result) => {
            setFormValues({
              ...formValues,
              type: result.value as number,
            });
          }}
          options={profileType?.map((type) => ({
            value: type.id,
            label: type.name,
          }))}
          label="Profession"
          value={formValues?.type}
        />

        <Input
          crossOrigin={undefined}
          type="text"
          label="Téléphone"
          onChange={(e): Promise<void> => handleInputChange(e)}
          name="phone"
              // @ts-ignore
          value={formValues?.phone || ''}
        />

        <div>
          <div className="mb-1">
            <Input
              crossOrigin={undefined}
              type="number"
              label="Nombre d'heures de travail"
              onChange={(e): Promise<void> => handleInputChange(e)}
              name="working_hours"
              value={formValues?.working_hours || ''}
            />
          </div>
          <p className="text-xs text-gray-600">
            Point déclaré sous la seule responsabilité du professionnel et il lui appartient,
            avec l&apos;établissement, de se conformer aux règles légales applicables en
            matière de cumul d&apos;emploi et de temps de travail/repos.
            <br />
            Si vous n&apos;êtes pas salarié, vous pouvez indiquer 0.
          </p>
        </div>

        <Select
          setValue={(result) => {
            // @ts-ignore
            setFormValues({ ...formValues, current_establishment_type: result.value });
          }}
          options={establishmentSectors.map((sector) => ({
            label: sector.name,
            value: sector.id,
          }))}
          label={"Type d'établissement dans lequel vous travaillez"}
          // @ts-ignore
          value={formValues.current_establishment_type}
        />

        <Input
          crossOrigin={undefined}
          type="date"
          label="Date de naissance"
          onChange={(e): Promise<void> => handleInputChange(e)}
          name="birth_date"
            // @ts-ignore
          value={formValues?.birth_date || ''}
        />

        <Textarea
          label="Biographie"
          onChange={(e): Promise<void> => handleTextareaChange(e)}
          name="bio"
              // @ts-ignore
          value={formValues?.bio || ''}
        />

        <Button type="submit" className="bg-primary">Enregistrer</Button>
      </fieldset>
    </form>
  );
}
