import PageTitle from '@/components/app/PageTitle';
import {
  Button, Input, Typography,
} from '@material-tailwind/react';
import React from 'react';
import { useAuth } from '@/context/Auth';
import { useProfile } from '@/lib/Profile';
import { PlusIcon } from '@heroicons/react/24/solid';
import Skeleton from 'react-loading-skeleton';
import Popover from '@/components/Popover';
import UpdateInfo from '@/pages/app/profile/cv/actions/update-info';
import CreateExperience from '@/pages/app/profile/cv/actions/create-experience';
import CreateEducation from '@/pages/app/profile/cv/actions/create-education';
import ErrorCard from '@/components/ErrorCard';
import { FullProfile } from '@/custom-types/Profile';
import UpdateAddress from '@/pages/app/profile/cv/actions/update-address';
import Supabase from '@/lib/Supabase';
import toast from 'react-hot-toast';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { deleteExperience, useExperiences } from '@lib/Experience';
import { deleteEducation, useEducations } from '@lib/Education';
import UpdateEducation from '@pages/app/profile/cv/actions/update-education';
import UpdateExperience from '@pages/app/profile/cv/actions/update-experience';
import Chip from '@components/Chip';
import Dropzone, { ExtendedFile } from '@components/form/DropZone';
import { useFiles } from '@lib/File';
import { useCandidateOverallRating } from '@lib/Rating';
import { jsPDF } from 'jspdf';
import { useFileTypes } from '@lib/FileTypes';
import { CheckIcon } from '@heroicons/react/24/outline';
import FullPageLoader from '@components/FullPageLoader';

export default function ProfileCV({
  showTitle,
}: {
  showTitle?: boolean;
}): React.ReactElement {
  // @ts-ignore
  const {
    data: profile,
    mutate,
    error: profileError,
  }: {
    data: FullProfile;
    mutate: () => MutationCallback;
    error: Error;
  } = useProfile();

  const {
    data: experiences,
    error: experiencesError,
    mutate: mutateExperiences,
  } = useExperiences();
  const {
    data: educations,
    error: educationsError,
    mutate: mutateEducations,
  } = useEducations();

  const {
    data: documents,
    error: documentsError,
    mutate: mutateDocuments,
  } = useFiles();

  const {
    data: overallRating,
    error: overallRatingError,
  } = useCandidateOverallRating(profile?.id);

  const {
    data: fileTypes,
    error: fileTypesError,
  } = useFileTypes();

  const [newExperienceYears, setNewExperienceYears] = React.useState<number>(0);

  const { session } = useAuth();
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openAddress, setOpenAddress] = React.useState(false);
  const [openExperiences, setOpenExperiences] = React.useState(false);
  const [openEducations, setOpenEducations] = React.useState(false);
  const [openUpdateEducations, setOpenUpdateEducations] = React.useState(false);
  const [educationToUpdate, setEducationToUpdate] = React.useState<any>(null);
  const [openUpdateExperiences, setOpenUpdateExperiences] = React.useState(false);
  const [experienceToUpdate, setExperienceToUpdate] = React.useState<any>(null);
  const [files, setFiles] = React.useState<ExtendedFile[]>([]);
  const [error, setError] = React.useState<string>('');

  React.useEffect(
    () => {
      setNewExperienceYears(profile?.experience_years as unknown as number);
    }, [profile],
  );

  if (profileError) {
    return (
      <ErrorCard message="Impossible de charger votre profil" />
    );
  }

  if (fileTypesError) {
    return (
      <ErrorCard message="Impossible de charger les types de fichiers" />
    );
  }

  if (overallRatingError) {
    return (
      <FullPageLoader />
    );
  }

  if (documentsError) {
    return (
      <ErrorCard message="Impossible de charger vos documents" />
    );
  }

  if (experiencesError) {
    return (
      <ErrorCard message="Impossible de charger vos expériences" />
    );
  }

  if (educationsError) {
    return (
      <ErrorCard message="Impossible de charger vos formations" />
    );
  }

  if (!profile || !experiences || !educations || !documents || !fileTypes) {
    return <FullPageLoader />;
  }

  /**
   * Triggerd when the user submit the form to update his experience years
   */
  const updateExperienceYears = async (): Promise<PostgrestSingleResponse<null>> => Supabase
    .from('profile')
    .update({
      experience_years: newExperienceYears,
    }).eq(
      'id', profile.id,
    );

  /**
   * Triggered when the user submit the form to update his experience years
   */
  const handleExperienceYearsSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    await toast.promise(
      updateExperienceYears(),
      {
        loading: 'Mise à jour de votre expérience...',
        success: 'Votre expérience a été mise à jour !',
        error: 'Impossible de mettre à jour votre expérience',
      },
      {
        style: {
          minWidth: '250px',
        },
      },
    );
  };

  /**
   * Upload files to the storage
   */
  const uploadFiles = async () => {
    files.map((file) => {
      const sanitizedName = file.name.replace(
        /[^a-zA-Z0-9.]/g, '_',
      );

      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      file.sanitizedName = sanitizedName;

      return file;
    });

    const { error: uploadError } = await Supabase
      .from('documents')
      .insert(files.map((file) => ({
        // @ts-ignore
        name: file.sanitizedName,
        user_id: session?.user?.id,
        type_id: file.docType,
      })));

    if (uploadError) {
      if (uploadError.code === '409') {
        throw new Error('Un fichier avec ce nom existe déjà');
      }

      throw new Error(uploadError.message);
    }

    return mutateDocuments();
  };

  /**
   * Upload files to the database
   */
  const handleUploadFiles = async (): Promise<void> => {
    // If a file is missing a type, return & notify
    if (files.some((file) => !file.docType)) {
      setError('Un fichier n\'a pas de type');
      return;
    }

    const promises = files.map(async (file) => {
      setError('');
      const sanitizedName = file.name.replace(
        /[^a-zA-Z0-9.]/g, '_',
      );
      const {
        error: uploadError,
      } = await Supabase
        .storage
        .from('documents')
        .upload(
          `${session?.user?.id}/${sanitizedName}`, file,
        );

      if (uploadError) {
        // @ts-ignore
        if (uploadError.statusCode === '409') {
          setError('Un fichier avec ce nom existe déjà');
          return;
        }

        setError('Impossible de téléverser vos documents');
        // eslint-disable-next-line consistent-return
        return {
          fileName: file.name,
          error: uploadError.message,
        };
      }

      // eslint-disable-next-line consistent-return
      return {
        fileName: file.name,
        error: null,
      };
    });

    const allPromises = await Promise.all(promises);

    // If any failed, don't upload to the database
    if (allPromises.some((promise) => promise?.error)) {
      // Remap the files array to remove the files that failed
      const newFiles = files
        .filter((file) => !allPromises
          .some((promise) => promise?.fileName === file.name));

      setFiles(newFiles);
      return;
    }

    await toast.promise(
      uploadFiles(),
      {
        loading: 'Téléversement de vos documents...',
        success: 'Vos documents ont été téléversés !',
        error: 'Impossible de téléverser vos documents',
      },
      {
        style: {
          minWidth: '250px',
        },
      },
    );

    setFiles([]);
  };

  /**
   * Delete a document
   * @param document
   */
  const handleDeleteDocument = async (document: { id: any; name: any; }) => {
    const { error: deleteFileDbError } = await Supabase
      .from('documents')
      .delete()
      .eq(
        'id', document.id,
      );

    if (deleteFileDbError) {
      throw new Error(deleteFileDbError.message);
    }

    const { error: deleteStorageError } = await Supabase
      .storage
      .from('documents')
      .remove([`${session?.user?.id}/${document.name}`]);

    if (deleteStorageError) {
      throw new Error(deleteStorageError.message);
    }
  };

  /**
   * Download a document
   * @param documentToDownload
   */
  const handleDownloadDocument = async (documentToDownload: { id: any; name: any; }) => {
    const { data, error: fileDownloadError } = await Supabase
      .storage
      .from('documents')
      .download(`${session?.user?.id}/${documentToDownload.name}`);

    if (fileDownloadError) {
      throw new Error(fileDownloadError.message);
    }

    //   Data is a blob, let's download it
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      documentToDownload.name,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  /**
   * Generate a resume in pdf format
   */
  const generateResume = async () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    const docName = `${profile?.first_name}-${profile?.last_name}-cv.pdf`;
    doc.setFontSize(22);
    doc.text(
      `${profile?.first_name} ${profile?.last_name}`, 10, 10,
    );
    doc.setFontSize(12);
    doc.text(
      `${session?.user?.email}`, 10, 18,
    );
    doc.setFontSize(16);
    doc.text(
      `${profile?.type?.name || ''}`, 10, 30,
    );
    doc.setFontSize(12);
    const splittedBio = doc.splitTextToSize(
      `${profile?.bio || ''}`, 190,
    );
    doc.text(
      splittedBio, 10, 40,
    );

    const offset = 50 + (splittedBio.length * 5);

    doc.setFontSize(16);
    doc.text(
      'Adresse', 10, offset,
    );
    doc.setFontSize(12);
    doc.text(
      `${profile?.address?.first_line || ''}`, 10, offset + 5,
    );
    doc.text(
      `${profile?.address?.second_line || ''}`, 10, offset + 10,
    );
    doc.text(
      `${profile?.address?.zip} ${profile?.address?.city || ''}`, 10, offset + 15,
    );
    doc.text(
      'FRANCE', 10, offset + 20,
    );

    doc.setFontSize(16);
    doc.text(
      'Téléphone', 10, offset + 30,
    );
    doc.setFontSize(12);
    doc.text(
      `${profile?.phone}`, 10, offset + 35,
    );

    // birthday
    doc.setFontSize(16);
    doc.text(
      'Date de naissance', 10, offset + 50,
    );

    doc.setFontSize(12);
    doc.text(
      // @ts-ignore
      `${new Date(profile?.birth_date).toLocaleDateString('fr-FR')}`, 10, offset + 55,
    );

    doc.setFontSize(16);
    doc.text(
      'Expériences', 10, offset + 70,
    );
    doc.setFontSize(12);

    experiences?.map((
      experience, index,
    ) => {
      doc.text(
        `${experience.title} - ${experience.company} - ${new Date(experience.start_date).toLocaleDateString(
          'fr-FR', { year: 'numeric', month: 'long' },
        )}`, 10, offset + 80 + (index * 12),
      );
      doc.text(
        `${experience.description}`, 10, offset + 85 + (index * 10),
      );

      return experience;
    });

    const currentY = offset + 100
        // eslint-disable-next-line no-unsafe-optional-chaining
        + (experiences && experiences?.length > 0 ? experiences?.length * 10 : 0);

    doc.setFontSize(16);
    doc.text(
      'Formations', 10, currentY,
    );
    doc.setFontSize(12);
    educations?.map((
      education, index,
    ) => {
      doc.text(
        `${education.title} - ${education.school} - ${new Date(education.start_date).toLocaleDateString(
          'fr-FR', { year: 'numeric', month: 'long' },
        )}`, 10, currentY + 10 + (index * 10),
      );
      doc.text(
        `${education.description}`, 10, currentY + 20 + (index * 10),
      );

      return education;
    });
    doc.save(docName);
  };

  const requiredDocumentIds = [1, 2, 3, 4];

  if (!documents) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Skeleton />
      </div>
    );
  }

  const requiredDocuments = documents
    .filter((document) => requiredDocumentIds
      .includes(Number(document.type_id)));
  const missingDocuments = requiredDocuments?.length !== requiredDocumentIds.length;

  return (
    <>
      {showTitle && (
        <PageTitle title="Mes Informations" />
      )}

      <div className="px-5 w-full">
        <Button size="sm" onClick={generateResume}>
          Télécharger mon CV
        </Button>
        {/* Informations */}
        <div className="mb-5">
          <div className="bg-base-100 rounded-xl p-10">
            <section className="mb-5">
              <article>
                <Typography variant="h5" className="mb-5">
                  {profile?.first_name || "Vous n'avez pas renseigné votre prénom."}
                  {' '}
                  {profile?.last_name || "Vous n'avez pas renseigné votre nom."}
                </Typography>
              </article>

              <article>
                <Typography variant="h6" className="mb-5">
                  Votre note:
                  {' '}
                  {overallRating ? `${overallRating}/5` : "Vous n'avez pas encore de note."}
                </Typography>
              </article>

              <article>
                <p className="font-bold text-gray-500 text-sm">Téléphone</p>
                <Typography variant="paragraph" className="mb-5">
                  {profile?.phone ? (
                    <>
                      {profile?.phone}
                      <br />
                    </>
                  ) : (
                    <span>
                      Vous n&apos;avez pas renseigné votre numéro de téléphone.
                    </span>
                  )}
                </Typography>
              </article>

              <article>
                <p className="font-bold text-gray-500 text-sm">Email</p>
                <Typography variant="paragraph" className="mb-5">
                  {session?.user?.email || <Skeleton />}
                </Typography>
              </article>

              <article>
                <p className="font-bold text-gray-500 text-sm">Profession</p>
                <Typography variant="paragraph" className="mb-5">
                  {!profile?.type?.name && "Vous n'avez pas renseigné votre profession."}
                  {profile?.type?.name && (
                    <Chip value={profile?.type?.name} />
                  )}
                </Typography>
              </article>

              <article>
                <p className="font-bold text-gray-500 text-sm">Date de naissance</p>
                <Typography variant="paragraph" className="mb-5">
                  {!profile?.birth_date && "Vous n'avez pas renseigné votre date de naissance."}
                  {profile?.birth_date && (
                    <Chip value={
                          // @ts-ignore
                          new Date(profile?.birth_date).toLocaleDateString('fr-FR')
                        }
                    />
                  )}
                </Typography>
              </article>

              <article>
                <p className="font-bold text-gray-500 text-sm">
                  Nombre d&apos; heures de travail par semaine prévu dans votre contrat
                </p>
                <Typography variant="paragraph" className="mb-5 text-sm">
                  {!profile?.working_hours && "Vous n'avez pas renseigné vos heures de travail prévues."}
                  {profile?.working_hours && (
                    <Chip value={profile?.working_hours} />
                  )}
                </Typography>
              </article>

              <article>
                <p className="font-bold text-gray-500 text-sm">
                  Type d&apos;établissement dans lequel vous travaillez actuellement
                </p>
                <Typography variant="paragraph" className="mb-5">
                  {/* @ts-ignore */}
                  {!profile?.establishment_sector?.name && "Vous n'avez pas renseigné vos heures de travail prévues."}
                  {/* @ts-ignore */}
                  {profile?.establishment_sector?.name && (
                    // @ts-ignore
                    <Chip value={profile?.establishment_sector?.name} />
                  )}
                </Typography>
              </article>

              <article>
                <p className="font-bold text-gray-500 text-sm">
                  Biographie
                </p>
                <Typography variant="paragraph" className="mb-5">
                  {profile?.bio ? (
                    profile?.bio
                  ) : (
                    <span>
                      Vous n&apos;avez pas renseigné votre biographie.
                    </span>
                  )}
                </Typography>
              </article>
            </section>

            <Button
              fullWidth
              size="sm"
              className="bg-primary w-full lg:w-auto"
              onClick={(): void => setOpenInfo(true)}
            >
              Modifier mes informations
            </Button>
          </div>
        </div>

        {/* Address */}
        <div className="mb-5">
          <div className="flex w-full justify-between items-center">
            <Typography variant="h4">Mon Adresse</Typography>
          </div>
          <div className="bg-base-100 rounded-xl p-10">
            <section className="mb-5">
              <article>
                <Typography variant="paragraph" className="mb-5 font-semibold">
                  {profile?.address ? (
                    <>
                      {/* @ts-ignore */}
                      {profile?.address?.first_line || <Skeleton />}
                      <br />
                      {/* @ts-ignore */}
                      {profile?.address?.second_line && (
                      <>
                        {/* @ts-ignore */}
                        {profile?.address?.second_line}
                        <br />
                      </>
                      )}
                      {/* @ts-ignore */}
                      {profile?.address?.zip || <Skeleton />}
                      {' '}
                      {/* @ts-ignore */}
                      {profile?.address?.city || <Skeleton />}
                      <br />
                      FRANCE
                    </>
                  ) : (
                    <span>
                      Vous n&apos;avez pas renseigné votre adresse.
                    </span>
                  )}
                </Typography>
                <Button
                  fullWidth
                  size="sm"
                  className="bg-primary w-full lg:w-auto"
                  onClick={(): void => setOpenAddress(true)}
                >
                  Modifier mon adresse
                </Button>
              </article>
            </section>

          </div>
        </div>

        {/* Experiences */}
        <div className="mb-5">
          <div className="flex flex-col lg:flex-row w-full mb-5 justify-between items-center">
            <div className="flex w-full justify-between items-center">
              <Typography variant="h4">Mes Expériences</Typography>
            </div>
          </div>
          <div className="bg-base-100 rounded-xl p-10">
            <Button
              size="sm"
              className="bg-primary mb-5 flex gap-2 items-center justify-center w-full lg:w-auto"
              onClick={(): void => setOpenExperiences(true)}
            >
              Ajouter une expérience
              <PlusIcon className="w-5 h-5" />
            </Button>
            <section className="mb-5">
              <form onSubmit={(e) => handleExperienceYearsSubmit(e)}>
                <div className="flex flex-col lg:flex-row gap-2">
                  <Input
                    crossOrigin="anonymous"
                    type="number"
                    label="Années d'expérience"
                    value={newExperienceYears}
                    onChange={(e): void => {
                      setNewExperienceYears(Number(e.target.value));
                    }}
                    className="w-full"
                  />
                  <Button
                    className="bg-primary"
                    type="submit"
                  >
                    Sauvegarder
                  </Button>
                </div>
              </form>
            </section>
            <section className="mb-5">
              {experiences && experiences?.length > 0 ? (
                experiences?.map((experience) => (
                  <article key={experience.id} className="border p-5 rounded-lg mb-5">
                    <Typography variant="h5">
                      {experience.title}
                    </Typography>
                    <Typography variant="paragraph" className="font-medium text-sm">
                      {experience.company}
                    </Typography>
                    <Typography variant="paragraph">
                      {experience.description}
                    </Typography>
                    <Typography variant="paragraph" className="text-sm">
                      De
                      {' '}
                      {new Date(experience.start_date).toLocaleDateString(
                        'fr-FR', { year: 'numeric', month: 'long' },
                      )}
                      {' '}
                      {experience.end_date ? (
                        <>
                          à
                          {' '}
                          {new Date(experience.end_date).toLocaleDateString(
                            'fr-FR', { year: 'numeric', month: 'long' },
                          )}
                        </>
                      ) : (
                        <span>jusqu&apos;à présent</span>
                      )}
                    </Typography>
                    <Button
                      size="sm"
                      className="bg-primary mt-5"
                      onClick={() => {
                        setExperienceToUpdate(experience);
                        setOpenUpdateExperiences(true);
                      }}
                    >
                      Modifier
                    </Button>
                    <Button
                      size="sm"
                      className="bg-base-100 text-text border shadow-none mt-5 ml-5"
                      onClick={async () => deleteExperience(experience.id).then(async () => {
                        await mutateExperiences();
                      })}
                    >
                      Supprimer
                    </Button>
                  </article>
                ))
              ) : (
                <span>
                  Vous n&apos;avez pas renseigné d&apos;expérience.
                </span>
              )}
            </section>

          </div>
        </div>

        {/* Education */}
        <div className="mb-5">
          <div className="flex w-full justify-between items-center">
            <Typography variant="h4">Mes Études</Typography>
          </div>
          <div className="bg-base-100 rounded-xl p-10">
            <Button
              size="sm"
              className="bg-primary flex gap-2 items-center w-full lg:w-auto mb-5 justify-center"
              onClick={(): void => setOpenEducations(true)}
            >
              Ajouter une formation
              <PlusIcon className="w-5 h-5" />
            </Button>
            <section className="mb-5">
              {educations && educations?.length > 0 ? (
                // @ts-ignore
                educations?.map((education) => (
                  <article key={education.id} className="border p-5 rounded-lg mb-5">
                    <Typography variant="h5">
                      {education.title}
                    </Typography>
                    <Typography variant="paragraph" className="font-medium text-sm">
                      {education.school}
                    </Typography>
                    <Typography variant="paragraph">
                      {education.description}
                    </Typography>
                    <Typography variant="paragraph" className="text-sm">
                      De
                      {' '}
                      {new Date(education.start_date).toLocaleDateString(
                        'fr-FR', { year: 'numeric', month: 'long' },
                      )}
                      {' '}
                      {education.end_date ? (
                        <>
                          à
                          {' '}
                          {new Date(education.end_date).toLocaleDateString(
                            'fr-FR', { year: 'numeric', month: 'long' },
                          )}
                        </>
                      ) : (
                        <span>jusqu&apos;à présent</span>
                      )}
                    </Typography>
                    <Button
                      size="sm"
                      className="bg-primary mt-5"
                      onClick={() => {
                        setEducationToUpdate(education);
                        setOpenUpdateEducations(true);
                      }}
                    >
                      Modifier
                    </Button>
                    <Button
                      size="sm"
                      className="bg-base-100 text-text border shadow-none mt-5 ml-5"
                      onClick={() => toast.promise(
                        deleteEducation(education.id), {
                          loading: 'Suppression de votre formation...',
                          success: 'Votre formation a été supprimée !',
                          error: 'Impossible de supprimer votre formation',
                        },
                      ).then(async () => {
                        await mutateEducations();
                      })}
                    >
                      Supprimer
                    </Button>
                  </article>
                ))
              ) : (
                <span>
                  Vous n&apos;avez pas renseigné de formation.
                </span>
              )}
            </section>

          </div>
        </div>
      </div>

      {/* Justification */}
      <div className="mb-5 px-5">
        <Typography variant="h4">Documents Justificatifs</Typography>
      </div>
      <p className="text-sm px-5">
        Documents obligatoires:
        <ul className="list-disc list-inside ml-12">
          <li>
            Carte d&apos;identité
            {requiredDocuments?.some((document) => Number(document.type_id) === 1) && (
              <CheckIcon className="w-4 h-4 text-success inline" />
            )}
          </li>
          <li>
            Carte vitale
            {requiredDocuments?.some((document) => Number(document.type_id) === 2) && (
            <CheckIcon className="w-4 h-4 text-success inline" />
            )}
          </li>
          <li>
            CV
            {requiredDocuments?.some((document) => Number(document.type_id) === 3) && (
            <CheckIcon className="w-4 h-4 text-success inline" />
            )}
          </li>
          <li>
            Justificatif de diplôme lié à votre profession spécifique.
            {requiredDocuments?.some((document) => Number(document.type_id) === 4) && (
            <CheckIcon className="w-4 h-4 text-success inline" />
            )}
          </li>
        </ul>
      </p>
      <br />
      <p className="text-sm px-5">
        Documents optionnels:
        <ul className="list-disc list-inside ml-12">
          <li>
            Extrait de casier judiciaire
            {requiredDocuments?.some((document) => Number(document.type_id) === 5) && (
            <CheckIcon className="w-4 h-4 text-success inline" />
            )}
          </li>
          <li>
            RIB
            {requiredDocuments?.some((document) => Number(document.type_id) === 6) && (
            <CheckIcon className="w-4 h-4 text-success inline" />
            )}
          </li>
          <li>
            Inscription à l&apos;ordre pour IDE
            {requiredDocuments?.some((document) => Number(document.type_id) === 7) && (
            <CheckIcon className="w-4 h-4 text-success inline" />
            )}
          </li>
          <li>
            Copier de bulletin de vaccination
            {requiredDocuments?.some((document) => Number(document.type_id) === 8) && (
            <CheckIcon className="w-4 h-4 text-success inline" />
            )}
          </li>
        </ul>
      </p>

      {/* List documents */}
      <div className="bg-base-100 rounded-xl p-10 mb-5">
        <section className="mb-5">
          {documents && documents?.length > 0 ? (
            documents?.map((document) => (
              <article
                key={document.id}
                className="border p-5 rounded-lg mb-5 flex flex-col lg:flex-row gap-2 items-center justify-between"
              >
                <Typography variant="h5" className="text-sm">
                  {document.name}
                </Typography>
                <p className="text-xs">
                  Type de document:
                  {' '}
                  {document.type}
                </p>
                <div className="flex gap-2">
                  <Button
                    size="sm"
                    className="bg-base-100 text-text border shadow-none"
                    onClick={() => toast.promise(
                      handleDeleteDocument(document), {
                        loading: 'Suppression de votre document...',
                        success: 'Votre document a été supprimé !',
                        error: 'Impossible de supprimer votre document',
                      },
                    ).then(async () => {
                      await mutateDocuments();
                    })}
                  >
                    Supprimer
                  </Button>
                  {/*  Download button */}
                  <Button
                    size="sm"
                    className="bg-primary text-white shadow-none"
                    onClick={async () => handleDownloadDocument(document)}
                  >
                    Télécharger
                  </Button>
                </div>
              </article>
            ))
          ) : (
            <span>
              Vous n&apos;avez pas de documents.
            </span>
          )}
        </section>
      </div>

      {/* Missing documents */}
      {missingDocuments && (
        <div className="bg-base-100 rounded-xl p-10 mb-5">
          <section className="mb-5">
            <article
              className="border p-5 rounded-lg mb-5 flex items-center justify-between"
            >
              <Typography variant="h5" className="text-sm ">
                Les documents suivants sont manquants:
                <ul className="list-disc list-inside ml-12 text-error">
                  {!requiredDocuments?.some((document) => Number(document.type_id) === 1) && (
                    <li>
                      Carte d&apos;identité
                    </li>
                  )}
                  {!requiredDocuments?.some((document) => Number(document.type_id) === 2) && (
                    <li>
                      Carte vitale
                    </li>
                  )}
                  {!requiredDocuments?.some((document) => Number(document.type_id) === 3) && (
                    <li>
                      CV
                    </li>
                  )}
                  {!requiredDocuments?.some((document) => Number(document.type_id) === 4) && (
                    <li>
                      Diplôme
                    </li>
                  )}
                </ul>
              </Typography>
            </article>
          </section>
        </div>
      )}

      {missingDocuments && (
      <p className="text-xs px-10">
        Des documents sont manquants à votre profil, il est donc incomplet.
        Veuillez les téléverser pour que votre profil soit complet et débloquer
        les fonctionnalités de candidature.
      </p>
      )}

      <Dropzone
        files={files}
        setFiles={setFiles}
        error={error}
        setError={setError}
        formats="png,pdf,jpg,heic,heif,webp,doc,docx,txt,svg,bmp"
        fileTypes={fileTypes?.map((type) => ({
          value: type.id,
          label: `${type.name}`,
        }))}
      />

      {files.length > 0 && (
        <div className="px-5">
          <Button
            size="sm"
            className="bg-primary flex gap-2 items-center w-full lg:w-auto mb-5 justify-center"
            onClick={async () => handleUploadFiles()}
          >
            Téléverser ces documents
          </Button>
        </div>
      )}

      {/* Edit General Info */}
      <Popover
        open={openInfo}
        setOpen={setOpenInfo}
        title="Informations"
      >
        {profile && (
          <UpdateInfo
            profile={profile}
            mutate={mutate}
            setOpenInfo={setOpenInfo}
          />
        )}
      </Popover>

      {/* Edit address */}
      <Popover
        open={openAddress}
        setOpen={setOpenAddress}
        title="Informations"
      >
        {profile && (
          <UpdateAddress
            profile={profile}
            mutate={mutate}
            setOpenInfo={setOpenAddress}
          />
        )}
      </Popover>

      {/* Add Experience */}
      <Popover
        open={openExperiences}
        setOpen={setOpenExperiences}
        title="Ajouter une expérience"
      >
        <CreateExperience
          setOpenExperiences={setOpenExperiences}
          mutate={mutateExperiences}
        />
      </Popover>

      {/* Add Education */}
      <Popover
        open={openEducations}
        setOpen={setOpenEducations}
        title="Ajouter une formation"
      >
        <CreateEducation
          mutate={mutateEducations}
          setOpen={setOpenEducations}
        />
      </Popover>

      {/* Update Education */}
      <Popover
        open={openUpdateEducations}
        setOpen={setOpenUpdateEducations}
        title="Modifier une Formation"
      >
        <UpdateEducation
          setOpen={setOpenUpdateEducations}
          education={educationToUpdate}
          mutate={mutateEducations}
        />
      </Popover>

      {/* Update Experience */}
      <Popover
        open={openUpdateExperiences}
        setOpen={setOpenUpdateExperiences}
        title="Modifier une Expérience"
      >
        <UpdateExperience
          setOpen={setOpenUpdateExperiences}
          education={experienceToUpdate}
          mutate={mutateExperiences}
        />
      </Popover>
    </>
  );
}

ProfileCV.defaultProps = {
  showTitle: true,
};
