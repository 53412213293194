import Supabase from '@lib/Supabase';
import useSWR from 'swr';
import { getAuth } from '@/context/Auth';

const tableName = 'documents';
const selectQuery = `
  id,
  user_id,
  name,
  type_id,
  document_type:type_id (
    id,
    name
  )
`;

type FileType = {
    id: string;
    user_id: string;
    name: string;
    type_id: string;
    type: string;
    document_type: {
        id: number;
        name: string;
    }
}

/**
 * Get all conversations
 */
export const getFiles = async (): Promise<FileType[]> => {
  const session = await getAuth();
  const userID = session?.data?.session?.user?.id;

  const { data, error } = await Supabase
    .from(tableName)
    .select(selectQuery)
    .eq(
      'user_id', userID,
    );

  if (error) throw new Error(error.message);

  // @ts-ignore
  data?.map((file: FileType) => {
    // eslint-disable-next-line no-param-reassign
    file.type = file.document_type.name;
    return file;
  });

  const { error: storageError } = await Supabase
    .storage
    .from('documents')
    .list(`documents/${userID}`);

  if (storageError) throw new Error(storageError.message);

  return data as unknown as FileType[];
};

/**
 * Get real-time conversations
 */
export const useFiles = () => useSWR(
  'all-files', getFiles,
);
