import React from 'react';
import ErrorCard from '@components/ErrorCard';
import PageTitle from '@components/app/PageTitle';
import { Button, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { useOffersFromFavoriteEstablishments } from '@lib/Offer';
import FullPageLoader from '@components/FullPageLoader';
import JobCard from '@components/app/JobCard';

export default function FavoritesPage(): React.ReactElement {
  const { data: missions, error: favoritesError } = useOffersFromFavoriteEstablishments();

  if (favoritesError) {
    return (
      <ErrorCard message="Impossible de charger vos favoris" />
    );
  }

  if (!missions) {
    return <FullPageLoader />;
  }

  return (
    <>
      <PageTitle title="Mes Favoris" />

      <div className="px-5">
        {missions && missions.length > 0 && (
          <Typography className="text-2xl font-bold mb-5">
            Liste des offres de vos établissements favoris
          </Typography>
        )}

        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {missions && missions.length > 0 && missions.map((mission) => (
            <JobCard
              key={
                              // @ts-ignore
                              mission.id
                          }
                          // @ts-ignore
              offer={mission}
            />
          ))}
        </div>

        {missions && missions.length === 0 && (
          <>
            <Typography className="text-2xl font-bold mb-5">
              Il n&apos;y a pas d&apos;offres de vos établissements favoris
            </Typography>

            <Link to="/" className="w-full">
              <Button className="bg-primary w-full">
                Trouver une mission
              </Button>
            </Link>
          </>
        )}
      </div>

    </>

  );
}
