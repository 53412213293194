import PageTitle from '@/components/app/PageTitle';
import Container from '@components/Container';
import NotificationsPanel from '@components/app/NotificationsPanel';
import React from 'react';

/**
 *
 */
export default function NotificationsPage(): React.ReactElement {
  return (
    <>
      <PageTitle title="Notifications" />
      <Container>
        <NotificationsPanel className="mx-auto" />
      </Container>
    </>
  );
}
