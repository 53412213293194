import Supabase from '@lib/Supabase';
import useSWR from 'swr';
import { getAdmins } from '@lib/Admins';
import { createNotification } from '@lib/Notification';

const tableName = 'signalment';
const selectQuery = `
  id,
  candidate_id,
  establishment_id,
  establishment:establishment_id (
    name
  ),
  message
`;

/**
 * Get all conversations
 */
export const getSignalments = async () => {
  const { data, error } = await Supabase
    .from(tableName)
    .select(selectQuery);

  if (error) throw new Error(error.message);

  const { data: userProfile, error: userProfileError } = await Supabase
    .from('profile')
    .select('first_name,last_name,user_id');

  if (userProfileError) throw new Error(userProfileError.message);

  const { data: establishmentManager, error: establishmentManagerError } = await Supabase
    .from('establishments_managers')
    .select('manager_id');

  if (establishmentManagerError) throw new Error(establishmentManagerError.message);

  const {
    data: establishmentManagerProfile,
    error: establishmentManagerProfileError,
  } = await Supabase
    .from('profile')
    .select('first_name,last_name,id');

  if (establishmentManagerProfileError) throw new Error(establishmentManagerProfileError.message);

  // eslint-disable-next-line no-restricted-syntax
  for (const signalment of data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const user of userProfile) {
      if (signalment.candidate_id === user.user_id) {
        // @ts-ignore
        signalment.candidate = user;
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const establishment of establishmentManager) {
      // @ts-ignore
      if (signalment.establishment_id === establishment.id) {
        // @ts-ignore
        signalment.establishment = establishment;
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const manager of establishmentManagerProfile) {
      // @ts-ignore
      if (signalment.establishment.manager_id === manager.id) {
        // @ts-ignore
        signalment.establishment.manager = manager;
      }
    }
  }

  return data;
};

/**
 * Get real-time conversations
 */
export const useSignalments = () => useSWR(
  'all-signalments', getSignalments,
);

export const insertSignalment = async (data: {
    candidate_id: string;
    establishment_id: string;
    message: string;
}) => {
  const { error } = await Supabase
    .from(tableName)
    .insert(data)
    .select(selectQuery);

  if (error) throw new Error(error.message);

  const { data: userProfile, error: userProfileError } = await Supabase
    .from('profile')
    .select('first_name,last_name')
    .eq(
      'user_id', data.candidate_id,
    )
    .single();

  if (userProfileError) throw new Error(userProfileError.message);

  const { data: establishmentManager, error: establishmentManagerError } = await Supabase
    .from('establishment')
    .select('manager_id')
    .eq(
      'id', data.establishment_id,
    )
    .single();

  if (establishmentManagerError) throw new Error(establishmentManagerError.message);

  const {
    data: establishmentManagerProfile,
    error: establishmentManagerProfileError,
  } = await Supabase
    .from('profile')
    .select('first_name,last_name')
    .eq(
      'id', establishmentManager.manager_id,
    )
    .single();

  if (establishmentManagerProfileError) throw new Error(establishmentManagerProfileError.message);

  const { data: establishment, error: establishmentError } = await Supabase
    .from('establishment')
    .select('name')
    .eq(
      'id', data.establishment_id,
    )
    .single();

  if (establishmentError) throw new Error(establishmentError.message);

  const message = `L'utilisateur ${establishmentManagerProfile.first_name} ${establishmentManagerProfile.last_name} gérant l'établissement ${establishment.name} a signalé le candidat ${userProfile.first_name} ${userProfile.last_name} pour la raison suivante : ${data.message}`;

  const admins = await getAdmins();

  // eslint-disable-next-line no-restricted-syntax
  for (const admin of admins) {
    // eslint-disable-next-line no-await-in-loop
    await createNotification({
      level: '2',
      title: 'Nouveau signalement',
      content: message,
      user_id: admin.id,
      type: 'Message',
      read: false,
    }).then(() => null);
  }

  return data;
};
