import React from 'react';
import ChatList from '@components/ChatList';
import PageTitle from '@components/app/PageTitle';

export default function EstablishmentChatPage(): React.ReactElement {
  return (
    <>
      <PageTitle title="Chat" />
      <ChatList />
    </>
  );
}
