import React from 'react';
import { useConversations } from '@lib/Conversation';
import ErrorCard from '@components/ErrorCard';
import FullPageLoader from '@components/FullPageLoader';
import { Link } from 'react-router-dom';
import { Button } from '@material-tailwind/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import useProfile from '@lib/Profile';

export default function ChatList(): React.ReactElement {
  const {
    data: conversations,
    error: conversationsError,
    isLoading,
  } = useConversations();

  const { data: profile, error: profileError } = useProfile();

  if (profileError) {
    return <ErrorCard message={profileError.message} />;
  }

  if (conversationsError) {
    return <ErrorCard message={conversationsError.message} />;
  }

  if (!conversations || isLoading || !profile) {
    return <FullPageLoader />;
  }

  conversations.map((conversation) => {
    // @ts-ignore
    if (conversation?.receiver?.id === profile?.id) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      conversation.receiver = conversation.sender;
    }

    return conversation;
  });

  return (
    <>
      <div className="px-5 mb-5">
        <h1>
          {conversations && conversations.length > 0 ? (
            'Liste des discussions'
          ) : (
            'Il n\'y a aucune discussion ouverte pour le moment'
          )}
        </h1>
      </div>
      <div className="px-5 w-full">
        <ul className="w-full">
          {conversations && conversations.length > 0 && conversations.map((conversation) => (
            <li key={conversation.id} className="w-full mb-3">
              <Link to={`/chat/${conversation.id}`}>
                <Button className="w-full bg-base-100 text-sm border text-text flex gap-2 items-center justify-between">
                  <div className="text-start text-sm">
                    {/* @ts-ignore */}
                    Établissement
                    {' '}
                    {' '}
                    {conversation && conversation.establishment?.name}
                    <br />
                    <span className="text-xs text-gray-400">
                      Offre
                      {' "'}
                      {/* @ts-ignore */}
                      {conversation && conversation?.offer?.title}
                      &quot;
                    </span>
                  </div>
                  <ChevronRightIcon className="h-6 md:h-5 w-6 md:w-5" strokeWidth={3} />
                </Button>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
