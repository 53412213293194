import BtnSpinner from '@components/BtnSpinner';
import Loader from '@components/Loader';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import {
  Button, Dialog, DialogBody, DialogFooter, DialogHeader, Input, Typography,
} from '@material-tailwind/react';
import toast from 'react-hot-toast';
import { useAuth } from '@/context/Auth';

/**
 * Description.
 * @returns
 *
 */
export default function LoginForm(): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    email: '',
  });

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [globalError, setGlobalError] = useState(false);
  const [globalErrorMessage, setGlobalErrorMessage] = useState('');

  const [globalLoading, setGlobalLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const callbackUrl = searchParams.get('callbackUrl');
  const urlError = searchParams.get('error');
  const navigate = useNavigate();
  const { session, login } = useAuth();

  if (session) {
    navigate('/');
  }

  React.useEffect(
    () => {
      if (urlError) {
        switch (urlError) {
          case 'OAuthAccountNotLinked':
            setGlobalError(true);
            setGlobalErrorMessage('This account is not linked to any email, please sign in with email and link your account.');
            break;
          default:
            setGlobalError(true);
            setGlobalErrorMessage('Internal server error, please try again later.');
            break;
        }
      }
    }, [callbackUrl, urlError],
  );

  /**
   * Description.
   * @param e:React.FormEvent
   * @param e
   * @returns
   *
   */
  const emailSignIn = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    try {
      setLoading(true);
      setError(false);
      setGlobalError(false);
      setGlobalErrorMessage('');
      setErrorMessage('');

      if (formValues.email === '' || !formValues.email) {
        setErrorMessage('Please enter a valid email.');
        setError(true);
        setLoading(false);
        return;
      }

      const urlFormattedEmail = encodeURIComponent(formValues.email);

      const { error: authError } = await login(formValues.email);

      if (authError) {
        if (authError.status === 429) {
          setErrorMessage('Trop de tentatives, veuillez réessayer plus tard.');
          setError(true);
          setLoading(false);
          return;
        }

        setErrorMessage(authError.message);
        setError(true);
        setLoading(false);
        return;
      }

      toast.success('Un email vous a été envoyé.');
      setLoading(false);
      navigate(`/auth/verify?sent=true&email=${urlFormattedEmail}`);
    } catch (signInError: unknown) {
      setLoading(false);
      setGlobalLoading(false);
      setError(true);
      setErrorMessage('Invalid credentials');
    }
  };

  /**
   * Description.
   * @param event:ChangeEvent<HTMLInputElement>
   * @param event
   * @returns
   *
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return globalLoading ? (
    <>
      <Loader />
      <div className="mb-5" />
    </>
  ) : (
    <>
      <Dialog open={globalError} handler={setGlobalError}>
        <DialogHeader>
          Une erreur est survenue
        </DialogHeader>
        <DialogBody>
          {globalErrorMessage}
        </DialogBody>
        <DialogFooter>
          <Button
            variant="gradient"
            color="red"
            onClick={
            (): void => {
              setGlobalError(false);
              setTimeout(
                () => {
                  setGlobalErrorMessage('');
                }, 500,
              );
            }
          }
          >
            <span>OK</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <section className={classNames('mb-5 w-full')}>

        <Dialog open={error} handler={setGlobalError}>
          <DialogHeader>
            <Typography variant="h6">
              Une erreur est survenue
            </Typography>
          </DialogHeader>
          <DialogBody>
            <Typography variant="paragraph">
              {errorMessage}
            </Typography>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="gradient"
              color="red"
              onClick={
            (): void => {
              setError(false);
              setTimeout(
                () => {
                  setGlobalErrorMessage('');
                }, 500,
              );
            }
          }
            >
              <span>OK</span>
            </Button>
          </DialogFooter>
        </Dialog>
        <form onSubmit={emailSignIn} className="w-full">
          <fieldset disabled={loading}>
            <div className="mb-6">
              <div className="mb-3">
                <Input
                  crossOrigin={null}
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={formValues?.email}
                  onChange={handleChange}
                  required
                  label="Adresse Email"
                />
              </div>
            </div>
            <Button
              type="submit"
              className="bg-primary flex items-center w-full justify-center gap-2"
              disabled={loading}
            >
              {loading && <BtnSpinner />}
              {loading ? 'Vérification' : 'Se Connecter'}
              <ArrowRightOnRectangleIcon className="w-5 h-5" />
            </Button>
          </fieldset>
        </form>
      </section>

    </>
  );
}
