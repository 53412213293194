import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ErrorCard from '@/components/ErrorCard';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { Typography } from '@material-tailwind/react';
import TitleContext from '@/context/Title';
import { useProfile } from '@/lib/Profile';
import { FullProfile } from '@/custom-types/Profile';
import { Link } from 'react-router-dom';
import { BriefcaseIcon, BuildingOfficeIcon, ChatBubbleLeftIcon } from '@heroicons/react/24/outline';

export default function EstablishmentDashboard(): React.ReactElement {
  let greeting = 'Bonjour';
  const hour = new Date().getHours();
  const { setTitle } = React.useContext(TitleContext);

  if (hour >= 12 && hour < 18) {
    greeting = 'Bon après-midi';
  } else if (hour >= 18) {
    greeting = 'Bonsoir';
  }

  // @ts-ignore
  const { data: profile, error }: {
        profile: FullProfile,
        error: Error
    } = useProfile();

  if (error) {
    return (
      <ErrorCard message="Impossible de charger votre profil" />
    );
  }

  React.useEffect(() => {
    setTitle('Tableau de Bord');
  });

  return (
    <div className="mb-5">
      <Typography variant="h4" className="font-normal">
        {profile?.first_name
          ? (
            <>
              <span>{greeting}</span>
              ,
              {' '}
              <strong>
                {profile?.first_name}
                .
              </strong>
            </>
          )
          : (
            <Skeleton />
          )}
      </Typography>
      <p className="mb-5 font-normal text-sm mt-0 text-gray-600">
        Nous sommes le
        {' '}
        {new Date().toLocaleDateString(
          'fr-FR',
          {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          },
        )}
        .
      </p>

      <section>
        <h3 className="mb-2">Vous souhaitez: </h3>

        <Link to="/establishment" title="Mon Etablissement" className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5">
          <div className="flex items-center gap-3">
            <BuildingOfficeIcon className="w-5 h-5" />
            Gérer mon établissement
          </div>
          <ArrowRightIcon className="w-5 h-5" />
        </Link>

        <Link
          to="/offers"
          title="Mes Offres"
          className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5"
        >
          <div className="flex items-center gap-3">
            <BriefcaseIcon className="w-5 h-5" />
            Gérer mes offres
          </div>
          <ArrowRightIcon className="w-5 h-5" />
        </Link>

        <Link
          to="/chat"
          title="Mes Conversations"
          className="bg-base-100 rounded-lg p-5 border flex items-center justify-between mb-5"
        >
          <div className="flex items-center gap-3">
            <ChatBubbleLeftIcon className="w-5 h-5" />
            Gérer mes conversations
          </div>
          <ArrowRightIcon className="w-5 h-5" />
        </Link>

      </section>
    </div>
  );
}
