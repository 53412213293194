import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  EyeIcon,
  UsersIcon,
  PencilIcon,
  DocumentDuplicateIcon,
  ArchiveBoxArrowDownIcon, ArchiveBoxXMarkIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import {
  CardHeader,
  Input,
  Typography,
  CardBody,
  IconButton,
  Tooltip,
  Button,
} from '@material-tailwind/react';
import toast from 'react-hot-toast';
import FullPageLoader from '@/components/FullPageLoader';
import ConfirmationModal from '@/components/ConfirmationModal';
import ErrorCard from '@/components/ErrorCard';
import { Offer } from '@/custom-types/Offer';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useUsers } from '@/lib/Users';
import {
  archiveOffer,
  duplicateOffer, unarchiveOffer,
} from '@lib/Offer';
import { useNavigate } from 'react-router-dom';
import { useEstablishment, useEstablishmentOffers } from '@lib/Establishment';
import Chip from '@components/Chip';
import OfferPopovers from '@components/OfferPopovers';
import InfoCard from '@components/InfoCard';

const TABLE_HEAD = ['ID', 'Titre', 'Type', 'Candidats', 'Statut', 'Date de Début', 'Date de fin', 'Actions'];
export default function EstablishmentOffersPage() {
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  // @ts-ignore
  const [offerToDelete, setOfferToDelete]: [
        null,
        React.Dispatch<React.SetStateAction<number | null>>
    ] = React.useState(null);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredOffers, setFilteredOffers] = React.useState(null);
  const [addOfferPopoverOpen, setAddOfferPopoverOpen] = React.useState(false);
  const [modifyOfferPopoverOpen, setModifyOfferPopoverOpen] = React.useState(false);

  const [formValues, setFormValues] = React.useState({
    id: 0,
    title: '',
    description: '',
    start_date: '',
    end_date: '',
    is_urgent: false,
    start_time: '8h00',
    contract_type_id: null,
    experience_required: '',
    conditions: '',
    profile_wished: '',
    missions: '',
    is_published: false,
    establishment_id: null,
    target: '',
  });

  const { data: offers, error, mutate } = useEstablishmentOffers();
  const { data: users, error: usersError } = useUsers();
  const navigate = useNavigate();
  const { data: establishment, error: establishmentError } = useEstablishment();

  React.useEffect(
    () => {
      if (establishment) {
        setFormValues({
          ...formValues,
          // @ts-ignore
          establishment_id: establishment?.id,
        });
      }

      if (offers) {
        // @ts-ignore
        setFilteredOffers(offers);
      }
    }, [establishment, offers],
  );

  if (establishmentError) {
    return <ErrorCard message={establishmentError.message} />;
  }

  if (error) {
    return <ErrorCard message={error.message} />;
  }

  if (usersError) {
    return <ErrorCard message={usersError.message} />;
  }

  if (!establishment) {
    return <InfoCard title="Aucun établissement trouvé" message="Vous devez être rattaché à un établissement pour accéder à cette page." />;
  }

  if (!offers || !users) {
    return <FullPageLoader />;
  }

  // Add a custom ID to each offer because the UUID is not offer-friendly
  let newID = 1;
  offers.forEach((offer) => {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    offer.customID = newID;
    newID += 1;
  });

  const offerUnarchiveCallback = async (id: number) => {
    toast.promise(
      // @ts-ignore
      unarchiveOffer(id),
      {
        loading: "Désarchivage de l'offre...",
        success: 'Offre désarchivée !',
        error: "Une erreur est survenue lors du désarchivage de l'offre",
      },
    )
      .then(() => {
        mutate();
      });
  };

  const handleModifyClick = (modifyOfferID: number) => {
    // eslint-disable-next-line no-shadow
    const offer = offers.find((offer) => offer.id === modifyOfferID);

    if (!offer) {
      return;
    }

    setFormValues({
      id: offer.id,
      title: offer.title,
      description: offer.description,
      start_date: offer.start_date,
      end_date: offer.end_date,
      is_urgent: offer.is_urgent,
      start_time: offer.start_time,
      contract_type_id: offer.contract_type_id,
      experience_required: offer.experience_required,
      conditions: offer.conditions,
      profile_wished: offer.profile_wished,
      missions: offer.missions,
      is_published: offer.is_published,
      // @ts-ignore
      establishment_id: establishment?.id,
      target: offer.target,
    });

    setModifyOfferPopoverOpen(true);
  };

  /**
     * Triggered when the offer clicks on the delete button in the table
     */
  const handleDeleteClick = (offerToDeleteID: number) => {
    setOfferToDelete(offerToDeleteID);
    setConfirmationModalOpen(true);
  };

  /**
     * Triggered when the offer conOffer the deletion of the offer via the modal
     */
  const offerDeleteCallback = async () => {
    toast
      .promise(
        // @ts-ignore
        archiveOffer(offerToDelete),
        {
          loading: "Suppression de l'offre...",
          success: 'Offre supprimée !',
          error: "Une erreur est survenue lors de la suppression de l'offre",
        },
      )
      .then(() => {
        mutate();
      });
  };

  const searchOffer = (searchTerm: string) => {
    if (!searchTerm) {
      // @ts-ignore
      setFilteredOffers(offers);
      return;
    }

    const matchedOffers = offers
    // @ts-ignore
      .filter((offer: Offer) => offer
        .title.toLowerCase().includes(searchTerm.toLowerCase()));

    // @ts-ignore
    setFilteredOffers(matchedOffers);
  };

  const handleDuplicateClick = (offerID: number) => toast.promise(
    duplicateOffer(offerID),
    {
      loading: "Duplication de l'offre...",
      success: 'Offre dupliquée !',
      error: "Une erreur est survenue lors de la duplication de l'offre",
    },
  ).then(() => mutate());

  users?.map((user) => {
    // @ts-ignore
    if (user.profile !== undefined) {
      return user;
    }

    // has no profile, not eligible, remove from list
    users.splice(
      // @ts-ignore
      user, 1,
    );

    return null;
  });

  // Put archived missions at the bottom
  offers.sort((
    a, b,
  ) => {
    if (a.archived && !b.archived) {
      return 1;
    }

    if (!a.archived && b.archived) {
      return -1;
    }

    return 0;
  });

  // @ts-ignore
  return (
    <>
      <OfferPopovers
        addOfferPopoverOpen={addOfferPopoverOpen}
        setAddOfferPopoverOpen={setAddOfferPopoverOpen}
        modifyOfferPopoverOpen={modifyOfferPopoverOpen}
        setModifyOfferPopoverOpen={setModifyOfferPopoverOpen}
        mutate={mutate}
        // @ts-ignore
        formValues={formValues}
        // @ts-ignore
        setFormValues={setFormValues}
      />
      {/* Offer deletion confirmation modal */}
      <div className="h-full w-full">
        <ConfirmationModal
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          title="Archiver l'offre"
          description="Cette action dépubliera l'offre et l'archivera."
          confirmText="Archiver"
          cancelText="Annuler"
          callBack={() => offerDeleteCallback()}
        />

        {/* Header w/ Search */}
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" color="blue-gray">
                Gestion des offres
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                Visualisez les informations de toutes les missions disponibles.
              </Typography>
            </div>
            <div>
              <Button
                size="sm"
                className="bg-primary flex items-center justify-center gap-2"
                onClick={() => setAddOfferPopoverOpen(true)}
              >
                Publier une offre
                <PlusIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <div className="w-full md:w-72">
              <Input
                type="search"
                crossOrigin="true"
                label="Rechercher"
                onChange={(e) => searchOffer(e.target.value)}
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </CardHeader>

        {/* Table */}
        <CardBody className="overflow-scroll px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((
                  head, index,
                ) => (
                  <th
                    key={head}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {head}
                      {' '}
                      {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon
                        strokeWidth={2}
                        className="h-4 w-4"
                      />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {/* @ts-ignore */}
              {filteredOffers?.map((
                {
                  // @ts-ignore
                  id,
                  // @ts-ignore
                  customID,
                  // @ts-ignore
                  // eslint-disable-next-line camelcase
                  title,
                  // @ts-ignore
                  // eslint-disable-next-line camelcase
                  is_published,
                  // @ts-ignore
                  candidates,
                  // @ts-ignore
                  archived,
                  // @ts-ignore
                  // eslint-disable-next-line camelcase
                  contract_type,
                  // @ts-ignore
                  // eslint-disable-next-line camelcase
                  start_date,
                  // @ts-ignore
                  // eslint-disable-next-line camelcase
                  end_date,
                },
                // @ts-ignore
                index,
              ) => {
              // eslint-disable-next-line no-unsafe-optional-chaining
                const isLast = index === offers?.length - 1;
                const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50';

                return (
                  <tr
                    key={id}
                    className={archived && 'text-gray-400'}
                  >
                    {/* ID */}
                    <td className={classes}>
                      <p className="text-xs">
                        {customID}
                      </p>
                    </td>

                    {/* Name */}
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium"
                      >
                        {title}
                      </Typography>
                    </td>

                    {/* Type */}
                    <td className={classes}>
                      <Typography
                        variant="small"
                        className="font-medium"
                      >
                        {
                          // @ts-ignore
                          // eslint-disable-next-line camelcase
                            end_date && contract_type?.name && contract_type.name
                        }
                        {
                          // @ts-ignore
                          // eslint-disable-next-line camelcase
                            end_date && !contract_type?.name && 'Mission Longue'
                        }
                        {
                          // @ts-ignore
                          // eslint-disable-next-line camelcase
                            !end_date && 'Mission Courte'
                        }
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium"
                      >
                        {candidates?.length}
                      </Typography>
                    </td>

                    <td className={classes}>
                      {/* eslint-disable-next-line camelcase */}
                      <Chip
                          // eslint-disable-next-line camelcase
                        value={is_published ? 'Publiée' : 'Brouillon'}
                          // eslint-disable-next-line camelcase
                        variant={is_published ? 'success' : 'warning'}
                      />
                    </td>

                    {/* Start Date */}
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {/* eslint-disable-next-line camelcase */}
                        {start_date
                            && new Date(start_date).toLocaleDateString(
                              'fr-FR', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              },
                            )}
                      </Typography>
                    </td>

                    {/* End Date */}
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {/* eslint-disable-next-line camelcase */}
                        {end_date
                            && new Date(end_date).toLocaleDateString(
                              'fr-FR', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              },
                            )}
                      </Typography>
                    </td>

                    {/* Actions */}
                    <td className={classes}>
                      <Tooltip content="Voir les candidats">
                        <IconButton
                          variant="text"
                          disabled={candidates?.length === 0}
                          onClick={() => navigate(`/offers/${id}/candidates`)}
                        >
                          <UsersIcon className="h-5 w-5" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip content="Voir l'offre">
                        <IconButton
                          variant="text"
                          onClick={() => navigate(`/offers/${id}`)}
                        >
                          <EyeIcon className="h-5 w-5" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip content="Modifier l'offre">
                        <IconButton
                          variant="text"
                          onClick={() => handleModifyClick(id)}
                        >
                          <PencilIcon className="h-5 w-5" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip content="Dupliquer l'offre">
                        <IconButton
                          variant="text"
                          onClick={() => handleDuplicateClick(id)}
                        >
                          <DocumentDuplicateIcon className="h-5 w-5" strokeWidth={2} />
                        </IconButton>
                      </Tooltip>
                      {archived ? (
                        <Tooltip content="Désarchiver l'offre">
                          <IconButton
                            variant="text"
                            className="text-success"
                            onClick={() => offerUnarchiveCallback(id)}
                          >
                            <ArchiveBoxXMarkIcon className="h-5 w-5 text-success" strokeWidth={2} />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip content="Archiver l'offre">
                          <IconButton
                            variant="text"
                            className="text-error"
                            onClick={() => handleDeleteClick(id)}
                          >
                            <ArchiveBoxArrowDownIcon className="h-5 w-5 text-error" strokeWidth={2} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </div>
    </>
  );
}
