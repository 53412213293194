import type React from 'react';
import { Card, Typography } from '@material-tailwind/react';

import classNames from 'classnames';
import SidebarContents from '@/components/admin/SidebarContents';
import { Link } from 'react-router-dom';

interface SidebarProps {
    children: React.ReactNode;
}

export default function Sidebar({ children }: SidebarProps): React.ReactElement {
  return (
    <section className="flex min-h-screen h-full overflow-y-auto p-0 bg-white z-50">
      <div className="w-[320px] hidden xl:block h-screen fixed">
        <Card className={classNames('w-full px-4 h-full shadow-none')}>
          <Link to="/" className="mb-2 p-4 flex items-center gap-2">
            <img src="/images/logo.svg" alt="Migration Logo" className="h-16 w-32" />
            <Typography variant="h5" color="blue-gray" className="sr-only">
              Azélie Santé
            </Typography>
          </Link>
          <SidebarContents />
        </Card>
      </div>
      <div className="w-full ml-0 xl:ml-[320px] h-full overflow-x-hidden">
        {children}
      </div>
    </section>
  );
}
