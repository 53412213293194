import { createAddressAlone, setAddressByID } from '@/lib/Address';
import Supabase from '@lib/Supabase';
import toast from 'react-hot-toast';
import useSWRImmutable from 'swr/immutable';
import { getProfile } from '@lib/Profile';
import useSWR from 'swr';
import { getSingleOffer } from '@lib/Offer';
import { getEstablishmentOverallRating } from '@lib/Rating';
import { getEstablishmentManagers } from '@lib/EstablishmentManagers';

export const getIsFavoriteEstablishment = async (establishmentID: number) => {
  if (establishmentID === undefined) {
    throw new Error('Veuillez renseigner l\'identifiant de l\'établissement');
  }

  const profile = await getProfile();

  const { data, error } = await Supabase
    .from('favorite_establishment')
    .select('profile_id, establishment_id')
    .eq(
      'profile_id', profile?.id,
    )
    .eq(
      'establishment_id', establishmentID,
    );

  if (error) throw new Error(error.message);

  return data?.length > 0;
};

export const getIsFavoriteEstablishmentFromProfile = async (
  establishmentID: number, profileID: number,
) => {
  if (establishmentID === undefined) {
    throw new Error('Veuillez renseigner l\'identifiant de l\'établissement');
  }

  const { data, error } = await Supabase
    .from('favorite_establishment')
    .select('profile_id,establishment_id')
    .eq(
      'profile_id', profileID,
    )
    .eq(
      'establishment_id', establishmentID,
    );

  if (error) throw new Error(error.message);

  return data?.length > 0;
};

export const useIsFavoriteEstablishment = (establishmentID: number) => useSWR(
  ['is-favorite-establishment', establishmentID], () => getIsFavoriteEstablishment(establishmentID),
);

/**
 * Get all establishments
 */
export const getEstablishments = async () => {
  const {
    data: establishments,
    error,
  } = await Supabase
    .from('establishment')
    .select(`
        address_id,
        address:address_id (id,
          city,
          country,
          zip,
          first_line,
          second_line
        ),
        capacity,
        id,
        name,
        phone,
        created_at,
        website,
        description,
        sector_id,
        establishment_sector:sector_id (
          id,
          name
        )
      `);

  if (error) {
    throw new Error(error.message);
  }

  const profile = await getProfile();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < establishments.length; i++) {
    // @ts-ignore
    // eslint-disable-next-line no-await-in-loop
    establishments[i].is_favorite = await getIsFavoriteEstablishmentFromProfile(
      establishments[i].id, profile?.id,
    );

    // @ts-ignore
    // eslint-disable-next-line no-await-in-loop
    establishments[i].rating = await getEstablishmentOverallRating(establishments[i].id);
  }

  const { data: ratings, error: ratingsError } = await Supabase
    .from('rating_establishment')
    .select(`
      id,
      created_at,
      profile_id,
      profile:profile_id (
        id,
        first_name,
        last_name,
        phone
      ),
      establishment_id,
      establishment:establishment_id (
        id,
        name
      ),
      criteria,
      stars,
      offer_id,
      offer:offer_id (
        id,
        title
      )
    `);

  if (ratingsError) {
    throw new Error(ratingsError.message);
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < establishments.length; i++) {
    const establishmentRatings = ratings
      .filter((rating) => rating.establishment_id === establishments[i].id);
    // @ts-ignore
    establishments[i].ratings = establishmentRatings;
    // Get overall rating on a scale of 5 stars
    const overallRating = establishmentRatings
      .reduce(
        (
          acc, rating,
        ) => acc + Number(rating.stars), 0,
      ) / ratings
      .filter((rating) => rating.establishment_id === establishments[i].id).length;

    // @ts-ignore
    establishments[i].overallRating = overallRating;
  }

  return establishments;
};

/**
 * Get real-time establishments via WebSocket
 */
export const useEstablishments = () => useSWR(
  ['establishments-list-page'], getEstablishments,
);

export const setIsFavoriteEstablishment = async (
  establishmentID: number,
  isFavorite: boolean,
) => {
  const profile = await getProfile();

  if (isFavorite) {
    const { data, error } = await Supabase
      .from('favorite_establishment')
      .insert({
        establishment_id: establishmentID,
        profile_id: profile?.id,
      });

    if (error) throw new Error(error.message);

    return data;
  }

  const { data, error } = await Supabase
    .from('favorite_establishment')
    .delete()
    .eq(
      'establishment_id', establishmentID,
    )
    .eq(
      'profile_id', profile?.id,
    );

  if (error) throw new Error(error.message);

  return data;
};

export const getEstablishmentOffers = async () => {
  const establishments = await getEstablishments();

  const establishmentManagers = await getEstablishmentManagers();

  const filteredEstablishments = establishments?.filter((establishment) => establishmentManagers
    .filter((establishmentManager) => establishmentManager.establishment_id === establishment.id)
    .length > 0);

  const { data, error } = await Supabase
    .from('offer')
    .select(`
        id,
        establishment_id,
        establishment:establishment_id (id,
          name,
          phone,
          address_id,
          address:address_id (id,
            city,
            country,
            zip,
            first_line,
            second_line
          ),
          created_at,
          website,
          description
        ),
        archived,
        target,
        title,
        description,
        is_urgent,
        start_time,
        start_date,
        end_date,
        contract_type_id,
        contract_type:contract_type_id (id,
          name
        ),
        experience_required,
        profile_wished,
        conditions,
        missions,
        is_published,
        created_at
      `)
    .in(
      // @ts-ignore
      'establishment_id', filteredEstablishments?.map((establishment) => establishment.id),
    );

  if (error) {
    throw new Error(error.message);
  }

  const { data: candidates, error: candidatesError } = await Supabase
    .from('profiles_offers')
    .select(`
      profile_id,
      profile:profile_id (
        id,
        first_name,
        last_name,
        phone,
        birth_date,
        bio,
        experience_years,
        address_id,
        education (
          id,
          title,
          description,
          start_date,
          end_date,
          city,
          school,
          profile_id
        ),
        experience (
          id,
          title,
          description,
          start_date,
          end_date,
          city,
          company,
          profile_id
        ),
        address:address_id (id,
            city,
            country,
            zip,
            first_line,
            second_line
        )
      ),
      offer_id
  `)
    .in(
      // @ts-ignore
      'offer_id', data?.map((offer) => offer.id),
    );

  if (candidatesError) {
    throw new Error(candidatesError.message);
  }

  return data?.map((offer) => {
    const offerCandidates = candidates?.filter((candidate) => candidate.offer_id === offer.id);
    return {
      ...offer,
      candidates: offerCandidates,
    };
  });
};

export const useEstablishmentOffers = () => {
  const { data, error, mutate } = useSWRImmutable(
    ['establishment-offers'], () => getEstablishmentOffers(),
  );

  Supabase.channel('establishment-offers-update-channel')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'establishment_offer' },
      () => mutate(),
    )
    .subscribe();

  return { data, error, mutate };
};

/**
 * Delete an establishment
 * @param id The establishment id
 */
export const deleteEstablishment = async (id: string) => {
  const { data, error } = await Supabase
    .from('establishment')
    .delete()
    .eq(
      'id', id,
    );

  if (error) {
    // eslint-disable-next-line no-console
    if (import.meta.env.VITE_APP_ENV === 'development') console.error(error);
    if (import.meta.env.VITE_APP_ENV === 'development') toast.error(error.message);
    if (import.meta.env.VITE_APP_ENV === 'production') toast.error('Une erreur est survenue');
    throw new Error(error.message);
  }

  return data;
};

/**
 * Create an establishment
 * @param establishmentData The establishment data
 */
export const createEstablishment = async (establishmentData: {
  name: string;
  phone: string;
  manager: string;
  address: {
    first_line: string;
    second_line: string;
    city: string;
    zip: string;
  };
  website: string;
  capacity: number;
  description: string;
  sector_id: number;
}) => {
  if (establishmentData === undefined || establishmentData === null) {
    throw new Error('Veuillez renseigner les informations de l\'établissement');
  }

  const address = await createAddressAlone(establishmentData.address);

  if (!address) {
    throw new Error('Impossible de créer l\'adresse');
  }

  const { data, error } = await Supabase
    .from('establishment')
    .insert({
      name: establishmentData.name,
      phone: establishmentData.phone,
      address_id: address?.id,
      website: establishmentData.website,
      capacity: establishmentData.capacity,
      description: establishmentData.description,
      sector_id: establishmentData.sector_id,
    });

  if (error) {
    if (error.code === '23505') {
      throw new Error('Un établissement avec ce numéro de téléphone existe déjà ou le manager spécifié est déjà associé à un établissement');
    }
    throw new Error(error.message);
  }

  return data;
};

export const getEstablishmentManager = async () => {
  const profile = await getProfile();

  const { data: establishmentManagers, error: establishmentManagersError } = await Supabase
    .from('establishments_managers')
    .select(`
            manager_id,
            profile:manager_id (
              id,
              first_name,
              last_name,
              phone
            ),
            establishment_id
        `)
    .eq(
      'manager_id', profile?.id,
    );

  if (establishmentManagersError) {
    throw new Error(establishmentManagersError.message);
  }

  if (!establishmentManagers || establishmentManagers.length === 0) {
    return null;
  }

  const { data, error } = await Supabase
    .from('establishment')
    .select(`
        address_id,
        address:address_id (id,
            city,
            country,
            zip,
            first_line,
            second_line
        ),
        id,
        name,
        phone,
        capacity,
        created_at,
        website,
        description,
        sector_id,
        establishment_sector:sector_id (
          id,
          name
        )
`)
    .eq(
      'id', establishmentManagers[0]?.establishment_id,
    )
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const useManagerEstablishment = () => useSWR(
  'manager-establishment', getEstablishmentManager,
);

export const countEstablishments = async () => {
  const { data, error } = await Supabase
    .from('establishment')
    .select('id');

  if (error) throw new Error(error.message);

  return data?.length;
};

export const useCountEstablishments = () => useSWR(
  'establishments-count', countEstablishments,
);

export const establishmentStats = async () => {
  // Get users created in the last month then compare to this month and return the difference
  let lastMothDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
  let thisMonthDate = new Date(new Date().setMonth(new Date().getMonth()));

  // Format date to YYYY-MM-DD
  // @ts-ignore
  // eslint-disable-next-line prefer-destructuring
  lastMothDate = lastMothDate.toISOString().split('T')[0];
  // @ts-ignore
  // eslint-disable-next-line prefer-destructuring
  thisMonthDate = thisMonthDate.toISOString().split('T')[0];

  const { data: establishments, error } = await Supabase
    .from('establishment')
    .select('id, created_at')
    .gte(
      'created_at', thisMonthDate,
    );

  if (error) throw new Error(error.message);

  const { data: establishmentsThisMonth, error: establishmentsThisMonthError } = await Supabase
    .from('establishment')
    .select('id, created_at')
    .gte(
      'created_at', lastMothDate,
    );

  if (establishmentsThisMonthError) throw new Error(establishmentsThisMonthError.message);

  const thisMonth = establishmentsThisMonth?.length || 0;

  const lastMonth = establishments?.length || 0;

  return thisMonth - lastMonth;
};

export const useEstablishmentStats = () => useSWR(
  'establishmentStats', establishmentStats,
);

export const getEstablishmentManagerFromOfferID = async (offerID: number) => {
  const offer = await getSingleOffer(offerID);

  const { data, error } = await Supabase
    .from('establishments_managers')
    .select(`
        manager_id,
        profile:manager_id (
            id,
            first_name,
            last_name,
            phone
        )
        `)
    .eq(
      'establishment_id', offer.establishment_id,
    )
    .single();

  if (error) throw new Error(error.message);

  return data;
};

export const getEstablishment = async () => {
  const profile = await getProfile();

  const { data, error } = await Supabase
    .from('establishments_managers')
    .select(`
            manager_id,
            profile:manager_id (
              id,
              first_name,
              last_name,
              phone
            ),
            establishment_id,
            establishment:establishment_id (
                id,
                address_id,
                address:address_id (id,
                    city,
                    country,
                    zip,
                    first_line,
                    second_line
                ),
                name,
                phone,
                capacity,
                created_at,
                website,
                description,
                sector_id,
                establishment_sector:sector_id (
                  id,
                  name
                )
            )
`)
    .eq(
      'manager_id', profile?.id,
    );

  if (error) throw new Error(error.message);

  if (!data || data.length === 0) {
    return null;
  }

  return data[0].establishment;
};

export const useEstablishment = () => useSWR(
  'single-establishment', getEstablishment,
);

export const editEstablishment = async (establishmentData: {
    name: string;
    phone: string;
    manager: string;
    address: {
        first_line: string;
        second_line: string;
        city: string;
        zip: string;
    };
    website: string;
    capacity: number;
    description: string;
    sector_id: number;
    }) => {
  if (establishmentData === undefined || establishmentData === null) {
    throw new Error('Veuillez renseigner les informations de l\'établissement');
  }

  // @ts-ignore
  const address = await setAddressByID(establishmentData.address);

  const { data, error } = await Supabase
    .from('establishment')
    .update({
      name: establishmentData.name,
      phone: establishmentData.phone,
      // @ts-ignore
      address_id: address?.id,
      website: establishmentData.website,
      capacity: establishmentData.capacity,
      description: establishmentData.description,
      sector_id: establishmentData.sector_id,
    })
    .eq(
      // @ts-ignore
      'id', establishmentData.id,
    );

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const getEstablishmentSectors = async () => {
  const { data, error } = await Supabase
    .from('establishment_sector')
    .select(`
            id,
            name
            `);

  if (error) throw new Error(error.message);

  return data;
};

export const useEstablishmentSectors = () => useSWR(
  'establishment-sectors', getEstablishmentSectors,
);

export const getSingleEstablishment = async (establishmentID: number) => {
  const { data, error } = await Supabase
    .from('establishment')
    .select(`
            address_id,
            address:address_id (id,
                city,
                country,
                zip,
                first_line,
                second_line
            ),
            id,
            name,
            phone,
            capacity,
            created_at,
            website,
            description,
            sector_id,
            establishment_sector:sector_id (
            id,
            name
            )
    `)
    .eq(
      'id', establishmentID,
    )
    .single();

  if (error) throw new Error(error.message);

  return data;
};

export const useSingleEstablishment = (establishmentID: number) => useSWR(
  ['single-establishment', establishmentID], () => getSingleEstablishment(establishmentID),
);
