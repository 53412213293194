import { Typography, Button, Checkbox } from '@material-tailwind/react';
import React from 'react';
import BtnSpinner from '@/components/BtnSpinner';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import Supabase from '@/lib/Supabase';
import useProfile from '@/lib/Profile';
import FullPageLoader from '@/components/FullPageLoader';
import { FullProfile } from '@/custom-types/Profile';
import ProfileCV from '@pages/app/profile/cv/page';

export default function OnboardingPage(): React.ReactElement {
  const [loading, setLoading] = React.useState(false);
  //  @ts-ignore
  const { data: profile, mutate }: {
        profile: FullProfile,
        profileError: Error,
    } = useProfile();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);

    await Supabase.from('profile')
      .update({
        onboarding_completed: true,
      })
    // @ts-ignore
      .eq(
        'id', profile?.id,
      );

    setLoading(false);
    window.location.href = '/';
  };

  if (!profile) {
    return <FullPageLoader />;
  }

  return (
    <div className="w-full">
      {profile?.role?.code === 'candidate' && (
        <>
          <Typography variant="h1" className="text-4xl font-bold mb-6">
            Pour commencer...
          </Typography>
          <Typography variant="paragraph" className="text-base mb-6">
            Renseignez vos informations personnelles, votre CV et vos expériences.
          </Typography>
          <div className="border rounded-lg py-5">
            <ProfileCV showTitle={false} />
          </div>
        </>
      )}

      {profile?.role?.code === 'establishment' && (
        <>
          <Typography variant="h1" className="text-4xl font-bold mb-6 text-center">
            Tout est prêt !
          </Typography>
          <Typography variant="paragraph" className="text-base mb-6 text-center">
            Cliquez sur le bouton ci-dessous pour commencer à utiliser l&apos;application.
          </Typography>
        </>
      )}

      <div className="flex flex-col gap-3">
        <Checkbox
          id="accepts_missions"
          name="accepts_missions"
          checked={profile?.accepts_missions}
          onChange={async (e) => {
            await Supabase
              .from('profile')
              .update({
                accepts_missions: e.target.checked,
              })
            // @ts-ignore
              .eq(
                'id', profile?.id,
              )
              .then(() => {
                mutate();
              });
          }}
          crossOrigin={undefined}
          label={(
            <p className="text-xs">
              Conformément à la Politique de Confidentialité figurant dans les Conditions Générales,
              <br />
              j’accepte que mes données personnelles fournies ci-avant soient automatiquement
              transmises à un Etablissement de Santé qui émettrait une Offre de Mission
              <br />
              correspondant  à l’un ou plusieurs de mes créneaux de disponibilité,
              afin que cette Offre de Mission puisse m’être proposée.
            </p>
          )}
        />

        <Checkbox
          id="accepts_cgu"
          name="accepts_cgu"
          checked={profile?.accepts_cgu}
          onChange={async (e) => {
            await Supabase
              .from('profile')
              .update({
                accepts_cgu: e.target.checked,
              })
            // @ts-ignore
              .eq(
                'id', profile?.id,
              )
              .then(() => {
                mutate();
              });
          }}
          crossOrigin={undefined}
          label={(
            <p className="text-xs">
              J’ai lu et accepte les CGU incluant la politique de confidentialité
              régissant l’utilisation de mes données personnelles.
            </p>
          )}
        />

        <Checkbox
          id="accepts_terms"
          name="accepts_terms"
          checked={profile?.accepts_terms}
          onChange={async (e) => {
            await Supabase
              .from('profile')
              .update({
                accepts_terms: e.target.checked,
              })
            // @ts-ignore
              .eq(
                'id', profile?.id,
              )
              .then(() => {
                mutate();
              });
          }}
          crossOrigin={undefined}
          label={(
            <p className="text-xs">
              Je déclare exactes les données précédemment saisies.
            </p>
          )}
        />
      </div>

      <div className="fixed w-full bottom-0 left-0 py-3 px-5 bg-white">
        <div className="w-full max-w-lg mx-auto">
          <form onSubmit={handleSubmit}>
            <fieldset disabled={loading}>
              {(profile?.accepts_terms !== true
                || profile?.accepts_cgu !== true
                || profile?.accepts_missions !== true) && (
                <p className="text-xs text-gray-600 mb-1">
                  Vous devez accepter les conditions générales d&apos;utilisation
                  et la politique de confidentialité pour continuer.
                </p>
              )}
              <Button
                type="submit"
                className="flex items-center justify-center gap-2 bg-primary w-full"
                disabled={
                  loading
                    || (profile?.role?.code === 'candidate' && (profile.first_name === ''
                    || profile.last_name === ''
                    || profile.address === null))
                    || profile?.type === null
                    || profile?.birth_date === null
                    || (profile?.accepts_terms !== true
                    || profile?.accepts_cgu !== true
                    || profile?.accepts_missions !== true)
                }
              >
                {loading && <BtnSpinner />}
                {loading ? 'Chargement' : 'Terminer'}
                <ArrowRightIcon className="w-5 h-5" />
              </Button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}
