import React from 'react';
import { editEstablishment, useEstablishmentSectors, useManagerEstablishment } from '@lib/Establishment';
import FullPageLoader from '@components/FullPageLoader';
import ErrorCard from '@components/ErrorCard';
import Chip from '@components/Chip';
import { Button, Input, Textarea } from '@material-tailwind/react';
import Select from '@components/form/Select';
import toast from 'react-hot-toast';

export default function EditEstablishmentPage(): React.ReactElement {
  const { data, error, mutate } = useManagerEstablishment();
  const { data: sectors, error: sectorsError } = useEstablishmentSectors();

  const [formData, setFormData] = React.useState({
    id: '',
    name: '',
    description: '',
    address: {
      id: '',
      first_line: '',
      second_line: '',
      zip: '',
      city: '',
    },
    phone: '',
    website: '',
    capacity: '',
    sector_id: '',
  });

  React.useEffect(
    () => {
      if (data) {
        setFormData({
          id: data.id,
          name: data.name,
          description: data.description,
          address: {
            //   @ts-ignore
            id: data.address.id,
            //   @ts-ignore
            first_line: data.address.first_line,
            //   @ts-ignore
            second_line: data.address.second_line,
            //   @ts-ignore
            zip: data.address.zip,
            //   @ts-ignore
            city: data.address.city,
          },
          phone: data.phone,
          website: data.website,
          capacity: data.capacity,
          //  @ts-ignore
          sector_id: data.establishment_sector.id,
        });
      }
    }, [data],
  );

  if (error) {
    return <ErrorCard message={error.message} />;
  }

  if (sectorsError) {
    return <ErrorCard message={sectorsError.message} />;
  }

  if (!data) {
    return <FullPageLoader />;
  }

  if (!sectors) {
    return <FullPageLoader />;
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    return toast.promise(
      //   @ts-ignore
      editEstablishment(formData),
      {
        loading: 'Modification en cours...',
        success: () => 'Modification effectuée avec succès !',
        error: (err) => err.message,
      },
    ).then(() => mutate());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      address: {
        ...formData.address,
        [name]: value,
      },
    });
  };

  return (
    <div>
      <h1 className="mb-2">Modifier mon Établissement</h1>

      <form onSubmit={handleFormSubmit}>
        <div className="mb-5">
          <Input
            crossOrigin={false}
            type="text"
            label="Nom"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-5">
          <Textarea
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleTextareaChange}
          />
        </div>

        <h3 className="text-lg font-medium mt-4">Coordonnées</h3>
        <div className="mb-5">
          <Chip value="Adresse" />

          <div className="mb-5 mt-5">
            <Input
              crossOrigin={false}
              type="text"
              name="first_line"
              label="Ligne 1"
                        // @ts-ignore
              value={formData.address.first_line}
              onChange={handleAddressChange}
            />
          </div>

          <div className="mb-5">
            <Input
              crossOrigin={false}
              type="text"
              name="second_line"
              label="Ligne 2"
                        // @ts-ignore
              value={formData.address.second_line}
              onChange={handleAddressChange}
            />
          </div>

          <div className="mb-5">
            <Input
              crossOrigin={false}
              type="number"
              name="zip"
              label="Code Postal"
                        // @ts-ignore
              value={formData.address.zip}
              onChange={handleAddressChange}
            />
          </div>

          <div className="mb-5">
            <Input
              className="uppercase"
              crossOrigin={false}
              type="text"
              name="city"
              label="Ville"
                        // @ts-ignore
              value={formData.address.city}
              onChange={handleAddressChange}
            />
          </div>

        </div>

        <div className="mb-5">
          <Chip value="Contact" />
          <div className="mb-5 mt-5">
            <Input
              crossOrigin={false}
              type="number"
              name="phone"
              label="Numéro de Téléphone"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="mb-5">

          <div className="mb-5 mt-5">
            <Input
              crossOrigin={false}
              type="text"
              name="website"
              label="Site Web"
              value={formData.website}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="mb-5">
          <div className="mb-5">
            <Input
              crossOrigin={false}
              type="number"
              name="capacity"
              label="Capacité"
              value={formData.capacity}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="mb-5">
          <Chip value="Type d'établissement" />
          <div className="mb-5 mt-5">
            <Select
              setValue={((result) => setFormData({
                ...formData,
                sector_id: result.value as string,
              }))}
              options={sectors.map((sector) => ({
                value: sector.id,
                label: sector.name,
              }))}
              label={"Type d'établissement"}
              value={formData.sector_id}
            />
          </div>
        </div>

        <Button
          type="submit"
          className="bg-primary w-full"
        >
          Valider
        </Button>
      </form>
    </div>
  );
}
