import NotificationsPanel from '@components/app/NotificationsPanel';
import
{
  BellIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { Badge } from '@material-tailwind/react';
import { useCountUnreadNotifications } from '@/lib/Notification';
import Popover from '@/components/Popover';

/**
 *
 */
export default function NotificationPopOver(): React.ReactElement {
  const [open, setOpen] = React.useState(false);
  const { data: count } = useCountUnreadNotifications();

  return (
    <>
      <button
        type="button"
        className="flex"
        onClick={(): void => setOpen(!open)}
      >
        {count !== 0 ? (
          <Badge>
            <BellIcon className="h-6 w-6" />
          </Badge>
        ) : (
          <BellIcon className="h-6 w-6" />
        )}
      </button>

      <Popover
        open={open}
        setOpen={setOpen}
        title={`Notifications (${count})`}
      >
        <NotificationsPanel />
      </Popover>
    </>
  );
}
