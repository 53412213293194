import React from 'react';
import { useMissions } from '@/lib/Mission';
import FullPageLoader from '@/components/FullPageLoader';
import ErrorCard from '@/components/ErrorCard';
import useProfile from '@/lib/Profile';
import { Database } from '@/custom-types/supabase';
import PageTitle from '@/components/app/PageTitle';
import classNames from 'classnames';
import {
  Button, ButtonGroup, Option, Select,
} from '@material-tailwind/react';
import { CalendarIcon, ListBulletIcon } from '@heroicons/react/24/solid';
import Popover from '@/components/Popover';
import JobDetails from '@/pages/app/dashboard/details/page';
/**
 *
 */
export default function Agenda(): React.ReactElement {
  const { data: profile, error: profileError } = useProfile();
  const { data: missions, error } = useMissions();
  const [view, setView] = React.useState<'list' | 'calendar'>('calendar');
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState<Database['public']['Tables']['offer']['Row'] | null>(null);
  const [currentYear, setCurrentYear] = React.useState(new Date().getFullYear());

  if (profileError) {
    return (
      <ErrorCard message={profileError.message} />
    );
  }

  if (!profile) {
    return (
      <FullPageLoader />
    );
  }

  if (error) {
    return (
      <ErrorCard message="Impossible de charger les missions" />
    );
  }

  const offers = missions?.map((mission) => mission.offer) as unknown as Database['public']['Tables']['offer']['Row'][];

  const currentMonth: string = new Date().toLocaleString(
    'default', { month: 'long' },
  );

  /**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */
  const getDaysInMonth = (
    month: number, year: number,
  ) => {
    const date = new Date(
      year, month, 1,
    );
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const months: string[] = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Julliet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  const years = [
    currentYear - 1,
    currentYear,
    currentYear + 1,
  ];

  return (
    <>
      <PageTitle title="Agenda" />
      <Popover open={open} setOpen={setOpen} title="Détails de l&apos;évènement">
        {event && (
          <JobDetails offerID={event.id} />
        )}
      </Popover>
      <div className="px-5">
        <section className="bg-base-100 p-10 rounded-xl shadow">
          <ButtonGroup className="mb-5">
            <Button
              size="sm"
              onClick={() => {
                setView('calendar');
              }}
              className={classNames(
                'flex gap-2 items-center text-xs', view === 'calendar' ? 'bg-primary' : 'bg-base-100 text-text',
              )}
            >
              <CalendarIcon className="w-5 h-5" />
              Calendrier
            </Button>
            <Button
              size="sm"
              onClick={() => {
                setView('list');
              }}
              className={classNames(
                'flex gap-2 items-center text-xs', view === 'list' ? 'bg-primary' : 'bg-base-100 text-text',
              )}
            >
              <ListBulletIcon className="w-5 h-5" />
              Liste
            </Button>
          </ButtonGroup>
          <div className="flex flex-col md:flex-row justify-between items-center mb-5">
            <div>
              <h3 className="font-medium text-xl mb-3">
                Mes Prochaines Missions (
                {offers?.filter((offer) => new Date(offer?.start_date).getFullYear()
              === currentYear).length}
                )
              </h3>
              {offers?.length > 0 && (
              <h4>
                Votre prochaine mission est le
                {' '}
                {new Date(offers?.filter((offer) => new Date(offer?.start_date)?.getFullYear()
                        === currentYear)[0]?.start_date).toLocaleDateString(
                  'fr-FR', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  },
                )}
                .
              </h4>
              )}
            </div>
            <div className="max-w-60">
              <Select
                name="view"
                value={currentYear as unknown as string}
                label="Année"
                onChange={(value) => {
                  setCurrentYear(Number(value));
                }}
              >
                {years.map((
                  mappedYear, index,
                ) => (
                  <Option
                        // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    value={mappedYear as unknown as string}
                  >
                    <div className="flex items-center">
                      <span>{mappedYear}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {view === 'calendar' && (
            <div className="grid grid-cols-1 lg:grid-cols-4">
              {months.map((month) => (
                <div
                  className={classNames(
                    'mb-5 p-5 flex flex-col h-full border', month.toLowerCase() === currentMonth && 'border-primary',
                  )}
                  key={month}
                >
                  <h4 className={classNames(
                    'font-medium text-sm text-gray-600 mb-3', month.toLowerCase() === currentMonth && 'text-primary',
                  )}
                  >
                    {month}
                    {' '}
                    (
                    {offers?.filter((offer) => new Date(offer.start_date).toLocaleString(
                      'fr-FR', { month: 'long' },
                    ) === month.toLowerCase()).length}
                    )
                  </h4>
                  <div className="grid grid-cols-7">
                    {
                  getDaysInMonth(
                    months.indexOf(month), currentYear,
                  ).map((day) => (

                    <button
                      type="button"
                      onClick={() => {
                        const hasOffer = offers?.filter((offer) => offer
                          .start_date === day.toLocaleDateString(
                          'fr-FR', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          },
                        )
                          .split('/')
                          .reverse()
                          .join('-'))[0];
                        if (hasOffer) {
                          setEvent(hasOffer);
                          setOpen(true);
                        }
                      }}
                      className={
                          classNames(
                            'flex justify-center rounded-full py-1 items-center mb-3 hover:bg-gray-100 hover:text-gray-900 transition-all mx-1',
                            day.toLocaleDateString(
                              'fr-FR', {
                                day: 'numeric',
                                month: 'long',
                              },
                            ) === new Date().toLocaleDateString(
                              'fr-FR', {
                                day: 'numeric',
                                month: 'long',
                              },
                            ) && 'border border-primary text-primary',
                            offers?.filter((offer) => offer.start_date === day.toLocaleDateString(
                              'fr-FR', {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                              },
                            )
                              .split('/')
                              .reverse()
                              .join('-')).length > 0 && 'bg-primary text-white',

                          )
                        }
                      key={day.getDate()}
                    >
                      <div>
                        <div className="text-sm font-medium text-neutral">
                          {day.toLocaleDateString(
                            'fr-FR', {
                              day: 'numeric',
                            },
                          )}
                        </div>
                      </div>
                    </button>
                  ))
                }
                  </div>
                </div>
              ))}
            </div>
          )}
          {view === 'list' && (
          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th className="text-left">Date</th>
                  <th className="text-left">Mission</th>
                  <th className="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {offers?.map((offer) => (
                  <tr key={offer.id}>
                    <td className="text-left">
                      {new Date(offer.start_date).toLocaleDateString(
                        'fr-FR', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        },
                      )}
                    </td>
                    <td className="text-left">{offer.title}</td>

                    <td className="text-left">
                      <Button
                        type="button"
                        size="sm"
                        className="bg-primary"
                        onClick={() => {
                          setEvent(offer);
                          setOpen(true);
                        }}
                      >
                        Voir
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        </section>
      </div>
    </>
  );
}
