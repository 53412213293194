import { createContext, useEffect, useState } from 'react';
import supabase from '@lib/Supabase';
import type {
  AuthError, AuthSession, Session, User,
} from '@supabase/supabase-js';

interface AuthContextType {
    session: Session;
    // eslint-disable-next-line no-unused-vars
    // @ts-ignore
    setSession: ({ user, session }) => {
        session: Session;
        user: User
    };
    status: 'loading' | 'authenticated' | 'unauthenticated';
}

export const AuthContext = createContext<AuthContextType>({
  // @ts-ignore
  session: null,
  // @ts-ignore
  setSession: () => null,
  status: 'loading',
});

export const useAuth = (): {
    logout: () => Promise<void>;
    session: Session;
    // eslint-disable-next-line no-unused-vars
    setSession: (value: (((prevState: Session) => Session) | Session)) => void;
    // eslint-disable-next-line no-unused-vars
    login: (email: string) => Promise<{
        data: {
            user: User;
            session: Session
        } | {
            user: null;
            session: null
        };
        error: AuthError
    }>;
    verify: (email: string, token: string) => Promise<{
        data: {
            user: User;
            session: Session
        } | {
            user: null;
            session: null
        };
        error: AuthError
    }>;
    status: 'loading' | 'authenticated' | 'unauthenticated'
} => {
  // @ts-ignore
  const [session, setSession] = useState<AuthSession>(null);
  const [status, setStatus] = useState<
        'loading' | 'authenticated' | 'unauthenticated'
    >('loading');

  const handleAuthChange = (event: string) => {
    if (event === 'SIGNED_OUT') {
    // @ts-ignore
      setSession(null);
      setStatus('unauthenticated');
    }

    if (event === 'SIGNED_IN') {
      supabase.auth.getSession().then(({ data }) => {
        // @ts-ignore
        setSession(data?.session);
        setStatus('authenticated');
      });
    }
  };

  useEffect(
    () => {
      supabase.auth.getSession()
        .then(({ data }) => {
        // @ts-ignore
          setSession(data?.session);

          if (data?.session) {
            setStatus('authenticated');
          } else {
            setStatus('unauthenticated');
          }
        });

      const { data } = supabase.auth.onAuthStateChange((event) => {
        handleAuthChange(event);
      });

      return () => {
        data.subscription.unsubscribe();
      };
    }, [],
  );

  const login = async (email: string): Promise<{
        data: {
            user: User;
            session: Session
        } | {
            user: null;
            session: null
        };
        error: AuthError
    // @ts-ignore
    }> => supabase.auth
    .signInWithOtp({
      email: email.trim(),
    })
    .then(({ data, error }) => ({ data, error }));

  const verify = async (
    email: string,
    token: string,
  ): Promise<{
        data: {
            user: User;
            session: Session
        } | {
            user: null;
            session: null
        };
        error: AuthError
    // @ts-ignore
    }> => supabase.auth
    .verifyOtp({
      email: email.trim(),
      token,
      type: 'email',
    })
    .then(({ data, error }) => ({ data, error }));

  const logout = async (): Promise<void> => {
    await supabase.auth.signOut();
    // @ts-ignore
    setSession(null);
    setStatus('unauthenticated');
  };

  return {
    session, setSession, login, verify, logout, status,
  };
};

export async function getAuth() {
  try {
    return await supabase.auth.getSession();
  } catch (error) {
    return null;
  }
}
