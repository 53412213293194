import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  EyeIcon,
  TrashIcon,
  UserPlusIcon, XMarkIcon, ArrowDownTrayIcon, PencilIcon, BriefcaseIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import {
  CardHeader,
  Input,
  Typography,
  CardBody,
  IconButton,
  Tooltip, Button,
} from '@material-tailwind/react';
import toast from 'react-hot-toast';
import Popover from '@/components/Popover';
import FullPageLoader from '@/components/FullPageLoader';
import Chip from '@/components/Chip';
import ConfirmationModal from '@/components/ConfirmationModal';
import ErrorCard from '@/components/ErrorCard';
import { useUsers, deleteUser } from '@/lib/Users';
import { User } from '@/custom-types/User';
import Select from '@components/form/Select';
import { useRoles } from '@lib/Role';
import { getSimpleProfile, updateProfile, useProfile } from '@lib/Profile';
import { useEstablishments } from '@lib/Establishment';
import classNames from 'classnames';
import supabaseAdmin from '@lib/SupabaseAdmin';
import { createNotification } from '@lib/Notification';
import Supabase from '@lib/Supabase';
import Dropzone from '@components/form/DropZone';
import { getCandidateMissionsByUserID, useProfileOffers } from '@lib/ProfileOffers';

const TABLE_HEAD = [
  'ID',
  'Nom',
  'Rôle',
  'Type',
  'Adresse',
  'Statut',
  'Offres postulées',
  'Date de Création',
  'Actions',
];

function TableRows({
  users,
  handleViewClick,
  handleDeleteClick,
  mutate,
  handleEditClick,
  handleMissionsClick,
}: {
  users: User[];
  handleViewClick: (userID: string) => void;
  handleDeleteClick: (userID: string) => void;
  handleMissionsClick: (userID: string) => void;
    mutate: () => void;
    handleEditClick: (
        id: string,
        profile: any,
        experiences: any,
        educations: any,
        documents: any
    ) => void;
}) {
  const { data: roles, error: rolesError } = useRoles();
  const { data: userProfile, error: userProfileError } = useProfile();
  const { data: establishments, error: establishmentsError } = useEstablishments();
  const { data: profileOffers, error: profileOffersError } = useProfileOffers();

  if (profileOffersError) {
    return <ErrorCard message={profileOffersError.message} />;
  }

  if (userProfileError) {
    return <ErrorCard message={userProfileError.message} />;
  }

  if (rolesError) {
    return <ErrorCard message={rolesError.message} />;
  }

  if (establishmentsError) {
    return <ErrorCard message={establishmentsError.message} />;
  }

  if (!roles || !establishments || !userProfile || !profileOffers) {
    return <FullPageLoader />;
  }

  // Map users, if their role is establishment, add the establishment to the user
  // eslint-disable-next-line no-param-reassign
  users = users.map((user) => {
    if (user?.profile?.role?.code === 'establishment') {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign,no-shadow
      const establishment = establishments
        .find((establishmentToMap) => establishmentToMap
          .manager_id === user.profile.id);

      if (!establishment) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        user.establishment = null;
        return user;
      }

      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      user.establishment = establishment;
    } else {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      user.establishment = null;
    }

    // If the user is a candidate, add the offers he applied to
    if (user?.profile?.role?.code === 'candidate') {
      const hasApplied = profileOffers
        .filter((profileOffer) => profileOffer.profile_id === user.profile.id);

      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      user.hasApplied = hasApplied;
    }

    return user;
  });

  // Sort by user who has applied to most offers
  users.sort((
    a, b,
  ) => {
    // @ts-ignore
    if (a.hasApplied?.length > b.hasApplied?.length) {
      return -1;
    }

    // @ts-ignore
    if (a.hasApplied?.length < b.hasApplied?.length) {
      return 1;
    }

    return 0;
  });

  return (
    <tbody>
      {/* @ts-ignore */}
      {users?.map((
        {
          id,
          // eslint-disable-next-line camelcase
          confirmed_at,
          // eslint-disable-next-line camelcase
          created_at,
          email,
          profile,
          // @ts-ignore
          hasApplied,
          // @ts-ignore
          experiences,
          // @ts-ignore
          educations,
          // @ts-ignore
          documents,
        }, index,
      ) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const isLast = index === users?.length - 1;
        const classes = isLast
          ? 'p-4'
          : 'p-4 border-b border-blue-gray-50';

        return (
          <tr key={id}>

            {/* ID */}
            <td className={classes}>
              <p className="text-xs">
                {profile?.id}
              </p>
            </td>

            {/* Name & Email.ts */}
            <td className={classes}>
              <div className="flex items-center gap-3">
                <div className="flex flex-col">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                  >
                    {profile?.first_name}
                    {' '}
                    {profile?.last_name}
                  </Typography>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal opacity-70"
                  >
                    {email}
                  </Typography>
                </div>
              </div>
            </td>

            {/* Role */}
            <td className={classes}>
              <Select
                size="sm"
                  // @ts-ignore
                // eslint-disable-next-line camelcase
                disabled={email === userProfile?.email || !confirmed_at}
                setValue={async (result) => {
                  const simpleProfile = await getSimpleProfile(id);
                  simpleProfile.role_id = result.value;

                  return toast.promise(
                    updateProfile(simpleProfile), {
                      loading: 'Mise à jour du rôle...',
                      success: 'Rôle mis à jour !',
                      error: 'Une erreur est survenue lors de la mise à jour du rôle',
                    },
                  ).then(() => {
                    mutate();
                  });
                }}
                // eslint-disable-next-line no-shadow
                options={roles.map((role) => ({
                  label: role.name,
                  value: role.id,
                }))}
                label="Rôle"
                value={profile?.role?.id}
              />
            </td>

            {/* Type */}
            <td className={classes}>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {/* eslint-disable-next-line camelcase */}
                {profile?.role?.code === 'candidate' && profile?.type?.name ? (
                  <Chip value={profile?.type?.name} variant="info" />
                ) : (
                  <Chip value={profile?.role?.name || 'Non défini'} variant="info" />
                )}
              </Typography>
            </td>

            {/* Address */}
            <td className={classes}>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {/* @ts-ignore */}
                {profile?.address?.zip}
                {' '}
                {/* @ts-ignore */}
                {profile?.address?.city.toUpperCase()}
              </Typography>
            </td>

            {/* Validated */}
            <td className={classes}>
              <Select
                size="sm"

                  // @ts-ignore
                  // eslint-disable-next-line camelcase,react/jsx-props-no-multi-spaces
                disabled={email === userProfile?.email || !confirmed_at}
                setValue={async (result) => {
                  const simpleProfile = await getSimpleProfile(id);
                  simpleProfile.validated = result.value;

                  return toast.promise(
                    updateProfile(simpleProfile), {
                      loading: 'Mise à jour du statut...',
                      success: 'Statut mis à jour !',
                      error: 'Une erreur est survenue lors de la mise à jour du statut',
                    },
                  ).then(async () => {
                    mutate();
                    await createNotification({
                      level: '1',
                      title: 'Statut mis à jour',
                      content: `Le statut de votre compte a été mis à jour. Votre profil candidat est désormais ${result.value ? 'validé' : 'refusé'}.`,
                      type: 'Mise à Jour',
                      user_id: id,
                      read: false,
                    });
                  });
                }}
                options={[
                  {
                    label: 'Validé',
                    value: true,
                  },
                  {
                    label: 'Refusé',
                    value: false,
                  },
                  {
                    label: 'En attente',
                    value: null,
                  },
                ]}
                label="Statut"
                value={profile?.validated}
                className={profile?.validated ? 'bg-success/10 text-success border-none' : 'bg-warning/10 text-warning border-none'}
              />
            </td>

            {/* Applied Offers */}
            <td className={classes}>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {/* @ts-ignore */}
                {hasApplied?.length}
              </Typography>
            </td>

            {/* Created At */}
            <td className={classes}>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {/* eslint-disable-next-line camelcase */}
                {created_at && new Date(created_at).toLocaleDateString(
                  'fr-FR', { year: 'numeric', month: 'long', day: 'numeric' },
                )}
              </Typography>
            </td>

            {/* Actions */}
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td className={classes}>
              <Tooltip content="Voir les missions postulées">
                <IconButton
                  disabled={hasApplied?.length === 0 || profile?.role?.code !== 'candidate'}
                  variant="text"
                  onClick={() => handleMissionsClick(id)}
                >
                  <BriefcaseIcon className="h-5 w-5" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Voir l'utilisateur">
                <IconButton variant="text" onClick={() => handleViewClick(id)}>
                  <EyeIcon className="h-5 w-5" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Modifier l'utilisateur">
                <IconButton
                  disabled={profile?.role?.code === 'admin' || profile?.role?.code === 'establishment'}
                  variant="text"
                  onClick={() => handleEditClick(
                    id,
                    profile,
                    experiences,
                    educations,
                    documents,
                  )}
                >
                  <PencilIcon className="h-5 w-5" />
                </IconButton>
              </Tooltip>
              <Tooltip content="Supprimer l'utilisateur">
                <IconButton variant="text" onClick={() => handleDeleteClick(id)}>
                  <TrashIcon className="h-5 w-5" />
                </IconButton>
              </Tooltip>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}

type FilterType = {
  name: string;
  value: string;
  isActive: boolean;
};

type FormValuesType = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    establishment: number | null;
};

export default function UserManagement() {
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
  const [userToDelete, setUserToDelete] = React.useState('');
  const [viewUserPopoverOpen, setViewUserPopoverOpen] = React.useState(false);
  const [userToView, setUserToView] = React.useState(null);
  const [filteredUsers, setFilteredUsers] = React.useState(null);
  const [addUserPopoverOpen, setAddUserPopoverOpen] = React.useState(false);
  const [addUserType, setAddUserType] = React.useState('candidate');
  const [editUserPopoverOpen, setEditUserPopoverOpen] = React.useState(false);
  // @ts-ignore
  const [userToViewMissions, setUserToViewMissions]: [
    [],
    React.Dispatch<React.SetStateAction<[]>>,
  ] = React.useState(null);
  const [missionsPopoverOpen, setMissionsPopoverOpen] = React.useState(false);
  const [userToEdit, setUserToEdit] = React.useState({
    id: null,
    profile: null,
    experiences: null,
    educations: null,
    documents: null,
  });
  const [confirmDeleteExperienceOpen, setConfirmDeleteExperienceOpen] = React.useState(false);
  const [experienceToDelete, setExperienceToDelete] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [filesError, setFilesError] = React.useState(null);
  const [documentToDelete, setDocumentToDelete] = React.useState(null);
  const [confirmDeleteDocumentOpen, setConfirmDeleteDocumentOpen] = React.useState(false);
  const [addingExperience, setAddingExperience] = React.useState(false);
  const [newExperience, setNewExperience] = React.useState({
    title: '',
    description: '',
    company: '',
    start_date: '',
    end_date: '',
    city: '',
  });
  const [addingEducation, setAddingEducation] = React.useState(false);
  const [newEducation, setNewEducation] = React.useState({
    title: '',
    description: '',
    school: '',
    start_date: '',
    end_date: '',
    city: '',
  });
  const [educationToDelete, setEducationToDelete] = React.useState(null);
  const [confirmDeleteEducationOpen, setConfirmDeleteEducationOpen] = React.useState(false);

  // @ts-ignore
  const [formValues, setFormValues]: [
    FormValuesType,
    React.Dispatch<React.SetStateAction<FormValuesType>>,
  ] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    establishment: null,
  });
  const [formDisabled, setFormDisabled] = React.useState(false);

  // @ts-ignore
  const [filters, setFilters]: [
    FilterType[],
    React.Dispatch<React.SetStateAction<FilterType[]>>,
  ] = React.useState([
    {
      name: 'Candidat.e.s',
      value: 'candidate',
      isActive: false,
    },
    {
      name: 'Etablissements',
      value: 'establishment',
      isActive: false,
    },
    {
      name: 'Administrateurs',
      value: 'admin',
      isActive: false,
    },
    {
      name: 'Candidat.e.s Validé.e.s',
      value: 'validated',
      isActive: false,
    },
  ]);

  const { data: users, error, mutate: mutateUsers } = useUsers();
  const {
    data: establishments,
    error: establishmentsError,
    mutate: mutateEstablishments,
  } = useEstablishments();

  React.useEffect(
    () => {
      if (!users) return;

      // @ts-ignore
      setFilteredUsers(users);

      if (filters.every((filter) => !filter.isActive)) return;

      // @ts-ignore
      const filtered = users.filter((user) => {
        // @ts-ignore
        const role = user.profile?.role.code;

        // If the filter is candidate or establishment, we need to check the role
        if (filters.some((filter) => filter.isActive && ['candidate', 'establishment'].includes(filter.value))) {
          // @ts-ignore
          const isActive = filters.find((filter) => filter.isActive && filter.value === role);
          return isActive;
        }

        // If the filter is validated, we need to check validated field
        if (filters.some((filter) => filter.isActive && filter.value === 'validated')) {
          // @ts-ignore
          const validated = user.profile?.validated;
          if (!validated) return false;

          return validated;
        }

        const isActive = filters.find((filter) => filter.isActive && filter.value === role);
        return isActive;
      });

      // @ts-ignore
      setFilteredUsers(filtered);
    }, filters,
  );

  if (establishmentsError) {
    return <ErrorCard message={establishmentsError.message} />;
  }

  if (error) {
  // @ts-ignore
    return <ErrorCard message={error.message} />;
  }

  if (!users || !establishments) {
    return <FullPageLoader />;
  }

  /**
   * Triggered when the user clicks on the delete button in the table
   */
  const handleDeleteClick = (userID: string) => {
    setUserToDelete(userID);
    setConfirmationModalOpen(true);
  };

  /**
   * Triggered when the user confirms the deletion of the user via the modal
   */
  const userDeleteCallback = async () => {
    toast.promise(
      deleteUser(userToDelete), {
        loading: 'Suppression de l\'utilisateur...',
        success: 'Utilisateur supprimé !',
        error: 'Une erreur est survenue lors de la suppression de l\'utilisateur',
      },
    ).then(async () => mutateUsers());
  };

  const handleViewClick = (userID: string) => {
    // @ts-ignore
    const user = users.find((u) => u.id === userID);
    // @ts-ignore
    setUserToView(user);
    setViewUserPopoverOpen(true);
  };

  const searchUser = (searchTerm: string) => {
    if (!searchTerm) {
      setFilteredUsers(null);
      return;
    }

    const st = searchTerm.toLowerCase();

    // @ts-ignore
    const matchedUsers = users.filter((user: User) => {
      const fullName = `${user?.profile?.first_name} ${user?.profile?.last_name}`;
      const email = user?.email;
      const profileID = user?.profile?.id;
      const fullAddress = `${user?.profile?.address?.first_line} ${user?.profile?.address?.second_line} ${user?.profile?.address?.zip} ${user?.profile?.address?.city}`;
      return fullName.toLowerCase().includes(st)
        || email?.toLowerCase().includes(st)
        || fullAddress?.toLowerCase().includes(st)
        || profileID?.toString().includes(st);
    });

    // @ts-ignore
    setFilteredUsers(matchedUsers);
  };

  const handleAddManager = async () => {
    const { data: invitedUser, error: inviteUserError } = await supabaseAdmin
      .auth
      .admin
      .inviteUserByEmail(formValues.email);

    if (inviteUserError) {
      throw new Error('Une erreur est survenue lors de l\'ajout du manager');
    }

    const profile = {
      first_name: formValues.firstName,
      last_name: formValues.lastName,
      phone: formValues.phone,
      role_id: 6,
      type: null,
      onboarding_completed: true,
      user_id: invitedUser?.user?.id,
      validated: true,
    };

    const { data: createdUser, error: userCreationError } = await Supabase
      .from('profile')
      .insert(profile)
      .select('*')
      .single();

    if (userCreationError) {
      // Delete the user
      await supabaseAdmin
        .auth
        .admin
        .deleteUser(invitedUser?.user?.id);

      await mutateUsers();

      if (userCreationError.code === '23505') {
        throw new Error('Un utilisateur avec cette adresse email ou ce numéro de téléphone existe déjà');
      }

      throw new Error("Une erreur est survenue lors de l'ajout du manager");
    }

    await mutateUsers();
    await mutateEstablishments();
    return createdUser;
  };

  const handleAddCandidate = async () => {
    const { data: invitedUser, error: inviteUserError } = await supabaseAdmin
      .auth
      .admin
      .inviteUserByEmail(formValues.email);

    if (inviteUserError) {
      throw new Error('Une erreur est survenue lors de l\'ajout du candidat');
    }

    const profile = {
      first_name: formValues.firstName,
      last_name: formValues.lastName,
      phone: formValues.phone,
      role_id: 5,
      type: null,
      onboarding_completed: false,
      user_id: invitedUser?.user?.id,
      validated: false,
    };

    const { data: createdUser, error: userCreationError } = await Supabase
      .from('profile')
      .insert(profile)
      .select('*')
      .single();

    if (userCreationError) {
      // Delete the user
      await supabaseAdmin
        .auth
        .admin
        .deleteUser(invitedUser?.user?.id);

      if (userCreationError.code === '23505') {
        throw new Error('Un utilisateur avec cette adresse email ou ce numéro de téléphone existe déjà');
      }

      throw new Error("Une erreur est survenue lors de l'ajout du candidat");
    }

    await mutateUsers();
    return createdUser;
  };

  /**
   * Triggered when the user submits the add manager form
   * @param e FormEvent
   */
  const handleAddManagerFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormDisabled(true);

    toast.promise(
      handleAddManager(), {
        loading: 'Ajout du gestionnaire...',
        success: 'Gestionnaire ajouté !',
        error: (handleAddManagerError) => handleAddManagerError.message,
      },
    ).then(() => {
      setFormDisabled(false);
      setAddUserPopoverOpen(false);
    })
      .catch(() => {
        setFormDisabled(false);
      });
  };

  /**
   * Triggered when the user submits the add candidate form
   * @param e FormEvent
   */
  const handleAddCandidateFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormDisabled(true);
    toast.promise(
      handleAddCandidate(), {
        loading: 'Ajout du candidat...',
        success: 'Candidat ajouté !',
        error: (handleAddManagerError) => handleAddManagerError.message,
      },
    ).then(() => {
      setFormDisabled(false);
      setAddUserPopoverOpen(false);
    })
      .catch(() => {
        setFormDisabled(false);
      });
  };

  const downloadDocument = async (
    documentToDownload: { id: any; name: any; }, userID: string,
  ) => {
    const sanitizedName = documentToDownload.name.replace(
      /[^a-zA-Z0-9.]/g, '_',
    );

    const { data, error: fileDownloadError } = await Supabase
      .storage
      .from('documents')
      .download(`${userID}/${sanitizedName}`);

    if (fileDownloadError) {
      // @ts-ignore
      if (fileDownloadError.status === 400) {
        throw new Error('Le document est introuvable');
      }

      throw new Error(fileDownloadError.message);
    }

    //   Data is a blob, let's download it
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      documentToDownload.name,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  /**
   * Triggered when the user clicks on the download button
   * @param documentToDownload
   * @param userID
   */
  const handleDownloadDocument = async (
    documentToDownload: { id: any; name: any; },
    userID: string,
  ) => toast.promise(
    downloadDocument(
      documentToDownload, userID,
    ), {
      loading: 'Téléchargement du document...',
      success: 'Document téléchargé !',
      error: (downloadDocumentError) => downloadDocumentError.message,
    },
  );

  //   Export all users to csv
  const downloadUserData = async () => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    const rows = [
      [
        'ID',
        'Nom',
        'Prénom',
        'Email',
        'Téléphone',
        'Adresse',
        'Rôle',
        'Type',
        'Statut',
        'Date de Création',
        'Date de Confirmation',
        'Dernière mise à jour',
      ],
    ];

    // @ts-ignore
    users.forEach((user) => {
      rows.push([
        // @ts-ignore
        user.profile?.first_name,
        // @ts-ignore
        user.profile?.last_name,
        user.email,
        // @ts-ignore
        user.profile?.phone,
        // @ts-ignore
        user.profile?.address?.first_line,
        // @ts-ignore
        user.profile?.address?.second_line,
        // @ts-ignore
        user.profile?.address?.zip,
        // @ts-ignore
        user.profile?.address?.city,
        // @ts-ignore
        user.profile?.address?.country,
        // @ts-ignore
        user.profile?.role?.name,
        // @ts-ignore
        user.profile?.type?.name,
        // @ts-ignore
        user.profile?.validated ? 'Validé' : 'En attente',
        user.created_at && new Date(user.created_at).toLocaleDateString(
          'fr-FR', { year: 'numeric', month: 'long', day: 'numeric' },
        ),
        user.confirmed_at && new Date(user.confirmed_at).toLocaleDateString(
          'fr-FR', { year: 'numeric', month: 'long', day: 'numeric' },
        ),
        user.updated_at && new Date(user.updated_at).toLocaleDateString(
          'fr-FR', { year: 'numeric', month: 'long', day: 'numeric' },
        ),
      ]);
    });

    rows.forEach((rowArray) => {
      const row = rowArray.join(',');
      csvContent += `${row}\r\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute(
      'href', encodedUri,
    );
    const date = new Date();
    const fileName = `utilisateurs-${date.toLocaleDateString(
      'fr-FR', { year: 'numeric', month: 'long', day: 'numeric' },
    )}.csv`;
    link.setAttribute(
      'download', fileName,
    );
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadUserData = async () => toast.promise(
    downloadUserData(), {
      loading: 'Téléchargement des données...',
      success: 'Données téléchargées !',
      error: (downloadUserDataError) => downloadUserDataError.message,
    },
  );

  const handleAddManagerClick = (open: boolean) => {
    setAddUserType('establishment');
    setAddUserPopoverOpen(open);
  };

  const handleAddCandidateClick = (open: boolean) => {
    setAddUserType('candidate');
    setAddUserPopoverOpen(open);
  };

  const handleEditFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormDisabled(true);
    toast.promise(
      updateProfile(formValues), {
        loading: 'Mise à jour du profil...',
        success: 'Profil mis à jour !',
        error: (handleAddManagerError) => handleAddManagerError.message,
      },
    ).then(() => {
      setFormDisabled(false);
      setEditUserPopoverOpen(false);
    })
      .catch(() => {
        setFormDisabled(false);
      });
  };

  const uploadFiles = async (userID: string): Promise<void> => {
    const { error: uploadError } = await Supabase
      .from('documents')
      .insert(files.map((file) => ({
        // @ts-ignore
        name: file.name,
        user_id: userID,
      })));

    if (uploadError) {
      if (uploadError.code === '409') {
        throw new Error('Un fichier avec ce nom existe déjà');
      }

      throw new Error(uploadError.message);
    }

    // @ts-ignore
    return mutateUsers().then((result) => {
      const updatedUser = result?.find((user) => user.id === userID);

      // @ts-ignore
      setUserToEdit(updatedUser);

      return updatedUser;
    });
  };

  const handleUploadFiles = async (userID: string): Promise<void> => {
    const promises = files.map(async (file) => {
      // @ts-ignore
      setFilesError('');
      // @ts-ignore
      const sanitizedName = file.name.replace(
        /[^a-zA-Z0-9.]/g, '_',
      );
      const {
        error: uploadError,
      } = await Supabase
        .storage
        .from('documents')
        .upload(
          `${userID}/${sanitizedName}`, file,
        );

      if (uploadError) {
        // @ts-ignore
        if (uploadError.statusCode === '409') {
          // @ts-ignore
          setFilesError('Un fichier avec ce nom existe déjà');
          return;
        }

        // @ts-ignore
        setFilesError('Impossible de téléverser vos documents');
      }
    });

    await Promise.all(promises);

    await toast.promise(
      uploadFiles(userID),
      {
        loading: 'Téléversement de vos documents...',
        success: 'Vos documents ont été téléversés !',
        error: 'Impossible de téléverser vos documents',
      },
      {
        style: {
          minWidth: '250px',
        },
      },
    );

    setFiles([]);
  };

  const handleMissionsClick = async (userID: string) => {
    const missions = await getCandidateMissionsByUserID(userID);
    // @ts-ignore
    setUserToViewMissions(missions);
    setMissionsPopoverOpen(true);
  };

  return (
    <>
      <ConfirmationModal
        open={confirmDeleteExperienceOpen}
        setOpen={setConfirmDeleteExperienceOpen}
        description="Êtes-vous sûr de vouloir supprimer cette expérience ?"
        title={'Supprimer l\'expérience'}
        callBack={async () => {
          // @ts-ignore
          const { error: deleteExperienceError } = await Supabase
            .from('experience')
            .delete()
            .eq(
              'id', experienceToDelete,
            )
            .single();

          if (deleteExperienceError) {
            throw new Error('Une erreur est survenue lors de la suppression de l\'expérience');
          }

          return mutateUsers().then((result) => {
            const updatedUser = result?.find((user) => user.id === userToEdit.id);

            // @ts-ignore
            setUserToEdit(updatedUser);

            return updatedUser;
          });
        }}
      />

      <ConfirmationModal
        open={confirmDeleteDocumentOpen}
        setOpen={setConfirmDeleteDocumentOpen}
        title="Supprimer le document"
          //   @ts-ignore
        description={`Êtes-vous sûr de vouloir supprimer le document ${documentToDelete?.name} ?`}
        callBack={async () => {
          // @ts-ignore
          const { error: deleteDocumentError } = await Supabase
            .from('documents')
            .delete()
            .eq(
              //   @ts-ignore
              'id', documentToDelete?.id,
            )
            .single();

          if (deleteDocumentError) {
            throw new Error('Une erreur est survenue lors de la suppression du document');
          }

          // Also delete from storage
          //   @ts-ignore
          const sanitizedName = documentToDelete?.name.replace(
            /[^a-zA-Z0-9.]/g, '_',
          );
          const { error: deleteStorageError } = await Supabase
            .storage
            .from('documents')
            .remove([`${userToEdit.id}/${sanitizedName}`]);

          if (deleteStorageError) {
            throw new Error('Une erreur est survenue lors de la suppression du document');
          }

          return mutateUsers().then((result) => {
            const updatedUser = result?.find((user) => user.id === userToEdit.id);

            // @ts-ignore
            setUserToEdit(updatedUser);

            return updatedUser;
          });
        }}
      />

      <ConfirmationModal
        title="Supprimer la formation"
        open={confirmDeleteEducationOpen}
        setOpen={setConfirmDeleteEducationOpen}
        description="Êtes-vous sûr de vouloir supprimer cette formation ?"
        callBack={async () => {
          // @ts-ignore
          const { error: deleteEducationError } = await Supabase
            .from('education')
            .delete()
            .eq(
              'id', educationToDelete,
            )
            .single();

          if (deleteEducationError) {
            throw new Error('Une erreur est survenue lors de la suppression de l\'éducation');
          }

          return mutateUsers().then((result) => {
            const updatedUser = result?.find((user) => user.id === userToEdit.id);

            // @ts-ignore
            setUserToEdit(updatedUser);

            return updatedUser;
          });
        }}
      />

      {/* Popover for Viewing users */}
      <Popover
        title="Informations de l'utilisateur"
        open={viewUserPopoverOpen}
        setOpen={setViewUserPopoverOpen}
      >
        <Typography variant="h6" className="font-semibold text-gray-600 text-sm mb-5">
          Informations générales
        </Typography>

        <div className="rounded-lg border mb-5">
          <table className="w-full table-auto text-left">
            <thead>
              <tr>
                <th className="bg-blue-gray-50/50 p-4 transition-colors">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    Clé
                  </Typography>
                </th>
                <th className="bg-blue-gray-50/50 p-4 transition-colors">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    Valeur
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Nom</td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {userToView?.profile?.first_name}
                  {' '}
                  {/* @ts-ignore */}
                  {userToView?.profile?.last_name}
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Email</td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {userToView?.email}
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Type d&apos;établissement souhaité/actuel
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {userToView?.profile?.establishment_sector?.name}
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Rôle</td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <td className="p-4">
                  <Chip
                    // @ts-ignore
                    value={userToView?.profile?.role?.name || 'Non défini'}
                    // @ts-ignore
                    variant={userToView?.profile?.role?.name ? 'primary' : 'secondary'}
                  />
                </td>
              </tr>
              {
              // @ts-ignore
                userToView?.profile?.role?.code === 'candidate' && (
                <tr>
                  <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Type</td>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <td className="p-4">
                    <Chip
                            // @ts-ignore
                      value={userToView?.profile?.type?.name || 'Non défini'}
                            // @ts-ignore
                      variant={userToView?.profile?.type?.name ? 'primary' : 'secondary'}
                    />
                  </td>
                </tr>
                )
            }

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Date de naissance
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {userToView?.profile?.birth_date && new Date(userToView?.profile
                    .birth_date).toLocaleDateString()}
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Date de naissance
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {userToView?.profile?.birth_date && new Date(userToView?.profile
                    .birth_date).toLocaleDateString()}
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Date de Création</td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {userToView?.created_at && new Date(userToView?.created_at).toLocaleDateString(
                    'fr-FR', { year: 'numeric', month: 'long', day: 'numeric' },
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Date de Confirmation</td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {userToView?.confirmed_at
                    // @ts-ignore
                    && new Date(userToView?.confirmed_at).toLocaleDateString(
                      'fr-FR', { year: 'numeric', month: 'long', day: 'numeric' },
                    )}
                  {/* @ts-ignore */}
                  {!userToView?.confirmed_at && (
                  <Chip variant="secondary" value="Non confirmé" />
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">Dernière mise à jour</td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {userToView?.updated_at && new Date(userToView?.updated_at).toLocaleDateString(
                    'fr-FR', { year: 'numeric', month: 'long', day: 'numeric' },
                  )}
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Adresse
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {userToView?.profile?.address?.first_line}
                  <br />
                  {/* @ts-ignore */}
                  {userToView?.profile?.address?.second_line}
                  <br />
                  {/* @ts-ignore */}
                  {userToView?.profile?.address?.zip}
                  {' '}
                  {/* @ts-ignore */}
                  {userToView?.profile?.address?.city.toUpperCase()}
                  <br />
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Expérience
                </td>
                <td className="p-4">
                  {
                  // @ts-ignore
                  userToView?.experiences?.map((experience) => (
                    <>
                      <div key={experience.id} className="mb-5">
                        <Typography variant="h6" className="font-semibold text-gray-600 text-sm mb-5">
                          {experience.title}
                        </Typography>
                        <Typography className="text-sm">
                          {experience.description}
                        </Typography>
                        <Typography className="text-sm">
                          {experience.start_date && new Date(experience
                            .start_date).toLocaleDateString()}
                        </Typography>
                        <Typography className="text-sm">
                          {experience.city.toUpperCase()}
                        </Typography>
                        <br />
                      </div>
                      <br />
                    </>
                  ))
                }
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Années d&apos;expérience
                </td>
                <td className="p-4">
                  {/* @ts-ignore */}
                  {userToView?.profile?.experience_years}
                </td>
              </tr>

              <tr>
                <td className="p-4 font-semibold text-sm text-gray-600 bg-blue-gray-50/50">
                  Education
                </td>
                <td className="p-4">
                  {
                  // @ts-ignore
                  userToView?.educations?.map((education) => (
                    <>
                      <div key={education.id} className="mb-5">
                        <Typography variant="h6" className="font-semibold text-gray-600 text-sm mb-5">
                          {education.title}
                        </Typography>
                        <Typography className="text-gray-600 text-sm">
                          {education.description}
                        </Typography>
                        <Typography className="text-sm">
                          {education.start_date && new Date(education
                            .start_date).toLocaleDateString()}
                        </Typography>
                        <Typography className="text-sm">
                          {education.city.toUpperCase()}
                        </Typography>
                        <br />
                      </div>
                      <br />
                    </>
                  ))
                }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {
          // @ts-ignore
            userToView?.profile?.role?.code === 'candidate' && (
            <section className="mb-5">
              {
                    // @ts-ignore
                    userToView?.documents && userToView?.documents?.length > 0 ? (
                    // @ts-ignore
                      userToView?.documents?.map((document) => (
                        <article
                          key={document.id}
                          className="border p-5 rounded-lg mb-5 flex flex-col gap-2 items-center justify-between"
                        >
                          <Typography variant="h5" className="text-sm">
                            {document.name}
                          </Typography>
                          <div className="flex gap-2 w-full">
                            {/*  Download button */}
                            <Button
                              size="sm"
                              className="bg-primary text-white shadow-none w-full"
                              onClick={async () => handleDownloadDocument(
                                // @ts-ignore
                                document, userToView?.id,
                              )}
                            >
                              Télécharger
                            </Button>
                          </div>
                        </article>
                      ))
                    ) : (
                      <span>
                        Le candidat n&apos;a pas encore envoyé de documents
                      </span>
                    )
              }

              {
                // @ts-ignore
                userToView?.ratings && userToView?.ratings?.length > 0 && (
                <div className="flex flex-col gap-3">
                  <Typography variant="h6" className="font-semibold text-gray-600 text-sm mb-5">
                    Notes (
                    {
                      // @ts-ignore
                      userToView?.ratings?.length
}
                    {' '}
                    avis)
                    <br />
                    Note Globale:
                    {' '}
                    {
                      // @ts-ignore
                      userToView.overallRating
}
                    /5
                  </Typography>
                    {
                      // @ts-ignore
                      userToView?.ratings?.map((rating) => (
                        <div key={rating.id} className="flex flex-col gap-3 border p-2 rounded mb-5">
                          <Typography variant="h6" className="font-semibold text-gray-600 text-sm">
                            Établissement
                            {' '}
                            {rating.establishment.name}
                          </Typography>
                          <Typography className="text-sm">
                            Note:
                            {' '}
                            {rating.stars}
                            /5
                          </Typography>
                          <Typography className="text-sm">
                            Critère:
                            {' '}
                            {rating.criteria}
                          </Typography>
                          <Typography className="text-sm">
                            Offre N°
                            {rating.offer.id}
                            {' '}
                            -
                            {' '}
                            {rating.offer.title}
                          </Typography>
                          <Typography className="text-sm">
                            {rating.created_at && (
                            <>
                              Le
                              {' '}
                              {new Date(rating.created_at).toLocaleDateString('fr-FR')}
                            </>
                            )}
                          </Typography>
                        </div>
                      ))
}
                </div>
                )
}
            </section>
            )
}
      </Popover>

      {/* Popover for editing users */}
      <Popover
        title="Modifier l'utilisateur"
        open={editUserPopoverOpen}
        setOpen={setEditUserPopoverOpen}
      >
        <form className="h-full" onSubmit={handleEditFormSubmit}>
          <fieldset className="flex flex-col gap-3 h-full" disabled={formDisabled}>
            {/* This is not meant to edit the whole user according
            to RGPD, just educations & experiences */}
            <Typography variant="h6" className="font-semibold text-gray-600 text-sm">
              Expériences
            </Typography>

            <div className="flex flex-col gap-3">
              {/* @ts-ignore */}
              {userToEdit?.experiences?.map((experience) => (
                <div key={experience.id} className="flex flex-col gap-3 border p-2 rounded mb-5">
                  <Input
                    crossOrigin={undefined}
                    label="Titre"
                    type="text"
                    value={experience.title}
                    onChange={(e) => {
                      setUserToEdit({
                        ...userToEdit,
                        //   @ts-ignore
                        experiences: userToEdit?.experiences?.map((exp) => {
                          if (exp.id === experience.id) {
                            return {
                              ...exp,
                              title: e.target.value,
                            };
                          }

                          return exp;
                        }),
                      });
                    }}
                  />

                  <Input
                    crossOrigin={undefined}
                    label="Description"
                    type="text"
                    value={experience.description}
                    onChange={(e) => {
                      setUserToEdit({
                        ...userToEdit,
                        //   @ts-ignore
                        experiences: userToEdit?.experiences?.map((exp) => {
                          if (exp.id === experience.id) {
                            return {
                              ...exp,
                              description: e.target.value,
                            };
                          }

                          return exp;
                        }),
                      });
                    }}
                  />

                  <Input
                    crossOrigin={undefined}
                    label="Ville"
                    type="text"
                    value={experience.city}
                    onChange={(e) => {
                      setUserToEdit({
                        ...userToEdit,
                        //   @ts-ignore
                        experiences: userToEdit?.experiences?.map((exp) => {
                          if (exp.id === experience.id) {
                            return {
                              ...exp,
                              city: e.target.value,
                            };
                          }

                          return exp;
                        }),
                      });
                    }}
                  />

                  {/* Company */}
                  <Input
                    crossOrigin={undefined}
                    label="Société"
                    type="text"
                    value={experience.company}
                    onChange={(e) => {
                      setUserToEdit({
                        ...userToEdit,
                        //   @ts-ignore
                        experiences: userToEdit?.experiences?.map((exp) => {
                          if (exp.id === experience.id) {
                            return {
                              ...exp,
                              company: e.target.value,
                            };
                          }

                          return exp;
                        }),
                      });
                    }}
                  />

                  <div className="flex gap-3">
                    <Input
                      crossOrigin={undefined}
                      label="Date de début"
                      type="date"
                      value={experience.start_date}
                      onChange={(e) => {
                        setUserToEdit({
                          ...userToEdit,
                          //   @ts-ignore
                          experiences: userToEdit?.experiences?.map((exp) => {
                            if (exp.id === experience.id) {
                              return {
                                ...exp,
                                start_date: e.target.value,
                              };
                            }

                            return exp;
                          }),
                        });
                      }}
                    />

                    <Input
                      crossOrigin={undefined}
                      label="Date de fin"
                      type="date"
                      value={experience.end_date}
                      onChange={(e) => {
                        setUserToEdit({
                          ...userToEdit,
                          //   @ts-ignore
                          experiences: userToEdit?.experiences?.map((exp) => {
                            if (exp.id === experience.id) {
                              return {
                                ...exp,
                                end_date: e.target.value,
                              };
                            }

                            return exp;
                          }),
                        });
                      }}
                    />
                  </div>
                  <div className="flex gap-3 justify-between">
                    <Button
                      size="sm"
                      className="bg-error text-white shadow-none"
                      onClick={() => {
                        setExperienceToDelete(experience.id);
                        setConfirmDeleteExperienceOpen(true);
                      }}
                    >
                      Supprimer
                    </Button>
                    <Button
                      size="sm"
                      className="bg-primary text-white shadow-none"
                      onClick={async () => toast.promise(
                        //   @ts-ignore
                        Supabase
                          .from('experience')
                          .update(experience)
                          .match({ id: experience.id }),
                        {
                          loading: 'Mise à jour de l\'expérience...',
                          success: 'Expérience mise à jour !',
                          error: (updateExperienceError) => updateExperienceError.message,
                        },
                      )}
                    >
                      Modifier
                    </Button>
                  </div>
                </div>
              ))}

              {addingExperience && (
                <div className="flex flex-col gap-3 border p-2 rounded mb-5">
                  <Input
                    crossOrigin={undefined}
                    label="Titre"
                    type="text"
                    value={newExperience.title}
                    onChange={(e) => {
                      setNewExperience({
                        ...newExperience,
                        title: e.target.value,
                      });
                    }}
                  />

                  <Input
                    crossOrigin={undefined}
                    label="Description"
                    type="text"
                    value={newExperience.description}
                    onChange={(e) => {
                      setNewExperience({
                        ...newExperience,
                        description: e.target.value,
                      });
                    }}
                  />

                  <Input
                    crossOrigin={undefined}
                    label="Ville"
                    type="text"
                    // @ts-ignore
                    value={newExperience.city}
                    onChange={(e) => {
                      setNewExperience({
                        ...newExperience,
                        // @ts-ignore
                        city: e.target.value,
                      });
                    }}
                  />

                  {/* Company */}
                  <Input
                    crossOrigin={undefined}
                    label="Société"
                    type="text"
                    value={newExperience.company}
                    onChange={(e) => {
                      setNewExperience({
                        ...newExperience,
                        company: e.target.value,
                      });
                    }}
                  />

                  <div className="flex gap-3">
                    <Input
                      crossOrigin={undefined}
                      label="Date de début"
                      type="date"
                      value={newExperience.start_date}
                      onChange={(e) => {
                        setNewExperience({
                          ...newExperience,
                          start_date: e.target.value,
                        });
                      }}
                    />

                    <Input
                      crossOrigin={undefined}
                      label="Date de fin"
                      type="date"
                      value={newExperience.end_date}
                      onChange={(e) => {
                        setNewExperience({
                          ...newExperience,
                          end_date: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="flex gap-3 justify-between">
                    <Button
                      size="sm"
                      className="bg-error text-white shadow-none"
                      onClick={() => setAddingExperience(false)}
                    >
                      Annuler
                    </Button>
                    <Button
                      size="sm"
                      className="bg-primary text-white shadow-none"
                      onClick={async () => {
                        // @ts-ignore
                        newExperience.profile_id = userToEdit.profile?.id;
                        const { error: experienceCreationError } = await Supabase
                          .from('experience')
                          .insert(newExperience)
                          .single();

                        if (experienceCreationError) {
                          throw new Error('Une erreur est survenue lors de la création de l\'expérience');
                        }

                        // @ts-ignore
                        return mutateUsers().then((result) => {
                          const updatedUser = result?.find((user) => user.id === userToEdit.id);

                          // @ts-ignore
                          setUserToEdit(updatedUser);

                          setAddingExperience(false);
                          return updatedUser;
                        });
                      }}
                    >
                      Ajouter
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-auto flex flex-col w-full gap-3">
              <Button
                type="button"
                onClick={() => setAddingExperience(true)}
                className="bg-primary mt-auto"
              >
                Ajouter une expérience
              </Button>

              <Typography variant="h6" className="font-semibold text-gray-600 text-sm">
                Formations
              </Typography>

              <div className="flex flex-col gap-3">
                {/* @ts-ignore */}
                {userToEdit?.educations?.map((education) => (
                  <div key={education.id} className="flex flex-col gap-3 border p-2 rounded mb-5">
                    <Input
                      crossOrigin={undefined}
                      label="Titre"
                      type="text"
                      value={education.title}
                      onChange={(e) => {
                        setUserToEdit({
                          ...userToEdit,
                          // @ts-ignore
                          educations: userToEdit?.educations?.map((edu) => {
                            if (edu.id === education.id) {
                              return {
                                ...edu,
                                title: e.target.value,
                              };
                            }

                            return edu;
                          }),
                        });
                      }}
                    />

                    <Input
                      crossOrigin={undefined}
                      label="Description"
                      type="text"
                      value={education.description}
                      onChange={(e) => {
                        setUserToEdit({
                          ...userToEdit,
                          // @ts-ignore
                          educations: userToEdit?.educations?.map((edu) => {
                            if (edu.id === education.id) {
                              return {
                                ...edu,
                                description: e.target.value,
                              };
                            }

                            return edu;
                          }),
                        });
                      }}
                    />

                    <Input
                      crossOrigin={undefined}
                      label="Ville"
                      type="text"
                      value={education.city}
                      onChange={(e) => {
                        setUserToEdit({
                          ...userToEdit,
                          // @ts-ignore
                          educations: userToEdit?.educations?.map((edu) => {
                            if (edu.id === education.id) {
                              return {
                                ...edu,
                                city: e.target.value,
                              };
                            }

                            return edu;
                          }),
                        });
                      }}
                    />

                    {/* School */}
                    <Input
                      crossOrigin={undefined}
                      label="École"
                      type="text"
                      value={education.school}
                      onChange={(e) => {
                        setUserToEdit({
                          ...userToEdit,
                          // @ts-ignore
                          educations: userToEdit?.educations?.map((edu) => {
                            if (edu.id === education.id) {
                              return {
                                ...edu,
                                school: e.target.value,
                              };
                            }

                            return edu;
                          }),
                        });
                      }}
                    />

                    <div className="flex gap-3">
                      <Input
                        crossOrigin={undefined}
                        label="Date de début"
                        type="date"
                        value={education.start_date}
                        onChange={(e) => {
                          setUserToEdit({
                            ...userToEdit,
                            // @ts-ignore
                            educations: userToEdit?.educations?.map((edu) => {
                              if (edu.id === education.id) {
                                return {
                                  ...edu,
                                  start_date: e.target.value,
                                };
                              }

                              return edu;
                            }),
                          });
                        }}
                      />

                      <Input
                        crossOrigin={undefined}
                        label="Date de fin"
                        type="date"
                        value={education.end_date}
                        onChange={(e) => {
                          setUserToEdit({
                            ...userToEdit,
                            // @ts-ignore
                            educations: userToEdit?.educations?.map((edu) => {
                              if (edu.id === education.id) {
                                return {
                                  ...edu,
                                  end_date: e.target.value,
                                };
                              }

                              return edu;
                            }),
                          });
                        }}
                      />

                    </div>
                    <div className="flex gap-3 justify-between">
                      <Button
                        size="sm"
                        className="bg-error text-white shadow-none"
                        onClick={() => {
                          setEducationToDelete(education.id);
                          setConfirmDeleteEducationOpen(true);
                        }}
                      >
                        Supprimer
                      </Button>

                      <Button
                        size="sm"
                        className="bg-primary text-white shadow-none"
                        onClick={async () => toast.promise(
                          // @ts-ignore
                          Supabase
                            .from('education')
                            .update(education)
                            .match({ id: education.id }),
                          {
                            loading: 'Mise à jour de la formation...',
                            success: 'Formation mise à jour !',
                            error: (updateEducationError) => updateEducationError.message,
                          },
                        )}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                ))}

                {addingEducation && (
                <div className="flex flex-col gap-3 border p-2 rounded mb-5">
                  <Input
                    crossOrigin={undefined}
                    label="Titre"
                    type="text"
                    value={newEducation.title}
                    onChange={(e) => {
                      setNewEducation({
                        ...newEducation,
                        title: e.target.value,
                      });
                    }}
                  />

                  <Input
                    crossOrigin={undefined}
                    label="Description"
                    type="text"
                    value={newEducation.description}
                    onChange={(e) => {
                      setNewEducation({
                        ...newEducation,
                        description: e.target.value,
                      });
                    }}
                  />

                  <Input
                    crossOrigin={undefined}
                    label="Ville"
                    type="text"
                        // @ts-ignore
                    value={newEducation.city}
                    onChange={(e) => {
                      setNewEducation({
                        ...newEducation,
                        // @ts-ignore
                        city: e.target.value,
                      });
                    }}
                  />

                  {/* School */}
                  <Input
                    crossOrigin={undefined}
                    label="École"
                    type="text"
                    value={newEducation.school}
                    onChange={(e) => {
                      setNewEducation({
                        ...newEducation,
                        school: e.target.value,
                      });
                    }}
                  />

                  <div className="flex gap-3">
                    <Input
                      crossOrigin={undefined}
                      label="Date de début"
                      type="date"
                      value={newEducation.start_date}
                      onChange={(e) => {
                        setNewEducation({
                          ...newEducation,
                          start_date: e.target.value,
                        });
                      }}
                    />

                    <Input
                      crossOrigin={undefined}
                      label="Date de fin"
                      type="date"
                      value={newEducation.end_date}
                      onChange={(e) => {
                        setNewEducation({
                          ...newEducation,
                          end_date: e.target.value,
                        });
                      }}
                    />

                  </div>
                  <div className="flex gap-3 justify-between">
                    <Button
                      size="sm"
                      className="bg-error text-white shadow-none"
                      onClick={() => setAddingEducation(false)}
                    >
                      Annuler
                    </Button>

                    <Button
                      size="sm"
                      className="bg-primary text-white shadow-none"
                      onClick={async () => {
                        // @ts-ignore
                        newEducation.profile_id = userToEdit.profile?.id;
                        const { error: educationCreationError } = await Supabase
                          .from('education')
                          .insert(newEducation)
                          .single();

                        if (educationCreationError) {
                          throw new Error('Une erreur est survenue lors de la création de la formation');
                        }

                        // @ts-ignore
                        return mutateUsers().then((result) => {
                          const updatedUser = result?.find((user) => user.id === userToEdit.id);

                          // @ts-ignore
                          setUserToEdit(updatedUser);

                          setAddingEducation(false);
                          return updatedUser;
                        });
                      }}
                    >
                      Ajouter
                    </Button>

                  </div>
                </div>
                )}
              </div>

              <Button
                type="button"
                className="bg-primary mt-auto"
                onClick={() => setAddingEducation(true)}
              >
                Ajouter une formation
              </Button>

              <hr />
              <Typography variant="h6" className="font-semibold text-gray-600 text-sm">
                Documents
              </Typography>

              {
                // @ts-ignore
                  userToEdit?.profile?.role?.code === 'candidate' && (
                  <section className="mb-5">
                    {
                          // @ts-ignore
                          userToEdit?.documents && userToEdit?.documents?.length > 0 ? (
                          // @ts-ignore
                            userToEdit?.documents?.map((document) => (
                              <article
                                key={document.id}
                                className="border p-5 rounded-lg mb-5 flex items-center justify-between"
                              >
                                <Typography variant="h5" className="text-sm">
                                  {document.name}
                                </Typography>
                                <div className="flex gap-2">
                                  {/*  Download button */}
                                  <Button
                                    size="sm"
                                    className="bg-primary text-white shadow-none"
                                    onClick={async () => handleDownloadDocument(
                                      // @ts-ignore
                                      document, userToEdit?.id,
                                    )}
                                  >
                                    Télécharger
                                  </Button>
                                  <Button
                                    size="sm"
                                    className="bg-error text-white shadow-none"
                                    onClick={() => {
                                      // @ts-ignore
                                      setDocumentToDelete(document);
                                      setConfirmDeleteDocumentOpen(true);
                                    }}
                                  >
                                    Supprimer
                                  </Button>
                                </div>
                              </article>
                            ))
                          ) : (
                            <span>
                              Le candidat n&apos;a pas encore envoyé de documents
                            </span>
                          )
                        }
                  </section>
                  )
              }

              <Dropzone
                formats="pdf,jpg,png"
                  // @ts-ignore
                setError={setFilesError}
                files={files}
                  // @ts-ignore
                error={filesError}
                  // @ts-ignore
                setFiles={setFiles}
              />
              {files.length > 0 && (
              <div className="px-5">
                <Button
                  className="bg-primary w-full"
                    // @ts-ignore
                  onClick={async () => handleUploadFiles(userToEdit?.id)}
                >
                  Téléverser ces documents
                </Button>
              </div>
              )}
            </div>
          </fieldset>
        </form>
      </Popover>

      {/* Popover for Viewing users */}
      <Popover
        title="Missions auxquelles l'utilisateur a candidaté"
        open={missionsPopoverOpen}
        setOpen={setMissionsPopoverOpen}
      >
        {userToViewMissions?.length > 0 && userToViewMissions?.map((mission) => (
          <article
              // @ts-ignore
            key={mission.offer.id}
            className="border p-5 rounded-lg mb-5 flex items-center justify-between"
          >
            <div>
              <Typography variant="h5" className="text-sm">
                {/* @ts-ignore */}
                {mission.offer.title}
                {' '}
                (Offre N°
                {/* @ts-ignore */}
                {mission.offer.id}
                )
              </Typography>
              <Typography variant="h6" className="text-xs">
                Établissement
                {' '}
                {/* @ts-ignore */}
                {mission.offer.establishment.name}
              </Typography>
            </div>
            <Typography variant="h6" className="text-xs">
              Retenu.e:
              {' '}
              {/* @ts-ignore */}
              {mission.retained ? 'Oui' : 'Non'}
            </Typography>
          </article>
        ))}
      </Popover>

      {/* Popover for adding user as establishment manager */}
      <Popover
        title={`Ajouter un ${addUserType === 'candidate' ? 'candidat' : 'gestionnaire d\'établissement'}`}
        open={addUserPopoverOpen}
        setOpen={setAddUserPopoverOpen}
      >
        <form className="h-full" onSubmit={addUserType === 'establishment' ? handleAddManagerFormSubmit : handleAddCandidateFormSubmit}>
          <fieldset className="flex flex-col gap-3 h-full" disabled={formDisabled}>
            {addUserType === 'establishment' && (
            <p className="text-xs">
              Lorsque vous ajoutez un gestionnaire d&apos;établissement
              il recevra un email lui permettant de confirmer son compte.
              Il est absolument nécessaire que l&apos;adresse email soit valide.
              Vous ne pouvez pas ajouter de gestionnaire d&apos;établissement
              sans avoir créé l&apos;établissement au préalable.
            </p>
            )}
            <Typography variant="h6" className="font-semibold text-gray-600 text-sm">
              Informations générales
            </Typography>
            <Input
              crossOrigin={undefined}
              label="Nom"
              type="text"
              required
              value={formValues.lastName}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  lastName: e.target.value,
                });
              }}
            />

            <Input
              crossOrigin={undefined}
              label="Prénom"
              type="text"
              value={formValues.firstName}
              required
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  firstName: e.target.value,
                });
              }}
            />

            <Input
              crossOrigin={undefined}
              label="Email"
              type="email"
              required
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  email: e.target.value,
                });
              }}
            />

            <Input
              crossOrigin={undefined}
              label="Téléphone"
              type="text"
              required
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  phone: e.target.value,
                });
              }}
            />

            {addUserType === 'establishment' && (
            <p className="text-base bg-primary/20 p-5 rounded-xl">
              Une fois le gestionnaire ajouté, il recevra un email lui
              permettant de confirmer son compte.
              <br />
              Il est absolument nécessaire que l&apos;adresse email soit valide.
              <br />
              <br />
              Pensez à ajouter le gestionnaire à l&apos;établissement une fois son compte confirmé.
            </p>
            )}

            <Button
              type="submit"
              className="bg-primary mt-auto"
            >
              Ajouter
            </Button>
          </fieldset>
        </form>
      </Popover>

      {/* User deletion confirmation modal */}
      <div className="h-full w-full">
        <ConfirmationModal
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          title="Supprimer l'utilisateur"
          description="Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
          confirmText="Supprimer"
          cancelText="Annuler"
          callBack={() => userDeleteCallback()}
        />

        {/* Header w/ Search */}
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="flex flex-col md:flex-row gap-3 justify-between mb-5">
            <div>
              <div className="mb-8 flex items-center justify-between gap-8">
                <div>
                  <Typography variant="h5" color="blue-gray">
                    Gestion des utilisateurs
                  </Typography>
                  <Typography color="gray" className="mt-1 font-normal">
                    Visualisez les informations de tous les membres
                  </Typography>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                <div className="w-full md:w-72">
                  <Input
                    type="search"
                    crossOrigin={undefined}
                    label="Rechercher"
                    onChange={(e) => searchUser(e.target.value)}
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <Button
                className="bg-primary flex gap-2 items-center justify-between"
                onClick={() => handleAddManagerClick(true)}
              >
                Ajouter un gestionnaire d&apos;établissement
                <UserPlusIcon className="h-5 w-5" />
              </Button>
              <Button
                className="bg-primary flex gap-2 items-center justify-between"
                onClick={() => handleAddCandidateClick(true)}
              >
                Ajouter un candidat
                <UserPlusIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-3">
            {filters.map((filter) => (
              <Button
                placeholder={filter.name}
                key={filter.value}
                className={classNames(
                  'font-medium text-xs',
                  filter.isActive ? 'bg-primary text-white' : 'bg-blue-gray-50 text-text',
                )}
                onClick={(e) => {
                  e.preventDefault();
                  // eslint-disable-next-line function-paren-newline
                  setFilters(
                    // @ts-ignore
                    filters.map((f) => {
                      if (f.value === filter.value) {
                        return {
                          ...f,
                          isActive: true,
                        };
                      }
                      return {
                        ...f,
                        isActive: false,
                      };
                    }));
                }}
              >
                {filter.name}
              </Button>
            ))}
            <div className="ml-auto">
              <Button
                size="sm"
                className="flex gap-2 items-center"
                onClick={() => handleDownloadUserData()}
              >
                Exporter
                <ArrowDownTrayIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>

          {/*  If any filter is active, show a disable filters button */}
          {filters.some((filter) => filter.isActive) && (
          <Button
            placeholder="Désactiver les filtres"
            className="flex items-center justify-center gap-2 font-medium bg-transparent shadow-none hover:shadow-none text-xs text-blue-gray-700 mt-5"
            onClick={(e) => {
              e.preventDefault();
              // eslint-disable-next-line function-paren-newline
              setFilters(
                // @ts-ignore
                filters.map((f) => ({
                  ...f,
                  isActive: false,
                })));
            }}
          >
            Désactiver les filtres
            <XMarkIcon className="h-4 w-4" />
          </Button>
          )}
        </CardHeader>

        {/* Table */}
        <CardBody className="overflow-scroll px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((
                  head, index,
                ) => (
                  <th
                    key={head}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {head}
                      {' '}
                      {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <TableRows
              // @ts-ignore
              users={filteredUsers || users}
              handleViewClick={handleViewClick}
              handleDeleteClick={handleDeleteClick}
              mutate={mutateUsers}
              handleMissionsClick={handleMissionsClick}
              handleEditClick={(
                id: string,
                profile: any,
                experiences: any,
                educations: any,
                documents: any,
              ) => {
                setUserToEdit({
                  // @ts-ignore
                  id,
                  profile,
                  experiences,
                  educations,
                  documents,
                });
                setEditUserPopoverOpen(true);
              }}
            />
          </table>
        </CardBody>
      </div>
    </>
  );
}
