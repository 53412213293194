import Supabase from '@lib/Supabase';

export async function send({
  to,
  subject,
  html,
}: {
  to: string;
  subject: string;
  html: string;
}) {
  if (!to) throw new Error('MISSING "TO" PARAM');
  if (!subject) throw new Error('MISSING "SUBJECT" PARAM');
  if (!html) throw new Error('MISSING "HTML" PARAM');

  const { data, error } = await Supabase
    .functions
    .invoke(
      'resend', {
        method: 'POST',
        body: JSON.stringify({
          from: import.meta.env.VITE_EMAIL_FROM,
          to,
          subject,
          html,
        }),
      },
    );

  if (error) {
    throw new Error(error);
  }

  return data;
}

export default {
  send,
};
