import React, { useContext } from 'react';
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Navbar as BaseNavbar,
  Typography,
} from '@material-tailwind/react';
import {
  Bars3BottomLeftIcon,
  PowerIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

import { useNavigate } from 'react-router-dom';
import { DrawerContext } from '@/context/Drawer';
import { useAuth } from '@/context/Auth';
import { useProfile } from '@/lib/Profile';
import { useTitle } from '@/context/Title';
import FullPageLoader from '@/components/FullPageLoader';
import ErrorCard from '@/components/ErrorCard';
import { FullProfile } from '@/custom-types/Profile';
import NotificationPopOver from '@components/app/NotificationPopOver';

function ProfileMenu(): React.ReactElement {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  //  @ts-ignore
  const { data: profile, error: profileError }: {
    data: FullProfile,
    error: Error
  } = useProfile();

  if (profileError) {
    return (
      <ErrorCard message="Impossible de charger votre profil" />
    );
  }

  if (!profile) {
    return <FullPageLoader />;
  }

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen}>
      <MenuHandler>
        <Button
          variant="text"
          className="flex items-center gap-2 rounded-full p-2 capitalize text-text bg-transparent"
        >
          <div>
            <UserIcon className="h-6 w-6" />
          </div>
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        <div className="flex-col justify-start align-start text-start flex px-5 py-2">
          <Typography as="span" className="text-text font-semibold text-md">
            {profile?.first_name}
            {' '}
            {profile?.last_name}
          </Typography>

          <Typography as="span" className="font-normal text-sm">
            {/* @ts-ignore */}
            {profile?.role?.code === 'admin' && 'Administrateur'}
            {/* @ts-ignore */}
            {profile?.role?.code === 'candidate' && 'Candidat'}
            {/* @ts-ignore */}
            {profile?.role?.code === 'establishment' && 'Etablissement'}
          </Typography>
        </div>
        <hr className="my-2" />

        <MenuItem
          onClick={(): void => {
            logout().then(() => {
              navigate('/auth/sign-in?signedout=true');
            });
          }}
          className="flex items-center gap-2 rounded hover:bg-gray-500/10 focus:bg-gray-500/10 active:bg-gray-500/10"
        >
          <PowerIcon className="h-4 w-4 font-semibold" strokeWidth={2} />
          <Typography as="span" variant="small" className="font-normal">
            Déconnexion
          </Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default function Navbar(): React.ReactElement {
  const [drawerOpen, setDrawerOpen] = useContext(DrawerContext);
  const { title } = useTitle();
  const { data: profile } = useProfile();

  return (
    <BaseNavbar className="mx-auto shadow-none w-full max-w-full px-3">
      <div className="relative justify-between flex items-center text-blue-gray-900">
        <button
          type="button"
          color="blue-gray"
          onClick={(): void => setDrawerOpen(!drawerOpen)}
          className="mr-2 xl:hidden"
        >
          <Bars3BottomLeftIcon className="h-7 w-7" />
        </button>
        <div className="flex items-center gap-3">
          <img src="/images/logo.svg" alt="Migration Logo" className="h-12 w-30 lg:hidden" />
          <h1 className="font-semibold text-gray-600">
            Espace
            {' '}
            {
              // @ts-ignore
                profile?.role?.code === 'establishment' && 'Etablissement'
            }
            {
              // @ts-ignore
                profile?.role?.code === 'admin' && 'Administrateur'
            }
          </h1>
          <h2 className="font-semibold text-gray-600">{title}</h2>
        </div>

        <div className="flex flex-1 mr-3 justify-end">
          <NotificationPopOver />
        </div>
        <ProfileMenu />
      </div>
    </BaseNavbar>
  );
}
