import { getAuth } from '@/context/Auth';
import Supabase from '@lib/Supabase';
import { Profile } from '@/custom-types/Profile';
import useSWR from 'swr';
import supabaseAdmin from '@lib/SupabaseAdmin';

const request = `
  id,
  created_at,
  user_id,
  first_name,
  last_name,
  birth_date,
  phone,
  onboarding_completed,
  bio,
  validated,
  type:profile_type (
    id,
    name
  ),
  experience_years,
  role:role_id (
    id,
    code
  ),
  address:address_id (
    id,
    first_line,
    second_line,
    city,
    zip,
    country
  ),
  current_establishment_type,
  establishment_sector:current_establishment_type (
    id,
    name
  ),
  accepts_terms,
  accepts_missions,
  accepts_cgu
`;

export const createProfile = async (profile: any) => {
  const { data, error } = await Supabase
    .from('profile')
    .insert(profile)
    .select(request);

  if (error) {
    throw new Error(error.message);
  }

  return data[0];
};

/**
 * Get profile data
 */
export const getProfile = async () => {
  const authResult = await getAuth();

  const userID = authResult?.data?.session?.user?.id;

  if (!userID) {
    return null;
  }

  const { data, error } = await Supabase
    .from('profile')
    .select(request)
    .eq(
      'user_id', userID,
    )
    .single();

  const userEmail = authResult?.data?.session?.user?.email;

  if (error && authResult?.data?.session?.user?.id) {
    const { data: profileFound } = await Supabase
      .from('profile')
      .select('user_id')
      .eq(
        'user_id', userID,
      );

    // Ensure that it's not an empty array
    if (profileFound?.length === 0 || !profileFound) {
      return createProfile({
        user_id: userID,
      });
    }
  }

  // @ts-ignore
  data.email = userEmail;

  return data;
};

/**
 * Get real-time profile data
 */
export const useProfile = () => useSWR(
  'profile', getProfile,
);

export const getSimpleProfile = async (profileID: string) => {
  const { data, error } = await Supabase
    .from('profile')
    .select('*')
    .eq(
      'user_id', profileID,
    )
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

/**
 * Set profile data
 * @param profile
 */
export const setProfile = async (profile: Profile) => {
  // @ts-ignore
  const auth = await getAuth();

  if (auth?.data?.session === null) {
    return { data: null, error: new Error('UNAUTHENTICATED') };
  }

  const { data, error } = await Supabase
    .from('profile')
    .update(profile)
    .eq(
      'user_id', auth?.data?.session?.user?.id,
    );

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export default useProfile;

export const updateProfile = async (profile: any) => {
  const userProfile = await getProfile();

  if (!userProfile) {
    throw new Error('No profile found');
  }

  // @ts-ignore
  if (userProfile.id === profile.id) {
    //   Ensure that the user is not trying to update their own role
    // @ts-ignore
    if (profile?.role?.id !== userProfile?.role?.id) {
      throw new Error('You cannot update your own role');
    }
  }

  const { data, error } = await Supabase
    .from('profile')
    .update(profile)
    .eq(
      'id', profile.id,
    );

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const getSingleProfile = async (profileID: number) => {
  const { data, error } = await Supabase
    .from('profile')
    .select(request)
    .eq(
      'id', profileID,
    )
    .single();

  if (error) {
    throw new Error(error.message);
  }

  // Add email to the profile
  const {
    data: { user },
  } = await supabaseAdmin.auth.getUser(data.user_id);

  // @ts-ignore
  data.email = user?.email;

  return data;
};

export const useSingleProfile = (profileID: number) => useSWR(
  ['profile', profileID], () => getSingleProfile(profileID),
);

export const getSingleProfileFromProfileID = async (profileID: number) => {
  const { data, error } = await Supabase
    .from('profile')
    .select(request)
    .eq(
      'id', profileID,
    )
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const getProfileTypes = async () => {
  const { data, error } = await Supabase
    .from('profile_type')
    .select('*');

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const useProfileTypes = () => useSWR(
  'profile_types', getProfileTypes,
);

export const getProfileByUserID = async (userID: string) => {
  const { data, error } = await Supabase
    .from('profile')
    .select(request)
    .eq(
      'user_id', userID,
    )
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const getProfilesByRoleID = async (roleID: number) => {
  const { data, error } = await Supabase
    .from('profile')
    .select(request)
    .eq(
      'role_id', roleID,
    );

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export const useProfilesByRoleID = (roleID: number) => useSWR(
  ['profiles', roleID], () => getProfilesByRoleID(roleID),
);
