import React from 'react';

export type DrawerContextType = [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
];

export const useDrawer = (): DrawerContextType => {
  const [
    drawerOpen,
    setDrawerOpen,
  ] = React.useState(false);

  return [drawerOpen, setDrawerOpen];
};

export const DrawerContext = React.createContext<DrawerContextType>({
  // @ts-ignore
  drawerOpen: false,
  setDrawerOpen: (): void => {
  },
});
