import { Typography } from '@material-tailwind/react';
import React from 'react';

export default function Copyright(): React.ReactElement {
  return (
    <Typography color="gray" className="text-sm text-gray-500 mt-10 text-center pb-20 lg:pb-5">
      ©
      {' '}
      {new Date().getFullYear()}
      {' '}
      Azélie Santé, Tous droits réservés.
    </Typography>
  );
}
