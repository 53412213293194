import { Link, useNavigate } from 'react-router-dom';
import FullPageLoader from '@components/FullPageLoader';
import React from 'react';
import { useAuth } from '@/context/Auth';
import { Button } from '@material-tailwind/react';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';

/**
 * Description.
 * @returns
 *
 */
export default function Verify(): React.ReactElement {
  // @ts-ignore
  const {
    session,
    login,
    verify,
  } = useAuth();
  const navigate = useNavigate();
  const [formValues, setFormValues] = React.useState({
    verificationCode: '',
  });
  const [verifying, setVerifying] = React.useState(false);
  const params = new URLSearchParams(window.location.search);

  const sent = params.get('sent');
  const email = params.get('email');

  if (!sent || sent === 'false') {
    navigate('/auth/sign-in');
    return (
      <FullPageLoader />
    );
  }

  if (!email || email === '') {
    navigate('/auth/sign-in');
    return (
      <FullPageLoader />
    );
  }

  if (session) {
    window.location.href = '/';
    return (
      <FullPageLoader />
    );
  }

  const resendVerificationCode = async () => {
    const { error: authError } = await login(email);

    if (authError) {
      if (authError.status === 429) {
        throw new Error('Trop de tentatives, veuillez réessayer plus tard.');
      }

      throw new Error('Une erreur est survenue, veuillez réessayer plus tard.');
    }

    return true;
  };

  const resendVerificationCodeClick = async () => toast.promise(
    resendVerificationCode(), {
      loading: 'Envoi du code de vérification...',
      success: 'Un nouvel email vous a été envoyé.',
      error: 'Une erreur est survenue, veuillez réessayer plus tard.',
    },
  );
  const verifyAuth = async () => {
    setVerifying(true);
    const { error: authError } = await verify(
      email as string,
      formValues.verificationCode,
    );

    setVerifying(false);

    if (authError) {
      if (authError.status === 429) {
        throw new Error('Trop de tentatives, veuillez réessayer plus tard.');
      }

      if (authError.status === 401) {
        throw new Error('Code de vérification invalide.');
      }

      throw new Error('Une erreur est survenue, veuillez réessayer plus tard.');
    }

    window.location.href = '/';

    return true;
  };

  const handleVerify = async () => toast.promise(
    verifyAuth(), {
      loading: 'Vérification du code de vérification...',
      success: 'Vous êtes maintenant connecté.',
      error: (err) => err.message,
    },
  );

  const handleVerifySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    return handleVerify();
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <section className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary to-secondary opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <img src="/images/logo.svg" alt="Logo" width={160} height={80} className="mx-auto" />
            <p className="text-2xl">C&apos;est dans la boite !</p>
            <h1 className="text-4xl font-bold tracking-tight text-neutral sm:text-6xl">
              Un email vous a été envoyé.
            </h1>
            <p className="mt-6 text-base leading-8 text-neutral/80 mb-10 text-center">
              Bienvenue ! Votre inscription a bien été bien prise en compte.
              <br />
              <br />
              Veuillez consulter votre messagerie pour valider votre adresse email
              et ainsi accéder à une vue d’ensemble de nos fonctionnalités.
              Pensez aussi à bien vérifier que l’email ne soit pas arrivé dans les spams.
              <br />
              <br />
              Attention : pour accéder à la totalité de nos fonctionnalités, vous devez compléter
              votre profil en nous joignant vos documents obligatoires.
              Dès leur validation, vous recevrez un email de confirmation.
              <br />
              <br />

              Nous vous remercions par avance de votre engagement.
              Votre implication favorise la richesse des missions qui vous seront proposées.
            </p>
            <div>
              <div className="flex flex-col space-y-16 items-center justify-center">
                <form
                  onSubmit={handleVerifySubmit}
                >
                  <fieldset disabled={verifying} className="flex flex-col items-center justify-center gap-3 max-w-lg">
                    <OtpInput
                      value={formValues.verificationCode}
                      onChange={async (value) => setFormValues({
                        ...formValues,
                        verificationCode: value,
                      })}
                      numInputs={6}
                      renderSeparator={() => <span>&nbsp;</span>}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      renderInput={(props) => <input {...props} />}
                      containerStyle="flex flex-row items-center justify-center"
                      inputStyle="w-16 h-24 text-center outline-none rounded-xl border border-gray-200 text-2xl font-bold bg-white focus:bg-primary/5 focus:ring-1 ring-primary text-gray-600 lg:pl-4"
                      shouldAutoFocus
                      skipDefaultStyles
                      inputType="number"
                    />
                    <Button
                      className="bg-primary w-full"
                      type="submit"
                    >
                      Confirmer
                    </Button>
                  </fieldset>
                </form>

                <div className="flex flex-col space-y-5">

                  <div
                    className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500"
                  >
                    <p className="text-sm">Vous n&apos;avez pas reçu de code ?</p>
                    {' '}
                    <button
                      type="button"
                      className="flex flex-row items-center text-primary"
                      onClick={resendVerificationCodeClick}
                    >
                      Renvoyer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to="/auth/sign-in"
                className="btn btn-cta w-full"
              >
                <Button
                  className="bg-primary"
                >
                  Retour à la connexion
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary to-secondary opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </section>
    </div>
  );
}
